import styled from 'styled-components';

import { Button as ButtonComponent } from 'antd';

export const Button = styled(ButtonComponent)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 45px;
  width: 33.33%;
  padding: 0 16px;
  align-items: center;
  justify-content: center;

  > span {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #353839;

    > span {
      font-size: 14px;
      line-height: 18px;
      color: #f78e1e;
    }
  }

  &.minimumQuantity {
    span {
      color: #52C41A;
    }
  }

  &.focus {
    background-color: #f78e1e !important;
    border-color: #f78e1e !important;

    span {
      color: #fff;
    }

    &.minimumQuantity {
      background-color: #52C41A !important;
      border-color: #52C41A !important;
      &:hover{
        filter: brightness(90%);
      }
    }
  }

  &.no-border-left {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.no-border-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  
  &:disabled{
    filter: brightness(90%);
    span{
      filter: brightness(90%);
    }
  }

`;

