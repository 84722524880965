import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, FileSyncOutlined, HistoryOutlined, IssuesCloseOutlined, StopOutlined, UserOutlined, WarningFilled } from '@ant-design/icons'
import Partial from 'assets/svgs/Partial.svg';
import * as S from './styles'

export default function TabLegend() {
  return (
    <S.Legend>
      <span>Legenda:</span>
     <S.LegendItems>
     <S.LegendItem>
        <CheckCircleOutlined style={{color: '#52C41A'}}/>
        <span>Confirmado</span>
      </S.LegendItem>
      <S.LegendItem>
        <img src={Partial} alt=""/>
        <span>Confirmado Parcial</span>
      </S.LegendItem>
      <S.LegendItem>
        <IssuesCloseOutlined style={{color: '#125C4F'}}/>
        <span>Confirmado com Alteração</span>
      </S.LegendItem>
      <S.LegendItem>
        <HistoryOutlined style={{color: '#FAAD14'}}/>
        <span>Pendente</span>
      </S.LegendItem>
      <S.LegendItem>
        <FileSyncOutlined style={{color: '#FAAD14'}}/>
        <span>Aguardando geração do Pedido</span>
      </S.LegendItem>
      <S.LegendItem>
        <UserOutlined style={{color: '#FAAD14'}}/>
        <span>Aguardando Produtor</span>
      </S.LegendItem>
      <S.LegendItem>
        <CloseCircleOutlined style={{color: '#FA541C'}} />
        <span>Recusado</span>
      </S.LegendItem>
      <S.LegendItem>
        <StopOutlined style={{color: '#FF0000'}}/>
        <span>Cancelado</span>
      </S.LegendItem>
      <S.LegendItem>
      <WarningFilled style={{color: '#000000'}}/>
        <span>Erro</span>
      </S.LegendItem>
      <S.LegendItem>
        <ClockCircleOutlined style={{color: '1E1E1E'}}/>
        <span>Expirado</span>
      </S.LegendItem>
     </S.LegendItems>
    </S.Legend>
  )
}