import { ApartmentOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Col, Form, Popover } from 'antd';
import Monetary from 'components/UI/Data/Monetary';
import { FastCartIcon } from 'components/UI/Icons';
import { isMobile } from 'react-device-detect';
import Packing from 'model/Packing';
import { useCallback } from 'react';
import { calculateMinBySaleType } from 'utils/util';
import { validatorUtils } from 'utils/validator';
import {
  ProductCardActions,
  PriceOptions,
  GroupTypeSaleProduct,
  AmountInfo,
  MinimumPurchase,
  MinimumPurchaseLabel,
  MinimunAmount,
  AvailableInfo,
  AvailableAmount,
  AvailableInfoLabel,
  AvailablePer,
  AvailablePackage,
  OrderActions,
  CardBottomContainer,
  PackagesDiv,
  ButtonNetwork,
  ButtonsNetworkCol,
  PackagesInput,
  TextPopoverComposition,
  PopoverComposition,
} from '../ProductOfferCard/styles';

import { FastBuyContainer } from './styles';

interface ProductActionsProps {
  packagingPrice: number;
  packingSelected: Packing;
  handleTypeSale: (value: string) => void;
  selectedTypeSale: string;
  packingCost: number;
  layerPrice: number;
  trolleyPrice: number;
  getMaxAvailable: {
    label: string;
    max: number;
    min: number;
  };
  getLabel: () => string;
  textInputTypeSale: string;
  isCompositionEnabled: boolean;
  isProducerAuthenticated: boolean;
  runInMobile?: boolean;
  handleVisibleModalComposition: () => void;
  submit: () => void;
  handleClickFastBuy?: () => void;
  isLkpOffer?: boolean;
}

function ProductActions({
  packagingPrice,
  packingSelected,
  handleTypeSale,
  selectedTypeSale,
  runInMobile,
  packingCost,
  layerPrice,
  trolleyPrice,
  getMaxAvailable,
  isProducerAuthenticated,
  getLabel,
  textInputTypeSale,
  isCompositionEnabled,
  handleVisibleModalComposition,
  submit,
  handleClickFastBuy,
  isLkpOffer,
}: ProductActionsProps) {
  const getErrorMinMessage = useCallback(() => {
    if (selectedTypeSale === 'Packing') {
      return `Quantidade mínima:${packingSelected?.minimumQuantity /
        +packingSelected?.maxAvailablePackingsLabel
        } ${textInputTypeSale}`;
    }
    const values = packingSelected?.maxAvailableLayersLabel
      .split('x')
      .map((x) => +x);

    if (selectedTypeSale === 'Layer') {
      const calcLayer =
        packingSelected?.minimumQuantity / values.reduce((a, b) => +a * +b);
      return `Quantidade mínima: ${calcLayer < 1 ? 1 : calcLayer
        } ${textInputTypeSale}`;
    }

    const calcTrolley =
      packingSelected?.minimumQuantity / values.reduce((a, b) => +a * +b);
    return `Quantidade mínima: ${calcTrolley < 1 ? 1 : calcTrolley
      } ${textInputTypeSale}`;
  }, [
    selectedTypeSale,
    packingSelected?.minimumQuantity,
    packingSelected.maxAvailableTrolleysLabel,
    packingSelected.maxAvailableLayersLabel,
    packingSelected.maxAvailablePackingsLabel,
  ]);

  const getMultipleCalc = useCallback(() => {
    if (selectedTypeSale === 'Packing') {
      return (
        packingSelected?.multiple / +packingSelected?.maxAvailablePackingsLabel
      );
    }
    if (selectedTypeSale === 'Layer') {
      const values = packingSelected?.maxAvailableLayersLabel
        .split('x')
        .map((x) => +x);
      const calc = packingSelected?.multiple / values.reduce((a, b) => +a * +b);
      return calc < 1 ? 1 : calc;
    }
    const values = packingSelected?.maxAvailableTrolleysLabel
      .split('x')
      .map((x) => +x);
    const calc = packingSelected?.multiple / values.reduce((a, b) => +a * +b);
    return calc < 1 ? 1 : calc;
  }, [
    selectedTypeSale,
    packingSelected?.multiple,
    packingSelected.maxAvailableTrolleysLabel,
    packingSelected.maxAvailableLayersLabel,
    packingSelected.maxAvailablePackingsLabel,
  ]);

  return (
    <ProductCardActions className="product-card-actions">
      <PriceOptions>
        <GroupTypeSaleProduct className="group-type-sale-product">
          <Button
            disabled={
              !packagingPrice || !packingSelected?.maxAvailablePackingsQuantity
            }
            onClick={() => handleTypeSale('Packing')}
            className={`button-select button-type-sale no-border-right ${selectedTypeSale === 'Packing' ? 'focus' : ''
              }`}
          >
            Embalagem{' '}
            <span className="value">
              <Monetary
                symbol
                value={
                  packagingPrice > 0 && packingSelected?.additionalCost
                    ? packagingPrice + packingCost
                    : packagingPrice
                }
              />
            </span>
          </Button>
          <Button
            disabled={
              !layerPrice || !packingSelected?.maxAvailableLayersQuantity
            }
            onClick={() => handleTypeSale('Layer')}
            className={`button-select button-type-sale no-border-right no-border-left ${selectedTypeSale === 'Layer' ? 'focus' : ''
              }`}
          >
            Camada{' '}
            <span className="value">
              <Monetary
                symbol
                value={
                  layerPrice > 0 && packingSelected?.additionalCost
                    ? layerPrice + packingCost
                    : layerPrice
                }
              />
            </span>
          </Button>
          <Button
            disabled={
              !trolleyPrice || !packingSelected?.maxAvailableTrolleysQuantity
            }
            onClick={() => handleTypeSale('Trolley')}
            className={`button-select button-type-sale no-border-left ${selectedTypeSale === 'Trolley' ? 'focus' : ''
              }`}
          >
            Carrinho{' '}
            <span className="value">
              <Monetary
                symbol
                value={
                  trolleyPrice > 0 && packingSelected?.additionalCost
                    ? trolleyPrice + packingCost
                    : trolleyPrice
                }
              />
            </span>
          </Button>
        </GroupTypeSaleProduct>
        <AmountInfo
          justify={runInMobile && !isMobile ? 'center' : 'space-between'}
          className="row-info"
        >
          <Col>
            <MinimumPurchase>
              <MinimumPurchaseLabel>Compra mínima:</MinimumPurchaseLabel>
              <MinimunAmount>
                {calculateMinBySaleType(selectedTypeSale, packingSelected)}{' '}
                {textInputTypeSale}
              </MinimunAmount>
            </MinimumPurchase>
          </Col>
          <Col>
            <AvailableInfo>
              <AvailableAmount>{getMaxAvailable.max}</AvailableAmount>
              <AvailableInfoLabel>disponíveis</AvailableInfoLabel>
            </AvailableInfo>
            <AvailablePer>
              <AvailablePackage>{getMaxAvailable.label}</AvailablePackage>
            </AvailablePer>
          </Col>
        </AmountInfo>
      </PriceOptions>
      <OrderActions className="order-actions">
        <CardBottomContainer
          className={runInMobile && !isMobile ? 'display-center' : ''}
        >
          <PackagesDiv>
            <Form.Item
              style={{ marginBottom: '0px !important' }}
              name="quantityPacking"
              rules={[
                {
                  required: true,
                  message: 'A quantidade é obrigatória.',
                },
                {
                  validator: (_, value) =>
                    !value || Number(value) <= getMaxAvailable.max
                      ? Promise.resolve()
                      : Promise.reject(
                        new Error(
                          `A quantidade máxima é ${getMaxAvailable.max} ${textInputTypeSale}`,
                        ),
                      ),
                },
                {
                  validator: (_, value) =>
                    !value ||
                      Number(value) * getMaxAvailable.min >=
                      packingSelected?.minimumQuantity
                      ? Promise.resolve()
                      : Promise.reject(new Error(getErrorMinMessage())),
                },
                {
                  validator: (_, value) =>
                    validatorUtils.validMultiple(
                      value,
                      packingSelected?.multiple ?? 0,
                      selectedTypeSale,
                      getLabel(),
                      getMultipleCalc(),
                      textInputTypeSale,
                    ),
                },
              ]}
            >
              <PackagesInput
                autoComplete="off"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                style={{ width: '65px' }}
                addonAfter={textInputTypeSale}
                type="tel"
              />
            </Form.Item>
          </PackagesDiv>
          <ButtonsNetworkCol>
            {!isProducerAuthenticated && (
              <Popover
                content={
                  <div style={{ maxWidth: '180px' }}>
                    <span>
                      {getMaxAvailable.max < calculateMinBySaleType(selectedTypeSale, packingSelected) ? 'Utilize a composição' : 'Adicionar ao carrinho'}
                    </span>
                  </div>
                }
              >
              <ButtonNetwork
                disabled={getMaxAvailable.max < calculateMinBySaleType(selectedTypeSale, packingSelected)}
                onClick={() => submit()}
                type="primary"
                icon={<ShoppingCartOutlined />}
              />
              </Popover>
            )}

            {isCompositionEnabled && (
              <PopoverComposition
                content={
                  <div style={{ maxWidth: '180px' }}>
                    <TextPopoverComposition>
                      Utilize produtos diferentes para preencher uma mesma
                      camada.
                    </TextPopoverComposition>
                  </div>
                }
                title="Criar composição"
              >
                <ButtonNetwork
                  type="primary"
                  icon={<ApartmentOutlined />}
                  onClick={() => handleVisibleModalComposition()}
                />
              </PopoverComposition>
            )}
          </ButtonsNetworkCol>
        </CardBottomContainer>

        {
          !isProducerAuthenticated && (
            <FastBuyContainer>
              {isLkpOffer && (
                <ButtonNetwork
                  type="default"
                  icon={<FastCartIcon />}
                  onClick={() => (handleClickFastBuy ? handleClickFastBuy() : {})}
                >
                  Comprar agora
                </ButtonNetwork>
              )}
            </FastBuyContainer>
          )}
      </OrderActions>
    </ProductCardActions>
  );
}

export default ProductActions;
