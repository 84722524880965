import { useSelector } from 'react-redux';
import * as BuyIntentionSelectors from 'store/state/buyIntention/buyIntenteionSelectors';
import AddBuyIntentionTable from '../List/AddBuyIntentionTable';

export default function Step01AssembleOrder() {
  const tableData = useSelector(BuyIntentionSelectors.getBuyIntentionEdit);
  return (
    <div key="0">
      <AddBuyIntentionTable tableData={tableData} />
    </div>
  );
}
