import styled from "styled-components";

export const Legend = styled.div`
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.593rem;

  & > span {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #353839;
  }

`

export const LegendItems = styled.div`
  display: flex;
  align-items: center;
  gap: 0.593rem;
`

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  svg {
    font-size: 1rem;
}
`

export const LegendForMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
  margin-top: 7px;

  svg {
    font-size: 1rem;
}
`

export const LegendForMobileContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const LegendItemsForMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.593rem;
`

export const LegendItemForMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 5px;

  svg {
    font-size: 1rem;
}
`