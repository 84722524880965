import styled from 'styled-components';

export const packingName = styled.p`
  text-align: start !important;
  max-width: 13ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const productName = styled.p`
  text-align: start !important;
  max-width: 25ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
`;

export const shipingFeeContent = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const customerContent = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const shipingFeeItem = styled.div`
  margin: 10;
`;
