import { Col, Form, Row } from 'antd';
import styled from 'styled-components';
import { breakAt, BreakpointSizes } from 'styles/Breakpoints';

export const Container = styled(Form).attrs({
  className: 'Container'
})`
  background: #fff;
  box-shadow: 0px 2px 16px rgba(38, 38, 38, 0.2);
  border: 0px solid #cccccc;
  border-radius: 0 0 8px 8px;
  position: relative;
  padding: 8px;

  ${breakAt(BreakpointSizes.md)} {
    padding: 16px;
  }
`;

export const Content = styled(Row).attrs({
  className: 'Content'
})`
  justify-content: center;
  align-items: center;
  height: 100%;

  ${breakAt(BreakpointSizes.md)} {
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const Gallery = styled.div.attrs({
  className: 'Gallery'
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  > div {
    min-height: 614px;
  }
`;

export const ProductCardComponentImage = styled(Row)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  span {
    white-space: nowrap;
  }
`;

export const ImagesListBackground = styled.div`
  max-height: 320px;
  height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  
  &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background: #F5F5F5;
  }

  &::-webkit-scrollbar-track {
      background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
  }
`;

export const ImagesList = styled.div.attrs({
  className: 'ImagesList'
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  div{
    cursor: pointer;
  }
`;

export const ImagesDisplay = styled.div.attrs({
  className: 'ImagesDisplay'
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export const ProductCardComponentDetails = styled(Col)`
  width: 100%;

  .header {
    margin-bottom: 8px;
    > .badge {
      > span {
        display: flex;
        justify-content: flex-start;
        > div {
          margin-left: 0;
        }
      }
    }
    > .tag {
      display: flex;
      justify-content: center;
    }
    > .share {
      display: flex;
      justify-content: flex-end;
    }
  }

  .col-details-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 200px;

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: #353839;

      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
      }
    }
  }
`;

export const ProductCardComponentActions = styled(Row)`
  display: flex;
  padding: 8px 0;

  ${breakAt(BreakpointSizes.md)} {
    padding: 16px 0;
  }

  .line-actions {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    gap: 8px;

    .value-product {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .line-actions-quantity {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding-left: 2px;

    .button-composition {
      align-self: center;
      width: 70%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .line-actions-input {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;

    input {
      /* width: 48px; */
    }
    label {
      font-size: 9px;
    }
    span {
      font-size: 11px;
    }
    > span {
      padding-top: 8px;
      min-width: 98px;
    }
    .ant-row {
      margin-bottom: 0;
    }
    /* @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    } */
  }


  @media (max-width: 992px) {
    .line-actions {
      justify-content: center;
    }
  }
`;
export const ButtonsContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  button {
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
  .buy-now {
    border: 0;
    color: #fff;
    background: linear-gradient(
      0deg,
      rgba(0, 91, 167, 1) 0%,
      rgba(39, 65, 116, 1) 100%
    );
    &:hover,
    &:active {
      color: #fff;
      background: linear-gradient(
        0deg,
        rgba(0, 91, 167, 1) 0%,
        rgba(39, 65, 116, 1) 100%
      );
    }
  }
  .button-cart {
    background: #fff;
    border: 1px solid #f78e1e;
    color: #f78e1e;
  }
`;
export const ComponentInfoNew = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -11px;
  right: -8px;
  background-color: #353839;
  color: #fff;
  border-radius: 16px;
  height: 22px;
  width: 50px;
`;

export const ComponentInfoDirected = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
  z-index: 99;
  padding: 2px;
  line-height: 18px;
  text-align: center;
  background-color: #52C41A;
  color: #fff;
  border-radius: 4px;
  padding: 0 8px;
  width: 180px;

  span.valeu {
    font-weight: 500;
    font-size: 14px;
  }
`;

export const ComponentInfoVoucher = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
  z-index: 99;
  padding: 2px;
  line-height: 18px;
  text-align: center;
  background-color: #f78e1e;
  color: #fff;
  border-radius: 4px;
  padding: 0 8px;

  span.valeu {
    font-weight: 500;
    font-size: 14px;
  }
`;

export const InformationVoucher = styled.p`
  margin-bottom: 0;
  font-size: 11px;
  color: #f78e1e !important;
  padding: 0px 0px 16px 0px;
`;

export const InformationProduct = styled(Row)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 40px;
  margin-left: 8px;

  .anticon{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .group-information {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .ant-select .ant-select-selector {
      z-index: 2;
      padding: 0 10px 0 0px !important;
    }

    .title-information {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: #8c8c8c;
    }
    span {
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #353839;
    }
  }
  .icon-information {
    color: #f78e1e;
  }
`;
export const ProductNameType = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  /* .remove-display-calendar {
    > span > span {
      display: none;
    }
  } */

  .name-product {
    /* display: flex;
    justify-content: flex-start;
    margin: 0 16px; */
    display: flex;
    flex-direction: column;
    gap: 8px;

    > span {
      justify-content: flex-start;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 100%;
      color: #353839;

      > div {
        margin: 0;
      }
    }

    .code-product {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: #8c8c8c;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .number-product {
        margin-right: 16px;
      }
    }

    .producer-name {
      display: flex;
      flex-direction: row;
      gap: 8px;
      > p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #8c8c8c;
        > span {
          color: #f78e1e;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
`;

export const ValueProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;

  .value-per-unity {
    margin-top: 4px;
  }

  span {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #8c8c8c;
  }

  span.value {
    font-weight: 700;
    font-size: 21px;
    line-height: 21px;
  }

  span.currency,
  span.value {
    color: #f78e1e;
  }
`;

export const GroupTypeSaleProduct = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
`;

export const MinQuantityProduct = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #353839;

  > span {
    color: #8c8c8c;
  }
`;

export const AvailableQuantity = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #353839;

  > span {
    color: #8c8c8c;
    margin-left: 4px;
  }
`;

export const WarningLabel = styled.p`
  padding: 0 8px;
`;

export const ShareAndClosedContainer = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ClosedContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
`;