import { useState } from 'react';
import { useSelector } from 'react-redux';

import { FilterOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider } from 'antd';

import { useGetQrCodeHistoryQuery } from 'services/ecommerceApi';
import { ComponentViewQrCode } from 'enums/componentViewQrCode';
import { QrCodeHistoryParam } from 'model/types';
import { sessionSelectors } from 'store/state/selectors';
import moment from 'moment';
import Filters from './Filter';
import Legend from './Legend';
import ProductHistory from './ProductHistory';
import { Body, Footer, Header, Root, CollapseComponent } from './styles';

const { Panel } = Collapse;

type props = {
  setComponentView: (value: ComponentViewQrCode) => void;
};

export default function History({ setComponentView }: props) {
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<QrCodeHistoryParam>({
    productId: 0,
    siteId: 0,
  });

  const { data: history } = useGetQrCodeHistoryQuery(
    {
      productId: filters.productId,
      date: filters.date,
      siteId: filters.siteId,
      customerId,
    },
    { skip: !customerId },
  );

  return (
    <Root>
      <Header>
        <Button
          type={showFilter ? 'primary' : 'default'}
          icon={<FilterOutlined />}
          onClick={() => setShowFilter(!showFilter)}
        />
      </Header>
      <Divider />
      <Body>
        {showFilter ? <Filters setFilters={setFilters} /> : <Legend />}

        {history &&
          history?.map((item) => (
            <CollapseComponent key={item?.saleDate}>
              <Panel
                header={`${moment(item?.saleDate).format('dddd')}, ${moment(
                  item?.saleDate,
                ).format('LL')}`}
                key="1"
              >
                {item?.sales &&
                  item?.sales.map((sale, index) => (
                    <>
                      <ProductHistory
                        sale={sale}
                        key={`product-${item?.saleDate}-${index * 1}`}
                      />
                      <Divider key={`divider-${item?.saleDate}-${index * 1}`} />
                    </>
                  ))}
              </Panel>
            </CollapseComponent>
          ))}
      </Body>
      <Footer>
        <Button onClick={() => setComponentView(ComponentViewQrCode.HOME)}>
          Voltar
        </Button>
      </Footer>
    </Root>
  );
}
