import styled from 'styled-components';

export const Container = styled.p`
  margin-top: 5px;
  font-size: 11px;
  color: #f78e1e !important;
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  padding: 0px 0px 16px 0px;
`;
