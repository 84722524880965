import { createSelector } from '@reduxjs/toolkit';
import { buyIntentionState } from './buyIntentionSlice';

export const getBuyIntention = (state: { buyIntention: buyIntentionState }) =>
  state.buyIntention as buyIntentionState;

export const getShowIntentionModal = createSelector(
  [getBuyIntention],
  (state) => state.showIntentionModal,
);

export const getCurrentStep = createSelector(
  [getBuyIntention],
  (state) => state.currentStep,
);

export const getPagination = createSelector(
  getBuyIntention,
  (state) => state.pagination,
);

export const getReloadPage = createSelector(
  getBuyIntention,
  (state) => state.reloadPage,
);

export const getCustomer = createSelector(
  getBuyIntention,
  (state) => state.customer,
);

export const getBuyIntentionEdit = createSelector(
  getBuyIntention,
  (state) => state.buyIntentionEdit,
);

export const getOffersList = createSelector(
  getBuyIntention,
  (state) => state.offersList,
);

export const getSubTotalList = createSelector(
  getBuyIntention,
  (state) => state.subTotalPriceList,
);

export const getSubmitOffers = createSelector(
  getBuyIntention,
  (state) => state.submitOffers,
);

export const getIdsProductSelected = createSelector(
  getBuyIntention,
  (state) => state.idsProductsSelected,
);

export const getIdsProductAccepted = createSelector(
  getBuyIntention,
  (state) => state.idsProductsAccepted,
);

export const getDeliveryDate = createSelector(
  getBuyIntention,
  (state) => state.deliveryDateForOffers,
);

export const getComercialDate = createSelector(
  getBuyIntention,
  (state) => state.comercialDateForOffers,
);

export const getDeliveryDateFinish = createSelector(
  getBuyIntention,
  (state) => state.deliveryDateForFinish,
);

export const getComercialDateFinish = createSelector(
  getBuyIntention,
  (state) => state.comercialDateForFinish,
);

export const getIdSelectedNow = createSelector(
  getBuyIntention,
  (state) => state.idSelectedNow,
);

export const getIsLoading = createSelector(
  getBuyIntention,
  (state) => state.isLoading,
);

export const getShowModalOffers = createSelector(
  getBuyIntention,
  (state) => state.showModalOffers,
);

export const getOverviewEdit = createSelector(
  getBuyIntention,
  (state) => state.overviewEdit,
);

export const getIdProductAdded = createSelector(
  getBuyIntention,
  (state) => state.idProductAdded,
);

export const getTotalPriceOffers = createSelector(
  getBuyIntention,
  (state) => state.totalPriceOffers,
);

export const getTotalPriceOffersOverview = createSelector(
  getBuyIntention,
  (state) => state.totalPriceOffersOverview,
);

export const getProducers = createSelector(
  getBuyIntention,
  (state) => state.producers,
);

export const getReloadOffers = createSelector(
  getBuyIntention,
  (state) => state.reloadOffers,
);

export const getSubPriceAdded = createSelector(
  getBuyIntention,
  (state) => state.subPriceAdded,
);
