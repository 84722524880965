import styled from 'styled-components';

import { Col } from 'antd';

interface STYLEDPROPS {
  pinned?: boolean;
}

export const ProductCardListComponent = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  width: 100%;
  padding: 8px 16px;
  background: #fff;
  margin-bottom: 8px;
`;

export const ComponentInfoNew = styled.div`
  position: absolute;
  top: -11px;
  right: -8px;
`;

export const ComponentInfoDirected = styled.span`
  position: absolute;
  top: 0px;
`;

export const ComponentInfoVoucher = styled.span`
  position: absolute;
  top: 0px;
`;

export const ProductCardComponentDetails = styled(Col)`
  .icon-information {
    color: #f78e1e;
  }
  padding: 12px 32px 12px 32px;

  .row-details {
    margin-top: 4px;
    height: 100%;
  }

  .col-details-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 100px;

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: #353839;

      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
      }

      .producer-name {
        color: #f78e1e;
      }
    }
  }

  @media (max-width: 992px) {
    margin-bottom: 60px;
  }
`;

export const ProductNameType = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .name-product {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 16px;

    > span {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #353839;
    }

    .code-product {
      font-weight: 400;
      padding-top: 6px;
      font-size: 11px;
      line-height: 9px;
      color: #8c8c8c;
      display: flex;
      flex-direction: row;

      .number-product {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    .name-product {
      padding: 12px 0;
    }
  }
`;

export const InformationVoucher = styled.p`
  margin-bottom: 0;
  font-size: 11px;
  color: #f78e1e !important;
  padding: 0px 0px 16px 0px;
`;

export const ContentColDetails = styled(Col)`
  margin-right: 20px;
`;

export const InformationProduct = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  margin-top: 12px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .group-information {
    margin-left: 16px;

    .ant-select .ant-select-selector {
      z-index: 2;
      padding: 0 10px 0 0px !important;
    }

    .title-information {
      margin: 0 0 3px;
      padding: 0;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: #353839;
    }
    &:not(.no-value) {
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        color: #353839;
      }
    }
  }
`;

export const Actions = styled.div<STYLEDPROPS>`
  margin-left: ${(props) => (props.pinned ? 8 : 14)}%;

  @media (min-width: 1920px) {
    margin-left: ${(props) => (props.pinned ? 18 : 22)}%;
  }
`;

export const ProductCardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 150px;
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

export const InformationGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BadgeContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 16px;

  > div:first-child {
    max-width: 110px;
  }

  > div:last-child {
    width: 117px;
  }
`;

export const ColDetails = styled(Col)`
  position: absolute;
  margin-left: 70%;
`;
