import { Menu } from 'antd';
import { useMyOrderIntentionsContext } from 'contexts/MyOrderIntentionsContext';
import { OrderIntentionOriginEnum } from 'enums/orderIntentionOrigin.enum';
import { OrderIntentionStatusEnum } from 'enums/orderIntentionStatus.enum';
import { OrderIntention } from 'model/OrderIntention';
import { useDispatch } from 'react-redux';
import { actions as MyOrderIntentionEditActions } from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSlice';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function TableTopDropdown() {
  const dispatch = useDispatch();
  const {
    setShowDetailsModal,
    selectedOrderIntentionsKeys,
    selectedOrderIntentions,
  } = useMyOrderIntentionsContext();
  const { t } = useTranslation();

  const validatorICDeliveryDate = (items: OrderIntention[]) => {
    let irregularDates = 0;
    items.forEach((item) => {
      if (moment(item.deliveryDate).isBefore(moment().add(-1, 'day'))) {
        irregularDates += 1;
      }
    });
    return irregularDates === 0;
  };

  return (
    <Menu>
      {selectedOrderIntentions.length &&
      selectedOrderIntentions[0].origin ===
        OrderIntentionOriginEnum.DIRECT_SALE &&
      selectedOrderIntentions[0].status ===
        OrderIntentionStatusEnum.PENDENTE ? (
        <Menu.Item
          onClick={() => {
            dispatch(MyOrderIntentionEditActions.setShowModalAprove(true));
            dispatch(
              MyOrderIntentionEditActions.setIdsBulkAprove(
                selectedOrderIntentionsKeys,
              ),
            );
          }}
        >
          Aprovar
        </Menu.Item>
      ) : null}
      {selectedOrderIntentions.length &&
      selectedOrderIntentions[0].origin ===
        OrderIntentionOriginEnum.ORDER_INTENTION &&
      selectedOrderIntentions[0].status ===
        OrderIntentionStatusEnum.EXPIRADO ? (
        <Menu.Item
          onClick={() => {
            if (validatorICDeliveryDate(selectedOrderIntentions)) {
              dispatch(MyOrderIntentionEditActions.setShowModalRepublish(true));
              dispatch(
                MyOrderIntentionEditActions.setIdRepublish(
                  selectedOrderIntentionsKeys,
                ),
              );
            } else {
              dispatch(
                MyOrderIntentionEditActions.setShowModalNoRepublish(true),
              );
            }
          }}
        >
          Republicar
        </Menu.Item>
      ) : null}
      {selectedOrderIntentions.length &&
      selectedOrderIntentions[0].origin ===
        OrderIntentionOriginEnum.ORDER_INTENTION &&
      selectedOrderIntentions[0].status ===
        OrderIntentionStatusEnum.AGUARDANDO_PRODUTOR ? (
        <Menu.Item
          onClick={() => {
            dispatch(MyOrderIntentionEditActions.setShowModalCancel(true));
            dispatch(
              MyOrderIntentionEditActions.setIdCancel(
                selectedOrderIntentionsKeys,
              ),
            );
          }}
        >
          Cancelar IC
        </Menu.Item>
      ) : null}
    </Menu>
  );
}
