import { memo } from 'react';

import { Tooltip } from 'antd';

import { ProductName, Size } from './styles';

interface Props {
  longName: string;
  size?: Size;
}

function InfoProductName({ longName, size = 'medium' }: Props) {
  return (
    <Tooltip title={longName}>
      <ProductName size={size}>{longName}</ProductName>
    </Tooltip>
  );
}

export default memo(InfoProductName);
