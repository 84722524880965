import styled from 'styled-components';

export const siteNameContent = styled.span`
  display: flex;
  flex-direction: row;
`;

export const siteName = styled.p`
  text-align: start !important;
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 5px
`;
