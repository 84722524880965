import styled, { css } from 'styled-components';
import { Alert } from 'antd';

interface ContainerProps {
  isClosing: number;
}

export const Container = styled.div<ContainerProps>`
  width: 80%;
  max-width: 300px;
  margin: 0;
  cursor: pointer;
  pointer-events: all;
  transition: all 0.7s ease-in-out;
  visibility: visible;
  opacity: 1;

  ${props => props.isClosing && css`
    visibility: hidden;
    opacity: 0;
  `}
`;

export const Message = styled(Alert)``;
