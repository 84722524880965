import React from 'react';
import {
  BgColorsOutlined,
  ExpandOutlined,
  GiftOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import Photo from 'assets/default/image.png';
import { Row, Tooltip } from 'antd';
import Monetary from 'components/UI/Data/Monetary';
import ShippingFee from 'components/UI/Data/ShippingFee';
import { roundDecimals } from 'utils/util';
import CartItem from '../../../../model/CartItem';
import {
  ProductCardCart,
  Frame812,
  ProductCard,
  ProductInfo,
  InfoLeft,
  ProductCardHeader,
  MainInfo,
  ProductName,
  ProductCodes,
  ProductCode,
  BarCode,
  MainInfoSpacing,
  ProductDetail,
  ProductQuality,
  QualityIcon,
  QualityInfo,
  QualityInfoLabel,
  QualityInfoValue,
  QualityInfoSpacing,
  ProductColor,
  ColorIcon,
  ColorInfo,
  ColorInfoLabel,
  ColorInfoValue,
  ColorInfoSpacing,
  ProductPackage,
  PackageIcon,
  PackageInfo,
  PackageInfoLabel,
  PackageInfoValue,
  PackageInfoSpacing,
  ProductSize,
  SizeIcon,
  SizeInfo,
  SizeInfoLabel,
  SizeInfoValue,
  SizeInfoSpacing,
  InfoLeftSpacing,
  InfoCenter,
  ProducedBy,
  ProducedByLabel,
  ButtonText,
  ProductNote,
  ProductDeliveryLabel,
  ProductDeliveryValue,
  ProductDelivery,
  InfoCenterSpacing,
  Frame811,
  InfoRight,
  UnitInfo,
  UnitInfoValue,
  UnitInfoLabel,
  TotalInfo,
  TotalInfoLabel,
  TotalInfoValue,
  PriceInfo,
  UnitPrice,
  UnitPriceLabel,
  UnitPriceInfoLabel,
  UnitPriceInfoValue,
  TotalPrice,
  TotalPriceLabel,
  TotalPriceInfoLabel,
  TotalPriceInfoValue,
  Frame812Spacing,
  ProductPhoto,
  ProductPhotoCol,
  ProductCardCol,
  GroupLineItemCart,
  PackingPrice,
  PackingPriceInfoLabel,
  PackingPriceInfoValue,
  PackingPriceLabel,
} from './styles';

type PropsProducerProductCardCartInComposition = {
  expired?: boolean;
  cartItem: CartItem;
};

function ProducerProductCardCartInComposition({
  expired,
  cartItem,
}: PropsProducerProductCardCartInComposition) {
  const consolidatedValue = cartItem.unitPrice + (cartItem.shippingFee ?? 0) + (cartItem.packingPrice ?? 0);
  return (
    <GroupLineItemCart>
      <ProductCardCart className={`${expired ? 'expired' : ''}`}>
        <Frame812>  
            <ProductCard>
              <ProductPhotoCol className="imagem-teste">
                <ProductPhoto
                  preview={false}
                  src={cartItem?.images.length ? cartItem?.images[0] : Photo}
                />
              </ProductPhotoCol>
              <ProductCardCol xs={24} sm={24} md={22} lg={22} xl={22}>
                <ProductInfo>
                  <InfoLeft>
                      <ProductCardHeader>
                        <MainInfo>
                              <ProductName
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {cartItem.description}
                              </ProductName>
                            <ProductCodes>
                              <ProductCode
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {cartItem.barcode}
                              </ProductCode>
                              <BarCode
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {cartItem.gtincode}
                              </BarCode>
                            </ProductCodes>
                        </MainInfo>
                      </ProductCardHeader>
                      <ProductDetail>
                        <ProductQuality>
                          <QualityIcon
                            className={`${expired ? 'expired' : ''}`}
                          >
                            <TrophyOutlined />
                          </QualityIcon>
                          <QualityInfo>
                            <QualityInfoSpacing>
                              <QualityInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                Qualidade:
                              </QualityInfoLabel>
                              <QualityInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {cartItem.quality}
                              </QualityInfoValue>
                            </QualityInfoSpacing>
                          </QualityInfo>
                        </ProductQuality>
                        <ProductColor>
                          <ColorIcon className={`${expired ? 'expired' : ''}`}>
                            <BgColorsOutlined />
                          </ColorIcon>
                          <ColorInfo>
                            <ColorInfoSpacing>
                              <ColorInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                Cores:
                              </ColorInfoLabel>
                              <ColorInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {cartItem.color}
                              </ColorInfoValue>
                            </ColorInfoSpacing>
                          </ColorInfo>
                        </ProductColor>
                        <ProductSize>
                          <SizeIcon className={`${expired ? 'expired' : ''}`}>
                            <ExpandOutlined />
                          </SizeIcon>
                          <SizeInfo>
                            <SizeInfoSpacing>
                              <SizeInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                Dimensão/Altura:
                              </SizeInfoLabel>
                              <SizeInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {cartItem.dimension}
                              </SizeInfoValue>
                            </SizeInfoSpacing>
                          </SizeInfo>
                        </ProductSize>
                        <ProductPackage>
                          <PackageIcon
                            className={`${expired ? 'expired' : ''}`}
                          >
                            <GiftOutlined />
                          </PackageIcon>
                          <PackageInfo>
                            <PackageInfoSpacing>
                              <PackageInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                Embalagem:
                              </PackageInfoLabel>
                              <PackageInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {cartItem.packingName}
                              </PackageInfoValue>
                            </PackageInfoSpacing>
                          </PackageInfo>
                        </ProductPackage>
                      </ProductDetail>
                  </InfoLeft>

                  <InfoCenter>
                    <InfoCenterSpacing>
                      <ProducedBy>
                        <ProducedByLabel
                          className={`${expired ? 'expired' : ''}`}
                        >
                          <ButtonText className={`${expired ? 'expired' : ''}`}>
                            {cartItem.siteName}
                          </ButtonText>
                        </ProducedByLabel>
                      </ProducedBy>

                      <ProductNote>
                        <ProductDeliveryLabel
                          className={`${expired ? 'expired' : ''}`}
                        >
                          Observação:
                          <ProductDeliveryValue
                            className={`${expired ? 'expired' : ''}`}
                          >
                             {cartItem?.observation?.length > 40 ? (
                              <Tooltip title={cartItem.observation}>
                                {cartItem?.observation?.length > 40
                                  ? `${cartItem.observation.substring(
                                      0,
                                      40,
                                    )}...`
                                  : cartItem.observation}
                              </Tooltip>
                            ) : (
                              cartItem?.observation
                            )}
                          </ProductDeliveryValue>
                        </ProductDeliveryLabel>
                      </ProductNote>

                      <ProductDelivery>
                        <ProductDeliveryLabel
                          className={`${expired ? 'expired' : ''}`}
                        >
                          Previsão de entrega na CVH:
                          <ProductDeliveryValue
                            className={`${expired ? 'expired' : ''}`}
                          >
                            {cartItem.deliveryDayLabel}
                          </ProductDeliveryValue>
                        </ProductDeliveryLabel>
                      </ProductDelivery>
                    </InfoCenterSpacing>
                  </InfoCenter>

                  <Frame811>
                    <InfoRight>
                      <PriceInfo>
                        <Row>
                          <UnitInfo>
                            <UnitInfoValue
                              className={`${expired ? 'expired' : ''}`}
                            >
                              {cartItem.packingQuantity}
                              <UnitInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {' Embalagens'}
                              </UnitInfoLabel>
                            </UnitInfoValue>
                          </UnitInfo>

                          <UnitPrice>
                            <UnitPriceLabel
                              className={`${expired ? 'expired' : ''}`}
                            >
                              Valor unitário consolidado:
                              <UnitPriceInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {' '}
                                R$
                              </UnitPriceInfoLabel>
                              <UnitPriceInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                <ShippingFee value={roundDecimals(consolidatedValue, 6)} />
                              </UnitPriceInfoValue>
                            </UnitPriceLabel>
                          </UnitPrice>
                        </Row>

                        <Row>
                          <TotalInfo>
                            <TotalInfoLabel
                              className={`${expired ? 'expired' : ''}`}
                            >
                              Total
                              <TotalInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {` ${cartItem.unitQuantity} `}
                              </TotalInfoValue>
                              Unidades
                            </TotalInfoLabel>
                          </TotalInfo>

                          <TotalPrice>
                            <TotalPriceLabel
                              className={`${expired ? 'expired' : ''}`}
                            >
                              Total:
                              <TotalPriceInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                R$
                              </TotalPriceInfoLabel>
                              <TotalPriceInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                <Monetary value={cartItem.subTotal} />
                              </TotalPriceInfoValue>
                            </TotalPriceLabel>
                          </TotalPrice>
                        </Row>

                        <Row>
                        <PackingPrice className="left">
                            <PackingPriceLabel
                              className={`${expired ? 'expired' : ''}`}
                            >
                              Valor frete:
                              <PackingPriceInfoValue>
                                {new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', maximumFractionDigits: 6}).format(cartItem.shippingFee)}
                              </PackingPriceInfoValue>
                            </PackingPriceLabel>
                          </PackingPrice>
                        </Row>

                        <Row>
                          <PackingPrice className="left">
                            <PackingPriceLabel
                              className={`${expired ? 'expired' : ''}`}
                            >
                              Valor da embalagem:
                              <PackingPriceInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {' '}
                                R$
                              </PackingPriceInfoLabel>
                              <PackingPriceInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                <Monetary value={cartItem.packingPrice ?? 0} />
                              </PackingPriceInfoValue>
                            </PackingPriceLabel>
                          </PackingPrice>

                          <PackingPrice>
                            <PackingPriceLabel
                              className={`${expired ? 'expired' : ''}`}
                            >
                              Valor unitário:
                              <PackingPriceInfoLabel
                                className={`${expired ? 'expired' : ''}`}
                              >
                                {' '}
                                R$
                              </PackingPriceInfoLabel>
                              <PackingPriceInfoValue
                                className={`${expired ? 'expired' : ''}`}
                              >
                                <Monetary value={cartItem.unitPrice ?? 0} />
                              </PackingPriceInfoValue>
                            </PackingPriceLabel>
                          </PackingPrice>
                        </Row>

                        {cartItem.voucherAmountLabel &&
                          cartItem.unitPriceConsolidated !==
                            cartItem.unitPrice && (
                            <Row>
                              <PackingPrice>
                                <PackingPriceLabel
                                  className={`${expired ? 'expired' : ''}`}
                                >
                                  {cartItem.unitPriceConsolidated >
                                  cartItem.unitPrice
                                    ? 'Adicional'
                                    : 'Desconto'}
                                  <PackingPriceInfoValue
                                    className={`${expired ? 'expired' : ''}`}
                                  >
                                    {cartItem.voucherAmountLabel}
                                  </PackingPriceInfoValue>
                                </PackingPriceLabel>
                              </PackingPrice>
                            </Row>
                          )}
                      </PriceInfo>
                    </InfoRight>
                  </Frame811>
                </ProductInfo>
              </ProductCardCol>
            </ProductCard>
        </Frame812>
      </ProductCardCart>
    </GroupLineItemCart>
  );
}

export default ProducerProductCardCartInComposition;
