import styled from 'styled-components';

import { CalendarOutlined } from '@ant-design/icons';

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopoverHeader = styled.div`
  color: #f78e1e;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
`;

export const PopoverBody = styled.div`
  .col-right {
    text-align: right;
  }
`;

export const CalendarIcon = styled(CalendarOutlined)`
  font-size: 16px;
  color: #f88f1f;
`;

export const Divider = styled.div`
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;