import styled from 'styled-components';
import { breakAt, BreakpointSizes } from 'styles/Breakpoints';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block !important;
      height: 150px;
    }
  }
`;

export const Divider = styled.div`
  background: #d9d9d9;
  height: 1px;
`;

export const Header = styled.div`

  &.mobile {
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f78e1e;
  height: 132px;
  padding: 8px 0;

  ${breakAt(BreakpointSizes.md)} {
    padding: 16px 0;
  }
`;

export const HeaderLine1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;
  
  @media (max-width: 768px) {
    justify-content: center;
    margin: 8px 0;
  }
`;

export const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0px 8px;
  }
`;

export const ICMSNotIncludedInValues = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
  > * {
    margin: 0px 4px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f5f5f5;
  height: 1245px;
  width: 100%;

  > div {         
    position: absolute;
    margin: -70px 2px 0 2px;
  }

  ${breakAt(BreakpointSizes.xs)} {
    height: 1230px;
    > div {   
      margin: -70px 4px 0 4px;
    }
  }

  ${breakAt(BreakpointSizes.sm)} {
    height: 1200px;
    > div {   
      margin: -70px 8px 0 8px;
    }
  }

  ${breakAt(BreakpointSizes.md)} {
    height: 620px;
    align-items: center;
    > div {   
      margin: -70px 16px 0 16px;
    }
  }

  ${breakAt(BreakpointSizes.lg)} {
    > div {   
      left: 32px;
      margin-top: -70px;
    }
  }

  &.empty-data {
    align-items: center !important;
  }

  .ant-empty {
    width: 100%;
    height: 320px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

`;