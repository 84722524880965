import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonNetwork = styled(Button)`
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-right: 0px;
  margin-top: 2px;
  min-width: 70px;

  span {
    margin-left: 0 !important;
  }
`;
