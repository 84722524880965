import { useCallback, useEffect, useRef, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';

import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';

import {
  useGetFilteredOffersQuery,
  useGetFilteredOffersByIdQuery,
  useGetDirectedOffersCountQuery,
} from 'services/ecommerceApi';
import {
  getAuthorizationToken,
  getLetterSelectedProductList,
  getModeListProductList,
  getPageSizeProductList,
  parseJwt,
} from 'services/config';
import { showAlertError } from 'services/alertService';

import ProductOffer from 'model/ProductOffer';
import { JwtToken } from 'model/types';

import { offerFiltersSelectors, sessionSelectors } from 'store/state/selectors';
import { OfferFiltersState } from 'store/state/offerFilters/offerFiltersSlice';

import useIsElementVisible from 'hooks/useIsElementVisible';
import useWindowSize from 'hooks/window-size';

import { offerTypeMap } from 'enums/offerTypeMap';
import { UserTypeEnum } from 'enums/userType';

import {
  DEFAULT_MODE_TYPE_DESKTOP,
  DEFAULT_MODE_TYPE_MOBILE,
  DEFAULT_MODE_TYPE_NOT_AUTHENTICATED,
} from './utils/consts/ModeTypes.const';
import { ModeType } from './utils/enums/ModeType.enum';
import { TOTAL_PAGE } from './utils/consts/Pagination.const';

import Header from './components/Header';
import ProductList from './components/ProductList';

import { Root, Divider } from './styles';

interface FilterParams extends OfferFiltersState {
  page: number;
  initial: string;
  siteId: number[];
  customerId: number;
  totalPage: number;
}

function verifyIfChanged(obj: any, objToCompare: any) {
  return Object.keys(obj)
    .map((key) => _.isEqual(obj[key], objToCompare[key]))
    .some((value) => !value);
}

export default function DirectedOffersContainer() {
  const tokenObject: JwtToken = parseJwt(getAuthorizationToken() ?? '');

  const dispatch = useDispatch();
  const lastRef = useRef<HTMLDivElement>(null);

  const deliveryDates = useSelector(sessionSelectors.getSelectedDeliveryDates);
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const offerFilters = useSelector(offerFiltersSelectors.getOfferFilters);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const siteIds = useSelector(sessionSelectors.getSitesIds);
  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );

  const [width] = useWindowSize();
  const [runInMobile, setRunInMobile] = useState<boolean>(false);

  const [products, setProducts] = useState<ProductOffer[]>([]);
  const [productsRendered, setProductsRendered] = useState(false);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    initial: getLetterSelectedProductList(),
    siteId: siteIds,
    customerId,
    totalPage: getPageSizeProductList() ?? TOTAL_PAGE,
    ...offerFilters,
    ...deliveryDates,
    offerTypes: ['directed'],
  });

  const [showMoreItens, setShowMoreItens] = useState<boolean>(false);
  const [offerInserted, setOfferInserted] =
    useState<{ offerId: number; offerType: offerTypeMap }>();
  const [modeList, setModeList] = useState<ModeType>(() => {
    if (!isAuthenticated) {
      return DEFAULT_MODE_TYPE_NOT_AUTHENTICATED;
    }
    const modeListStorage = getModeListProductList();
    return (
      modeListStorage ??
      (isMobile ? DEFAULT_MODE_TYPE_MOBILE : DEFAULT_MODE_TYPE_DESKTOP)
    );
  });
  const [pagesCount, setPagesCount] = useState(0);

  const isLastVisible = useIsElementVisible(lastRef.current, {
    root: null,
    rootMargin: '0px 0px 800px 0px',
    threshold: 0,
  });

  const { data, isError, refetch } = useGetFilteredOffersQuery(
    {
      ...filterParams,
      businessDate: deliveryDates.businessDate,
      siteId: siteIds,
      deliveryDate: deliveryDates.deliveryDate,
      offerTypes: ['directed'],
    },
    {
      skip:
        isAuthenticated &&
        !filterParams.customerId &&
        +tokenObject.veiling_claim_type_role !==
          UserTypeEnum.VEILING_EMPLOYEE &&
        !isProducerAuthenticated,
    },
  );

  useEffect(() => {
    refetch();
  }, [filterParams]);

  const { data: updatedOfferInsertedArray, originalArgs } =
    useGetFilteredOffersByIdQuery(
      {
        ...filterParams,
        offerId: offerInserted?.offerId,
        offerTypes: [offerTypeMap[Number(offerInserted?.offerType)]],
        page: 1,
        totalPage: TOTAL_PAGE,
      },
      {
        skip: !offerInserted,
      },
    );

  const { data: countDirectedOffers, refetch: refetchDirectedOffersCount } =
    useGetDirectedOffersCountQuery(
      { deliveryDate: deliveryDates.deliveryDate, customerId },
      { skip: !customerId },
    );

  const resetList = useCallback(() => {
    setProducts([]);
    setShowMoreItens(false);
    setProductsRendered(false);
  }, []);

  const handleSelectLetter = useCallback(
    (initial: string) => {
      setFilterParams((oldValue) => ({
        ...oldValue,
        page: 1,
        initial,
      }));
      resetList();
    },
    [resetList],
  );

  const handleOfferInserted = useCallback(
    (offerId: number, offerType: offerTypeMap) => {
      setOfferInserted({ offerId, offerType });
    },
    [],
  );

  const handleChangePagination = useCallback(
    (page: number, totalPage: number) => {
      setFilterParams((oldValue) => ({
        ...oldValue,
        page,
        totalPage,
      }));
      resetList();
    },
    [resetList],
  );

  const handleChangeModeList = useCallback(
    (value: number) => setModeList(value),
    [],
  );

  // Effect for prepare list
  useEffect(() => {
    const offers = data?.offers;
    if (offers?.length) {
      setProducts((oldValue) => [...oldValue, ...offers]);
    }
    setProductsRendered(true);
    setShowMoreItens(offers?.length === TOTAL_PAGE);
    setPagesCount(offers?.length ? offers[0].pagesCount : 0);
  }, [data]);

  // Effect for update quantity after add in cart
  useEffect(() => {
    if (updatedOfferInsertedArray?.offers.length) {
      const updatedOfferInserted = updatedOfferInsertedArray.offers[0];
      setProducts((oldValue) =>
        oldValue.map((product) => {
          if (
            product.offerId === updatedOfferInserted.offerId &&
            product.offerType === updatedOfferInserted.offerType
          ) {
            return {
              ...updatedOfferInserted,
            };
          }
          return {
            ...product,
          };
        }),
      );
    } else {
      setProducts((oldValue) =>
        oldValue.filter((offer) => offer.offerId !== originalArgs?.offerId),
      );
    }
  }, [originalArgs?.offerId, updatedOfferInsertedArray]);

  // Effect for show infinite scroll
  useEffect(() => {
    if (isLastVisible) {
      if (isError) {
        refetch();
      } else {
        setFilterParams((oldValue) => ({
          ...oldValue,
          page: oldValue.page + 1,
        }));
        setProductsRendered(false);
      }
    }
  }, [isLastVisible, refetch, isError]);

  // Effect for show error
  useEffect(() => {
    if (isError) {
      showAlertError(
        dispatch,
        'Não foi possível carregar a lista de oferta no momento, por favor tente novamente mais tarde.',
      );
    }
  }, [isError, dispatch]);

  // Effect to catch store change - Offer filters
  useEffect(() => {
    setFilterParams((oldValue) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { deliveryDate, businessDate, ...rest } = offerFilters;
      if (verifyIfChanged(rest, oldValue)) {
        resetList();
      }
      return {
        ...oldValue,
        page: 1,
        ...rest,
      };
    });
  }, [offerFilters, resetList]);

  // Effect to catch store change - Delivery dateS
  useEffect(() => {
    setFilterParams((oldValue) => {
      if (verifyIfChanged(deliveryDates, oldValue)) {
        resetList();
      }
      return {
        ...oldValue,
        page: 1,
        ...deliveryDates,
      };
    });
  }, [deliveryDates, resetList]);

  // Effect to catch store change - Site Id
  useEffect(() => {
    if (siteIds.length) {
      setFilterParams((oldValue) => ({
        ...oldValue,
        page: 1,
        siteIds,
      }));
      resetList();
    }
  }, [siteIds, resetList]);

  // Effect to catch store change - Customer Id
  useEffect(() => {
    setFilterParams((oldValue) => ({
      ...oldValue,
      page: 1,
      customerId,
    }));
    resetList();
  }, [customerId, resetList]);

  // Effect to control resize
  useEffect(() => {
    if (width && width <= 1200) {
      setRunInMobile(true);
      setModeList(1);
    } else {
      setRunInMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (products && products?.length) {
      if (countDirectedOffers && countDirectedOffers > 0) {
        refetchDirectedOffersCount();
      }
    }
  }, [products]);

  return (
    <Screen
      content={
        <Root>
          <Divider />
          <Header
            isAuthenticated={isAuthenticated}
            offerFilters={offerFilters}
            totalProducts={products?.length}
            changeModeList={handleChangeModeList}
            modeList={modeList}
            selectedLetter={filterParams.initial}
            onSelectLetter={handleSelectLetter}
            runInMobile={runInMobile}
          />

          <ProductList
            products={products}
            mode={modeList}
            productsRendered={productsRendered}
            showMoreItens={showMoreItens}
            lastRef={lastRef}
            offerInserted={handleOfferInserted}
            runInMobile={runInMobile}
            page={filterParams.page}
            pageSize={filterParams.totalPage}
            pagesCount={pagesCount}
            onChangePagination={handleChangePagination}
          />
        </Root>
      }
    />
  );
}
