import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { sessionSelectors } from 'store/state/selectors';

import Monetary from 'components/UI/Data/Monetary';

import {
  PriceInfo,
  PriceInfoLabel,
  PriceOld,
  PriceNew,
  Currency,
  Price,
  Unit,
} from './styles';

type Props = {
  price: number;
  hasSavingPrice?: boolean;
  savingPrice?: number;
  className?: string;
};

function ProductOfferPrice({
  price,
  hasSavingPrice = false,
  savingPrice = 0.0,
  className,
}: Props) {
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);

  if (!isAuthenticated) {
    return <div />;
  }

  return (
    <PriceInfo className={className}>
      <PriceInfoLabel hasSavingPrice={hasSavingPrice ? 0 : 1}>
        A partir de
      </PriceInfoLabel>
      {hasSavingPrice && (
        <PriceOld>
          <Monetary symbol value={savingPrice} />
        </PriceOld>
      )}
      <PriceNew>
        <Currency hasSavingPrice={hasSavingPrice ? 0 : 1}>R$</Currency>
        <Price hasSavingPrice={hasSavingPrice ? 0 : 1}>
          <Monetary value={price} />
        </Price>
        <Unit>un</Unit>
      </PriceNew>
    </PriceInfo>
  );
}

export default memo(ProductOfferPrice);
