import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment';

import Banner from 'model/Banner';
import CartItem from 'model/CartItem';
import { Order } from 'model/Order';
import ProducerListItem from 'model/ProducerListItem';
import ProducerProduct from 'model/ProducerProduct';
import Product from 'model/Product';
import ProductListItem from 'model/ProductListItem';
import QrCodeHistoryGroupDate from 'model/QrCodeHistoryGroupDate';
import ProductOffer from 'model/ProductOffer';
import ProductOfferFilter from 'model/ProductOfferFilter';
import QrCodeData from 'model/QrCodeData';
import {
  CartItemsParam,
  ItemsCartParam,
  MyOffersCountParam,
  OfferCompositionFilterParam,
  OrdersParam,
  ParamKeyword,
  PostBodyQrCodeProduct,
  PostCheckout,
  PostProductInCart,
  ProductCategory,
  ProductGroup,
  ProductQuality,
  ProductOfferFilterParam,
  QrCodeDataParam,
  QrCodeHistoryParam,
  SaleUsersByCustomerId,
  SummaryCart,
  SummaryCartParam,
  OrderOpNature,
  OrderPublisher,
  ProductRecipient,
  ProductRecipientParams,
  PutOrderApproveOrRefuse,
  PutOrderApproveOrRefuseParams,
  ProductSelectOption,
  OrderIntentionRepublishParams,
  GetProductCategoriesParams,
} from 'model/types';
import User from 'model/User';
import Response from 'model/Response';
import { Announcement, AnnouncementFile } from 'model';
import ProductOfferWithBox from 'model/ProductOfferWithBox';
import { getAuthorizationToken } from './config';
import { OfferService } from './offerService';

import commomImage from '../assets/default/image.png';

const requestProductInCart = {
  query: ({ customerId, cartItems, buyNow = false }: PostProductInCart) => ({
    url: `/api/cart/items/${customerId}?buyNow=${buyNow}`,
    method: 'POST',
    body: cartItems,
  }),
};

const requestOffer = {
  query: (productOfferFilterParam: ProductOfferFilterParam) => {
    const {
      name,
      initial,
      offerId,
      deliveryDate,
      businessDate,
      categories,
      offerTypes,
      productsIds,
      commercialGroupIds,
      siteId,
      qualities,
      totalPage,
      page,
      isMyOffers,
      colors,
      heights,
      dimensions,
      producerIds,
      customerId,
      orderBy,
      packagingByLayer
    } = { ...productOfferFilterParam };
    const params = new URLSearchParams();
    if (name) params.append('name', `${name}`);
    if (initial) params.append('initial', `${initial}`);
    if (offerId) params.append('offerId', offerId.toString());
    if (deliveryDate) params.append('deliveryDate', deliveryDate);
    if (isMyOffers) {
      params.append('isMyOffers', `${isMyOffers}`);
      params.append('totalPage', String(9999));
    }
    if (page) params.append('page', `${page}`);
    if (!isMyOffers && totalPage) params.append('totalPage', `${totalPage}`);
    if (businessDate) params.append('businessDate', businessDate);
    if (categories && categories.length > 0 && categories[0]) {
      categories.forEach((category) =>
        params.append('categoryIds', String(category)),
      );
    }
    if (siteId && siteId.length > 0 && siteId[0] !== 0) {
      siteId.forEach((site) => params.append('siteId', String(site)));
    }
    if (offerTypes && offerTypes.length > 0) {
      offerTypes.forEach((offerType) => params.append('offerTypes', offerType));
    }
    if (productsIds && productsIds.length > 0) {
      productsIds.forEach((productId) =>
        params.append('productsIds', String(productId)),
      );
    }
    if (commercialGroupIds && commercialGroupIds.length > 0) {
      commercialGroupIds.forEach((commercialGroupId) =>
        params.append('commercialGroupIds', String(commercialGroupId)),
      );
    }
    if (qualities && qualities.length > 0) {
      qualities.forEach((quality) => params.append('qualities', quality));
    }
    if (colors && colors.length > 0)
      colors.forEach((color) => params.append('colors', color));
    if (heights && heights.length > 0)
      heights.forEach((height) => params.append('heights', height));
    if (dimensions && dimensions.length > 0)
      dimensions.forEach((dimension) => params.append('dimensions', dimension));
    if (producerIds && producerIds.length > 0)
      producerIds.forEach((producerId) =>
        params.append('producerId', String(producerId)),
      );
    if (customerId && customerId > 0)
      params.append('customerId', String(customerId));

    if (packagingByLayer && packagingByLayer > 0)
      params.append('packagingByLayer', String(packagingByLayer));

    if (orderBy) params.append('orderBy', orderBy);
    return {
      url: '/api/Offer',
      params,
    };
  },
  transformResponse: (response: ProductOfferWithBox) =>
    {
      const returOffers = new ProductOfferWithBox(
        response.hasBox,
        []
      );
      returOffers.offers = response?.offers?.map(
        (product) =>
          ({
            ...product,
            imagesFormatted:
              product.images?.length > 0
                ? product.images
                : [product.defaultImage || commomImage],
            id: (Math.random() + 1).toString(36).substring(7),
            infoVoucher: OfferService.generateVoucherDescription(
              product.reajustmentAmount,
              product.minimumUnitQuantityVoucher,
              product.voucherQuantityType,
            ), 
            endDateFormatted: moment(product.lkpAuctionDate).format('DD/MM'),
            siteDeliveryPatterns: product.siteDeliveryPatterns.sort(
              (a, b) => a.orderEndDayNumber - b.orderEndDayNumber,
            ),
          }),
      ) || []
      return returOffers
    }
};

export const ecommerceApi = createApi({
  reducerPath: 'ecommerceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ECOMMERCE_URL,
    prepareHeaders: (headers) => {
      const token = getAuthorizationToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    'SummaryCart',
    'QRCodeDataList',
    'Balance',
    'CartItems',
    'Sales',
    'OfferId',
  ],
  endpoints: (builder) => ({
    getUserWithCustomers: builder.query<User, void>({
      query: () => '/api/me',
    }),
    getBalance: builder.query<number, string>({
      query: (accountCode) => `/api/customer/${accountCode}/balance`,
      providesTags: ['Balance'],
    }),
    getQrCodeData: builder.query<QrCodeData, QrCodeDataParam>({
      query: ({ lotAndbolNumber, standNumber }: QrCodeDataParam) =>
        `/api/qrcode/${lotAndbolNumber}/${standNumber}`,
      providesTags: ['QRCodeDataList'],
    }),
    getPostProductInCart: builder.mutation<SummaryCart, PostProductInCart>({
      ...requestProductInCart,
      invalidatesTags: (result, error, { cartItems }) => [
        'Balance',
        'SummaryCart',
        'CartItems',
        {
          type: 'OfferId',
          id: cartItems[0].lkpOfferId || cartItems[0].volOfferId || undefined,
        },
      ],
    }),
    getPostAnnouncementRead: builder.mutation<any, number>({
      query: (id: number) => ({
        url: `/api/announcement/${id}`,
        method: 'POST',
      }),
    }),
    getAnnouncements: builder.query<Announcement[], void>({
      query: () => ({
        url: '/api/announcement/visualization',
      }),
    }),
    getAnnouncementFiles: builder.query<AnnouncementFile[], number>({
      query: (id: number) => `/api/announcement/file/${id}`,
    }),
    getPostQrCodeProduct: builder.mutation<void, PostBodyQrCodeProduct>({
      query: (qrCodeProductBody) => ({
        url: `/api/qrcode`,
        method: 'POST',
        body: qrCodeProductBody,
      }),
      invalidatesTags: ['QRCodeDataList'],
    }),
    getPostCheckout: builder.mutation<any, PostCheckout>({
      query: (postCheckout: PostCheckout) => ({
        url: `/api/checkout`,
        method: 'POST',
        body: postCheckout,
      }),
      invalidatesTags: ['SummaryCart', 'CartItems', 'Sales', 'Balance'],
    }),
    getSummaryCart: builder.query<SummaryCart, SummaryCartParam>({
      query: ({ customerId }: SummaryCartParam) => `/api/cart/${customerId}`,
      providesTags: ['SummaryCart'],
    }),
    getLinkSite: builder.mutation<any, void>({
      query: () => `/api/site/link-site`,
    }),
    getLinkSiteByUrl: builder.mutation<any, string>({
      query: (url: string) => `/api/site/link-site/${url}`,
    }),
    getOrderOpNatures: builder.query<OrderOpNature[], void>({
      query: () => `/api/order/nature-operation`,
    }),
    getSitesSelect: builder.query<any[], void>({
      query: () => `/api/site/list`,
    }),
    getOrderPublishers: builder.query<string[], void>({
      query: () => `/api/order/publishedBy`,
      transformResponse: (response: OrderPublisher) =>
      response?.publisherNames || [],
    }),
    putOrderApproveOrRefuse: builder.mutation<PutOrderApproveOrRefuse, PutOrderApproveOrRefuseParams>({
      query: (params) => ({
        url: `/api/qrcode`,
        method: 'POST',
        body: params,
      }),
    }),
    getProductCategories: builder.query<ProductCategory[], GetProductCategoriesParams>({
      query: ({siteId}) =>  {
        if (siteId && !Number.isNaN(siteId)) {
          return `/api/productcategory/${siteId}`;
        }

        return `/api/productcategory`;
      },
    }),
    getProductGroups: builder.query<ProductGroup[], number>({
      query: (siteId) => {
        if (siteId && !Number.isNaN(siteId)) {
          const params = new URLSearchParams();
          if (siteId) params.append('siteId', `${siteId}`);
          return {
            url: '/product-group',
            params,
          }
        }
        return `/product-group`;
      },
    }),
    getProductQualities: builder.query<ProductQuality[], void>({
      query: () => `/product-quality`,
    }),
    getProductRecipients: builder.query<ProductRecipient[], ProductRecipientParams>({
      query: ({
        siteId
      }) => {
        const params = new URLSearchParams();
        if (siteId) params.append('siteId', `${siteId}`);
        return {
          url: '/api/product/recipients/detailed',
          params,
        }
      },
    }),
    getProductsBasedOnLastVisit: builder.query<ProducerProduct[], number[]>({
      query: (sitesIds: number[]) => {
        const params = new URLSearchParams();

        if (sitesIds && sitesIds?.length) {
          sitesIds.forEach((siteId) => params.append('siteId', String(siteId)));
        }

        return {
          url: '/api/Offer/last-visited',
          params,
        };
      },
    }),
    getProducts: builder.query<ProductListItem[], ParamKeyword>({
      query: ({ keyword }: ParamKeyword) => {
        const params = new URLSearchParams();
        if (keyword) {
          params.append('keyword', keyword);
        }

        return {
          url: '/api/product',
          params,
        };
      },
    }),
    getProductsSelectOptions: builder.query<ProductSelectOption[], number>({
      query: (siteId) =>  {
        if (siteId) {
          return `/api/product/products/${siteId}`;
        }

        return '/api/product/products';
      },
    }),
    getProducers: builder.query<ProducerListItem[], ParamKeyword>({
      query: ({ keyword }: ParamKeyword) => {
        const params = new URLSearchParams();
        if (keyword) {
          params.append('keyword', keyword);
        }

        return {
          url: '/api/site',
          params,
        };
      },
    }),
    getProductsMostSearched: builder.query<Product[], number[]>({
      query: (sitesIds: number[]) => {
        const params = new URLSearchParams();

        if (sitesIds && sitesIds?.length) {
          sitesIds.forEach((siteId) => params.append('siteId', String(siteId)));
        }

        return {
          url: '/api/Offer/last-visited-general',
          params,
        };
      },
    }),
    getProductsMostBuyByCategory: builder.query<Product[], void>({
      query: () => ({
        url: '/api/product/category/ranked',
      }),
    }),
    getCartItems: builder.query<CartItem[], CartItemsParam>({
      query: ({ cartId }: CartItemsParam) => `/api/cart/${cartId}/items`,
      providesTags: ['CartItems'],
    }),
    getBanners: builder.query<Banner[], string>({
      query: (url?: string) => {
        if (url && url !== 'veiling') {
          return `/api/banner?url=${url}`;
        }
        return '/api/banner';
      },
    }),
    getMyOrders: builder.query<Response<Order[]>, OrdersParam>({
      keepUnusedDataFor: 0,
      query: (params) => ({
        url: `/api/sale`,
        params,
      }),
      providesTags: ['Sales'],
    }),
    getSaleUsersByCustomerId: builder.query<string[], SaleUsersByCustomerId>({
      keepUnusedDataFor: 0,
      query: ({customerId}) => ({
        url: `/api/sale/users/${customerId}`,
      }),
      providesTags: ['Sales'],
    }),
    getDeleteCart: builder.mutation<void, CartItemsParam>({
      query: ({ cartId }: CartItemsParam) => ({
        url: `/api/cart/${cartId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SummaryCart', 'CartItems'],
    }),
    getDeleteItemCart: builder.mutation<void, ItemsCartParam>({
      query: ({ id }: ItemsCartParam) => ({
        url: `/api/cart/items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SummaryCart', 'CartItems'],
    }),
    getQrCodeHistory: builder.query<
      QrCodeHistoryGroupDate[],
      QrCodeHistoryParam
    >({
      query: (qrCodeHistoryParam: QrCodeHistoryParam) => {
        const { siteId, productId, date, customerId } = {
          ...qrCodeHistoryParam,
        };
        const params = new URLSearchParams();
        if (date) params.append('date', date.format('YYYY-MM-DD'));
        if (productId) params.append('productId', String(productId));
        if (siteId) params.append('siteId', String(siteId));
        if (customerId) params.append('customerId', String(customerId));
        return {
          url: '/api/qrcode/history',
          params,
        };
      },
    }),
    getOfferFilters: builder.query<ProductOfferFilter, ProductOfferFilterParam>(
      {
        query: (filterParam: ProductOfferFilterParam) => {
          const {
            deliveryDate,
            businessDate,
            customerId,
            siteId,
            categories,
            commercialGroupIds,
            offerTypes,
            productsIds,
            qualities,
            colors,
            heights,
            dimensions,
            producerIds,
            packagingByLayer,
          } = {
            ...filterParam,
          };
          const params = new URLSearchParams();
          if (deliveryDate) params.append('deliveryDate', deliveryDate);
          if (businessDate) params.append('businessDate', businessDate);
          if (customerId && customerId > 0){
            params.append('customerId', String(customerId));
          }
          if (siteId && siteId?.length) {
            siteId.forEach((site) => params.append('siteId', String(site)));
          }
          if (categories && categories.length > 0 && categories[0]) {
            categories.forEach((category) =>
              params.append('categoryIds', String(category)),
            );
          }
          if (offerTypes && offerTypes.length > 0) {
            offerTypes.forEach((offerType) =>
              params.append('offerTypes', offerType),
            );
          }
          if (commercialGroupIds && commercialGroupIds.length > 0) {
            commercialGroupIds.forEach((commercialGroupId) =>
              params.append('commercialGroupIds', String(commercialGroupId)),
            );
          }
          if (productsIds && productsIds.length > 0) {
            productsIds.forEach((productId) =>
              params.append('productsIds', String(productId)),
            );
          }
          if (qualities && qualities.length > 0) {
            qualities.forEach((quality) => params.append('qualities', quality));
          }
          if (colors && colors.length > 0)
            colors.forEach((color) => params.append('colors', color));
          if (heights && heights.length > 0)
            heights.forEach((height) => params.append('heights', height));
          if (dimensions && dimensions.length > 0){
            dimensions.forEach((dimension) =>
              params.append('dimensions', dimension),
            );
          }
          if (producerIds && producerIds.length > 0){
            producerIds.forEach((producerId) =>
              params.append('producerId', String(producerId)),
            );
          }
          if (customerId && customerId > 0){
            params.append('customerId', String(customerId));
          }
          if (packagingByLayer && packagingByLayer > 0){
            params.append('packagingByLayer', String(packagingByLayer));
          }
          return {
            url: '/api/Offer/filters',
            params,
          };
        },
      },
    ),
    getOfferById: builder.query<ProductOfferWithBox, ProductOfferFilterParam>({
      query: (filterParam: ProductOfferFilterParam) => {
        const { customerId, offerId, offerTypes } = {
          ...filterParam,
        };
        const params = new URLSearchParams();
        if (customerId && customerId > 0){
          params.append('customerId', String(customerId));
        }
        if (offerId && offerId > 0) {
          params.append('offerId', String(offerId));
        }
        if (offerTypes && offerTypes.length > 0){
          offerTypes.forEach((offerType) => params.append('offerTypes', offerType));
        }
        return {
          url: '/api/Offer',
          params
        };
      },
      providesTags: ['OfferId'],
    }),
    getMyOffers: builder.query<ProductOfferWithBox, ProductOfferFilterParam>({
      keepUnusedDataFor: 0,
      ...requestOffer,
    }),
    getMyOffersById: builder.query<ProductOfferWithBox, ProductOfferFilterParam>({
      keepUnusedDataFor: 0,
      ...requestOffer,
      providesTags: (result) =>
        result
          ? [
              ...result.offers.map(
                ({ offerId }) => ({ type: 'OfferId', id: offerId } as const),
              ),
            ]
          : [{ type: 'OfferId', id: 0 }],
    }),
    getFilteredOffers: builder.query<ProductOfferWithBox, ProductOfferFilterParam>({
      keepUnusedDataFor: 0,
      ...requestOffer,
    }),
    getFilteredOffersById: builder.query<
    ProductOfferWithBox,
      ProductOfferFilterParam
    >({
      keepUnusedDataFor: 0,
      ...requestOffer,
      providesTags: (result) =>
        result
          ? [
              ...result.offers.map(
                ({ offerId }) => ({ type: 'OfferId', id: offerId } as const),
              ),
            ]
          : [{ type: 'OfferId', id: 0 }],
    }),
    getOffersComposition: builder.mutation<
      ProductOfferWithBox,
      OfferCompositionFilterParam
    >({
      query: (offerCompositionFilterParam: OfferCompositionFilterParam) => {
        const {
          deliveryDate,
          category,
          categoryId,
          offerType,
          dimension,
          producerId,
          groupId,
          packagingByLayer,
          commercialGroupIds,
          customerId
        } = {
          ...offerCompositionFilterParam,
        };
        const params = new URLSearchParams();
        if (customerId) params.append('customerId', customerId.toString());
        if (deliveryDate) params.append('deliveryDate', deliveryDate);
        if (categoryId || category)
          params.append('categoryIds', `${categoryId || category}`);
        if (offerType !== null && offerType !== undefined)
          params.append('offerTypes', `${offerType}`);
        if (dimension) params.append('dimensions', dimension);
        if (producerId) params.append('producerId', `${producerId}`);
        if (groupId) params.append('groupId', `${groupId}`);
        if (packagingByLayer){          
          params.append('packagingByLayer', `${packagingByLayer}`);
        }
        params.append('isFromComposition', 'true');
        params.append('page', '1');
        params.append('totalPage', '9999');
        if (commercialGroupIds && commercialGroupIds.length){
          commercialGroupIds.forEach((commercialGroupId) =>
          params.append('commercialGroupIds', String(commercialGroupId)),
        );
        }

        return {
          url: '/api/Offer',
          params,
        };
      },
    }),
    getDirectedOffersCount: builder.query<number, MyOffersCountParam>({
      keepUnusedDataFor: 0,
      query: (directedOffersCountParam: MyOffersCountParam) => {
        const { deliveryDate, customerId } = {
          ...directedOffersCountParam,
        };
        const params = new URLSearchParams();
        if (deliveryDate) params.append('deliveryDate', deliveryDate);
        if (customerId) params.append('customerId', `${customerId}`);
        return {
          url: '/api/offer/directed-offers-count',
          params,
        };
      },
    }),
    getMyOffersCount: builder.query<number, MyOffersCountParam>({
      keepUnusedDataFor: 0,
      query: (myOffersCountParam: MyOffersCountParam) => {
        const { deliveryDate, customerId } = {
          ...myOffersCountParam,
        };
        const params = new URLSearchParams();
        if (deliveryDate) params.append('deliveryDate', deliveryDate);
        if (customerId) params.append('customerId', `${customerId}`);
        return {
          url: '/api/offer/my-offers-count',
          params,
        };
      },
    }),
    getUnavailableDates: builder.query<string[], void>({
      query: () => ({
        url: '/unavailable-date',
      }),
    }),
    OrderIntentionRepublish: builder.mutation<any, OrderIntentionRepublishParams>({
      query: ({id}) => ({
        url: `/api/order/buy-intention/republish/${id}`,
        method: 'PUT'
      }),
    }),
    OrderIntentionCancel: builder.mutation<any, number>({
      query: (id: number) => ({
        url: `/api/order/buy-intention/${id}`,
        method: 'PUT',
        body: {id},
      }),
    }),
  }),
});

export const {
  useGetBalanceQuery,
  useGetUserWithCustomersQuery,
  useGetPostProductInCartMutation,
  useGetPostCheckoutMutation,
  useGetOfferFiltersQuery,
  useGetDeleteCartMutation,
  useGetDeleteItemCartMutation,
  useGetFilteredOffersQuery,
  useGetFilteredOffersByIdQuery,
  useGetOfferByIdQuery,
  useGetMyOffersQuery,
  useGetAnnouncementFilesQuery,
  useGetAnnouncementsQuery,
  useGetMyOffersByIdQuery,
  useGetDirectedOffersCountQuery,
  useGetMyOffersCountQuery,
  useGetQrCodeHistoryQuery,
  useGetProductsBasedOnLastVisitQuery,
  useGetProductsMostSearchedQuery,
  useGetProductsMostBuyByCategoryQuery,
  useGetProductsQuery,
  useGetProductsSelectOptionsQuery,
  useGetProducersQuery,
  useGetBannersQuery,
  useGetMyOrdersQuery,
  useGetSaleUsersByCustomerIdQuery,
  useGetCartItemsQuery,
  useGetPostAnnouncementReadMutation,
  useGetQrCodeDataQuery,
  useGetLinkSiteMutation,
  useGetLinkSiteByUrlMutation,
  useGetOrderOpNaturesQuery,
  useGetSitesSelectQuery,
  useGetOrderPublishersQuery,
  useGetProductCategoriesQuery,
  useGetProductGroupsQuery,
  useGetProductQualitiesQuery,
  useGetProductRecipientsQuery,
  useGetSummaryCartQuery,
  useGetOffersCompositionMutation,
  useGetPostQrCodeProductMutation,
  useGetUnavailableDatesQuery,
  useOrderIntentionRepublishMutation,
  useOrderIntentionCancelMutation,
  endpoints,
} = ecommerceApi;
