import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import * as S from './styles';

type ShareProps = {
  emailProps?: {
    body?: string;
    separator?: string;
    subject?: string;
  },
  whatsAppProps?: {
    title?: string;
    separator?: string;
  }
}

export default function Share({
  emailProps,
  whatsAppProps,
}: ShareProps) {
  const [visible, setVisible] = useState(false);
  const url = window.location.href;
  const iconProps = {
    size: 40,
    round: true
  };

  return (
    <S.Container>
      <ShareAltOutlined style={{ fontSize: '20px' }} onClick={() => setVisible(true)} />
      <Modal
        closable
        onCancel={() => setVisible(false)}
        visible={visible}
        width="414px"
        footer={null}
      >
        <S.Content>
          <h2>Compartilhar</h2>
          <Input.Group compact>
            <Input
              style={{ width: 'calc(100% - 31px)' }}
              defaultValue={url}
            />
            <Tooltip title="copy url">
              <Button 
                icon={<CopyOutlined />} 
                onClick={() => navigator.clipboard.writeText(url)} 
              />
            </Tooltip>
          </Input.Group>
          <div className="icons">
            <EmailShareButton url={url} {...emailProps}>
              <EmailIcon {...iconProps} />
            </EmailShareButton>
            <WhatsappShareButton url={url} {...whatsAppProps}>
              <WhatsappIcon {...iconProps} />
            </WhatsappShareButton>
          </div>
        </S.Content>
      </Modal>
    </S.Container>
  );
}
