import { AnyPtrRecord } from 'dns';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface ContextProps {
  hasBox: boolean;
  setHasBox: any;
}
export const offersContext = createContext<ContextProps>({
  hasBox: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHasBox: () => {},
});

interface OffersContextProps {
  children: React.ReactNode;
  hasBox: boolean;
}
export default function MyOrdersContext(props: OffersContextProps) {
  const { children } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const [hasBox, setHasBox] = useState(false);
  const value = useMemo(() => ({ hasBox, setHasBox }), [hasBox, setHasBox]);

  useEffect(() => {
    setHasBox(props.hasBox);
  }, [props.hasBox]);
  return (
    <offersContext.Provider value={value}>{children}</offersContext.Provider>
  );
}
