import { memo, useContext, useMemo, useState } from 'react';

import Monetary from 'components/UI/Data/Monetary';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { offersContext } from 'contexts/OffersContext';
import { sessionSelectors } from 'store/state/selectors';
import ProductOffer from 'model/ProductOffer';
import { useSelector } from 'react-redux';
import { Container } from './styles';
import ShippingFee from '../Data/ShippingFee';

interface Props {
  product: ProductOffer;
}

function ProductShippingFee({ product }: Props) {
  const { hasBox } = useContext(offersContext);
  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );
  const { customers } = useSelector(sessionSelectors.getRoot);

  const [selectedCustomer, _setSelectedCustomer] = useState(
    customers?.find((customer) => customer.id === selectedCustomerId),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shippingFee = useMemo(
    () =>
      product?.shippingFeeFilials?.find(
        (shippingFee) =>
          shippingFee.organizationName === selectedCustomer?.branch,
      )?.productShippingValue,
    [product.shippingFeeFilials, selectedCustomer?.branch],
  );
  return shippingFee ? (
    <Row justify="end">
      <Col>
        <Container>
          <ExclamationCircleOutlined />
          <span>
            &nbsp; Frete: <ShippingFee symbol value={shippingFee} />
          </span>
        </Container>
      </Col>
    </Row>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
}

export default memo(ProductShippingFee);
