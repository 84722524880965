export const statusMap = {
  A: {
    description: 'Aberto',
    color: 'gray',
  },
  F: {
    description: 'Gerado',
    color: 'green',
  },
  R: {
    description: 'Processando',
    color: 'blue',
  },
  P: {
    description: 'Programado',
    color: 'orange',
  },
  E: {
    description: 'Não gerado',
    color: 'red',
  },
  C: {
    description: 'Cancelado',
    color: 'red',
  },
  V: {
    description: 'Validação',
    color: 'red',
  },
};
