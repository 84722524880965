import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const ModalConfirmation = styled(Modal)``;

export const ModalConfirmationHeader = styled.div`
  span { 
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #262626;

    &.title {
        padding-left: 16px;
    }
}

.anticon > svg {
    color: #FAAD14;
}
`;

export const ModalConfirmationBody = styled.div`
p { 
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #262626;
}
padding: 8px 32px;
`;

export const ModalConfirmationFooter = styled.div``;
export const ButtonsActions = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
`;
export const ButtonCancel = styled(Button)`
margin-right: 16px;
`;
export const ButtonOk = styled(Button).attrs({type: 'primary'})`
margin-right: 16px;
`;
