import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FileSyncOutlined,
  HistoryOutlined,
  IssuesCloseOutlined,
  StopOutlined,
  UserOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { OrderIntentionStatusEnum } from 'enums/orderIntentionStatus.enum';
import { useMemo } from 'react';
import Partial from 'assets/svgs/Partial.svg'

interface Props {
  status: OrderIntentionStatusEnum;
  statusDescription: string;
  message?: string;
}
export default function StatusTag({ status, statusDescription, message }: Props) {
  const errorValidator = status === OrderIntentionStatusEnum.ERRO;
  const renderTag = useMemo(() => {
    switch (status) {
      case OrderIntentionStatusEnum.CONFIRMADO: {
        return <CheckCircleOutlined style={{ color: '#52C41A' }} />;
      }
      case OrderIntentionStatusEnum.CONFIRMADO_PARCIAL: {
        return <img src={Partial} alt='' style={{ maxWidth: '0.75rem'}} />;
      }

      case OrderIntentionStatusEnum.CONFIRMADO_ALTERACAO: {
        return <IssuesCloseOutlined style={{ color: '#125C4F' }} />
      }

      case OrderIntentionStatusEnum.PENDENTE: {
        return <HistoryOutlined style={{ color: '#FAAD14' }} />;
      }
      case OrderIntentionStatusEnum.AGUARDANDO_GERACAO_PEDIDO: {
        return <FileSyncOutlined style={{ color: '#FAAD14' }} />;
      }
      case OrderIntentionStatusEnum.AGUARDANDO_PRODUTOR: {
        return <UserOutlined style={{ color: '#FAAD14' }} />;
      }
      case OrderIntentionStatusEnum.RECUSADO: {
        return <CloseCircleOutlined style={{ color: '#FA541C' }} />;
      }
      case OrderIntentionStatusEnum.CANCELADO: {
        return <StopOutlined style={{ color: '#FF0000' }} />;
      }
      case OrderIntentionStatusEnum.EXPIRADO: {
        return <ClockCircleOutlined style={{ color: '#1E1E1E' }} />;
      }
      case OrderIntentionStatusEnum.ERRO: {
        return <WarningFilled style={{ color: '#1E1E1E' }} />;
      }
      default:
        <div>{status}</div>;
    }
    return <div>{status}</div>;
  }, [status]);

  return (
    <Tooltip title={errorValidator ? message : statusDescription}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {renderTag}
      </div>
    </Tooltip>
  );
}
