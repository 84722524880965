import { memo } from 'react';

import { DeliveryPatterns } from 'model/DeliveryPatterns';

import PopoverCalendar from 'components/UI/PopoverCalendarDates';

import { Container } from './styles';

interface Props {
  isLkpOffer: boolean;
  endDateFormatted: string;
  startDate: Date;
  endDate: Date;
  siteDeliveryPatterns: DeliveryPatterns[];
}

function InfoDelivery({
  isLkpOffer,
  endDateFormatted,
  startDate,
  endDate,
  siteDeliveryPatterns,
}: Props) {
  return (
    <Container isLkpOffer={isLkpOffer ? 1 : 0}>
      {isLkpOffer ? (
        <p>{endDateFormatted}</p>
      ) : (
        <PopoverCalendar
          startDate={startDate}
          endDate={endDate}
          siteDeliveryPatterns={siteDeliveryPatterns}
        />
      )}
    </Container>
  );
}

export default memo(InfoDelivery);
