import { Col, Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > * {
    margin: 0px 8px;
  }
`;
export const LKPDateText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #8c8c8c;
`;
export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PopoverHeader = styled.div`
  color: #f78e1e;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
`;
export const PopoverBody = styled.div`
  .col-right {
    text-align: right;
  }
`;
export const RowTitle = styled(Row)`
  margin-bottom: 12px;
`;
export const ColTitle = styled(Col)`
  color: #f78e1e;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
`;

