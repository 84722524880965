import styled from 'styled-components';

interface ContainerProps {
  isLkpOffer?: number;
}

export const Container = styled.div<ContainerProps>`
  margin-right: ${(props) => (props.isLkpOffer ? 6 : 12)}px;
  
  p {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: bold;
    color: rgba(0, 91, 167, 1);
    margin-left: -6.5px;
    line-height: 12px;
  }
`;
