// eslint-disable-next-line no-shadow
export enum OrderIntentionStatusEnum {
  CONFIRMADO = 'C',
  CONFIRMADO_PARCIAL = 'P',
  CONFIRMADO_ALTERACAO = 'F',
  PENDENTE = 'D',
  AGUARDANDO_GERACAO_PEDIDO = 'A',
  AGUARDANDO_PRODUTOR = 'W',
  RECUSADO = 'R',
  CANCELADO = 'I',
  EXPIRADO = 'E',
  ERRO = 'X',
  AGUARDANDO_PENDENTE = 'S',
  EXPIRED = 'EXPIRED',
}
