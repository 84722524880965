import styled, { css } from 'styled-components';
import { Button } from 'antd';

interface ProductViewButtonProps {
  active: number;
}

interface AlphabetButtonSelectProps {
  selected: number;
}

export const Container = styled.div`
  &.mobile {
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f78e1e;
  padding: 12px 0px;
  min-height: 104px;
`;

export const HeaderLine1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;

  @media (max-width: 768px) {
    justify-content: center;
    margin: 8px 0;
  }
`;

export const FilterButton = styled(Button)`
  box-shadow: none;
`;

export const HeaderLine2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // flex-wrap: wrap;
  margin: 0px 40px;

  @media (max-width: 768px) {
    justify-content: center;
    margin: 8px 0;
  }
`;

export const HeaderLine2LeftGroup = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0px 8px;
  }
`;

export const ShowTotalResults = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
`;

export const HeaderLine2RightGroup = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ICMSNotIncludedInValues = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
  > * {
    margin: 0px 4px;
  }
`;

export const SelectedFilters = styled.span`
  font-weight: 500;
  color: #fff;
  margin-left: 16px;
`;

export const ClearSelectedFilters = styled.span`
  font-weight: 500;
  color: #fff;
  margin-left: 8px;
  padding: 0;
  cursor: pointer;
`;

export const SeparateFilters = styled.span`
  font-weight: 500;
  color: #fff;
  margin-left: 4px;
`;

export const GroupFilter = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductViewButton = styled(Button)<ProductViewButtonProps>`
  margin-right: 8px;
  border: none;

  background-color: transparent;
  color: white;

  :focus {
    background-color: white;
    color: black;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: white;
      color: black;
    `}
`;

export const HeaderLine2CenterGroup = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const AlphabetButtonSelect = styled.div<AlphabetButtonSelectProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: transparent;
  border-radius: 4px;
  padding: 6px;

  ${(props) =>
    !!props.selected &&
    css`
      color: #000;
      background-color: #fff;
    `}
`;
