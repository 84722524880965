export enum PackingTypeSale {
  Packing = 'Packing',
  Layer = 'Layer',
  Trolley = 'Trolley',
}

export enum PackingTypeSaleMap {
  Packing = 'Embalagem',
  Layer = 'Camada',
  Trolley = ' Carrinho',
}
