import { AxiosResponse } from 'axios';
import { Loader, Overlay } from 'components/Wired/DefaultLoader/styles';
import { useMyOrderIntentionsContext } from 'contexts/MyOrderIntentionsContext';
import { OfferTypeEnum } from 'enums/offerType';
import { OrderIntention } from 'model/OrderIntention';
import Response from 'model/Response';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyOrderIntentions } from 'services/orderIntentionsService';
import { sessionSelectors } from 'store/state/selectors';
import {
  myOrderIntentionEditActions,
  sessionActions,
} from 'store/state/slices';
import Filter from '../Filter';
import List from '../List';
import TabLegend from './TabLegend';
import TabLegendForMobile from './TabLegendForMobile';

interface Props {
  customerId: number;
}

export default function Tab({ customerId }: Props) {
  const dispatch = useDispatch();
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [totalResults, setTotalResults] = useState<number>(0);
  const {
    dataTable,
    setDataTable,
    formFilter,
    loading,
    setLoading,
    pageNumber,
    setPageNumber,
    pageSize,
    setActiveFilters,
  } = useMyOrderIntentionsContext();
  const { customers, timeLeft } = useSelector(sessionSelectors.getRoot);
  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );
  const [customerSelected, setCustomerSelected] = useState(
    customers.find((customer) => customer.id === selectedCustomerId),
  );

  const handleFetch = useCallback(() => {
    if (timeLeft <= 25) {
      dispatch(sessionActions.setRefreshToken(true));
    }
    const formFiltersFields: any = formFilter.getFieldsValue();
    let startDate;
    let endDate;
    if (formFiltersFields.date?.length) {
      startDate = formFiltersFields.date[0]?.format('YYYY-MM-DD');
      endDate = formFiltersFields.date[1]?.format('YYYY-MM-DD');
    }

    setActiveFilters(formFiltersFields);

    const filters = {
      'Data.FilterBy': formFiltersFields['Data.FilterBy'],
      'Data.StartDate': startDate,
      'Data.EndDate': endDate,
      'Data.Status': formFiltersFields['Data.Status'],
      'Data.Origin': formFiltersFields['Data.Origin'],
      'Data.ProductCategoryId': formFiltersFields['Data.ProductCategoryId'],
      'Data.ProductGroupId': formFiltersFields['Data.ProductGroupId'],
      'Data.ProductId': formFiltersFields['Data.ProductId'],
      'Data.ProductQualityId': formFiltersFields['Data.ProductQualityId'],
      'Data.SiteId': formFiltersFields['Data.SiteId'],
      'Data.NatureOperationId': formFiltersFields['Data.NatureOperationId'],
      'Data.Recipient': formFiltersFields['Data.Recipient'],
      'Data.PublishedBy': formFiltersFields['Data.PublishedBy'],
      'Data.CustomerId':
        formFiltersFields['Data.CustomerId'] === 0
          ? null
          : formFiltersFields['Data.CustomerId'],
      accountCode: customers.find(
        (customer) => customer.id === formFiltersFields['Data.CustomerId'],
      )?.accountCode,
      // 'Data.Sort': '',
      // 'Data.OrderBy': '',
      PageNumber: 1,
      PageSize: 99999,
    };

    dispatch(myOrderIntentionEditActions.setFilterExport(filters));

    setLoading(true);
    fetchMyOrderIntentions(filters)
      .then((response: AxiosResponse<Response<OrderIntention[]>>) => {
        setLoading(false);
        console.log('response data >:>:>:>:>:>', response.data);
        const { results, totalCount } = response.data;
        setDataTable((oldValue) => {
          if (typeof oldValue !== 'undefined') {
            return [...oldValue, ...results];
          }
          return [...results];
        });
        setTotalResults(totalCount);
        dispatch(myOrderIntentionEditActions.setClearCheckbox(true));
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    formFilter,
    setActiveFilters,
    customers,
    dispatch,
    setLoading,
    setDataTable,
  ]);

  const onFormSubmit = useCallback(() => {
    setDataTable([]);
    setTotalResults(0);
    handleFetch();
  }, [handleFetch, setDataTable]);

  const isLastPage = useCallback(
    () => typeof dataTable !== 'undefined' && dataTable.length >= totalResults,
    [dataTable, totalResults],
  );

  const onLoadMoreClick = useCallback(() => {
    setPageNumber((oldValue) => oldValue + 1);
    handleFetch();
  }, [setPageNumber, handleFetch]);

  useEffect(() => {
    if (isLastPage()) {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
  }, [dataTable, isLastPage]);

  useEffect(() => {
    setDataTable([]);
  }, [customerId, setDataTable]);

  return (
    <>
      <Filter onSubmit={onFormSubmit} />
      <List offerType={OfferTypeEnum.VOL} data={dataTable} />
      {isMobile ? <TabLegendForMobile /> : <TabLegend />}
      {/* {showPagination && (
        <PaginationContainer>
          <Button
            onClick={() => onLoadMoreClick()}
            type="primary"
            icon={<DownCircleOutlined />}
          >
            Carregar mais itens
          </Button>
        </PaginationContainer>
      )} */}
      {loading && (
        <Overlay>
          <Loader spinning={loading} size="large" />
        </Overlay>
      )}
    </>
  );
}
