import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorState = {
  error: string | null;
};

export const initialState = { error: null } as ErrorState;

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    throw(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clear(state) {
      state.error = null;
    },
  },
  extraReducers: {},
});

export const { actions, reducer } = errorSlice;
