import styled, { keyframes } from 'styled-components';

interface Props {
  sizeGroup: string;
  timeToGroup: string;
}

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Header = styled.div`
  flex-shrink: 0;
`;

export const SubHeader = styled.div`
  flex-shrink: 0;
`;

export const Content = styled.main`
  flex-shrink: 0;
  flex-grow: 1;
`;

export const Footer = styled.div`
  flex-shrink: 0;
`;

export const Announcement = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #fff;
  height: 34px;
  z-index: 99;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: row;
`;

export const LastAnnouncement = styled.div`
  background-color: #faad14;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  z-index: 1;
  min-width: 200px;
`;

export const AnnouncementList = styled.div`
  overflow-x: hidden;
  display: flex;
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
  }
`;

export const AnnouncementTitle = styled.span`
  min-width: fit-content;
`;

export const AnnouncementGroup = styled.div.attrs((props: Props) => ({
  sizeGroup: props.sizeGroup,
  timeToGroup: props.timeToGroup
}))`
  overflow-x: hidden;
  animation: title ${props => props.timeToGroup} infinite linear;
  min-width: ${props => props.sizeGroup};
  display: flex;
  align-items: center;
  @keyframes title {
    0% {transform: translateX(100%);}
    100% {transform: translateX(-100%);}
  }

  &:hover {
    animation-play-state: paused;
  }
`;
