import * as React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import CartItem from 'model/CartItem';
import Monetary from 'components/UI/Data/Monetary';
import { TableOrderSummary } from './styles';
import { roundDecimals } from 'utils/util';
import ShippingFee from 'components/UI/Data/ShippingFee';

const { Column } = Table;

type Props = {
  isLkp: boolean;
  cartItems: CartItem[];
  billingDate: string;
  user: string;
};

export default function TableSummary({
  isLkp,
  cartItems,
  billingDate,
  user,
}: Props) {
  return (
    <TableOrderSummary>
      <Table pagination={false} bordered dataSource={cartItems}>
        {isLkp ? (
          <Column
            title="Data do Leilão"
            dataIndex="auctionDate"
            key="auctionDate"
            render={(auctionDate) => moment(auctionDate).format('L')}
            align="left"
          />
        ) : (
          <Column
            title="Data de Faturamento"
            dataIndex="date"
            key="date"
            render={() => moment(billingDate).format('L')}
            align="left"
          />
        )}
        <Column
          title="Produto"
          dataIndex="description"
          key="description"
          align="left"
        />
        <Column
          title="Qualidade"
          dataIndex="quality"
          key="quality"
          align="left"
        />
        <Column
          title="Produtor"
          dataIndex="siteName"
          key="siteName"
          align="left"
        />
        <Column
          title="Embalagem"
          dataIndex="packingName"
          key="packingName"
          align="left"
        />
        <Column
          title="QE"
          dataIndex="packingQuantity"
          key="packingQuantity"
          align="left"
        />
        <Column
          title="QpE"
          dataIndex="unitPerPacking"
          key="unitPerPacking"
          align="left"
        />
        <Column
          title="Total Unidades"
          dataIndex="unitQuantity"
          key="unitQuantity"
          align="left"
        />
        {!isLkp && (
          <Column
            title="Adicional / Desconto"
            dataIndex="voucherAmountLabel"
            key="voucherAmountLabel"
            align="left"
          />
        )}
        <Column
          title="Valor da Emb."
          dataIndex="packingPrice"
          key="packingPrice"
          align="left"
          render={(value) => <Monetary symbol value={value} />}
        />
        <Column
          title="Valor Frete"
          dataIndex="shippingFee"
          key="shippingFee"
          align="left"
          render={(value) => (
            <ShippingFee symbol value={roundDecimals(value, 6)} />
          )}
        />
        <Column
          title="Valor Unitário"
          dataIndex="unitPrice"
          key="unitPrice "
          align="left"
          render={(value) => <Monetary symbol value={value} />}
        />
        <Column
          title="Valor Unitário Consolidado"
          dataIndex="unitPriceConsolidated"
          key="unitPriceConsolidated "
          align="left"
          render={(value, item: CartItem) => (
            <ShippingFee
              symbol
              value={roundDecimals(value + item.shippingFee, 6)}
            />
          )}
        />
        <Column
          title="Valor total"
          dataIndex="subTotal"
          key="subTotal"
          align="left"
          render={(value, item: CartItem) => (
            <ShippingFee
              symbol
              value={roundDecimals(
                value + item.shippingFee * item.unitQuantity,
                2,
              )}
            />
          )}
        />

        <Column
          title="Usuário"
          dataIndex="user"
          key="user"
          align="left"
          render={() => user}
        />
        <Column
          title="Observação"
          dataIndex="observation"
          key="observation"
          align="left"
        />
      </Table>
    </TableOrderSummary>
  );
}
