import { Alert } from 'antd';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type } from 'os';

type AlertType = 'success' | 'info' | 'warning' | 'error' | undefined;

export interface Alert {
  id: string;
  type: AlertType;
  message: string,
  showIcon: boolean,
  timeout: number,
};

export type AlertState = {
  alerts: Alert[]
};

export const initialState: AlertState = {
  alerts: []
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    alertRequest(state, action: PayloadAction<Omit<Alert, 'id'>>) {
      const { type, message, showIcon, timeout} = action.payload
      state.alerts.push({
        id: (Math.random() + 1).toString(36).substring(7),
        type,
        message,
        showIcon,
        timeout,
      })
    },
    alertClose(state, action: PayloadAction<string>) {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload)
    },
  },
  extraReducers: {},
});

export const { actions, reducer } = alertSlice;
