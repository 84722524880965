import { memo } from 'react';
import { Container, ContainerType, Text } from './styles';

export type BadgeProps = {
  backgroundColor: string;
  text: string;
  type?: ContainerType;
};

function Badge({ text, backgroundColor, type }: BadgeProps) {
  return (
    <Container
      type={type}
      backgroundColor={backgroundColor}
      className="badge-component"
    >
      <Text>{text}</Text>
    </Container>
  );
}

export default memo(Badge);
