/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-plusplus */
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { myOrderIntentionEditSelectors } from 'store/state/selectors';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { OrderIntentionStatusEnum } from 'enums/orderIntentionStatus.enum';
import { OrderIntentionOriginEnum } from 'enums/orderIntentionOrigin.enum';
import {
  ModalBody,
  ModalDefault,
  ModalHeader,
  ModalFooter,
} from 'components/UI/Modal';

interface Props {
  observationText: string;
  isVisible: boolean;
  onCancel: () => void;
}

export default function ObservationModal({
  observationText,
  isVisible,
  onCancel,
}: Props) {
  const { t } = useTranslation();

  const [formModal] = Form.useForm();

  const handleCancel = useCallback(() => {
    onCancel();
  }, []);

  return (
    <ModalDefault
      title=""
      width={800}
      visible={isVisible}
      onCancel={handleCancel}
      footer={() => {}}
    >
      <ModalHeader>
        <span>{t('myOrderIntentions.observationModal.title')}</span>
      </ModalHeader>
      <ModalBody>
        <p>{observationText}</p>
      </ModalBody>
      <ModalFooter>
        <Button type="primary" style={{ width: '50%', marginTop: 10 }} onClick={handleCancel}>
          Voltar
        </Button>
      </ModalFooter>
    </ModalDefault>
  );
}
