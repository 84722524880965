import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as S from './styles';

export default function Footer() {
  const { t } = useTranslation('translation', { keyPrefix: 'footer' });

  const navigateForExternalUrl = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  return (
    <S.Root>
      <S.Container>
        <S.Logo />
        <S.RelatedApps>
          <S.RelatedAppButton
            onClick={() =>
              navigateForExternalUrl('https://www.univeiling.com.br/')
            }
          >
            {t('univeiling')}
          </S.RelatedAppButton>
          <S.RelatedAppButton
            onClick={() =>
              navigateForExternalUrl('https://cvhnet.veilingnet.com.br')
            }
          >
            {t('cvhnet')}
          </S.RelatedAppButton>
        </S.RelatedApps>
        <S.ContactUs>
          <S.Address>
            <S.Title>{t('address')}</S.Title>
            <S.Text>{t('street')}</S.Text>
            <S.Text>{t('cityStateZipCode')}</S.Text>
          </S.Address>
          <S.OfficeHours>
            <S.Title>{t('officeHours')}</S.Title>
            <S.Text>
              <Trans t={t} i18nKey="attendence">
                <S.TextBold>Atendimento</S.TextBold>: Das 7h00 às 17h00
              </Trans>
            </S.Text>
            <S.Text>
              <Trans t={t} i18nKey="auction">
                <S.TextBold>Leilão</S.TextBold>:Segunda e quarta-feira às 6h
                <br />
                Terça e sexta-feira às 7h
              </Trans>
            </S.Text>
            <S.Text>
              <Trans t={t} i18nKey="noAuction">
                <S.TextBold>Leilão</S.TextBold>:Segunda e quarta-feira às 6h
                <br />
                Terça e sexta-feira às 7h
              </Trans>
            </S.Text>
            <S.Text>
              <Trans t={t} i18nKey="warningAge">
                <S.TextBold>Leilão</S.TextBold>:Segunda e quarta-feira às 6h
                <br />
                Terça e sexta-feira às 7h
              </Trans>
            </S.Text>
          </S.OfficeHours>
          <S.CustomerService>
            <S.Title>{t('sac')}</S.Title>
            <S.Text>{t('phoneNumber')}</S.Text>
            <S.Text>{t('email')}</S.Text>
          </S.CustomerService>
        </S.ContactUs>
        <S.SocialMedia>
          <S.FacebookIcon
            onClick={() =>
              navigateForExternalUrl(
                'https://www.facebook.com/veilingholambrabrasil',
              )
            }
          />
          <S.InstagramIcon
            onClick={() =>
              navigateForExternalUrl(
                'https://www.instagram.com/veilingholambra/',
              )
            }
          />
        </S.SocialMedia>
        <S.Subtitle
          onClick={() =>
            navigateForExternalUrl(
              'https://veiling.com.br/aviso-de-privacidade/',
            )
          }
        >
          {t('privacyWarning')}
        </S.Subtitle>
        <S.Text>{t('veilingHolambraProduct')}</S.Text>
      </S.Container>
    </S.Root>
  );
}
