import { createSelector } from '@reduxjs/toolkit';
import { myOrderIntentionEditState } from './myOrderIntentionEditSlice';

export const getMyOrderIntentionEdit = (state: { myOrderIntentionEdit: myOrderIntentionEditState }) =>
  state.myOrderIntentionEdit as myOrderIntentionEditState;

export const getShowIntentionModal = createSelector(
  [getMyOrderIntentionEdit],
  (state) => state.showIntentionModal,
);

export const getCurrentStep = createSelector(
  [getMyOrderIntentionEdit],
  (state) => state.currentStep,
);

export const getPagination = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.pagination,
);

export const getReloadPage = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.reloadPage,
);

export const getCustomer = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.customer,
);

export const getBuyIntentionEdit = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.buyIntentionEdit,
);

export const getOffersList = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.offersList,
);

export const getSubTotalList = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.subTotalPriceList,
);

export const getSubmitOffers = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.submitOffers,
);

export const getIdsProductSelected = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idsProductsSelected,
);

export const getIdsProductAccepted = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idsProductsAccepted,
);

export const getDeliveryDate = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.deliveryDateForOffers,
);

export const getComercialDate = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.comercialDateForOffers,
);

export const getDeliveryDateFinish = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.deliveryDateForFinish,
);

export const getComercialDateFinish = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.comercialDateForFinish,
);

export const getIdSelectedNow = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idSelectedNow,
);

export const getIsLoading = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.isLoading,
);

export const getShowModalOffers = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.showModalOffers,
);

export const getOverviewEdit = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.overviewEdit,
);

export const getIdProductAdded = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idProductAdded,
);

export const getTotalPriceOffers = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.totalPriceOffers,
);

export const getTotalPriceOffersOverview = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.totalPriceOffersOverview,
);

export const getProducers = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.producers,
);

export const getReloadOffers = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.reloadOffers,
);

export const getFormInfo = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.formInfo,
);

export const getRefreshForm = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.refreshForm,
);

export const getShowModalCancel = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.showmodalCacel,
);

export const getIdCancel = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idCancel,
);

export const getShowModalRepublish = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.showmodalRepublish,
);

export const getIdRepublish = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idRepublish,
);

export const getClearCheckbox = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.clearCheckBox,
)

export const getShowModalAprove = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.showmodalAprove,
)

export const getIdsBulkAprove = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idsBulkAprove,
)

export const getShowModalReprove = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.showmodalReprove,
)

export const getIdsBulkReprove = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idsBulkReprove,
)

export const getIdsJustification = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.idsJustification,
)

export const getItemsJustification = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.itemsJustification,
)

export const getShowModalNoRepublish = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.showmodalNoRepublish,
)

export const getShowModalNoJustification = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.showmodalNoJustification,
)

export const getJustificationText = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.justificationText,
)

export const getFilterExport = createSelector(
  getMyOrderIntentionEdit,
  (state) => state.filterExport,
)