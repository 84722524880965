import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Alert as AlertSlice } from 'store/state/alert/alertSlice';
import { alertActions } from 'store/state/slices';

import { Container, Message } from './styles';

export default function Alert({
  id,
  type,
  message,
  showIcon,
  timeout,
}: AlertSlice) {
  const dispatch = useDispatch();

  const [closing, setClosing] = useState(false);

  const handleClose = useCallback(() => {
    setClosing(true);
    setTimeout(() => {
      dispatch(alertActions.alertClose(id));
    }, 600);
  }, [dispatch, id]);

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, timeout);
  }, [timeout, handleClose]);

  return (
    <Container isClosing={closing ? 1 : 0} onClick={() => handleClose()}>
      <Message message={message} type={type} showIcon={showIcon} />
    </Container>
  );
}
