import styled from 'styled-components';
import { Form, Modal, Space } from 'antd';

export const ModalHeader = styled.div`
  background-color: #f78e1e;
  padding: 24px;
  > span {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
  .ant-col {
    > span {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }
    button {
      font-size: 14px;
      max-width: 98px;
      background-color: #f78e1e;
      border-color: #fff;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #f78e1e;
      }
    }
  }
`;
export const ModalBody = styled.div`
  margin-top: -20px;
  padding: 24px;
`;

export const ModalFooter = styled.div`
  padding: 24px;
  border-top: 1px solid #d9d9d9;
`;

export const ModalAdd = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
`;

export const ModalHeaderAdd = styled.div`
  background-color: #f78e1e;
  padding: 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  span {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
`;
export const ModalBodyAdd = styled.div`
  padding: 24px;
`;

export const AddForm = styled(Form)`
  width: 100%;
`;

export const ModalFooterInformationText = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:space-between;
    margin-bottom: -7px; 
    margin-right: 15px;
    > p {
        text-align: start;
        margin: 0;
        margin-right: 8px;
        padding-bottom: 16px;
        margin-bottom: -3px;
        color: #8c8c8c;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    } 
    > span {
        text-align: start;
        color: #353839;
        padding-bottom: 16px;      
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
    }
`;

export const ContentButtons = styled(Space)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  margin-top: 15px;
`;
