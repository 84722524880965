import styled, { css } from 'styled-components';
import logo from 'assets/logos/logo.svg';
import footerLeft from 'assets/backgrounds/footerLeft.svg';
import footerRight from 'assets/backgrounds/footerRight.svg';
import { Button, Col, Row } from 'antd';
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';

export const Root = styled.footer`
  display: block;
  background-color: #f78e1e;
  background-image: url(${footerLeft}), url(${footerRight});
  background-position: left center, right center;
  background-repeat: no-repeat;
  background-size: 300.12px 320px, 300.12px 320px;
  color: transparent;
  height: 480px;
  @media (max-width: 768px) {
    height: 640px;
  }
  text-decoration: none;
  /* position: sticky; */
  /* min-height: fit-content; */
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* width: 100vw; */

  > div {
    height: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: fit-content;
  padding: 24px;
`;

export const Logo = styled.a`
  display: block;
  width: 215px;
  height: 108px;
  background-image: url(${logo});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  text-decoration: none;
  margin-bottom: 16px;
`;

export const RelatedApps = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  > * {
    margin: 0px 16px;
  }
`;

export const RelatedAppButton = styled(Button).attrs(() => ({
  type: 'ghost',
}))`
  width: 160px;
  height: 32px;
  color: white;
`;

export const ContactUs = styled(Row).attrs({ gutter: 50 })`
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ContactUsColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  > * {
    margin: 2px 0px;
  }
`;

export const Address = styled(ContactUsColumn).attrs({ lg: 8 })`
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;
export const OfficeHours = styled(ContactUsColumn).attrs({ lg: 8 })`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;
export const CustomerService = styled(ContactUsColumn).attrs({ lg: 8 })`
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const Text = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
`;

export const TextBold = styled(Text)`
  font-weight: bold;
`;

export const Title = styled(TextBold)`
  font-size: 16px;
`;

export const Subtitle = styled(Text)`
  cursor: pointer;
  font-size: 14px;
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > * {
    margin: 0px 8px;
  }
`;

const socialMediaIcon = css`
  width: 16px;
  height: 16px;
  color: white;
`;

export const FacebookIcon = styled(FacebookOutlined)`
  ${socialMediaIcon}
`;
export const InstagramIcon = styled(InstagramOutlined)`
  ${socialMediaIcon}
`;
