import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type MonetaryProps = {
  value: number | string;
  symbol?: boolean;
};

export default function Monetary(props: MonetaryProps) {
  const { value, symbol = false } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'common.monetary' });
  const valueWithSymbol = useCallback(
    (value) => t('symbolValue', { value }),
    [t],
  );
  const valueWithoutSymbol = useCallback((value) => t('value', { value }), [t]);

  const renderValue = useMemo(() => {
    if (value === 0.0 || Number.isNaN(value)) {
      return '-';
    }
    if (symbol) {
      return valueWithSymbol(value.toString());
    }
    return valueWithoutSymbol(value.toString());
  }, [value, symbol, valueWithSymbol, valueWithoutSymbol]);

  return <span>{renderValue}</span>;
}
