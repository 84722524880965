import { useAuth } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLogout } from 'services/config';
import { errorSelectors } from 'store/state/selectors';
import { errorActions, sessionActions } from 'store/state/slices';
import Alerts from '../Alerts';
import * as S from './styles';

type ScreenProps = {
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
};

const UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST';

export default function Screen(props: ScreenProps) {
  const { header, subHeader, children, footer } = props;

  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector(errorSelectors.getError);

  useEffect(() => {
    if (error === UNAUTHORIZED_REQUEST) {
      setLogout();
      dispatch(sessionActions.logout());
      navigate('/logout');
      dispatch(errorActions.clear());
    }
  }, [dispatch, error, navigate]);

  return (
    <S.Root>
      <S.Header>{header}</S.Header>
      <S.SubHeader>{subHeader}</S.SubHeader>
      <S.Content>{children}</S.Content>
      <S.Footer>{footer}</S.Footer>
      {/* {isAuthenticated && <Announcement />} */}
      <Alerts />
    </S.Root>
  );
}
