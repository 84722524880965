import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .ant-divider {
    margin: 12px 0 !important;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Body = styled.div`
  padding: 16px;
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    width: 100%;
  }
`;