/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-plusplus */
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { myOrderIntentionEditSelectors } from 'store/state/selectors';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useMyOrderIntentionsContext } from 'contexts/MyOrderIntentionsContext';
import { OrderIntentionStatusEnum } from 'enums/orderIntentionStatus.enum';
import { OrderIntentionOriginEnum } from 'enums/orderIntentionOrigin.enum';
import { ModalBody, ModalDefault, ModalHeader } from 'components/UI/Modal';

interface Props {
  onSubmit: (value: any) => void;
}

export default function JustificationModal({ onSubmit }: Props) {
  const {
    selectedOrderIntentions,
    showJustificationModal,
    setShowJustificationModal,
    setSelectedOrderIntentions,
    setSelectedOrderIntentionsKeys,
  } = useMyOrderIntentionsContext();

  const { t } = useTranslation();

  const [formModal] = Form.useForm();

  const justificationItems = useSelector(
    myOrderIntentionEditSelectors.getItemsJustification,
  );
  const validatorIC =
    justificationItems.length &&
    justificationItems[0].origin === OrderIntentionOriginEnum.ORDER_INTENTION;

  const handleCancel = useCallback(() => {
    setShowJustificationModal(false);
    setSelectedOrderIntentions([]);
    setSelectedOrderIntentionsKeys([]);
  }, [setShowJustificationModal]);

  const onFinish = useCallback(
    (values: any) => {
      if (validatorIC) {
        setSelectedOrderIntentions([]);
        setSelectedOrderIntentionsKeys([]);
      }
      if (selectedOrderIntentions) {
        onSubmit(values.justification);
      }
      setShowJustificationModal(false);
    },
    [selectedOrderIntentions, setShowJustificationModal, onSubmit],
  );

  useEffect(() => {
    if (showJustificationModal && selectedOrderIntentions) {
      formModal.setFieldsValue({
        justification: selectedOrderIntentions[0]?.justification,
      });
    }
  }, [selectedOrderIntentions, showJustificationModal, formModal]);

  return (
    <ModalDefault
      title=""
      width={800}
      visible={showJustificationModal}
      onCancel={handleCancel}
      footer={() => {}}
    >
      <ModalHeader>
        <span>{t('myOrderIntentions.justificationModal.title')}</span>
      </ModalHeader>
      <ModalBody>
        <Form form={formModal} onFinish={onFinish} layout="vertical">
          <Row gutter={16}>
            <Col lg={24}>
              <Form.Item name="justification">
                <TextArea
                  disabled={validatorIC || justificationItems[0]?.status === OrderIntentionStatusEnum.RECUSADO}
                  autoFocus
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      formModal?.submit();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20 }} justify="end">
            {!validatorIC && (
              <Col xs={24} md={12}>
                <Button
                  style={{ width: '100%', marginTop: 10 }}
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </Col>
            )}

            <Col xs={24} md={12}>
              <Button
                style={{ width: '100%', marginTop: 10 }}
                disabled={false}
                type="primary"
                htmlType="submit"
              >
                {validatorIC ? 'Voltar' : 'Aplicar'}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </ModalDefault>
  );
}
