import React from 'react';
import { useAuth } from 'hooks/useAuth';
import { RouteProps } from 'react-router-dom';
import RequireAuth from './RequireAuth';

type PrivateRouteProps = RouteProps;

export default function PrivateRoute(props: PrivateRouteProps) {
  const { element = null } = props;
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <RequireAuth />;
}
