import { HistoryOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { ComponentViewQrCode } from 'enums/componentViewQrCode';

import { Body, Footer, Header, Root } from './styles';

type props = {
  setComponentView: (value: ComponentViewQrCode) => void
};

export default function Home({setComponentView}: props) {
  return (
    <Root>
      <Header>
        <Button icon={<HistoryOutlined />} onClick={() => setComponentView(ComponentViewQrCode.HISTORY)}/>
      </Header>
      <Divider />
      <Body>Leia o QRCode do produto desejado ou acesse o seu histórico.</Body>
      <Footer>
        <Button onClick={() => setComponentView(ComponentViewQrCode.SCAN)}>Ler QRCode</Button>
      </Footer>
    </Root>
  );
}
