import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetOrderOpNaturesQuery } from 'services/ecommerceApi';

interface Props extends SelectProps<number | ''> {
  allowEmpty?: boolean;
}
export default function OrderOpNaturesSelect({ allowEmpty = false, ...props }: Props) {
  const { t } = useTranslation();

  const { data: orderOpNatures, isLoading } = useGetOrderOpNaturesQuery();

  return (
    <Select
      showSearch
      allowClear
      loading={isLoading}
      placeholder={t('generals.selectPlaceholder')}
      optionFilterProp="children"
      filterOption
      {...props}
    >
      {allowEmpty ? (
        <Select.Option key="all" value="">
          Todas
        </Select.Option>
      ) : null}
      {orderOpNatures?.map((productQuality) => (
        <Select.Option key={productQuality.id} value={productQuality.id}>
          {productQuality.description}
        </Select.Option>
      ))}
    </Select>
  );
}
