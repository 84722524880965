import { createAsyncThunk } from '@reduxjs/toolkit';
import * as buyIntentionServices from 'services/buyIntentionService';
import * as BuyIntentionModels from 'model/BuyIntention';
import { OrderIntention, FetchXMLdownload } from 'model/OrderIntention';
import { alertActions } from 'store/state/slices';
import { AxiosError } from 'axios';
import { Key } from 'react';

export const fetchProductsList = createAsyncThunk(
  `myOrderIntentionEdit/fetchProductsList`,
  async (params: any) => {
    try {
      const data = await buyIntentionServices.getProducts(params);
      return data;
    } catch (err: any) {
      return false;
    }
  },
);

export const fetchPacking = createAsyncThunk(
  `myOrderIntentionEdit/fetchPacking`,
  async (params: BuyIntentionModels.FetchPackings) => {
    try {
      const data = await buyIntentionServices.getPackings(params);
      return data.data;
    } catch (err: any) {
      return false;
    }
  },
);

export const fetchOffersByProducts = createAsyncThunk(
  `myOrderIntentionEdit/fetchOffersByProducts`,
  async (params: {
    order: OrderIntention;
    accountCode: string | undefined;
  }) => {
    try {
      let unitPrice = 0;
      let unitPriceWithFee = 0;
      const data = await buyIntentionServices.getOffersByProductsById({
        id: params.order.productId,
        accountCode: params.accountCode,
      });
      const quality = await buyIntentionServices.getQuality();
      const packing = await buyIntentionServices.getPackings({
        siteId: params.order.siteId,
        productId: params.order.productId,
      });
      const packingSelected = packing?.data.find(
        (pack: any) => pack.code === params.order.packingCode,
      );
      if (packingSelected.additionalCost === 'S') {
        const price =
          params.order.unitPrice - parseFloat(packingSelected?.packingCost);
        unitPrice = parseFloat(price.toFixed(2));
        unitPriceWithFee = parseFloat(price.toFixed(2));
      } else {
        unitPrice = parseFloat(params.order.unitPrice.toFixed(2));
        unitPriceWithFee = parseFloat(params.order.unitPrice.toFixed(2));
      }
      if (data.data.length && data.data[0].shippingFeeFilials) {
        unitPriceWithFee +=
          data.data[0].shippingFeeFilials.productShippingValue;
      }
      const newdata: any[] =
        data.data.map((product: BuyIntentionModels.BuyIntentionData) => ({
          ...product,
          id: (Math.random() + 1).toString(36).substring(7),
          qualitySelect: params.order.qualityCode,
          qualityList: quality.data,
          packingSelected,
          productPreference: packing?.data,
          quantityPacking: params.order.quantityPacking,
          quantityUnit: params.order.quantityUnit,
          unitPrice: `${unitPrice}`,
          unitPriceNumber: unitPrice,
          totalPrice:
            params.order.quantityPacking *
            params.order.quantityUnit *
            (unitPriceWithFee +
              (packingSelected?.additionalCost === 'S'
                ? packingSelected?.packingCost
                : 0)),
          embPrice: 0,
          observationText: params.order.observation,
          bought: false,
          intention: false,
        })) || [];
      const newDataFilter = newdata.filter(
        (offer: any) => offer.productId === params.order.productId,
      );
      newDataFilter[0].site = newDataFilter[0].siteList.find(
        (site: any) => site.id === params.order.siteId,
      );
      return newDataFilter;
    } catch (err: any) {
      return false;
    }
  },
);

export const putBuyIntention = createAsyncThunk(
  `buyIntention/putBuyIntention`,
  async (params: any, { dispatch }) => {
    try {
      const data = await buyIntentionServices.putBuyIntention(params);
      dispatch(
        alertActions.alertRequest({
          message: 'Intenção de Compra Editada com sucesso!',
          type: 'success',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return true;
    } catch (err: any) {
      const error = err as AxiosError;
      return false;
    }
  },
);

export const cancelBuyIntention = createAsyncThunk(
  `buyIntention/cancelBuyIntention`,
  async (params: number[] | Key[], { dispatch }) => {
    try {
      const data = await buyIntentionServices.deleteBuyIntention(params);
      dispatch(
        alertActions.alertRequest({
          message: 'Intenção de compra cancelada com sucesso',
          type: 'success',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return true;
    } catch (err: any) {
      const error = err as AxiosError;
      return false;
    }
  },
);

export const republishBuyIntention = createAsyncThunk(
  `buyIntention/republishBuyIntention`,
  async (params: number[] | Key[], { dispatch }) => {
    try {
      const data = await buyIntentionServices.republishBuyIntention(params);
      dispatch(
        alertActions.alertRequest({
          message: 'Intenção de Compra republicada!',
          type: 'success',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return true;
    } catch (err: any) {
      const error = err as AxiosError;
      return false;
    }
  },
);

export const aproveOrReproveDirectSale = createAsyncThunk(
  `buyIntention/aproveOrReproveDirectSale`,
  async (params: BuyIntentionModels.UpdateDirectSale, { dispatch }) => {
    try {
      const data = await buyIntentionServices.updateDirectSale(params);
      dispatch(
        alertActions.alertRequest({
          message: params.approve
            ? 'Pedido aprovado com sucesso'
            : 'Pedido recusado com sucesso',
          type: 'success',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return true;
    } catch (err: any) {
      dispatch(
        alertActions.alertRequest({
          message: err.response.data,
          type: 'error',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return false;
    }
  },
);

export const exportPurchases = createAsyncThunk(
  'salesReportEcommerce/exportPurchases',
  async (fetchListParams: FetchXMLdownload, { dispatch }) => {
    try {
      const response = await buyIntentionServices.onExport(fetchListParams);
      const data: string = response?.data?.data;
      const binaryString = window.atob(data);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      const fileData = bytes;

      const blob = new Blob([fileData], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.style.display = 'none';
      const fileName = 'Gerenciamento_de_pedidos.xlsx';
      link.download = fileName;
      link.click();
      return true;
    } catch (error) {
      dispatch(
        alertActions.alertRequest({
          message: 'Exportação falhou!',
          type: 'error',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return false;
    }
  },
);
