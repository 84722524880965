import { Button, Select, Empty } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  background-color: #f5f5f5;
`;

export const EmptyComponent = styled(Empty)`
  min-height: 332px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const Divider = styled.div`
  background: #d9d9d9;
  height: 1px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f78e1e;
  padding: 16px 0px;
`;

export const HeaderLine1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;

  @media (max-width: 500px) {
    margin: 0px 30px;
  }
`;

export const YourShoppingCart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  > * {
    margin: 0px 8px;
  }
  @media (max-width: 500px) {
    span.anticon.anticon-shopping-cart {
      font-size: 16px !important;
    }
  }
`;

export const YourShoppingCartText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const HeaderLine2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 40px;

  @media (max-width: 500px) {
    margin: 0px 30px;
    flex-direction: column;
  }
`;

export const HeaderLine2LeftGroup = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0px 8px;
  }

  @media (max-width: 500px) {
    margin: 16px 0;
    justify-content: center;
    width: 100%;
  }
`;

export const Sorter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px;
`;

export const OrderSelect = styled(Select)`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

export const ShowTotalResults = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
`;

export const HeaderLine2RightGroup = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 500px) {
    align-self: center;
  }
`;

export const ContinueShoppingButton = styled(Button)`
  width: 167px;
  height: 32px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  padding: 8px 0px;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
`;

export const ProductCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const TabsTypeProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 16px;

  gap: 16px;
`;

export const GroupButtonAndTimer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    font-size: 14px;
  }

  &.button-in-focus > button > span {
    color: #f78e1e;
    font-weight: 700;
  }
`;

export const TimerLabel = styled.span`
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 8px;
  margin-right: 32px;
  .ant-statistic {
    linhe-height: inherit;
    display: inline;
  }
  .ant-statistic-content {
    display: inherit;
  }
  .ant-statistic-content-value {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    display: inherit;
  }
`;

export const FooterProductCart = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
`;

export const GroupInformationTotal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;

  &.set-in-flex-end {
    justify-content: flex-end;
  }
`;

export const GroupInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 992px) {
    &.align-start {
      align-items: flex-start;
    }
    &.align-end {
      align-items: flex-end;
    }
    flex-direction: column;
  }

  @media (max-width: 427px) {
    &.align-end {
      align-items: flex-start;
    }
  }
`;

export const SpanInformation = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-right: 24px;

  &.last-item {
    margin-right: 0 !important;
  }

  @media (max-width: 992px) {
    margin-right: 0px;

    &.last-item {
      margin-right: 0 !important;
    }
  }
`;

export const SpecialSpan = styled.span`
  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 18px;
  }
  color: #353839;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 8px;
`;

export const GroupActionsButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .margin-right {
    margin-right: 16px;
  }
`;

export const Tag = styled.span<{
  status: string;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.status !== 'success' ? `${props.status === 'error' ? '#f04134' : '#d9d9d9'}` : '#00a854'} !important;
  color: ${props => props.status === 'success' || props.status === 'error' ? '#fbfbfb' : '#222222'} !important;
  z-index: 2;
  border-radius: 16px;
  height: 22px;
  padding: 4px 8px;
  top: -11px;
  right: -8px;
`;
