import { memo } from 'react';

import { Container } from './styles';

interface Props {
  voucher: string;
}

function InfoVoucher({ voucher }: Props) {
  return <Container>{voucher}</Container>;
}

export default memo(InfoVoucher);
