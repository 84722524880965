import { useSelector } from 'react-redux';
import * as MyOrderIntentionsEditSelector from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSelectors';
import AddBuyIntentionTable from '../List/AddBuyIntentionTable';

export default function Step02Resume() {
  const tableData = useSelector(MyOrderIntentionsEditSelector.getOverviewEdit);
  return (
    <div key="1">
      <AddBuyIntentionTable tableData={tableData} />
    </div>
  );
}
