import styled from "styled-components";

export const FilterComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div.ant-form-item-label {
    padding: 0 !important;
  }

  label {
    height: auto !important;
  }

  .ant-form-item {
    margin-bottom: 16px !important;
  }
`;

export const LabelFormItem = styled.span``;