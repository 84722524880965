import {
  ClearOutlined,
  DownloadOutlined,
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import { useMyOrderIntentionsContext } from 'contexts/MyOrderIntentionsContext';
import { OrderIntentionStatusEnum } from 'enums/orderIntentionStatus.enum';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as MyOrderIntentionEditCalls from 'store/state/myOrderIntentionEdit/apiCalls';
import * as MyOrderIntentionEditSelectors from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSelectors';
import { actions as MyOrderIntentionEditActions } from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSlice';
import {
  myOrderIntentionEditSelectors,
  sessionSelectors,
} from 'store/state/selectors';
import { alertActions, sessionActions } from 'store/state/slices';
import HideFilters from './HideFilters';
import * as S from './styles';

const { Option } = Select;
interface Props {
  onSubmit: () => void;
}
export default function Filter({ onSubmit }: Props) {
  const dispatch = useDispatch();
  const { formFilter, setFilters, activeFilters } =
    useMyOrderIntentionsContext();
  const { t } = useTranslation();
  const [showHideFilters, setShowHideFilters] = useState<boolean>(false);
  const [onlyPending, setOnlyPending] = useState<boolean>(true);
  const { customers } = useSelector(sessionSelectors.getRoot);

  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );
  const selectedCustomerIdFilter = useSelector(
    sessionSelectors.getSelectedCustomerIdFilter,
  );

  const filterExport = useSelector(
    myOrderIntentionEditSelectors.getFilterExport,
  );

  const refreshForm = useSelector(MyOrderIntentionEditSelectors.getRefreshForm);

  const defaultDate = {
    startDate: moment(moment(), 'YYYY-MM-DD'),
    endDate: moment(moment(), 'YYYY-MM-DD'),
  };

  const formatCustomerName = (customerName: string) => {
    if (customerName.length <= 22) return customerName;

    return `${customerName.substring(0, 22)}...`;
  };

  const setSelectedCustomer = useCallback(
    (value) => {
      dispatch(sessionActions.setSelectedCustomerFilter(value));
    },
    [dispatch],
  );

  const handleGenerateReport = useCallback(() => {
    const filters = formFilter.getFieldsValue();
    const completeFilters = {
      ...filters,
      date: filters.date && [
        moment(filters.date[0]).format('DD/MM/YYYY'),
        moment(filters.date[1]).format('DD/MM/YYYY'),
      ],
    };
    const completeActive = {
      ...activeFilters,
      date: activeFilters.date && [
        moment(activeFilters.date[0]).format('DD/MM/YYYY'),
        moment(activeFilters.date[1]).format('DD/MM/YYYY'),
      ],
    };

    if (JSON.stringify(completeFilters) === JSON.stringify(completeActive)) {
      dispatch(MyOrderIntentionEditCalls.exportPurchases(filterExport));
    } else {
      dispatch(
        alertActions.alertRequest({
          message: t('myOrderIntentions.searchAgain'),
          type: 'warning',
          showIcon: true,
          timeout: 3000,
        }),
      );
    }
  }, [activeFilters, dispatch, filterExport, formFilter, t]);

  useEffect(() => {
    formFilter.setFieldsValue({
      date: [defaultDate.startDate, defaultDate.endDate],
      'Data.FilterBy': 'P',
    });
  }, []);

  useEffect(() => {
    if (refreshForm) {
      formFilter.submit();
      dispatch(MyOrderIntentionEditActions.setRefreshForm(false));
    }
  }, [dispatch, formFilter, refreshForm]);

  useEffect(() => {
    formFilter.setFieldsValue({
      'Data.CustomerId': selectedCustomerId,
    });
  }, [formFilter, selectedCustomerId]);

  useEffect(() => {
    formFilter.resetFields([
      'Data.NatureOperationId',
      'Data.Origin',
      'Data.ProductCategoryId',
      'Data.ProductGroupId',
      'Data.ProductId',
      'Data.ProductQualityId',
      'Data.PublishedBy',
      'Data.Recipient',
      'Data.SiteId',
    ]);
    setFilters((oldValue) => ({
      ...oldValue,
      'Data.NatureOperationId': undefined,
      'Data.Origin': undefined,
      'Data.ProductCategoryId': undefined,
      'Data.ProductGroupId': undefined,
      'Data.ProductId': undefined,
      'Data.ProductQualityId': undefined,
      'Data.PublishedBy': undefined,
      'Data.Recipient': undefined,
      'Data.SiteId': undefined,
    }));
  }, [formFilter, setFilters, showHideFilters]);

  return (
    <Form
      layout="vertical"
      form={formFilter}
      onFinish={onSubmit}
      initialValues={{
        'Data.StartDate': undefined,
        'Data.EndDate': undefined,
        'Data.Status': 'S',
        'Data.Origin': undefined,
        'Data.ProductCategoryId': undefined,
        'Data.ProductGroupId': undefined,
        'Data.ProductId': undefined,
        'Data.ProductQualityId': undefined,
        'Data.SiteId': undefined,
        'Data.NatureOperationId': undefined,
        'Data.Recipient': undefined,
        'Data.PublishedBy': undefined,
        'Data.CustomerId': undefined,
      }}
    >
      <Row gutter={16}>
        <Col lg={4}>
          <Form.Item
            label={t('myOrderIntentions.formFilter.customers')}
            name="Data.CustomerId"
          >
            {customers?.length && (
              <Select
                showSearch
                optionFilterProp="children"
                allowClear
                defaultValue={selectedCustomerId}
                value={selectedCustomerIdFilter}
                onChange={setSelectedCustomer}
              >
                <Option
                  key="Todos os clientes"
                  title="Todos os clientes"
                  value={0}
                >
                  TODOS OS CLIENTES
                </Option>
                {customers.map(({ id, accountCode, name }) => (
                  <Option
                    key={id}
                    value={id}
                    title={`${accountCode} - ${name}`}
                  >
                    {`${accountCode} - ${formatCustomerName(name)}`}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col lg={4}>
          <Form.Item
            label={t('myOrderIntentions.formFilter.saleDate')}
            name="date"
            rules={[
              {
                required: !onlyPending,
                message: 'Selecione o período.',
              },
            ]}
          >
            <DatePicker.RangePicker
              defaultValue={[defaultDate.startDate, defaultDate.endDate]}
              placeholder={[
                t('myOrderIntentions.formFilter.since'),
                t('myOrderIntentions.formFilter.until'),
              ]}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>

        <Col lg={4}>
          <Form.Item
            label={t('myOrderIntentions.formFilter.filterDateBy')}
            name="Data.FilterBy"
            rules={[
              {
                required: !onlyPending,
                message: 'Selecione o Filtrar por.',
              },
            ]}
          >
            <Select
              placeholder={t('myOrderIntentions.formFilter.select')}
              defaultValue="P"
              allowClear
            >
              <Option key="P" value="P">
                {t('myOrderIntentions.formFilter.publishDate')}
              </Option>
              <Option key="E" value="E">
                {t('myOrderIntentions.formFilter.deliveryDate')}
              </Option>
              <Option key="F" value="F">
                {t('myOrderIntentions.formFilter.fatDate')}
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col lg={4}>
          <Form.Item
            label={t('myOrderIntentions.formFilter.status')}
            name="Data.Status"
          >
            <Select
              allowClear
              defaultValue={OrderIntentionStatusEnum.AGUARDANDO_PENDENTE}
              disabled={onlyPending}
              placeholder="Selecione"
            >
              <Option
                key={OrderIntentionStatusEnum.CONFIRMADO}
                value={OrderIntentionStatusEnum.CONFIRMADO}
              >
                {t('myOrderIntentions.formFilter.statusOptions.generate')}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.CONFIRMADO_PARCIAL}
                value={OrderIntentionStatusEnum.CONFIRMADO_PARCIAL}
              >
                {t('myOrderIntentions.formFilter.statusOptions.notGenerate')}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.CONFIRMADO_ALTERACAO}
                value={OrderIntentionStatusEnum.CONFIRMADO_ALTERACAO}
              >
                {t('myOrderIntentions.formFilter.statusOptions.changed')}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.PENDENTE}
                value={OrderIntentionStatusEnum.PENDENTE}
              >
                {t('myOrderIntentions.formFilter.statusOptions.open')}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.AGUARDANDO_GERACAO_PEDIDO}
                value={OrderIntentionStatusEnum.AGUARDANDO_GERACAO_PEDIDO}
              >
                {t('myOrderIntentions.formFilter.statusOptions.process')}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.AGUARDANDO_PRODUTOR}
                value={OrderIntentionStatusEnum.AGUARDANDO_PRODUTOR}
              >
                {t(
                  'myOrderIntentions.formFilter.statusOptions.waitingProducer',
                )}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.RECUSADO}
                value={OrderIntentionStatusEnum.RECUSADO}
              >
                {t('myOrderIntentions.formFilter.statusOptions.refused')}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.CANCELADO}
                value={OrderIntentionStatusEnum.CANCELADO}
              >
                {t('myOrderIntentions.formFilter.statusOptions.canceled')}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.EXPIRADO}
                value={OrderIntentionStatusEnum.EXPIRADO}
              >
                {t('myOrderIntentions.formFilter.statusOptions.programmed')}
              </Option>
              <Option
                key={OrderIntentionStatusEnum.ERRO}
                value={OrderIntentionStatusEnum.ERRO}
              >
                {t('myOrderIntentions.formFilter.statusOptions.error')}
              </Option>
              <Option
                disabled
                style={{ display: 'none' }}
                key={OrderIntentionStatusEnum.AGUARDANDO_PENDENTE}
                value={OrderIntentionStatusEnum.AGUARDANDO_PENDENTE}
              >
                {t('myOrderIntentions.formFilter.statusOptions.waitingAndOpen')}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          lg={3}
          style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
        >
          <Form.Item name="OnlyPending" noStyle>
            <S.SwitchComponent>
              <Switch
                defaultChecked
                onChange={(checked) => {
                  formFilter.setFieldsValue({
                    'Data.Status': checked ? 'S' : null,
                  });
                  setOnlyPending(checked);
                }}
              />
            </S.SwitchComponent>
          </Form.Item>
          <span>Exibir apenas pendentes</span>
        </Col>
        <Col
          lg={2}
          style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
        >
          <Button
            icon={<FilterOutlined />}
            onClick={() => setShowHideFilters((prevState) => !prevState)}
          />
          <Button icon={<SearchOutlined />} htmlType="submit" type="primary" />
          <Button
            icon={<ClearOutlined />}
            onClick={() => {
              formFilter.resetFields();
              formFilter.setFieldsValue({ date: null, 'Data.FilterBy': null });
            }}
          />
          <S.ActionsContainer>
            <Tooltip title={t('myOrderIntentions.exportReport')}>
              <Button
                disabled={!filterExport}
                className="margin-right"
                onClick={handleGenerateReport}
                icon={<DownloadOutlined />}
              />
            </Tooltip>
          </S.ActionsContainer>
        </Col>
      </Row>
      {showHideFilters ? <HideFilters /> : null}
    </Form>
  );
}
