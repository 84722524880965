import { useSelector } from 'react-redux';

import { alertSelectors } from 'store/state/selectors';

import Alert from './Alert';
import { Container } from './styles';

export default function Alerts() {
  const alerts = useSelector(alertSelectors.getAlerts);

  return (
    <Container>
      {alerts.map((alert) => (
        <Alert key={alert.id} {...alert} />
      ))}
    </Container>
  );
}
