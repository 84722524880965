import { useCallback, useState, useEffect } from 'react';
import * as BuyIntentionModels from 'model/BuyIntention';
import {
  DatePicker,
  Form,
  Button,
  Row,
  Select,
  DatePickerProps,
  Input,
} from 'antd';
import {
  sessionActions,
  myOrderIntentionEditActions,
  alertActions,
} from 'store/state/slices';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as MyOrdersIntentionEditSelectors from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSelectors';
import * as BuyIntentionCalls from 'store/state/buyIntention/apiCalls';
import moment, { Moment } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as S from './style';

const { Option } = Select;

function prepareDate(date: Moment) {
  return date.set('hour', 0).format('YYYY-MM-DD');
}

export default function FormBuyIntention() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentStep = useSelector(
    MyOrdersIntentionEditSelectors.getCurrentStep,
  );
  const producers = useSelector(MyOrdersIntentionEditSelectors.getProducers);
  const formInfo = useSelector(MyOrdersIntentionEditSelectors.getFormInfo);
  const modalIsVisible = useSelector(
    MyOrdersIntentionEditSelectors.getShowIntentionModal,
  );

  const [formBuyIntention] = Form.useForm();
  const [deliveryDate, setDeliveryDate] = useState<string | undefined>(
    formInfo?.deliveryDate,
  );
  const [comercialDate, setComercialDate] = useState<string | undefined>(
    undefined,
  );
  const [product, setProduct] = useState<string | undefined>(undefined);
  const [producerId, setProducerId] = useState<number | undefined>(undefined);

  const onChangeDeliveryDate: DatePickerProps['onChange'] = (dateString) => {
    const date = moment(dateString).format('YYYY-MM-DD');
    const dateFinish = moment(dateString).format('YYYY-MM-DDTHH:mm:ss.SSSS');
    setComercialDate(undefined);
    formBuyIntention.setFieldsValue({ comercialDate: null });
    if (dateString !== null) {
      setDeliveryDate(date);
      dispatch(myOrderIntentionEditActions.setDeliveryDateFinish(dateFinish));
    } else {
      setDeliveryDate(undefined);
      dispatch(myOrderIntentionEditActions.setDeliveryDateFinish(''));
      dispatch(myOrderIntentionEditActions.setComercialDateFinish(''));
    }
  };

  const onChangeComercialDate: DatePickerProps['onChange'] = (dateString) => {
    const date = moment(dateString).format('YYYY-MM-DD');
    const dateFinish = moment(dateString).format('YYYY-MM-DDTHH:mm:ss.SSSS');
    if (dateString !== null) {
      setComercialDate(date);
      dispatch(myOrderIntentionEditActions.setComercialDateFinish(dateFinish));
    } else {
      setComercialDate(undefined);
      dispatch(myOrderIntentionEditActions.setComercialDateFinish(''));
    }
  };

  const onSubmit = useCallback(
    (formValues: BuyIntentionModels.FormFilters) => {
      const delivery = formBuyIntention.getFieldValue('deliveryDate');
      const comercial = formBuyIntention.getFieldValue('comercialDate');
      dispatch(
        sessionActions.setDates({
          billingDate: comercial ? prepareDate(comercial) : '',
          deliveryDate: delivery ? prepareDate(delivery) : '',
        }),
      );
      dispatch(
        myOrderIntentionEditActions.setDatesForOffers({
          comercial: comercial ? prepareDate(comercial) : '',
          delivery: delivery ? prepareDate(delivery) : '',
        }),
      );
      if (formValues.producer) {
        dispatch(
          BuyIntentionCalls.fetchOffersByProducers({
            producerId: formValues?.producer,
            keyword: formValues.product,
            accountCode: formInfo?.customerCode
          }),
        );
        dispatch(myOrderIntentionEditActions.setIsLoading(true));
        dispatch(myOrderIntentionEditActions.setSClearTotalPriceOffers());
      } else if (formValues.product) {
        dispatch(
          BuyIntentionCalls.fetchOffersByProducts({
            keyword: formValues?.product,
            accountCode: formInfo?.customerCode,
          }),
        );
        dispatch(myOrderIntentionEditActions.setIsLoading(true));
        dispatch(myOrderIntentionEditActions.setSClearTotalPriceOffers());
      } else {
        dispatch(
          alertActions.alertRequest({
            message: 'Preencha o campo Produto ou Produtor!',
            type: 'warning',
            showIcon: true,
            timeout: 3000,
          }),
        );
      }
    },
    [formBuyIntention, dispatch],
  );

  const disableDatesDelivery = (value: Moment) => {
    const initalDay = moment().add(-1, 'day');
    return value.isBefore(initalDay);
  };

  const disableDatesComercial = (value: Moment) => {
    if (deliveryDate !== undefined) {
      let lastDay: any;
      const initalDay = moment(deliveryDate);
      if (moment(deliveryDate).format('dddd') === 'sábado') {
        lastDay = moment(deliveryDate).add(3, 'days');
      } else {
        lastDay = moment(deliveryDate).add(2, 'days');
      }

      return value.isAfter(lastDay) || value.isBefore(initalDay);
    }
    return false;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      formBuyIntention.submit();
    }
  };

  const clearForm = () => {
    setComercialDate(undefined);
    setDeliveryDate(undefined);
    setProducerId(undefined);
    setProduct(undefined);
    formBuyIntention.resetFields();
  };

  function getPopupContainer(triggerNode: any) {
    return triggerNode.parentElement;
  }

  useEffect(() => {
    if (modalIsVisible) {
      const dateFinishDelivery = moment(formInfo?.deliveryDate).format(
        'YYYY-MM-DDTHH:mm:ss.SSSS',
      );
      const dateFinishCommercial = moment(formInfo?.commercialDate).format(
        'YYYY-MM-DDTHH:mm:ss.SSSS',
      );
      dispatch(
        myOrderIntentionEditActions.setDeliveryDateFinish(dateFinishDelivery),
      );
      dispatch(
        myOrderIntentionEditActions.setComercialDateFinish(
          dateFinishCommercial,
        ),
      );
    }
  }, [modalIsVisible]);

  return (
    <S.Step form={formBuyIntention} key="1">
      <Form layout="horizontal" form={formBuyIntention} onFinish={onSubmit}>
        <Row gutter={8}>
          <S.ColStyled xs={24} md={12} lg={12}>
            <Form.Item label={t('buyIntention.form.customer')} name="customer">
              <Select
                disabled
                placeholder={`${formInfo?.customerCode} - ${formInfo?.customerName}`}
              />
            </Form.Item>
          </S.ColStyled>

          <S.ColStyled xs={24} md={6} lg={6}>
            <Form.Item
              key="form-item-delivery-date"
              label={t('buyIntention.form.deliveryDate')}
              name="deliveryDate"
              rules={[
                {
                  required: true,
                  message: 'Selecione a data.',
                },
              ]}
            >
              <DatePicker
                getPopupContainer={(v) => getPopupContainer(v)}
                defaultValue={
                  formInfo
                    ? moment(formInfo.deliveryDate)
                    : moment('20/12/2020')
                }
                allowClear
                disabledDate={disableDatesDelivery}
                format="DD/MM/YYYY"
                placeholder="Selecione"
                onChange={onChangeDeliveryDate}
              />
            </Form.Item>
          </S.ColStyled>

          <S.ColStyled xs={24} md={6} lg={6}>
            <Form.Item
              key="form-item-comercial-date"
              label={t('buyIntention.form.comercialDate')}
              name="comercialDate"
              rules={[
                {
                  required: true,
                  message: 'Selecione a data.',
                },
              ]}
            >
              <DatePicker
                getPopupContainer={(v) => getPopupContainer(v)}
                defaultValue={
                  formInfo
                    ? moment(formInfo.commercialDate)
                    : moment('20/12/2020')
                }
                allowClear
                disabledDate={disableDatesComercial}
                disabled={!deliveryDate}
                format="DD/MM/YYYY"
                placeholder="Selecione"
                onChange={onChangeComercialDate}
              />
            </Form.Item>
          </S.ColStyled>
        </Row>

        <S.ContentTitleProducers stepTwo={currentStep === 1}>
          <S.Line lineSize={3} />
          <S.TitleProducers>
            {t('buyIntention.form.productsLine')}
          </S.TitleProducers>
          <S.Line lineSize={100} />
        </S.ContentTitleProducers>

        {currentStep === 0 && (
          <S.RowStyled justify="space-between">
            <S.ColStyled xs={24} md={8} lg={8} style={{ zIndex: 10 }}>
              <Form.Item label={t('buyIntention.form.product')} name="product">
                <Input
                  placeholder="Pesquise aqui"
                  value={product}
                  style={{
                    width: 300,
                  }}
                  onChange={(v) => setProduct(v.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </Form.Item>
            </S.ColStyled>

            <S.ContentAside>
              <S.ColStyled xs={24} md={8} lg={8}>
                <Form.Item
                  label={t('buyIntention.form.producer')}
                  name="producer"
                >
                  <Select
                    style={{ width: 330 }}
                    onChange={setProducerId}
                    value={producerId}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption
                    placeholder="Selecione"
                    onKeyDown={handleKeyDown}
                  >
                    {producers?.map((value: BuyIntentionModels.Producers) => (
                      <Option key={value.id} value={value.id}>
                        {value.account}-{value.corporateName}(
                        {value.tradingName})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </S.ColStyled>

              <S.ContentButton>
                <Button onClick={clearForm}>
                  <ClearOutlined />
                </Button>
              </S.ContentButton>

              <S.ContentButton>
                <Button
                  type="primary"
                  onClick={() => formBuyIntention.submit()}
                >
                  <SearchOutlined />
                </Button>
              </S.ContentButton>
            </S.ContentAside>
          </S.RowStyled>
        )}
      </Form>
    </S.Step>
  );
}
