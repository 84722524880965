import styled from 'styled-components';

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 235px;
  margin: 16px 16px;

  &.hover-card-product {
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 2px 16px rgba(38, 38, 38, 0.2);
      border: 0px solid #cccccc;
      border-radius: 8px;
      background-color: white;
      transform-origin: 50% 50%;
      z-index: -1;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const ProductImage = styled.img`
  align-self: center;
  width: 110px;
  height: 110px;
`;

export const Product = styled.span`
  align-self: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 16px;
`;
export const CategoryDescription = styled.span`
  font-size: 12px;
  text-align: 'left';
  color: #888;
  margin-top: 4px;
`;
