import {
  DeleteOutlined,
  LeftOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Button, Select } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import ProducerProductCardCart from 'components/BusinessLogic/Product/ProducerProductCardCart';
import ProducerProductCardCartComposition from 'components/BusinessLogic/Product/ProducerProductCardCartComposition';
import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';
import ModalConfirmation from 'components/Modals/Confirmation';
import ModalInformationExpiredItems from 'components/Modals/InformationExpiredItems';
import ModalOrderSummary from 'components/Modals/OrderSummary';
import ModalSuccessfulPurchase from 'components/Modals/SuccessfulPurchase';
import Breadcrumb from 'components/UI/Breadcrumb';
import Monetary from 'components/UI/Data/Monetary';
import ShippingFee from 'components/UI/Data/ShippingFee';
import { OfferTypeEnum } from 'enums/offerType';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getTypeOfferSelectedCart,
  setTypeOfferSelectedCart,
  setTypeOfferSelectedMyOrders,
} from 'services/config';
import {
  useGetCartItemsQuery,
  useGetDeleteCartMutation,
  useGetDeleteItemCartMutation,
} from 'services/ecommerceApi';
import { cartSelectors, sessionSelectors } from 'store/state/selectors';
import { cartActions } from 'store/state/slices';
import { dateUtils } from 'utils/date';
import { roundDecimals } from 'utils/util';
import CartItem from '../../model/CartItem';
import * as S from './styles';

export default function ShoppingCart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const volOfferId = useSelector(cartSelectors.getVolOfferId);
  const lkpOfferId = useSelector(cartSelectors.getLkpOfferId);
  const lkpCartExpiration = useSelector(cartSelectors.getLkpExpiration);
  const volCartExpiration = useSelector(cartSelectors.getVolExpiration);
  const totalValueVol = useSelector(cartSelectors.getVolTotal);
  const totalValueLkp = useSelector(cartSelectors.getLkpTotal);

  const billingDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);
  const refetchCartItems = useSelector(cartSelectors.getRefetchCartItems);
  const [typeOfferSelected, setTypeOfferSelected] = useState<string>(
    getTypeOfferSelectedCart() !== '' ? getTypeOfferSelectedCart() : 'vol',
  );
  const [modalInformationsItensExpired, setModalInformationsItensExpired] =
    useState<boolean>(false);
  const [
    cartItemsVolGroupedByComposition,
    setCartItemsVolGroupedByComposition,
  ] = useState<Array<CartItem[] | CartItem>>([]);
  const [cartItemsVol, setCartItemsVol] = useState<CartItem[]>([]);
  const [cartItemsLkp, setCartItemsLkp] = useState<CartItem[]>([]);
  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );

  const totalValueMarkup = useMemo(() => {
    let totalMarkup = 0.0;

    cartItemsLkp.forEach((cartItemLkp) => {
      totalMarkup += cartItemLkp.shippingFee
        ? cartItemLkp.shippingFee * cartItemLkp.unitQuantity
        : 0;
    });
    cartItemsVol.forEach((cartItemVol) => {
      totalMarkup += cartItemVol.shippingFee
        ? cartItemVol.shippingFee * cartItemVol.unitQuantity
        : 0;
    });

    return totalMarkup;
  }, [cartItemsLkp, cartItemsVol]);

  const totalVolAndShipping = useMemo(
    () => totalValueVol + totalValueMarkup,
    [totalValueMarkup, totalValueVol],
  );

  const totalLkpAndShipping = useMemo(
    () => totalValueLkp + totalValueMarkup,
    [totalValueMarkup, totalValueLkp],
  );

  const [removeId, setRemoveId] = useState<number>(0);
  const [lkpExpiration, setLkpExpiration] = useState<{
    isExpired: boolean;
    expirationDate?: string;
  }>({ isExpired: false });
  const [volExpiration, setVolExpiration] = useState<{
    isExpired: boolean;
    expirationDate?: string;
  }>({ isExpired: false });
  const [modalConfirmationIsVisible, setModalConfirmationIsVisible] =
    useState<boolean>(false);
  const [modalOrderSumaryIsVisible, setModalOrderSumaryIsVisible] =
    useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [
    modalConfirmationRemoveItensIsVisible,
    setModalConfirmationRemoveItensIsVisible,
  ] = useState<boolean>(false);
  const { t } = useTranslation('translation', { keyPrefix: 'shoppingCart' });
  const { t: tRoot } = useTranslation();
  const { Option } = Select;
  const [getDeleteItemCart] = useGetDeleteItemCartMutation();
  const [getDeleteCart] = useGetDeleteCartMutation();

  const { data: volCartItems, refetch: refetchCartItemsVol } =
    useGetCartItemsQuery(
      {
        cartId: volOfferId,
      },
      { skip: !volOfferId },
    );

  const { data: lkpCartItems, refetch: refetchCartItemsLkp } =
    useGetCartItemsQuery(
      {
        cartId: lkpOfferId,
      },
      { skip: !lkpOfferId },
    );

  useEffect(() => {
    if (!volOfferId) {
      setCartItemsVol([]);
    }
    if (!lkpOfferId) {
      setCartItemsLkp([]);
    }
  }, [volOfferId, lkpOfferId]);

  useEffect(() => {
    if (refetchCartItems) {
      refetchCartItemsLkp();
      refetchCartItemsVol();
      dispatch(cartActions.setRefetchRequestCartItems(false));
    }
  }, [dispatch, refetchCartItems, refetchCartItemsLkp, refetchCartItemsVol]);

  useEffect(() => {
    const arrayItemsVol: Array<CartItem | CartItem[]> = [];
    const compositionKeysAdded: string[] = [];

    cartItemsVol.forEach((item) => {
      if (!item.compositionKey) {
        arrayItemsVol.push(item);
      } else if (!compositionKeysAdded.includes(item.compositionKey)) {
        arrayItemsVol.push(
          cartItemsVol.filter(
            (cartItem) => cartItem.compositionKey === item.compositionKey,
          ),
        );
        compositionKeysAdded.push(item.compositionKey);
      }
    });

    setCartItemsVolGroupedByComposition(arrayItemsVol);
  }, [cartItemsVol]);

  useEffect(() => {
    if (volCartItems && volCartItems.length > 0) {
      const arrayItems: CartItem[] = [...volCartItems];
      let arrayItemsVol: CartItem[] = [];

      arrayItemsVol = arrayItems.map((item) => ({
        ...item,
        isExpired: dateUtils.isExpired(
          dateUtils.AddTimeZoneInExpirationDate(item.expirationDate),
        ),
      }));

      setCartItemsVol(arrayItemsVol);
    } else {
      setCartItemsVol([]);
    }

    if (lkpCartItems && lkpCartItems.length > 0) {
      const arrayItems: CartItem[] = [...lkpCartItems];
      let arrayItemsLkp: CartItem[] = [];

      arrayItemsLkp = arrayItems.map((item) => ({
        ...item,
        isExpired: dateUtils.isExpired(
          dateUtils.AddTimeZoneInExpirationDate(item.expirationDate),
        ),
      }));
      setCartItemsLkp(arrayItemsLkp);
    } else {
      setCartItemsLkp([]);
    }
  }, [volCartItems, lkpCartItems]);

  const onClickRemoveItem = (id: number) => {
    setRemoveId(id);
    setModalConfirmationRemoveItensIsVisible(true);
  };

  const handleShowModalSuccess = () => {
    setShowModalSuccess(true);
  };

  const onClikCancelInConfirmationRemoveItemModal = () => {
    setModalConfirmationRemoveItensIsVisible(false);
  };

  const closeModalOrderSumary = () => {
    setModalOrderSumaryIsVisible(false);
  };

  const onClikOkInConfirmationRemoveItemModal = () => {
    getDeleteItemCart({ id: removeId });
    setModalConfirmationRemoveItensIsVisible(false);
  };

  const onClikCancelInConfirmationModal = () => {
    setModalConfirmationIsVisible(false);
  };

  const onClickMyOrders = () => {
    setTypeOfferSelectedMyOrders(typeOfferSelected);
    navigate(`/${producerUrl}/my-orders`);
    setShowModalSuccess(false);
  };

  const onClickCloseShowSuccess = () => {
    const isLkpSelected = typeOfferSelected === 'lkp';
    const volItems = volCartItems?.length ? volCartItems : [];
    const lkpItems = lkpCartItems?.length ? lkpCartItems : [];

    const cartItems = isLkpSelected ? volItems : lkpItems;
    if (cartItems?.length) {
      setShowModalSuccess(false);
      setTypeOfferSelected(isLkpSelected ? 'vol' : 'lkp');
    } else {
      navigate(`/${producerUrl}/products`);
      setShowModalSuccess(false);
    }
  };

  const onClikOkInConfirmationModal = () => {
    const isLkpOffer = typeOfferSelected === 'lkp';
    const cartId = isLkpOffer ? lkpOfferId : volOfferId;
    getDeleteCart({ cartId });
    setCartItemsVol([]);
    setCartItemsLkp([]);
    setModalConfirmationIsVisible(false);
  };

  const handleExpiredItem = useCallback(
    (offerType: OfferTypeEnum, id: number) => {
      if (offerType === OfferTypeEnum.LKP) {
        setCartItemsLkp((items) =>
          items.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                isExpiredInFront: true,
              };
            }
            return item;
          }),
        );
      } else {
        setCartItemsVol((items) =>
          items.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                isExpiredInFront: true,
              };
            }
            return item;
          }),
        );
      }
    },
    [setCartItemsLkp, setCartItemsVol],
  );

  const expireAllItensVol = useCallback(() => {
    setCartItemsVol((items) =>
      items.map((item) => ({
        ...item,
        isExpiredInFront: true,
      })),
    );
  }, []);

  const expireAllItensLkp = useCallback(() => {
    setCartItemsLkp((items) =>
      items.map((item) => ({
        ...item,
        isExpiredInFront: true,
      })),
    );
  }, []);

  useEffect(() => {
    if (!totalValueVol && getTypeOfferSelectedCart() === '') {
      setTypeOfferSelected('lkp');
    }
  }, [totalValueLkp, totalValueVol]);

  useEffect(() => {
    if (cartItemsVol?.length > 0 && volCartExpiration !== '') {
      let expirationDate = '';
      if (cartItemsVol.length > 1) {
        expirationDate = dateUtils
          .AddTimeZoneInExpirationDate(volCartExpiration)
          .replace('Z', '');
      } else {
        expirationDate = volCartExpiration;
      }

      setVolExpiration({
        isExpired: dateUtils.isExpired(expirationDate),
        expirationDate,
      });
    }
  }, [cartItemsVol, volCartExpiration]);

  useEffect(() => {
    if (cartItemsLkp.length > 0) {
      let expirationDate = '';

      if (cartItemsLkp.length > 1) {
        expirationDate = dateUtils
          .AddTimeZoneInExpirationDate(lkpCartExpiration)
          .replace('Z', '');
      } else {
        expirationDate = lkpCartExpiration;
      }

      setLkpExpiration({
        isExpired: dateUtils.isExpired(expirationDate),
        expirationDate,
      });
    }
  }, [cartItemsLkp]);

  const handleClickInBuy = useCallback(() => {
    if (typeOfferSelected === 'vol') {
      if (cartItemsVol.find((item) => item.isExpired)) {
        setModalInformationsItensExpired(true);
        return;
      }
    }

    if (typeOfferSelected === 'lkp') {
      if (cartItemsLkp.find((item) => item.isExpired)) {
        setModalInformationsItensExpired(true);
        return;
      }
    }

    setModalOrderSumaryIsVisible(true);
  }, [typeOfferSelected, cartItemsVol, cartItemsLkp]);

  return (
    <Screen
      content={
        <S.Root>
          <S.Divider />
          <S.Header>
            <S.HeaderLine1>
              <S.YourShoppingCart>
                <ShoppingCartOutlined
                  style={{ fontSize: '24px', color: 'white' }}
                />
                <S.YourShoppingCartText>{t('yourCart')}</S.YourShoppingCartText>
              </S.YourShoppingCart>
              <Breadcrumb
                levels={[
                  { description: tRoot('breadcrumb.store') },
                  { description: tRoot('breadcrumb.shoppingCart') },
                ]}
              />
            </S.HeaderLine1>
            <S.HeaderLine2>
              <S.HeaderLine2LeftGroup>
                <S.OrderSelect defaultValue="1" bordered={false}>
                  <Option value="1">Padrão</Option>
                </S.OrderSelect>
                <S.ShowTotalResults>
                  {t('showingResults', { total: 50 })}
                </S.ShowTotalResults>
              </S.HeaderLine2LeftGroup>
              <S.HeaderLine2RightGroup>
                <S.ContinueShoppingButton
                  icon={<LeftOutlined />}
                  onClick={() => navigate(`/${producerUrl}/products`)}
                >
                  {t('continueShopping')}{' '}
                </S.ContinueShoppingButton>
              </S.HeaderLine2RightGroup>
            </S.HeaderLine2>
          </S.Header>
          {(volCartItems && volCartItems.length > 0) ||
          (lkpCartItems && lkpCartItems.length > 0) ? (
            <S.Content>
              <S.TabsTypeProducts>
                <S.GroupButtonAndTimer
                  className={
                    typeOfferSelected === 'vol' ? 'button-in-focus' : ''
                  }
                >
                  <Button
                    onClick={() => {
                      setTypeOfferSelected('vol');
                      setTypeOfferSelectedCart('vol');
                    }}
                  >
                    Produtos ENP
                  </Button>

                  <S.TimerLabel>
                    ({`O carrinho será confirmado em: `}
                    {volOfferId > 0 && !volExpiration.isExpired ? (
                      <Countdown
                        format="mm:ss"
                        value={volExpiration.expirationDate}
                        onFinish={expireAllItensVol}
                      />
                    ) : (
                      '00:00'
                    )}
                    )
                  </S.TimerLabel>
                </S.GroupButtonAndTimer>
                <S.GroupButtonAndTimer
                  className={
                    typeOfferSelected === 'lkp' ? 'button-in-focus' : ''
                  }
                >
                  <Button
                    onClick={() => {
                      setTypeOfferSelected('lkp');
                      setTypeOfferSelectedCart('lkp');
                    }}
                  >
                    Produtos LKP
                  </Button>
                  <S.TimerLabel>
                    ({`O carrinho será confirmado em: `}
                    {lkpOfferId > 0 && !lkpExpiration.isExpired ? (
                      <Countdown
                        format="mm:ss"
                        value={lkpExpiration.expirationDate}
                        onFinish={expireAllItensLkp}
                      />
                    ) : (
                      '00:00'
                    )}
                    )
                  </S.TimerLabel>
                </S.GroupButtonAndTimer>
              </S.TabsTypeProducts>
              {typeOfferSelected === 'vol' ? (
                <S.ProductCards>
                  {cartItemsVolGroupedByComposition &&
                    cartItemsVolGroupedByComposition.map(
                      (cartItem: CartItem | CartItem[]) =>
                        Array.isArray(cartItem) ? (
                          <ProducerProductCardCartComposition
                            cartItems={cartItem}
                            key={`${cartItem[0].compositionKey}-typeVol-cart-composition`}
                            onClickRemoveItem={onClickRemoveItem}
                            onExpireInFront={(id: number) =>
                              handleExpiredItem(OfferTypeEnum.VOL, id)
                            }
                          />
                        ) : (
                          <ProducerProductCardCart
                            cartItem={cartItem}
                            key={`${cartItem.id}-typeVol-cart-unit`}
                            onClickRemoveItem={onClickRemoveItem}
                            onExpiredItem={(id: number) =>
                              handleExpiredItem(OfferTypeEnum.VOL, id)
                            }
                          />
                        ),
                    )}
                </S.ProductCards>
              ) : (
                <S.ProductCards>
                  {cartItemsLkp &&
                    cartItemsLkp.map((cartItem: CartItem) => (
                      <ProducerProductCardCart
                        isLkp
                        cartItem={cartItem}
                        key={`${cartItem.id}-typelkp-cart-composition`}
                        onClickRemoveItem={onClickRemoveItem}
                        onExpiredItem={(id: number) =>
                          handleExpiredItem(OfferTypeEnum.LKP, id)
                        }
                      />
                    ))}
                </S.ProductCards>
              )}
            </S.Content>
          ) : (
            <S.EmptyComponent />
          )}
          {((volCartItems && volCartItems.length > 0) ||
            (lkpCartItems && lkpCartItems.length > 0)) && (
            <S.FooterProductCart>
              <S.GroupInformationTotal
                className={typeOfferSelected !== 'vol' ? 'set-in-flex-end' : ''}
              >
                {typeOfferSelected === 'vol' && (
                  <S.GroupInformation className="align-start">
                    <S.SpanInformation>
                      {volOfferId > 0 ? 'Data de Entrega' : ''}
                      <S.SpecialSpan>
                        {volOfferId > 0 && deliveryDate
                          ? moment(deliveryDate).format('L')
                          : ''}
                      </S.SpecialSpan>
                    </S.SpanInformation>
                    <S.SpanInformation>
                      {volOfferId > 0 ? 'Data de Faturamento' : ''}
                      <S.SpecialSpan>
                        {volOfferId > 0 && billingDate
                          ? moment(billingDate).format('L')
                          : ''}
                      </S.SpecialSpan>
                    </S.SpanInformation>
                  </S.GroupInformation>
                )}

                <S.GroupInformation className="align-end">
                  <S.SpanInformation>
                    Quantidade de itens
                    <S.SpecialSpan>
                      {typeOfferSelected === 'vol'
                        ? cartItemsVolGroupedByComposition.length
                        : cartItemsLkp.length}
                    </S.SpecialSpan>
                  </S.SpanInformation>
                  <S.SpanInformation>
                    Total frete:
                    <S.SpecialSpan>
                      <ShippingFee value={roundDecimals(totalValueMarkup, 6)} />
                    </S.SpecialSpan>
                  </S.SpanInformation>
                  <S.SpanInformation className="last-item">
                    Total
                    <S.SpecialSpan>
                      <Monetary
                        value={
                          typeOfferSelected === 'vol'
                            ? parseFloat(
                                parseFloat(
                                  totalVolAndShipping.toString(),
                                ).toFixed(2),
                              )
                            : parseFloat(
                                parseFloat(
                                  totalLkpAndShipping.toString(),
                                ).toFixed(2),
                              )
                        }
                      />
                    </S.SpecialSpan>
                  </S.SpanInformation>
                </S.GroupInformation>
              </S.GroupInformationTotal>

              <S.GroupActionsButtons>
                <Button
                  className="margin-right"
                  disabled={
                    (typeOfferSelected === 'vol' &&
                      (Number.isNaN(volOfferId) || volOfferId === 0)) ||
                    (typeOfferSelected === 'lkp' &&
                      (Number.isNaN(lkpOfferId) || lkpOfferId === 0))
                  }
                  onClick={() => setModalConfirmationIsVisible(true)}
                  icon={<DeleteOutlined />}
                />
                <Button
                  type="primary"
                  onClick={() => handleClickInBuy()}
                  disabled={
                    (typeOfferSelected === 'vol' && totalValueVol === 0) ||
                    (typeOfferSelected === 'lkp' && totalValueLkp === 0)
                  }
                >
                  Finalizar compra
                </Button>
              </S.GroupActionsButtons>
            </S.FooterProductCart>
          )}
          <ModalConfirmation
            modalIsVisible={modalConfirmationIsVisible}
            textTitle="Atenção"
            textDescription="Tem certeza que deseja limpar o carrinho?"
            textButtonOk="Sim, limpar carrinho"
            textButtonCancel="Cancelar"
            actionButtonOk={onClikOkInConfirmationModal}
            actionButtonCancel={onClikCancelInConfirmationModal}
          />
          <ModalConfirmation
            modalIsVisible={modalConfirmationRemoveItensIsVisible}
            textTitle="Atenção"
            textDescription="Tem certeza que deseja excluir o item?"
            textButtonOk="Sim, excluir"
            textButtonCancel="Cancelar"
            actionButtonOk={onClikOkInConfirmationRemoveItemModal}
            actionButtonCancel={onClikCancelInConfirmationRemoveItemModal}
          />
          <ModalSuccessfulPurchase
            modalIsVisible={showModalSuccess}
            actionButtonOk={onClickMyOrders}
            actionButtonCancel={onClickCloseShowSuccess}
          />
          <ModalOrderSummary
            setModalIsVisible={closeModalOrderSumary}
            modalIsVisible={modalOrderSumaryIsVisible}
            showModalSuccess={handleShowModalSuccess}
            isLkp={typeOfferSelected === 'lkp'}
          />
          <ModalInformationExpiredItems
            modalIsVisible={modalInformationsItensExpired}
            setModalIsVisible={setModalInformationsItensExpired}
          />
        </S.Root>
      }
    />
  );
}
