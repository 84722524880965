/* eslint-disable no-nested-ternary */
import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ApartmentOutlined,
  BgColorsOutlined,
  ExpandOutlined,
  EyeOutlined,
  GiftOutlined,
  GroupOutlined,
  ShoppingCartOutlined,
  TrophyOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Packing from 'model/Packing';
import ProductOffer from 'model/ProductOffer';
import { useGetDeleteCartMutation } from 'services/ecommerceApi';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { cartSelectors, sessionSelectors, offerFiltersSelectors } from 'store/state/selectors';
import moment, { Moment } from 'moment';
import { PackingTypeSale, PackingTypeSaleMap } from 'enums/packingTypeSale';
import DeliveryDate from 'components/UI/DeliveryDate';
import PopoverCalendar from 'components/UI/PopoverCalendarDates';
import { sessionActions, offerFiltersActions } from 'store/state/slices';
import { deliveryDatePattern } from 'services/deliveryDatePattern';
import { validatorUtils } from 'utils/validator';
import { PackingTypeEnum } from 'enums/packingType';
import { Image } from 'components/UI/Image/Image';
import Quality from 'components/UI/Quality';
import { calculateMinBySaleType } from 'utils/util';
import { FastCartIcon } from 'components/UI/Icons';
import ProductShippingFee from 'components/UI/ProductShippingFee';
import { offerTypeMap } from 'enums/offerTypeMap';
import { CalendarIcon } from 'components/UI/PopoverCalendarDates/styles';
import ModalExpiredItemsInCart from '../../../Modals/ExpiredItemsInCart/index';
import imageComum from '../../../../assets/antIcons/fileImage.svg';
import OfferTypeBadge from '../OfferTypeBadge';
import Share from '../../../UI/Share';
import * as S from './styles';
import { ProductBuy, ProductBuyHandle } from '../ProductBuy';
import { ButtonPacking } from '../ButtonPacking';

type Props = {
  productOffer: ProductOffer;
  setModalIsVisibleComposition: (
    productOfferSelected: ProductOffer,
    packingSelected: Packing,
  ) => void;
  setOfferIdModalComposition: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};

const { Option } = Select;

export default function ProductOfferDetails({
  productOffer,
  setModalIsVisibleComposition,
  setOfferIdModalComposition,
}: Props) {
  const {
    offerId,
    offerType,
    voucherId,
    longName,
    defaultImage,
    images,
    barcode,
    gtincode,
    voucherQuantityType,
    voucherReajustmentType,
    quality,
    packings,
    colors,
    siteName,
    dimension,
    minimumUnitQuantityVoucher,
    reajustmentAmount,
    layerPrice,
    packagingPrice,
    startDate,
    endDate,
    lkpAuctionDate,
    observation,
    minimumQuantity,
    specialPrice,
    siteDeliveryPatterns,
    isCompositionEnabled,
    unavailableDates,
  } = productOffer;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fastBuy, setFastBuy] = useState(false);
  const { packingSelectedId, packingTypeSale } = useParams<{
    packingSelectedId: string;
    packingTypeSale: string;
  }>();
  const [selectedTypeSale, setSelectedTypeSale] = useState<PackingTypeSale>(
    packingTypeSale
      ? (packingTypeSale as PackingTypeSale)
      : PackingTypeSale.Packing,
  );
  const dispatch = useDispatch();

  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );
  const [getDeleteCart] = useGetDeleteCartMutation();
  const [arrayImage, setArrayImage] = useState<string[]>([
    defaultImage || imageComum,
  ]);
  const [showModalExpiredItemsInCart, setShowModalExpiredItemsInCart] =
    useState<boolean>(false);
  const [
    displayModalInformationComposition,
    setDisplayModalInformationComposition,
  ] = useState<boolean>(false);
  const [imageDisplay, setImageDisplay] = useState<number>(0);

  const [packingSelected, setPackingSelected] = useState<Packing>(
    packings.find((packing) => packing.id === Number(packingSelectedId)) ??
      packings.find((packing) => packing.type === PackingTypeEnum.RETORNAVEL) ??
      packings.find((packing) => packing.code === '800') ??
      packings[0],
  );
  const [displayModalInformation, setDisplayModalInformation] =
    useState<boolean>(false);
  const [textInputTypeSale, setTextInputTypeSale] = useState<string>(() => {
    if (
      packingTypeSale &&
      Object.values(PackingTypeSale).includes(
        packingTypeSale as unknown as PackingTypeSale,
      )
    ) {
      return `${PackingTypeSaleMap[packingTypeSale as PackingTypeSale]}(s)`;
    }
    if (packagingPrice) {
      return 'Embalagem(s)';
    }
    if (layerPrice) {
      return 'Camada(s)';
    }
    return 'Carrinho(s)';
  });

  const [quantityPacking, setQuantityPacking] = useState(0);

  const customerSelected = useSelector(sessionSelectors.getSelectedCustomer);
  const lkpOfferId = useSelector(cartSelectors.getLkpOfferId);
  const volOfferId = useSelector(cartSelectors.getVolOfferId);
  const billingDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const { producerUrl } = useSelector(sessionSelectors.getRoot);
  const offersFilters = useSelector(offerFiltersSelectors.getOfferFilters);

  // const isLkpOffer = useMemo(
  //   () => offerType === 1 || offerType === 2,
  //   [offerType],
  // );

  const isLkpOffer = useMemo(
    () =>
      offerType !== offerTypeMap.onSite && offerType !== offerTypeMap.directed,
    [offerType],
  );

  const handleTypeSale = (value: PackingTypeSale) => {
    if (value === PackingTypeSale.Packing) {
      setSelectedTypeSale(PackingTypeSale.Packing);
      setTextInputTypeSale(`${PackingTypeSaleMap.Packing}(s)`);
      return;
    }
    if (value === PackingTypeSale.Layer) {
      setSelectedTypeSale(PackingTypeSale.Layer);
      setTextInputTypeSale(`${PackingTypeSaleMap.Layer}(s)`);
      return;
    }
    if (value === PackingTypeSale.Trolley) {
      setSelectedTypeSale(PackingTypeSale.Trolley);
      setTextInputTypeSale(`${PackingTypeSaleMap.Trolley}(s)`);
    }
  };

  const clickCloseModalInformationComposition = () => {
    setDisplayModalInformationComposition(false);
  };

  const handleClickButtonGoToCart = () => {
    navigate('/shopping-cart');
    setShowModalExpiredItemsInCart(false);
  };

  const handleClickButtonDeleteCart = useCallback(() => {
    const cartId = isLkpOffer ? lkpOfferId : volOfferId;
    getDeleteCart({ cartId });
    setShowModalExpiredItemsInCart(false);
  }, [getDeleteCart, isLkpOffer, lkpOfferId, volOfferId]);

  const handleVisibleModalComposition = useCallback(() => {
    if (deliveryDate && billingDate) {
      setModalIsVisibleComposition(productOffer, packingSelected);
      setOfferIdModalComposition(offerId);
    } else {
      setDisplayModalInformation(true);
    }
  }, [
    billingDate,
    deliveryDate,
    offerId,
    packingSelected,
    productOffer,
    setModalIsVisibleComposition,
    setOfferIdModalComposition,
  ]);

  const clickCloseModalInformation = () => {
    setDisplayModalInformation(false);
  };

  const getLabel = useCallback(() => {
    if (selectedTypeSale === PackingTypeSale.Packing) {
      return packingSelected?.maxAvailablePackingsLabel || '';
    }
    if (selectedTypeSale === PackingTypeSale.Layer) {
      return packingSelected?.maxAvailableLayersLabel || '';
    }
    return packingSelected?.maxAvailableTrolleysLabel || '';
  }, [
    selectedTypeSale,
    packingSelected.maxAvailableTrolleysLabel,
    packingSelected.maxAvailableLayersLabel,
    packingSelected.maxAvailablePackingsLabel,
  ]);

  const getMaxAvailable = useMemo(() => {
    if (selectedTypeSale === PackingTypeSale.Packing) {
      return {
        label: packingSelected?.maxAvailablePackingsLabel || '',
        max: packingSelected?.maxAvailablePackingsQuantity || 0,
        min: packingSelected?.unitsPerPackage,
      };
    }
    if (selectedTypeSale === PackingTypeSale.Layer) {
      return {
        label: packingSelected?.maxAvailableLayersLabel || '',
        max: packingSelected?.maxAvailableLayersQuantity || 0,
        min:
          packingSelected?.packagingByLayer * packingSelected?.unitsPerPackage,
      };
    }
    return {
      label: packingSelected?.maxAvailableTrolleysLabel || '',
      max: packingSelected?.maxAvailableTrolleysQuantity || 0,
      min:
        packingSelected?.packagingByLayer *
        packingSelected?.layersByTrolley *
        packingSelected?.unitsPerPackage,
    };
  }, [packingSelected, selectedTypeSale]);

  useEffect(() => {
    const quantityPackingValue = form.getFieldValue(['quantityPacking']);
    if (quantityPackingValue) {
      form.validateFields(['quantityPacking']);
    }
  }, [getMaxAvailable]);

  const handleOnSelect = (id: number) => {
    packings.find((packing) => {
      if (packing.id === id) {
        setPackingSelected(packing);
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setArrayImage(images);
    } else {
      setArrayImage([defaultImage || imageComum]);
    }
  }, [images]);

  // Modal para data
  const [deliveryDateValue, setDeliveryDateValue] = useState<Moment | null>(
    null,
  );
  const [showDatePickerDelivery, setShowDatePickerDelivery] =
    useState<boolean>(true);
  const [showDatePickerBilling, setShowDatePickerBilling] =
    useState<boolean>(false);
  const [billingDateValue, setBillingDateValue] = useState<Moment | null>(null);

  const disableDatesDelivery = (value: Moment) => {
    const calendarValue = moment(value).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });
    const dateNow = moment().set({
      hour: 0,
      minute: 0,
      second: 1,
      millisecond: 0,
    });
    const endDateFormatted = moment(endDate).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });

    const deliveryDays = siteDeliveryPatterns.map(
      (date) => date.deliveryDayNumber,
    );
    const deliveryDate = deliveryDatePattern(
      siteDeliveryPatterns,
    ).getDeliveryDateByDatePatterns(dateNow.toDate().getDay());
    return (
      calendarValue.isBefore(deliveryDate) ||
      value.isAfter(endDateFormatted) ||
      !deliveryDays.includes(calendarValue.day())
    );
  };

  const disableDatesBilling = (value: Moment) => {
    if (deliveryDateValue !== null) {
      const initalDay = moment(deliveryDateValue);
      const lastDay = moment(deliveryDateValue).add(2, 'days');

      return value.isAfter(lastDay) || value.isBefore(initalDay);
    }
    return false;
  };

  const handleOnOpenChangeDelivery = (event: boolean) => {
    setShowDatePickerDelivery(event);

    if (deliveryDateValue !== null) {
      setShowDatePickerBilling(true);
    }
  };

  const handleOnOpenChangeBilling = (event: boolean) => {
    setShowDatePickerBilling(event);
  };

  const handleOnChangeDelivery = (value: Moment | null) => {
    setBillingDateValue(null);
    setDeliveryDateValue(value);

    if (value) {
      setShowDatePickerBilling(true);
    }
  };

  const handleOnChangeBilling = (value: Moment | null) => {
    setBillingDateValue(value);
  };

  const getMultipleCalc = useCallback(() => {
    if (selectedTypeSale === PackingTypeSale.Packing) {
      return (
        packingSelected?.multiple / +packingSelected?.maxAvailablePackingsLabel
      );
    }
    if (selectedTypeSale === PackingTypeSale.Layer) {
      const values = packingSelected?.maxAvailableLayersLabel
        .split('x')
        .map((x) => +x);
      const calc = packingSelected?.multiple / values.reduce((a, b) => +a * +b);
      return calc < 1 ? 1 : calc;
    }
    const values = packingSelected?.maxAvailableTrolleysLabel
      .split('x')
      .map((x) => +x);
    const calc = packingSelected?.multiple / values.reduce((a, b) => +a * +b);
    return calc < 1 ? 1 : calc;
  }, [
    selectedTypeSale,
    packingSelected?.multiple,
    packingSelected.maxAvailableTrolleysLabel,
    packingSelected.maxAvailableLayersLabel,
    packingSelected.maxAvailablePackingsLabel,
  ]);

  const handleOnConfirmationDeliveryDate = useCallback(() => {
    dispatch(
      sessionActions.setDates({
        billingDate:
          offerType === 1 || offerType === 2
            ? lkpAuctionDate.toString()
            : billingDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
        deliveryDate:
          offerType === 1 || offerType === 2
            ? lkpAuctionDate.toString()
            : deliveryDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
      }),
    );
    clickCloseModalInformation();
    form.submit();
  }, [
    billingDateValue,
    deliveryDateValue,
    dispatch,
    form,
    lkpAuctionDate,
    offerType,
  ]);

  const handleOnConfirmationDeliveryDateComposition = useCallback(() => {
    dispatch(
      sessionActions.setDates({
        billingDate:
          offerType === 1 || offerType === 2
            ? lkpAuctionDate.toString()
            : billingDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
        deliveryDate:
          offerType === 1 || offerType === 2
            ? lkpAuctionDate.toString()
            : deliveryDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
      }),
    );
    clickCloseModalInformationComposition();
    setModalIsVisibleComposition(productOffer, packingSelected);
    setOfferIdModalComposition(offerId);
  }, [
    billingDateValue,
    deliveryDateValue,
    dispatch,
    lkpAuctionDate,
    offerId,
    offerType,
    packingSelected,
    productOffer,
    setModalIsVisibleComposition,
    setOfferIdModalComposition,
  ]);

  const getErrorMinMessage = useCallback(() => {
    if (selectedTypeSale === PackingTypeSale.Packing) {
      return `Quantidade mínima: ${
        packingSelected?.minimumQuantity /
        +packingSelected?.maxAvailablePackingsLabel
      } ${textInputTypeSale}`;
    }
    if (selectedTypeSale === PackingTypeSale.Layer) {
      const values = packingSelected?.maxAvailableLayersLabel
        .split('x')
        .map((x) => +x);
      const calc =
        packingSelected?.minimumQuantity / values.reduce((a, b) => +a * +b);
      return `Quantidade mínima: ${calc < 1 ? 1 : calc} ${textInputTypeSale}`;
    }
    const values = packingSelected?.maxAvailableTrolleysLabel
      .split('x')
      .map((x) => +x);
    const calc =
      packingSelected?.minimumQuantity / values.reduce((a, b) => +a * +b);
    return `Quantidade mínima: ${calc < 1 ? 1 : calc} ${textInputTypeSale}`;
  }, [
    selectedTypeSale,
    packingSelected?.maxAvailableTrolleysLabel,
    packingSelected?.minimumQuantity,
    packingSelected?.maxAvailablePackingsLabel,
    packingSelected?.maxAvailableLayersLabel,
    textInputTypeSale,
  ]);

  const infoVoucherLabel = useMemo(
    () => (
      <>
        {reajustmentAmount < 0 ? 'Desconto ' : 'Adicional '}
        <span className="valeu">
          {voucherReajustmentType === 'P'
            ? `${Math.abs(reajustmentAmount)}%`
            : `R$${Math.abs(reajustmentAmount)}`}
        </span>
      </>
    ),
    [reajustmentAmount, voucherReajustmentType],
  );

  const infoVoucher = useMemo(
    () =>
      `*Para aplicação do ${
        reajustmentAmount < 0 ? 'desconto' : 'adicional'
      } a quantidade mínima deve ser de ${minimumUnitQuantityVoucher} ${
        voucherQuantityType === 'P'
          ? minimumUnitQuantityVoucher > 1
            ? 'embalagens'
            : 'embalagem'
          : voucherQuantityType === 'L'
          ? minimumUnitQuantityVoucher > 1
            ? 'camadas'
            : 'camada'
          : minimumUnitQuantityVoucher > 1
          ? 'carrinhos'
          : 'carrinho'
      }. O valor ${
        reajustmentAmount < 0 ? 'do desconto' : 'adicional'
      } será aplicado sobre o preço do produto`,
    [reajustmentAmount, minimumUnitQuantityVoucher, voucherQuantityType],
  );

  const handleClickFastBuy = useCallback(() => {
    setFastBuy(true);
    form.submit();
  }, [form]);

  const productBuyRef = useRef<ProductBuyHandle>(null);

  const handleSubmit = (formValues: any) => {
    productBuyRef.current?.verifyXpedAndBuyFunc(formValues);
  };

  const filterProducts = useCallback(() => {
    dispatch(
      offerFiltersActions.filter({
        ...offersFilters,
      }),
    );
    navigate(`/${producerUrl}/products`);
  }, [dispatch, navigate, offersFilters, producerUrl]);

  return (
    <S.Container
      form={form}
      onFinish={handleSubmit}
      initialValues={{ packingSelectedId: packingSelected.id }}
    >
      <S.Content gutter={16}>
        <Col md={24} lg={13} style={{ width: '100%' }}>
          <S.Gallery>
            <S.ImagesListBackground>
              <S.ImagesList>
                {Children.toArray(
                  arrayImage?.map((image, index) => (
                    <Image
                      border="1px solid #D9D9D9"
                      size={40}
                      src={image}
                      alt={`${productOffer.name} ${index}`}
                      onClick={() => setImageDisplay(index)}
                    />
                  )),
                )}
              </S.ImagesList>
            </S.ImagesListBackground>
            <S.ImagesDisplay>
              <Image
                label={`Imagem ${
                  images.length > 0 && !isLkpOffer
                    ? 'do produtor'
                    : 'ilustrativa'
                }`}
                size={320}
                imageNumber={`${imageDisplay + 1}/${arrayImage.length}`}
                src={arrayImage[imageDisplay]}
                alt={productOffer.name}
              />
            </S.ImagesDisplay>
          </S.Gallery>
        </Col>
        <S.ProductCardComponentDetails md={24} lg={11}>
          <Row className="header" justify="space-between">
            <Col className="badge" md={8}>
              <OfferTypeBadge hideCalendar offerType={offerType} />
            </Col>
            <Col className="info" md={14}>
              {offerType === 3 && (
                <S.ComponentInfoDirected>
                  Oferta Direcionada
                </S.ComponentInfoDirected>
              )}
              {!isLkpOffer && voucherId && (
                <S.ComponentInfoVoucher>
                  {infoVoucherLabel}
                </S.ComponentInfoVoucher>
              )}
            </Col>
            <S.ShareAndClosedContainer className="share" md={2}>
              <Share />
              <S.ClosedContent>
                <CloseOutlined onClick={() => filterProducts()} />
              </S.ClosedContent>
            </S.ShareAndClosedContainer>
          </Row>
          <Row>
            <S.ProductNameType className="col-details" md={23}>
              <Tooltip title={longName}>
                <div className="name-product">
                  <span>
                    {longName.slice(0, 40) +
                      (longName.length > 40 ? '...' : '')}
                  </span>
                  <div className="code-product">
                    <div className="number-product">{barcode}</div>
                    <div>{gtincode}</div>
                  </div>
                  <div className="producer-name">
                    <p>Produtor:</p>
                    <p>
                      <span className="producer-name"> {siteName}</span>
                    </p>
                  </div>
                </div>
              </Tooltip>
            </S.ProductNameType>
          </Row>
          <Row>
            <Col className="col-information" md={24}>
              <S.InformationProduct>
                <TrophyOutlined className="icon-information" />
                <div className="group-information">
                  <p className="title-information">Qualidade:</p>
                  <Quality quality={quality} />
                </div>
              </S.InformationProduct>
              <S.InformationProduct>
                <ExpandOutlined className="icon-information" />
                <div className="group-information">
                  <p className="title-information">Dimensão/Altura:</p>
                  <span>{dimension}</span>
                </div>
              </S.InformationProduct>
              <S.InformationProduct>
                <GiftOutlined className="icon-information" />
                <div className="group-information">
                  <p className="title-information">Embalagem:</p>
                  {packings && packings.length > 0 ? (
                    <Form.Item name="packingSelectedId">
                      <Select
                        style={{ display: 'flex' }}
                        bordered={false}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onSelect={handleOnSelect}
                        defaultValue={packingSelected.id}
                        value={packingSelected.id}
                      >
                        {packings &&
                          packings.map((packing: Packing) => (
                            <Option
                              value={packing.id}
                              key={`${offerId}-${packing.id}`}
                            >
                              {packing.code} - {packing.abbrev}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  ) : null}
                </div>
              </S.InformationProduct>
              <S.InformationProduct>
                <GroupOutlined className="icon-information" />
                <div className="group-information">
                  <p className="title-information">Múltiplo:</p>
                  <span>{packingSelected?.multiple}</span>
                </div>
              </S.InformationProduct>
              <S.InformationProduct>
                <BgColorsOutlined className="icon-information" />
                <div className="group-information">
                  <p className="title-information">Cores:</p>
                  <span>{colors}</span>
                </div>
              </S.InformationProduct>
              <S.InformationProduct>
                <EyeOutlined className="icon-information" />
                <div className="group-information">
                  <p className="title-information">Observações:</p>
                  <span>
                    {' '}
                    {observation?.length > 40 ? (
                      <Tooltip title={observation}>
                        {observation?.length > 40
                          ? `${observation.substring(0, 40)}...`
                          : observation}
                      </Tooltip>
                    ) : (
                      observation
                    )}
                  </span>
                </div>
              </S.InformationProduct>
              <S.InformationProduct>
                {!isLkpOffer && voucherId && (
                  <S.InformationVoucher>{infoVoucher}</S.InformationVoucher>
                )}
              </S.InformationProduct>
              <S.InformationProduct>
                {!!specialPrice && !!minimumQuantity && (
                  <S.InformationVoucher>
                    {minimumQuantity === 1
                      ? `*Para aplicação do valor promocional, a compra mínima deverá ser de 1 unidade`
                      : `*Para aplicação do valor promocional, a compra mínima deverá ser de ${minimumQuantity} unidades`}
                  </S.InformationVoucher>
                )}
              </S.InformationProduct>
              {startDate && endDate && (
                <S.InformationProduct>
                  {isLkpOffer ? (
                    <CalendarIcon />
                  ) : (
                    <PopoverCalendar
                      startDate={startDate}
                      endDate={endDate}
                      siteDeliveryPatterns={siteDeliveryPatterns}
                    />
                  )}
                  <div className="group-information">
                    <span
                      style={{
                        color: isLkpOffer ? 'rgba(0,91,167,1)' : '#353839',
                      }}
                    >
                      {isLkpOffer
                        ? `${moment(endDate).format('DD/MM')}`
                        : `Válido de ${moment(startDate).format(
                            'DD/MM/YYYY',
                          )} até ${moment(endDate).format('DD/MM/YYYY')}`}
                    </span>
                  </div>
                </S.InformationProduct>
              )}
            </Col>
          </Row>
          <S.ProductCardComponentActions>
            <Col className="line-actions" lg={13}>
              {isAuthenticated && (
                <>
                  {productOffer.shippingFeeFilials.length > 0 && (
                    <ProductShippingFee product={productOffer} />
                  )}

                  <S.GroupTypeSaleProduct>
                    <ButtonPacking
                      handleTypeSale={handleTypeSale}
                      selectedTypeSale={selectedTypeSale as PackingTypeSale}
                      packingSelected={packingSelected}
                      price={productOffer.packagingPrice}
                      packingCost={productOffer.packingCost}
                      packingType={PackingTypeSale.Packing}
                      quantityPacking={quantityPacking}
                      specialPrice={productOffer.specialPrice}
                      minimumQuantity={productOffer.minimumQuantity}
                    />
                    <ButtonPacking
                      handleTypeSale={handleTypeSale}
                      selectedTypeSale={selectedTypeSale as PackingTypeSale}
                      packingSelected={packingSelected}
                      price={productOffer.layerPrice}
                      packingCost={productOffer.packingCost}
                      packingType={PackingTypeSale.Layer}
                      quantityPacking={quantityPacking}
                      specialPrice={productOffer.specialPrice}
                      minimumQuantity={productOffer.minimumQuantity}
                    />
                    <ButtonPacking
                      handleTypeSale={handleTypeSale}
                      selectedTypeSale={selectedTypeSale as PackingTypeSale}
                      packingSelected={packingSelected}
                      price={productOffer.trolleyPrice}
                      packingCost={productOffer.packingCost}
                      packingType={PackingTypeSale.Trolley}
                      quantityPacking={quantityPacking}
                      specialPrice={productOffer.specialPrice}
                      minimumQuantity={productOffer.minimumQuantity}
                    />
                  </S.GroupTypeSaleProduct>
                  <Row className="line-actions-input">
                    <Form.Item
                      name="quantityPacking"
                      rules={[
                        {
                          required: true,
                          message: 'A quantidade é obrigatória.',
                        },
                        {
                          validator: (_, value) =>
                            !value || Number(value) <= getMaxAvailable.max
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    `Quantidade máxima: ${getMaxAvailable.max} ${textInputTypeSale}`,
                                  ),
                                ),
                        },
                        {
                          validator: (_, value) =>
                            !value ||
                            Number(value) * getMaxAvailable.min >=
                              packingSelected?.minimumQuantity
                              ? Promise.resolve()
                              : Promise.reject(new Error(getErrorMinMessage())),
                        },
                        {
                          validator: (_, value) =>
                            validatorUtils.validMultiple(
                              value,
                              packingSelected?.multiple,
                              selectedTypeSale,
                              getLabel(),
                              getMultipleCalc(),
                              textInputTypeSale,
                            ),
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="input-quantity"
                        addonAfter={textInputTypeSale}
                        onChange={({ target }) => {
                          const value = Number(target.value);
                          if (value >= 0) {
                            setQuantityPacking(value);
                            form.setFieldsValue({ quantityPacking: value });
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                </>
              )}
            </Col>
            <Col className="line-actions-quantity" lg={11}>
              <S.MinQuantityProduct>
                Compra mínima:
                <span>
                  {' '}
                  {calculateMinBySaleType(selectedTypeSale, packingSelected)}
                </span>
                <span> {textInputTypeSale}</span>
              </S.MinQuantityProduct>
              <S.AvailableQuantity>
                {getMaxAvailable.max} disponíveis
                <span>{getMaxAvailable.label}</span>
              </S.AvailableQuantity>
              <Popover
                content={
                  <div style={{ maxWidth: '180px' }}>
                    <span>
                      Utilize produtos diferentes para preencher uma mesma
                      camada.
                    </span>
                  </div>
                }
                title="Criar composição"
              >
                {isCompositionEnabled && (
                  <Button
                    type="primary"
                    className="button-composition"
                    onClick={() => handleVisibleModalComposition()}
                  >
                    <ApartmentOutlined />
                  </Button>
                )}
              </Popover>
            </Col>
          </S.ProductCardComponentActions>
          <S.ButtonsContainer>
            {customerSelected && isLkpOffer && (
              <button
                type="button"
                className="buy-now"
                onClick={handleClickFastBuy}
              >
                <FastCartIcon />
                Comprar agora
              </button>
            )}
            {customerSelected && !isProducerAuthenticated && (
              <button
                type="button"
                className="button-cart"
                onClick={() => form.submit()}
              >
                <ShoppingCartOutlined />
                Adicionar ao Carrinho
              </button>
            )}
          </S.ButtonsContainer>
        </S.ProductCardComponentDetails>
      </S.Content>
      <Modal
        closable
        onCancel={clickCloseModalInformationComposition}
        visible={displayModalInformationComposition}
        width="414px"
        footer={null}
      >
        <S.WarningLabel>
          <b>Atenção!</b> É necessário que selecione uma data para entrega e
          faturamento.
        </S.WarningLabel>
        <DeliveryDate
          initialDatePicker={{
            disabled: false,
            disabledDate: disableDatesDelivery,
            value: deliveryDateValue,
            open: showDatePickerDelivery && displayModalInformationComposition,
            onOpenChange: handleOnOpenChangeDelivery,
            onChange: handleOnChangeDelivery,
          }}
          endDatePicker={{
            disabled: deliveryDateValue === null,
            disabledDate: disableDatesBilling,
            value: billingDateValue,
            open:
              showDatePickerBilling &&
              displayModalInformationComposition &&
              !showDatePickerDelivery,
            onOpenChange: handleOnOpenChangeBilling,
            onChange: handleOnChangeBilling,
          }}
          onConfirmation={handleOnConfirmationDeliveryDateComposition}
          producerUnavailableDates={unavailableDates}
        />
      </Modal>
      <Modal
        closable
        onCancel={clickCloseModalInformation}
        visible={displayModalInformation}
        width="414px"
        footer={null}
      >
        <S.WarningLabel>
          <b>Atenção!</b> É necessário que selecione uma data para entrega e
          faturamento.
        </S.WarningLabel>
        <DeliveryDate
          initialDatePicker={{
            disabled: false,
            disabledDate: disableDatesDelivery,
            value: deliveryDateValue,
            open: showDatePickerDelivery && displayModalInformation,
            onOpenChange: handleOnOpenChangeDelivery,
            onChange: handleOnChangeDelivery,
          }}
          endDatePicker={{
            disabled: deliveryDateValue === null,
            disabledDate: disableDatesBilling,
            value: billingDateValue,
            open:
              showDatePickerBilling &&
              displayModalInformation &&
              !showDatePickerDelivery,
            onOpenChange: handleOnOpenChangeBilling,
            onChange: handleOnChangeBilling,
          }}
          onConfirmation={handleOnConfirmationDeliveryDate}
          producerUnavailableDates={unavailableDates}
        />
      </Modal>
      <ModalExpiredItemsInCart
        modalIsVisible={showModalExpiredItemsInCart}
        actionButtonCancel={handleClickButtonGoToCart}
        actionButtonOk={handleClickButtonDeleteCart}
      />
      <ProductBuy
        ref={productBuyRef}
        billingDateValue={billingDateValue}
        deliveryDateValue={deliveryDateValue}
        fastBuy={fastBuy}
        productOffer={productOffer}
        selectedTypeSale={selectedTypeSale}
        onFinish={() => setFastBuy(false)}
        onResetFields={form.resetFields}
        onSetDisplayModalInformation={setDisplayModalInformation}
      />
    </S.Container>
  );
}
