import styled from 'styled-components';

export const GroupCardComposition = styled.div`
    position: relative;
    width: 100%;
    @media (max-width: 500px) {
      .ant-collapse-header, .ant-collapse-header > span {
          font-family: Roboto;
          font-weight: bold;
          font-size: 14px !important;
          text-align: start;
          display: flex;
          align-items: center;
      }
    }
    
    &:not(.expired) .ant-collapse-header, .ant-collapse-header > span {
        color: #36393a;
    }
    &.expired .ant-collapse-header, .ant-collapse-header > span {
        color: #bfbfbf;
    }
    
    .ant-collapse-header, .ant-collapse-header > span {
        font-family: Roboto;
        font-weight: bold;
        font-size: 20px;
        text-align: start;
        display: flex;
        align-items: center;
    }
    
    &:not(.expired) .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
        color: #36393a;
    }
    &.expired .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
        color: #bfbfbf;
    }

    &:not(.expired) .ant-collapse-header {
      background-color: white !important;
      box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 0px rgb(0 0 0 / 8%), 0px 0px 3px 0px rgb(0 0 0 / 12%) !important;
      margin: 0 16px !important;
    }
    &.expired .ant-collapse-header {
      background-color: transparent !important;
      border: 1px solid #D9D9D9;
      margin: 0 16px !important;
    }

    .ant-collapse-content.ant-collapse-content-active > .ant-collapse-content-box {
      padding-top: 0;
    }
`;

export const GroupComposition = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const GroupCards = styled.div`
    width: 100%;

    &:not(.expired) {
      background-color: #fff;
      box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 32px rgb(0 0 0 / 8%), 0px 0px 3px 0px rgb(0 0 0 / 12%);
    }
    &.expired {
      background-color: transparent;
      border: 1px solid #D9D9D9;
    }
`;

export const GroupRemoveAndExpireTime = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderCollapse = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DeleteButtonComponent = styled.div`
  margin: 0 32px;
`;

export const ExpireTimeComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LabelExpireTime = styled.span`
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  
  &:not(.expired) {
    color: #f78e1e;
  }
  .expired {
    color: #bfbfbf;
  }
`;

export const TimerExpireTime = styled.span`
  .ant-statistic-content {
    font-size: 12px;
  }
  span {
    &:not(.expired) {
      color: #f78e1e;
    }
    
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
  }

  .expired {
    color: #bfbfbf;
  }
`;