import styled, { css } from 'styled-components';

import { Layout, Col, Row, Space } from 'antd';

interface ProductCardProps {
  pinned: 1 | 0;
}
interface ProductGridProps {
  typeOffer?: number;
}

const cardHovered = css`
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

  .cardExpanded {
    display: block !important;
  }
`;

const subOfWidth = (cols: number) => ((cols - 1) * 16) / cols;

const generateWidth = (cols: 1 | 2 | 3 | 4 | 5) => css`
  flex: 0 0 calc(${100 / cols}% - ${subOfWidth(cols)}px);
`;

export const CardProduct = styled.div`

  ${generateWidth(1)};

  @media (min-width: 768px) {
    ${generateWidth(2)};
  }
  
  @media (min-width: 992px) {
    ${generateWidth(3)};
  }

  @media (min-width: 1200px) {
    ${generateWidth(4)};
  }

  @media (min-width: 1600px) {
    ${generateWidth(5)};
  }
`;

export const ProductCard = styled(Layout)<ProductCardProps>`
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;

  ${(props) =>
    props.pinned
      ? cardHovered
      : css`
          :hover,
          &.hover {
            ${cardHovered}
          }
        `};
`;

export const ComponentInfoNew = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #353839;
  color: #fff;
  z-index: 2;
  border-radius: 16px;
  height: 22px;
  width: 50px;

  top: -11px;
  right: -8px;

  &.voucher-active {
    top: -18px;
    left: 0px;
  }
`;

export const ComponentInfoDirected = styled.span`
  position: absolute;
  top: -6px;
  z-index: 1;
  right: 0;
`;

export const ComponentInfoVoucher = styled.span`
  position: absolute;
  top: -6px;
  z-index: 1;
  right: 0;
`;

export const ProductCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 16px;
`;

export const PinContainer = styled.div``;

export const ProductImage = styled(Col).attrs({ xs: 24 })`
  flex: 0 0 auto !important;
  height: 150px;
  display: flex;
  justify-content: center;

`;

export const ProductCardFooter = styled(Col).attrs({ xs: 24 })`
  padding: 4px 16px 0px 16px !important;
  flex: 0 0 auto !important;
`;

export const ProductCardFooterSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 4.0,
})`
  width: 100%;
  padding: 8px 0;
  justify-content: flex-start;
`;
export const MainInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 6px;
`;

export const SecondaryInfo = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 38px;
`;

export const ProductCodes = styled(Col).attrs({ md: 9, xs: 11 })``;

export const ProductCodesSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 4.0,
})`
  width: 100%;
  text-align: left;
`;

export const ProducedBy = styled(Col).attrs({ md: 10, xs: 11 })``;

export const ProducedBySpacing = styled(Space).attrs({
  direction: 'vertical',
})`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
`;

export const ProducedByLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #8d8d8d;
  text-align: center;
  margin-bottom: 0px !important;
`;

export const ProducedByValue = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: right;
`;

export const ProductInfo = styled(Col).attrs({ xs: 24 })`
  padding-left: 16px !important;
  padding-bottom: 4px;
  padding-right: 16px;
`;

export const ProductNote = styled(Col).attrs({ xs: 24 })`
  padding-left: 0 !important;
  margin-bottom: 8px;
`;

export const ProductNoteLabel = styled.h1`
  font-weight: 400;
  font-size: 12px;
  color: #36393a;
  text-align: left;
  margin-bottom: 0 !important;
`;

export const ProductNoteValue = styled.h1`
  font-weight: bold;
  font-size: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductCardExpanded = styled(Col).attrs({ xs: 24 })`
  box-shadow: 0px 9px 28px -28px rgba(0, 0, 0, 0.05),
    0px 17px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  top: 99%;
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  z-index: 2;
  padding: 0 !important;

  &.not-display-none {
    display: block !important;
  }

  &.cardExpanded {
    display: none;
  }
`;

export const ProductDetails = styled(Row).attrs({
  gutter: [8.0, 0],
  justify: 'space-between',
})``;

export const ProductDetailLeft = styled(Col).attrs({ md: 12, xs: 12 })``;

export const ProductQuality = styled(Row).attrs({
  gutter: [8.0, 0],
  //   justify: 'space-between',
  align: 'middle',
})``;

export const QualityIcon = styled(Row)`
  border-radius: 4px;
  margin-right: 8px;
`;

export const QualityInfo = styled(Col).attrs({ md: 15, xs: 12 })``;

export const QualityInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const QualityInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductSize = styled(Row).attrs({
  gutter: [8.0, 0],
  //   justify: 'space-between',
  align: 'middle',
})``;

export const SizeIcon = styled(Row)`
  border-radius: 4px;
  margin-right: 8px;
`;

export const SizeInfo = styled(Col).attrs({ md: 15, xs: 12 })``;

export const SizeInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const SizeInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const SizeInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductColor = styled(Row).attrs({
  gutter: [8.0, 0],
  //   justify: 'space-between',
  align: 'middle',
})``;

export const ColorIcon = styled(Row)`
  border-radius: 4px;
  margin-right: 8px;
`;

export const ColorInfo = styled(Col).attrs({ md: 9, xs: 12 })``;

export const ColorInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const ColorInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ColorInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductMultiple = styled(Row).attrs({
  gutter: [8.0, 8.0],
  //   justify: 'space-between',
  align: 'middle',
})``;

export const MultipleIcon = styled(Row)`
  border-radius: 4px;
  margin-right: 8px;
`;

export const MultipleInfo = styled(Col).attrs({ md: 15, xs: 12 })``;

export const MultipleInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const MultipleLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const MultipleValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductDetailRight = styled(Col).attrs({ md: 12, xs: 12 })``;

export const ProductPackage = styled(Col).attrs({ md: 24, xs: 24 })`
  margin-top: 8px;
  max-height: 40px;
`;

export const PackageIcon = styled(Row)``;

export const PackageInfo = styled(Col).attrs({ md: 22, xs: 22 })``;

export const PackageInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  display: flex;
  flex-direction: column;

  .ant-select-selection-item {
    width: 100%;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px !important;
    color: #36393a;
    text-align: left;
    position: relative;
    margin-right: 8px;
  }

  .ant-select-arrow {
    user-select: none;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    top: 7px;
    line-height: 12px;
    color: #36393a;
    text-align: left;
  }
`;

export const PackageInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
  padding: 0 11px;
`;

export const Actions = styled.div<ProductGridProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  padding-right: ${(props) => props.typeOffer === 1 || props.typeOffer === 2 ? '5px' : '20px'};
  margin-left: ${(props) => props.typeOffer === 1 || props.typeOffer === 2 ? '10px' : '-5px'};

  @media screen and (max-width: 1200px) {
    margin-left: 1%;
    padding-right: 1%
  }
`;

export const BadgeAndInfo = styled.div`
  display: flex;
  gap: 8px;
`;