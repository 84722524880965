import styled from 'styled-components';

export const ComponentInputXped = styled.div``;

export const TextExped = styled.p`
  color: #8c8c8c;
  margin-bottom: 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;