import styled from 'styled-components';

export const Button = styled.button`
  background-color: transparent;
  border: 0px solid transparent;
`;

export const ImageContent = styled.img`
  width: auto !important;
  height: auto !important;
  max-width: none !important;
  max-height: none !important;
  transform: none !important;
  cursor: pointer;
`;
