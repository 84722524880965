/* eslint-disable no-nested-ternary */
import ModalExpiredItemsInCart from 'components/Modals/ExpiredItemsInCart';
import ModalXped from 'components/Modals/Xped';
import { offerTypeMap } from 'enums/offerTypeMap';
import ProductOffer from 'model/ProductOffer';
import { SummaryCart } from 'model/types';
import { Moment } from 'moment';
import {
  ElementRef,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  showAlertError,
  showAlertSuccess,
  showAlertWarning,
} from 'services/alertService';
import {
  useGetDeleteCartMutation,
  useGetPostCheckoutMutation,
  useGetPostProductInCartMutation,
} from 'services/ecommerceApi';
import { cartSelectors, sessionSelectors } from 'store/state/selectors';
import { cartActions } from 'store/state/slices';
import { prepareObjectToInsertInCart } from 'utils/cart';

type ProductBuyProps = {
  billingDateValue: Moment | null;
  deliveryDateValue: Moment | null;
  fastBuy: boolean;
  productOffer: ProductOffer;
  selectedTypeSale: string;
  onFinish: () => void;
  onResetFields: (fields: string[]) => void;
  onSetDisplayModalInformation: (value: boolean) => void;
  setOfferIdInserted?: (offerId: number, offerType: number) => void;
};
interface RefObject {
  verifyXpedAndBuyFunc: (values: any) => void;
}
export type ProductBuyHandle = ElementRef<typeof ProductBuy>;

export const ProductBuy = forwardRef(
  (
    {
      billingDateValue,
      deliveryDateValue,
      fastBuy,
      productOffer,
      selectedTypeSale,
      onFinish,
      onResetFields,
      onSetDisplayModalInformation,
      setOfferIdInserted,
    }: ProductBuyProps,
    ref: Ref<RefObject>,
  ) => {
    const {
      offerId,
      offerType,
      voucherId,
      siteId,
      productQualityId,
      productId,
      producerId,
      productColorId,
      endDate,
      lkpAuctionDate,
    } = productOffer;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [preparedObject, setPreparedObject] = useState<any>(undefined);
    const [showXpedModal, setShowXpedModal] = useState(false);
    const [xped, setXped] = useState('');
    const [showModalExpiredItemsInCart, setShowModalExpiredItemsInCart] =
      useState(false);
    const [getDeleteCart] = useGetDeleteCartMutation();
    const [postCheckout] = useGetPostCheckoutMutation();
    const [getPostProductInCart] = useGetPostProductInCartMutation();
    const totalLkpItems = useSelector(cartSelectors.getTotalLkpItems);
    const totalVolItems = useSelector(cartSelectors.getTotalVolItems);
    const customerSelected = useSelector(sessionSelectors.getSelectedCustomer);
    const billingDate = useSelector(sessionSelectors.getSelectedBillingDate);
    const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
    const volOfferId = useSelector(cartSelectors.getVolOfferId);
    const lkpOfferId = useSelector(cartSelectors.getLkpOfferId);
    const isLkpOffer = useMemo(
      () => offerType === 1 || offerType === 2,
      [offerType],
    );

    const insertProductInCartFetch = useCallback(
      async (willBuy = false) => {
        const result = (await getPostProductInCart({
          ...preparedObject,
          buyNow: willBuy,
          xped,
        })) as {
          data?: SummaryCart;
          error?: any;
        };
        if (result?.data) {
          dispatch(cartActions.setSummaryCart(result.data));
          if (setOfferIdInserted) {
            setOfferIdInserted(offerId, offerType);
          }
          onResetFields(['quantityPacking']);
          return result.data;
        }
        throw result?.error?.data || result;
      },
      [
        dispatch,
        getPostProductInCart,
        offerId,
        offerType,
        onResetFields,
        preparedObject,
        setOfferIdInserted,
        xped,
      ],
    );

    const insertProductInCartAndBuy = useCallback(async () => {
      try {
        const { lkpOfferId, volOfferId } = await insertProductInCartFetch(true);
        const result: any = await postCheckout({
          cartId: Number(lkpOfferId || volOfferId),
          xped,
          isVolOffer: !isLkpOffer,
          isLkpOffer,
        });
        console.log('result:: ', result);
        if (result?.error) {
          throw result?.error?.data || result;
        }
        showAlertSuccess(dispatch, 'Compra realizada com sucesso!');
      } catch (error) {
        showAlertError(
          dispatch,
          `Erro ao efetuar compra: ${String(error)}`,
          true,
          10000,
        );
      }
    }, [dispatch, insertProductInCartFetch, isLkpOffer, postCheckout, xped]);

    const handleSubmitXpedModal = useCallback(
      async (xpedValue: any) => {
        if (typeof xpedValue === 'string') {
          setXped(xpedValue);
        } else {
          setXped(xpedValue.xped);
        }

        setShowXpedModal(false);

        if (fastBuy) {
          await insertProductInCartAndBuy();
          return;
        }
        await insertProductInCartFetch(false);
        showAlertSuccess(dispatch, 'Produto inserido ao carrinho sucesso!');
      },
      [dispatch, fastBuy, insertProductInCartAndBuy, insertProductInCartFetch],
    );

    const insertProductInCart = async (valueToInsert: any) => {
      const { billingDateTemp, deliveryDateTemp, objectPayload } =
        prepareObjectToInsertInCart(valueToInsert, {
          customerId: Number(customerSelected?.id),
          productColorId,
          voucherId,
          productId,
          productQualityId,
          siteId,
          producerId,
          offerType,
          selectedTypeSale,
          billingDate:
            offerType === 1 || offerType === 2
              ? lkpAuctionDate.toString()
              : billingDate,
          deliveryDate:
            offerType === 1 || offerType === 2
              ? lkpAuctionDate.toString()
              : deliveryDate,
          lkpAuctionDate,
          offerId,
          billingDateValue,
          deliveryDateValue,
          endDate,
        });
      if (
        (billingDateTemp && deliveryDateTemp) ||
        offerType === 1 ||
        offerType === 2
      ) {
        setPreparedObject(objectPayload);
        return;
      }

      onSetDisplayModalInformation(true);
    };

    const verifyXpedAndBuy = useCallback(async () => {
      try {
        if (customerSelected?.hasXmlOrder) {
          setShowXpedModal(true);
          return;
        }
        if (fastBuy) {
          await insertProductInCartAndBuy();
          return;
        }
        await insertProductInCartFetch(false);

        if (preparedObject?.cartItems[0]?.volOfferId && totalVolItems <= 0) {
          showAlertWarning(
            dispatch,
            'Atenção! Após o tempo de expiração do carrinho ou do produto a compra será confirmada.',
            true,
            30000,
          );
          return;
        }
        if (preparedObject?.cartItems[0]?.lkpOfferId && totalLkpItems <= 0) {
          showAlertWarning(
            dispatch,
            'Atenção! Após a expiração do carrinho a compra será confirmada.',
            true,
            30000,
          );
          return;
        }
        showAlertSuccess(dispatch, 'Produto inserido ao carrinho sucesso!');
        return;
      } catch (err: Error | any) {
        showAlertError(
          dispatch,
          typeof err === 'string'
            ? err
            : 'Ocorreu um erro ao inserir o produto no carrinho.',
        );
      }
      onFinish();
    }, [
      customerSelected?.hasXmlOrder,
      dispatch,
      fastBuy,
      insertProductInCartAndBuy,
      insertProductInCartFetch,
      onFinish,
      preparedObject?.cartItems,
      totalLkpItems,
      totalVolItems,
    ]);

    useEffect(() => {
      if (customerSelected && preparedObject) {
        verifyXpedAndBuy();
      }
    }, [preparedObject]);

    useImperativeHandle(ref, () => ({
      verifyXpedAndBuyFunc: insertProductInCart,
    }));

    const handleClickButtonGoToCart = () => {
      navigate('/veiling/shopping-cart');
      setShowModalExpiredItemsInCart(false);
    };

    const handleClickButtonDeleteCart = () => {
      const isLkpOffer =
        productOffer.offerType !== offerTypeMap.onSite &&
        productOffer.offerType !== offerTypeMap.directed;
      const cartId = isLkpOffer ? lkpOfferId : volOfferId;
      getDeleteCart({ cartId });
      setShowModalExpiredItemsInCart(false);
    };

    return (
      <>
        {showModalExpiredItemsInCart && (
          <ModalExpiredItemsInCart
            modalIsVisible={showModalExpiredItemsInCart}
            actionButtonCancel={handleClickButtonGoToCart}
            actionButtonOk={handleClickButtonDeleteCart}
          />
        )}
        <ModalXped
          onCancel={() => setShowXpedModal(false)}
          visible={showXpedModal}
          onSubmit={handleSubmitXpedModal}
        />
      </>
    );
  },
);
