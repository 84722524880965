import { useCallback, useState, memo } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Container, ContainerImage, ImageLegend, ButtonImage } from './styles';

interface Props {
  images: string[];
  maxHeight: string;
  imageByProducer: boolean;
  open(): void;
}
function ProductCardImage({ images, maxHeight, open, imageByProducer }: Props) {
  const [imageDisplay, setImageDisplay] = useState<number>(0);

  const handleImageDisplay = useCallback(
    (value: number) => {
      let index = value;
      if (value < 0) {
        index = images.length - 1;
      } else if (value >= images.length) {
        index = 0;
      }
      setImageDisplay(index);
    },
    [images.length],
  );

  return (
    <Container>
      <ContainerImage>
        {images.length > 1 && (
          <Button
            type="text"
            onClick={() => handleImageDisplay(imageDisplay - 1)}
            icon={<LeftOutlined />}
          />
        )}
        <ButtonImage type="button" onClick={open}>
          <img
            style={{ maxHeight, maxWidth: '100%' }}
            src={images[imageDisplay]}
            alt="Imagem do produto"
            loading="lazy"
          />
        </ButtonImage>
        {images.length > 1 && (
          <Button
            type="text"
            onClick={() => handleImageDisplay(imageDisplay + 1)}
            icon={<RightOutlined />}
          />
        )}
      </ContainerImage>
      <ImageLegend>
        Imagem
        <span>{imageByProducer ? 'do produtor' : 'ilustrativa'}</span>
      </ImageLegend>
    </Container>
  );
}

export default memo(ProductCardImage);
