import { memo, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import moment, { Moment } from 'moment';

import { sessionActions } from 'store/state/slices';
import { deliveryDatePattern } from 'services/deliveryDatePattern';
import DeliveryDate from 'components/UI/DeliveryDate';
import { DeliveryPatterns } from 'model/DeliveryPatterns';

import { sessionSelectors } from 'store/state/selectors';
import UnavailableDate from 'model/UnavailableDate';
import { WarningLabel, Bold } from './styles';

interface Props {
  onCancel(): void;
  onSubmit(): void;
  endDate: Date;
  startDate?: Date;
  siteDeliveryPatterns: DeliveryPatterns[];
  producerUnavailableDates: UnavailableDate[];
}

function prepareDate(date: Moment) {
  return date.set('hour', 0).format('YYYY-MM-DD');
}

function ModalDeliveryDate({
  onCancel,
  onSubmit,
  endDate,
  siteDeliveryPatterns,
  startDate,
  producerUnavailableDates
}: Props) {
  const dispatch = useDispatch();
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const businessDate = useSelector(sessionSelectors.getSelectedBillingDate);

  const [deliveryDateValue, setDeliveryDateValue] = useState<Moment | null>(
    null,
  );
  const [showDatePickerDelivery, setShowDatePickerDelivery] =
    useState<boolean>(true);

  const [billingDateValue, setBillingDateValue] = useState<Moment | null>(null);
  const [showDatePickerBilling, setShowDatePickerBilling] =
    useState<boolean>(false);

  const handleOnOpenChangeDelivery = (event: boolean) => {
    setShowDatePickerDelivery(event);
    setShowDatePickerBilling(!deliveryDateValue);
  };

  const handleOnOpenChangeBilling = (event: boolean) => {
    setShowDatePickerBilling(event);
  };

  const handleOnChangeDelivery = (value: Moment | null) => {
    setBillingDateValue(null);
    setDeliveryDateValue(value);
    setShowDatePickerBilling(!!value);
  };

  const handleOnChangeBilling = (value: Moment | null) => {
    setBillingDateValue(value);
  };

  const disableDatesDelivery = useCallback(
    (value: Moment) => {
      const calendarValue = moment(value).set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });
      const dateNow = moment().set({
        hour: 0,
        minute: 0,
        second: 1,
        millisecond: 0,
      });
      const endDateFormatted = moment(endDate).set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });
      const deliveryDays = siteDeliveryPatterns.map(
        (date) => date.deliveryDayNumber,
      );
      const deliveryDate = deliveryDatePattern(
        siteDeliveryPatterns,
      ).getDeliveryDateByDatePatterns(dateNow.toDate().getDay());
      return (
        calendarValue.isBefore(moment(startDate).isAfter(moment()) ? startDate : deliveryDate) ||
        value.isAfter(endDateFormatted) ||
        !deliveryDays.includes(calendarValue.day()) ||
        Boolean(producerUnavailableDates?.some(item => moment(item.unavailableDate).isSame(value, 'day')))
      );
    },
    [endDate, siteDeliveryPatterns, startDate, producerUnavailableDates],
  );

  const disableDatesBilling = useCallback(
    (value: Moment) => {
      if (deliveryDateValue !== null) {
        const initalDay = moment(deliveryDateValue);
        const lastDay = moment(deliveryDateValue).add(2, 'days');

        return value.isAfter(lastDay) || value.isBefore(initalDay);
      }
      return false;
    },
    [deliveryDateValue],
  );

  const handleOnConfirmationDeliveryDateComposition = useCallback(() => {
    dispatch(
      sessionActions.setDates({
        billingDate: billingDateValue ? prepareDate(billingDateValue) : '',
        deliveryDate: deliveryDateValue ? prepareDate(deliveryDateValue) : '',
      }),
    );
  }, [billingDateValue, deliveryDateValue, dispatch]);

  useEffect(() => {
    if (deliveryDate && businessDate) {
      // eslint-disable-next-line no-console
      console.log(`deliveryDate(${deliveryDate}) && businessDate(${businessDate})`);
      onSubmit();
    }
  }, [deliveryDate, businessDate]);

  return (
    <Modal closable onCancel={onCancel} visible width="414px" footer={null}>
      <WarningLabel>
        <Bold>Atenção!</Bold> É necessário que selecione uma data para entrega e
        faturamento.
      </WarningLabel>
      <DeliveryDate
        initialDatePicker={{
          disabled: false,
          disabledDate: disableDatesDelivery,
          value: deliveryDateValue,
          open: showDatePickerDelivery,
          onOpenChange: handleOnOpenChangeDelivery,
          onChange: handleOnChangeDelivery,
        }}
        endDatePicker={{
          disabled: deliveryDateValue === null,
          disabledDate: disableDatesBilling,
          value: billingDateValue,
          open: showDatePickerBilling && !showDatePickerDelivery,
          onOpenChange: handleOnOpenChangeBilling,
          onChange: handleOnChangeBilling,
        }}
        onConfirmation={handleOnConfirmationDeliveryDateComposition}
        producerUnavailableDates={[]}
      />
    </Modal>
  );
}

export default memo(ModalDeliveryDate);
