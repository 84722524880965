import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ProductCardImage = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  span {
    white-space: nowrap;
  }
`;

export const ImageComponentsRow = styled(Row)`
  width: 100%;
`;
export const IllustrativeImage = styled.div`
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #8c8c8c;
  margin-top: 12px;
`;
