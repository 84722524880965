import { memo } from 'react';
import { Tooltip } from 'antd';

import { Container, InfoValue, Label, Value } from './styles';

interface Props {
  observation: string;
}

function InfoObservation({ observation }: Props) {
  return (
    <Container>
      <InfoValue>
        <Tooltip title={observation}>
          <Value>
            {observation?.length > 15
              ? `${observation.substring(0, 15)}...`
              : observation}
          </Value>
        </Tooltip>
      </InfoValue>
    </Container>
  );
}

export default memo(InfoObservation);
