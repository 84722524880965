import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

export const ImageLegend = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  color: #8c8c8c;
  margin-top: 12px;
  > span {
    margin-left: 2px;
  }
`;

export const ButtonImage = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
`;
