import { Table, Spin } from 'antd';
import styled from 'styled-components';

interface STYLEDPROPS {
  descroption?: boolean;
}

export const TableSon = styled(Table)`
  margin-top: 24px;
  .ant-table-thead .ant-table-cell {
    background-color: #fff9eb;
  }
`;

export const GroupTitleInformation = styled.div<STYLEDPROPS>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.descroption ? 'flex-start' : 'center'};
`;

export const TitleText = styled.h4`
  font: 12px 'Roboto', sans-serif;
  font-weight: 600;
  margin-bottom: 4px;
  text-align: start !important;
`;

export const TitleTextWrap = styled.h4`
  font: 12px 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 3px;
  text-align: start !important;
  max-width: 25ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DescriptionText = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: start !important;
  color: #8c8c8c !important;
`;

export const DescriptionTextChunk = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: start !important;
  color: #8c8c8c !important;
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ObsContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenterContent = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SubText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #8c8c8c;
`;

export const SubTextBody = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #8c8c8c;
  margin-top: 5px;
  margin-bottom: -5px;
`;

export const InputCurrency = styled.input`
  text-align: center;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
  cursor: pointer;
`;

export const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

export const BoughtContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -20px;
  margin-top: -5px;
`;

export const BoughtText = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  text-align: start !important;
  color: #f78e1e !important;
`;
