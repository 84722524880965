import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { buyIntentionActions } from 'store/state/slices';
import * as BuyIntentionCalls from 'store/state/buyIntention/apiCalls';
import { Button, Popover, Switch } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import {
  Container,
  PopoverContainer,
  PopoverItem,
  Label,
  Title,
  Line,
  ButtonBuyIntention,
  ContentButton,
  TextButton,
} from './styles';

interface Props {
  modePagination: boolean;
  changeSwitch(modePagination: boolean): void;
}

function Config({ modePagination, changeSwitch }: Props) {
  const dispatch = useDispatch();
  const [showPopover, setShowPopover] = useState(false);

  const handleClickSwitchConfig = useCallback(
    (modePagination: boolean) => {
      changeSwitch(modePagination);
      setShowPopover(false);
    },
    [changeSwitch],
  );

  const handleClickChange = useCallback((visible: boolean) => {
    setShowPopover(visible);
  }, []);

  const handleClickBuyIntention = useCallback(() => {
    dispatch(buyIntentionActions.setShowIntentionModal(true));
    dispatch(BuyIntentionCalls.fetchProducers());
  }, [dispatch]);

  const popoverContent = useMemo(
    () => (
      <PopoverContainer>
        <Title>Configurações da listagem de produtos</Title>
        <PopoverItem>
          <Label>Lista com paginação</Label>
          <Switch
            size="small"
            id="switch-pagination"
            checked={modePagination}
            onChange={() => handleClickSwitchConfig(true)}
          />
        </PopoverItem>
        <PopoverItem>
          <Label>Lista infinita</Label>
          <Switch
            size="small"
            id="switch-infinite"
            checked={!modePagination}
            onChange={() => handleClickSwitchConfig(false)}
          />
        </PopoverItem>
        {/* <Line />
        <ContentButton>
          <ButtonBuyIntention onClick={() => handleClickBuyIntention()}>
            <TextButton>+ Cadastrar Intenção de Compra</TextButton>
          </ButtonBuyIntention>
        </ContentButton> */}
      </PopoverContainer>
    ),
    [modePagination, handleClickSwitchConfig, handleClickBuyIntention],
  );

  return (
    <Container>
      <Popover
        placement="right"
        content={popoverContent}
        trigger="click"
        visible={showPopover}
        onVisibleChange={handleClickChange}
      >
        <Button shape="circle" size="large" icon={<SettingOutlined />} />
      </Popover>
    </Container>
  );
}

export default memo(Config);
