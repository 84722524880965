import { memo } from 'react';

import {
  BgColorsOutlined,
  ExpandOutlined,
  GiftOutlined,
  GroupOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';

import InfoNew from 'screens/DirectedOffers/components/ProductItem/components/InfoNew';
import SelectPacking from 'components/BusinessLogic/Product/SelectPacking';
import ProductActions from 'screens/DirectedOffers/components/ProductActions';

import Quality from 'components/UI/Quality';
import ShowDetails from 'components/UI/ShowDetails';

import InfoDirected from 'screens/Products/components/ProductItem/components/InfoDirected';
import InfoDelivery from '../../components/InfoDelivery';
import InfoDirectedTag from '../../components/InfoDirectedTag';
import InfoBarCode from '../../components/InfoBarCode';
import InfoObservation from '../../components/InfoObservation';
import ProductCardImage from '../../components/ProductCardImage';

import { ModesProps } from '../props';

import {
  ProductCardListComponent,
  ProductCardComponentDetails,
  ProductCardImageContainer,
  ProductNameType,
  InformationProduct,
  Actions,
  InfoNewContainer,
  InfoDirectedTagContainer,
  MainContent,
  BadgeContainer,
  ProductName,
} from './styles';

function MinifyList({
  product,
  packingSelected,
  handleOnSelectPacking,
  openImage,
  isProducerAuthenticated,
  addInCart,
  buy,
  showModalComposition,
  producerUrl,
  selectedTypeSale,
  onChangePackingTypeSale,
}: ModesProps) {
  return (
    <ProductCardListComponent>
      {product.isNewOffer && (
        <InfoNewContainer>
          <InfoNew />
        </InfoNewContainer>
      )}

      {product.offerType === 3 && (
        <InfoDirectedTagContainer>
          <InfoDirectedTag specialPrice={product.specialPrice} />
        </InfoDirectedTagContainer>
      )}

      <ProductCardImageContainer>
        <ProductCardImage
          images={product.imagesFormatted}
          imageByProducer={product.images.length > 0 && !product.isLkpOffer}
          maxHeight="50px"
          open={openImage}
        />
      </ProductCardImageContainer>

      <MainContent>
        {/* informações de dimensão e altura */}
        <ProductCardComponentDetails xs={4} sm={8} md={10} lg={13} xl={13}>
          <Row className="row-details-name">
            <Col className="col-details" xs={24}>
              <ProductNameType>
                <div className="name-product-div">
                  <InfoDelivery
                    isLkpOffer={product.isLkpOffer}
                    endDateFormatted={product.endDateFormatted}
                    endDate={product.endDate}
                    startDate={product.startDate}
                    siteDeliveryPatterns={product.siteDeliveryPatterns}
                  />
                  <ProductName>{product.longName}</ProductName>
                  <InfoBarCode
                    barcode={product.barcode}
                    gtincode={product.gtincode}
                  />
                </div>
                <BadgeContainer>
                  <ShowDetails
                    producerUrl={producerUrl}
                    offerId={product.offerId}
                    offerType={product.offerType}
                    packingSelectedId={packingSelected.id}
                    selectedTypeSale={selectedTypeSale}
                  />
                </BadgeContainer>
              </ProductNameType>
            </Col>
          </Row>
          <Row className="row-details">
            <Col className="col-details" md={24} lg={14} xl={19}>
              <Row>
                <InformationProduct>
                  <TrophyOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Qualidade:</p>
                    <Quality quality={product.quality} />
                  </div>
                </InformationProduct>

                <InformationProduct>
                  <GroupOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Múltiplo:</p>
                    <span>{packingSelected?.multiple}</span>
                  </div>
                </InformationProduct>
                <InformationProduct>
                  <BgColorsOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Cores:</p>
                    <span>{product.colors}</span>
                  </div>
                </InformationProduct>
                <InformationProduct>
                  <GiftOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Embalagem:</p>
                    <SelectPacking
                      offerId={product.offerId}
                      onSelect={handleOnSelectPacking}
                      packings={product.packings}
                      value={packingSelected.id}
                    />
                  </div>
                </InformationProduct>
                <InformationProduct>
                  <ExpandOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Dimensão/Altura:</p>
                    <span>{product.dimension}</span>
                  </div>
                </InformationProduct>
              </Row>
            </Col>
            <Col className="col-details-color" md={24} lg={10} xl={5}>
              <p>
                <span className="producer-name"> {product.siteName}</span>
              </p>
              {!!product.specialPrice && !!product.minimumQuantity && (
                <InfoDirected
                  directedMessage={
                    product.minimumQuantity === 1
                      ? `*Para aplicação do valor promocional, a compra mínima deverá ser de 1 unidade`
                      : `*Para aplicação do valor promocional, a compra mínima deverá ser de ${product.minimumQuantity} unidades`
                  }
                />
              )}
              {!!product.observation && (
                <InfoObservation observation={product.observation} />
              )}
            </Col>
          </Row>
        </ProductCardComponentDetails>

        {/* Ações sobre o produto */}
        <Col xs={20} sm={16} md={14} lg={11} xl={11}>
          <Actions>
            <ProductActions
              product={product}
              packingSelected={packingSelected}
              isProducerAuthenticated={isProducerAuthenticated}
              openComposition={showModalComposition}
              onClickBuy={addInCart}
              onClickFastBuy={buy}
              actionsButtonType="small"
              formType="small"
              mode="row"
              theme="light"
              selectedTypeSale={selectedTypeSale}
              onChangePackingTypeSale={onChangePackingTypeSale}
            />
          </Actions>
        </Col>
      </MainContent>
    </ProductCardListComponent>
  );
}

export default memo(MinifyList);
