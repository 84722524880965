import { Select, SelectProps } from 'antd';
import { ProductSelectOption } from 'model/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProductsSelectOptionsQuery } from 'services/ecommerceApi';

interface Props extends SelectProps<number | ''> {
  allowEmpty?: boolean;
  categoryId: number;
  groupId: number;
  siteId: number;
  onFilterProducts?: (filteredProducts: ProductSelectOption[]) => void;
}
export default function ProductsSelect({
  allowEmpty = false,
  categoryId,
  groupId,
  siteId,
  onFilterProducts,
  ...props
}: Props) {
  const { t } = useTranslation();

  const { data, isLoading } = useGetProductsSelectOptionsQuery(siteId);

  const [productsList, setProductList] = useState<ProductSelectOption[]>([]);

  useEffect(() => {
    if (onFilterProducts) {
      onFilterProducts(productsList);
    }
  }, [productsList]);

  useEffect(() => {
    if (data?.length) {
      let filteredProducts: ProductSelectOption[] = [...data];
      if (categoryId && !Number.isNaN(categoryId)) {
        filteredProducts = filteredProducts.filter(
          (i) => i.categoryId === categoryId,
        );
      }
      if (groupId && !Number.isNaN(groupId)) {
        filteredProducts = filteredProducts.filter(
          (i) => i.groupId === groupId,
        );
      }
      setProductList(filteredProducts);
    }
  }, [data, categoryId, groupId]);

  return (
    <Select
      showSearch
      allowClear
      loading={isLoading}
      placeholder={t('generals.selectPlaceholder')}
      optionFilterProp="children"
      filterOption
      {...props}
    >
      {allowEmpty ? (
        <Select.Option key="all" value="">
          Todos
        </Select.Option>
      ) : null}
      {productsList?.map((product: any) => (
        <Select.Option key={product.id} value={product.id}>
          {product.fullName}
        </Select.Option>
      ))}
    </Select>
  );
}
