import { createContext, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sessionSelectors } from 'store/state/selectors';

interface RedirectContextData {
  navigate: (route?: string) => void;
}

const RedirectContext = createContext<RedirectContextData>(
  {} as RedirectContextData,
);

function RedirectProvider({ children }: any) {
  const navigateRouterDom = useNavigate();
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);

  const navigate = useCallback((route = '') => {
    navigateRouterDom(`/${producerUrl}/${route}`);
  }, []);

  const valueMemo = useMemo(() => ({
    navigate
  }), []);
  
  return (
    <RedirectContext.Provider value={valueMemo}>
      {children}
    </RedirectContext.Provider>
  );
};

function useRedirect(): RedirectContextData {
  const context = useContext(RedirectContext);

  if (!context) {
    throw new Error('useRedirect must be used within an RedirectProvider');
  }

  return context;
}

export { RedirectProvider, useRedirect };
