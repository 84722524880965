import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #353839;
  color: #fff;
  border-radius: 16px;
  font-size: 8px;

  padding: 2px 10px;
`;
