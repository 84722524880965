import { useState } from 'react';

import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';
import { ComponentViewQrCode } from 'enums/componentViewQrCode';
import Home from './Home';
import History from './History';

import { Header, Root, HeaderTitleText, Body } from './styles';
import ReadQrCode from './ReadQrCode';
import ProductListQrCode from './ProductListQrCode';

export default function ShoppingLkByQRCode() {
  const [componentView, setComponentView] = useState<ComponentViewQrCode>(
    ComponentViewQrCode.HOME,
  );

  const [codeQrScan, setCodeQrScan] = useState<string>('');

  return (
    <Screen
      content={
        <Root>
          <Header>
            <HeaderTitleText>Compra LKP QRCode</HeaderTitleText>
          </Header>

          <Body>
            {componentView === ComponentViewQrCode.HOME && (
              <Home setComponentView={setComponentView} />
            )}
            {componentView === ComponentViewQrCode.HISTORY && (
              <History setComponentView={setComponentView} />
            )}
            {componentView === ComponentViewQrCode.SCAN && (
              <ReadQrCode setCodeQrScan={setCodeQrScan} setComponentView={setComponentView}/>
            )}
            {componentView === ComponentViewQrCode.PRODUCT_LIST && (
              <ProductListQrCode codeQrScan={codeQrScan} setComponentView={setComponentView}/>
            )}
          </Body>
        </Root>
      }
    />
  );
}
