import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FileSyncOutlined,
  HistoryOutlined,
  IssuesCloseOutlined,
  StopOutlined,
  UserOutlined,
  WarningFilled,
} from '@ant-design/icons';
import Partial from 'assets/svgs/Partial.svg';
import * as S from './styles';

export default function TabLegend() {
  return (
    <S.LegendForMobile>
      <span>Legenda:</span>
      <S.LegendItemsForMobile>
        <S.LegendForMobileContent>
          <S.LegendItemForMobile>
            <CheckCircleOutlined style={{ color: '#52C41A' }} />
            <span>Confirmado</span>
          </S.LegendItemForMobile>
          <S.LegendItemForMobile>
            <img src={Partial} alt="" />
            <span>Confirmado Parcial</span>
          </S.LegendItemForMobile>
          <S.LegendItemForMobile>
            <IssuesCloseOutlined style={{ color: '#125C4F' }} />
            <span>Confirmado com Alteração</span>
          </S.LegendItemForMobile>
        </S.LegendForMobileContent>

        <S.LegendForMobileContent>
          <S.LegendItemForMobile>
            <HistoryOutlined style={{ color: '#FAAD14' }} />
            <span>Pendente</span>
          </S.LegendItemForMobile>
          <S.LegendItemForMobile>
            <FileSyncOutlined style={{ color: '#FAAD14' }} />
            <span>Aguardando geração do Pedido</span>
          </S.LegendItemForMobile>
        </S.LegendForMobileContent>

        <S.LegendForMobileContent>
          <S.LegendItemForMobile>
            <UserOutlined style={{ color: '#FAAD14' }} />
            <span>Aguardando Produtor</span>
          </S.LegendItemForMobile>
          <S.LegendItemForMobile>
            <CloseCircleOutlined style={{ color: '#FA541C' }} />
            <span>Recusado</span>
          </S.LegendItemForMobile>
        </S.LegendForMobileContent>

        <S.LegendForMobileContent>
          <S.LegendItemForMobile>
            <StopOutlined style={{ color: '#FF0000' }} />
            <span>Cancelado</span>
          </S.LegendItemForMobile>
          <S.LegendItemForMobile>
            <WarningFilled style={{ color: '#000000' }} />
            <span>Erro</span>
          </S.LegendItemForMobile>
        </S.LegendForMobileContent>

        <S.LegendItemForMobile>
          <ClockCircleOutlined style={{ color: '1E1E1E' }} />
          <span>Expirado</span>
        </S.LegendItemForMobile>
      </S.LegendItemsForMobile>
    </S.LegendForMobile>
  );
}
