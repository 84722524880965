import styled from 'styled-components';

export const Container = styled.span`
  padding: 2px;
  left: 0;
  text-align: center;
  background-color: #f78e1e;
  color: #fff;
  border-radius: 4px;
  word-break: break;

  display: flex;
  gap: 4px;
  font-size: 8px;
`;
