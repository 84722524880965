/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';
import ProductCardList from 'components/BusinessLogic/Product/ProductCardList';
import {
  AppstoreOutlined,
  BarsOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
  MenuOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import {
  getTypeMenu,
  getShowPinnedFilter,
} from 'store/state/offerFilters/offerFiltersSelectors';
import { Empty, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ProductOffer from 'model/ProductOffer';
import { isMobile } from 'react-device-detect';
import Breadcrumb from 'components/UI/Breadcrumb';
import { offerFiltersActions } from 'store/state/slices';
import ProductOfferCard from 'components/BusinessLogic/Product/ProductOfferCard';
import OrderSelectComponent from 'components/UI/OrderSelect';
import {
  getOrderByProductList,
  setModeListMyOffers,
  setOrderByProductList,
  setModeListProductList,
  getPageSizeProductList,
  getLetterSelectedProductList,
} from 'services/config';
import {
  useGetMyOffersQuery,
  useGetMyOffersCountQuery,
  useGetMyOffersByIdQuery,
} from 'services/ecommerceApi';
import { offerFiltersSelectors, sessionSelectors } from 'store/state/selectors';
import ProductCardListSimplified from 'components/BusinessLogic/Product/ProductCardListSimplified';
import ModalCreateComposition from 'components/Modals/CreateComposition';
import useIsElementVisible from 'hooks/useIsElementVisible';
import Packing from 'model/Packing';
import { SelectValue } from 'antd/lib/select';
import Filter from 'components/UI/Filter';
import ProductOfferWithBox from 'model/ProductOfferWithBox';
import { Product } from 'model';
import { verifyIfChanged } from 'screens/Products/index';
import FilterPinned from 'components/UI/FilterPinned';
import { ModeType } from './enums/ModeType.enum';
import * as S from './styles';

export default function MyOffers() {
  const dispatch = useDispatch();
  const lastRef = useRef<HTMLDivElement>(null);
  const [enablePagination, setEnablePagination] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductOffer[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(10);
  const [offerIdInserted, setOfferIdInserted] = useState<number>();
  const [packingSelected, setPackingSelected] = useState<Packing>(
    {} as Packing,
  );
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [numberFilters, setNumberFilter] = useState<number>(0);

  const [orderBy, setOrderBy] = useState<string>(
    getOrderByProductList() !== '' ? getOrderByProductList() : 'AZ',
  );

  const { t } = useTranslation('translation', { keyPrefix: 'productList' });
  const { t: tRoot } = useTranslation();
  const {
    selectedCustomerId: customerId,
    deliveryDate,
    billingDate,
    sitesIds,
  } = useSelector(sessionSelectors.getRoot);

  const typeMenu = useSelector(getTypeMenu);

  const { data: countMyOffers, refetch: refetchMyOffers } =
    useGetMyOffersCountQuery(
      { deliveryDate, customerId },
      { skip: !customerId },
    );

  const showPinnedFilter = useSelector(getShowPinnedFilter);
  const offerFilters = useSelector(offerFiltersSelectors.getOfferFilters);
  const isLastVisible = useIsElementVisible(lastRef.current);

  const changeOrder = useCallback((orderFilter: SelectValue) => {
    setOrderByProductList(orderFilter as string);
    setOrderBy(orderFilter as string);
  }, []);

  useMemo(() => {
    setPage(1);
    setTotalPage(10);
    setEnablePagination(true);
  }, [offerFilters]);

  useMemo(() => {
    if (
      isLastVisible &&
      products !== null &&
      products &&
      products?.length > 0 &&
      enablePagination
    ) {
      const pageValue = page + 1;
      const totalPageValue = 10;
      setPage(pageValue);
      setTotalPage(totalPageValue);
      setEnablePagination(false);
    }
  }, [isLastVisible]);

  const [filterParams, setFilterParams] = useState<any>({
    ...offerFilters,
  });

  const { data } = useGetMyOffersQuery({
    isMyOffers: true,
    businessDate: billingDate,
    deliveryDate,
    page,
    siteId: sitesIds,
    totalPage,
    customerId,
    ...filterParams,
  });

  const { data: offersInserted } = useGetMyOffersByIdQuery(
    {
      isMyOffers: true,
      businessDate: billingDate,
      siteId: sitesIds,
      deliveryDate,
      page,
      totalPage,
      customerId,
      offerId: offerIdInserted,
    },
    {
      skip: !offerIdInserted,
    },
  );

  const resetList = useCallback(() => {
    setProducts([]);
  }, []);

  // Effect to catch store change - Offer filters
  useEffect(() => {
    setFilterParams((oldValue: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { deliveryDate, businessDate, ...rest } = offerFilters;
      if (verifyIfChanged(rest, oldValue)) {
        resetList();
      }
      return {
        ...oldValue,
        page: 1,
        ...rest,
      };
    });
  }, [offerFilters, resetList]);

  useEffect(() => {
    if (data) {
      const offers = data?.offers as ProductOffer[];
      if (offers?.length) {
        const dataKeys = products.map((product) => product.offerId);
        const productKeys = offers.map((item) => item.offerId);
        const isNewList = !dataKeys.some((r) => productKeys.includes(r));

        if (isNewList) {
          setEnablePagination(true);
          setProducts((oldValue) => [...oldValue, ...offers]);
        } else {
          setProducts(offers);
        }
      }
    }
  }, [data, products]);

  const [modeList, setModeList] = useState<ModeType>(2);

  const [productOfferOpened, setProductOfferOpened] = useState<
    ProductOffer | undefined
  >(undefined);

  const [modalIsVisibleComposition, setModalIsVisibleComposition] =
    useState(false);
  const [offerIdModalComposition, setOfferIdModalComposition] = useState<
    number | undefined
  >();

  const onShowModalComposition = (
    productOfferSelected: ProductOffer,
    packingSelected: Packing,
  ) => {
    setModalIsVisibleComposition(true);
    setProductOfferOpened(productOfferSelected);
    setPackingSelected(packingSelected);
  };
  const onCloseModalComposition = () => {
    setModalIsVisibleComposition(false);
  };

  const handleClickCloseFilter = () => {
    setVisibleFilter(false);
  };

  const handleClickClearFilters = useCallback(() => {
    dispatch(offerFiltersActions.setClearFilter(true));
  }, [dispatch]);

  const numberFiltersFC = useMemo(
    () => (
      <>
        <S.SeparateFilters>|</S.SeparateFilters>
        <S.SelectedFilters>
          Filtros Selecionados ({numberFilters})
        </S.SelectedFilters>
        <S.ClearSelectedFilters onClick={handleClickClearFilters}>
          X
        </S.ClearSelectedFilters>
      </>
    ),
    [numberFilters, handleClickClearFilters],
  );

  const handleClickFilterButton = useCallback(() => {
    if (!showPinnedFilter) {
      setVisibleFilter(true);
    } else {
      dispatch(offerFiltersActions.setShowPinnedFilter(false));
    }
  }, [setVisibleFilter, showPinnedFilter, dispatch]);

  const filterButtonFC = useMemo(
    () => (
      <Tooltip title="Filtros avançados">
        <S.FilterButton
          onClick={() => handleClickFilterButton()}
          type="primary"
          icon={<MenuOutlined />}
        >
          {t('filter')}
        </S.FilterButton>
        {showPinnedFilter && (
          <S.FilterButton
            onClick={() => {
              dispatch(offerFiltersActions.setShowPinnedFilter(false));
              setVisibleFilter(true);
            }}
            type="primary"
            icon={<PushpinOutlined />}
          />
        )}
      </Tooltip>
    ),
    [handleClickFilterButton, t, showPinnedFilter, dispatch],
  );

  useEffect(() => {
    const offerInserted = offersInserted ? offersInserted.offers[0] : null;
    if (offerInserted) {
      setProducts((oldValue) =>
        oldValue.map((product) => {
          if (product.offerId === offerInserted.offerId) {
            return {
              ...offerInserted,
            };
          }
          return {
            ...product,
          };
        }),
      );
    }
  }, [offersInserted]);

  useEffect(() => {
    if (products && products?.length) {
      if (countMyOffers && countMyOffers > 0) {
        refetchMyOffers();
      }
    }
  }, [countMyOffers, products, refetchMyOffers]);

  useEffect(() => {
    const mode = parseInt(localStorage.getItem('ModeListStorage') || '2', 10);
    setModeList(mode);
  }, [typeMenu]);

  return (
    <Screen
      content={
        <S.Root>
          <S.Divider />
          <S.Header className="desktop">
            <S.HeaderLine1>
              <S.GroupFilter>
                {filterButtonFC}
                {!!numberFilters && numberFiltersFC}
              </S.GroupFilter>
              <Breadcrumb
                levels={[
                  { description: tRoot('breadcrumb.store') },
                  { description: tRoot('breadcrumb.myOffers') },
                ]}
              />
            </S.HeaderLine1>
            <S.HeaderLine2>
              <S.HeaderLine2LeftGroup>
                <OrderSelectComponent
                  changeOrder={changeOrder}
                  orderBy={orderBy}
                />

                <S.ShowTotalResults>
                  {t('showingResults', { total: products?.length })}
                </S.ShowTotalResults>
              </S.HeaderLine2LeftGroup>
              <S.HeaderLine2RightGroup>
                <S.ICMSNotIncludedInValues>
                  <InfoCircleOutlined />
                  <span>{t('icmsDisclamer')}</span>
                </S.ICMSNotIncludedInValues>
                <Tooltip title="Exibição em grade">
                  <S.ProductViewButton
                    style={
                      modeList === 0
                        ? { backgroundColor: 'white', color: 'black' }
                        : {}
                    }
                    onClick={() => {
                      setModeList(0);
                      setModeListMyOffers(0);
                      setModeListProductList(0);
                      localStorage.setItem('ModeListStorage', '0');
                    }}
                  >
                    <AppstoreOutlined />
                  </S.ProductViewButton>
                </Tooltip>
                <Tooltip title="Exibição em lista">
                  <S.ProductViewButton
                    onClick={() => {
                      setModeList(1);
                      setModeListMyOffers(1);
                      setModeListProductList(1);
                      localStorage.setItem('ModeListStorage', '1');
                    }}
                    style={
                      modeList === 1
                        ? { backgroundColor: 'white', color: 'black' }
                        : {}
                    }
                  >
                    <BarsOutlined />
                  </S.ProductViewButton>
                </Tooltip>
                <Tooltip title="Exibição em lista minificada">
                  <S.ProductViewButton
                    style={
                      modeList === 2
                        ? { backgroundColor: 'white', color: 'black' }
                        : {}
                    }
                    onClick={() => {
                      setModeList(2);
                      setModeListMyOffers(2);
                      setModeListProductList(2);
                      localStorage.setItem('ModeListStorage', '2');
                    }}
                  >
                    <ProfileOutlined />
                  </S.ProductViewButton>
                </Tooltip>
              </S.HeaderLine2RightGroup>
            </S.HeaderLine2>
          </S.Header>

          <S.Header className="mobile">
            <S.HeaderLine1>
              <Breadcrumb
                levels={[
                  { description: tRoot('breadcrumb.store') },
                  { description: tRoot('breadcrumb.myOffers') },
                ]}
              />
            </S.HeaderLine1>
            <S.HeaderLine2>
              <S.HeaderLine2LeftGroup>
                <OrderSelectComponent
                  changeOrder={changeOrder}
                  orderBy={orderBy}
                />
              </S.HeaderLine2LeftGroup>
              <S.HeaderLine2RightGroup>
                <Tooltip title="Exibição em grade">
                  <S.ProductViewButton
                    style={
                      !modeList
                        ? { backgroundColor: 'white', color: 'black' }
                        : {}
                    }
                    onClick={() => {
                      setModeList(0);
                      setModeListMyOffers(0);
                    }}
                  >
                    <AppstoreOutlined />
                  </S.ProductViewButton>
                </Tooltip>

                <Tooltip title="Exibição em lista">
                  <S.ProductViewButton
                    style={
                      modeList
                        ? { backgroundColor: 'white', color: 'black' }
                        : {}
                    }
                    onClick={() => {
                      setModeList(1);
                      setModeListMyOffers(1);
                    }}
                  >
                    <BarsOutlined />
                  </S.ProductViewButton>
                </Tooltip>

                {isMobile && (
                  <Tooltip title="Exibição em lista minificada">
                    <S.ProductViewButton
                      style={
                        modeList === 2
                          ? { backgroundColor: 'white', color: 'black' }
                          : {}
                      }
                      onClick={() => {
                        setModeList(2);
                        setModeListMyOffers(2);
                      }}
                    >
                      <ProfileOutlined />
                    </S.ProductViewButton>
                  </Tooltip>
                )}
              </S.HeaderLine2RightGroup>
            </S.HeaderLine2>
            <S.HeaderLine1>
              <S.ICMSNotIncludedInValues>
                <InfoCircleOutlined />
                <span>{t('icmsDisclamer')}</span>
              </S.ICMSNotIncludedInValues>
            </S.HeaderLine1>
            <S.HeaderLine1>
              <S.ShowTotalResults>
                {t('showingResults', { total: products?.length })}
              </S.ShowTotalResults>
            </S.HeaderLine1>
          </S.Header>
          <S.Content
            PinnedFilter={showPinnedFilter}
            className={products && products?.length ? '' : 'empty-data'}
          >
            {showPinnedFilter && <FilterPinned />}
            {!modeList ? (
              <S.ProductCards>
                {products?.length ? (
                  products.map((productOffer: ProductOffer) => (
                    <ProductOfferCard
                      isNewOffer={productOffer.isNewOffer}
                      key={`ProductOfferCard-${productOffer.id}`}
                      productOffer={productOffer}
                      setOfferIdModalComposition={setOfferIdModalComposition}
                      setModalIsVisibleComposition={onShowModalComposition}
                      setOfferIdInserted={setOfferIdInserted}
                    />
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </S.ProductCards>
            ) : modeList === 1 ? (
              <S.ProductCardsList>
                {products?.length ? (
                  products.map((productOffer: ProductOffer) => (
                    <ProductCardList
                      runInMobile={false}
                      isNewOffer={productOffer.isNewOffer}
                      setOfferIdModalComposition={setOfferIdModalComposition}
                      setModalIsVisibleComposition={onShowModalComposition}
                      key={`ProductCardList-${productOffer.id}`}
                      productOffer={productOffer}
                      setOfferIdInserted={setOfferIdInserted}
                    />
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </S.ProductCardsList>
            ) : (
              <S.ProductCardsList>
                {products?.length ? (
                  products.map((productOffer: ProductOffer) => (
                    <ProductCardListSimplified
                      key={productOffer.id}
                      setOfferIdModalComposition={setOfferIdModalComposition}
                      setModalIsVisibleComposition={onShowModalComposition}
                      productOffer={productOffer}
                      setOfferIdInserted={setOfferIdInserted}
                    />
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </S.ProductCardsList>
            )}
          </S.Content>
          <ModalCreateComposition
            productOfferOpened={productOfferOpened}
            offerId={offerIdModalComposition}
            setModalIsVisible={onCloseModalComposition}
            modalIsVisible={modalIsVisibleComposition}
            packingSelected={packingSelected}
          />
          <div ref={lastRef} />
          <Filter
            eventFilterEmited={() => {
              // do nothing.
            }}
            onClose={handleClickCloseFilter}
            visible={visibleFilter}
          />
        </S.Root>
      }
    />
  );
}
