import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalBody, ModalHeader, ModalDefault } from 'components/UI/Modal';
import { useMyOrderIntentionsContext } from 'contexts/MyOrderIntentionsContext';
import moment from 'moment';
import { OrderIntentionStatusEnum } from 'enums/orderIntentionStatus.enum';
import * as S from './styles';

export default function DetailsModal() {
  const { t } = useTranslation();
  const { selectedOrderIntentions, showDetailsModal, setShowDetailsModal } =
    useMyOrderIntentionsContext();
  return (
    <ModalDefault
      width={700}
      visible={showDetailsModal}
      onCancel={() => setShowDetailsModal(false)}
    >
      <ModalHeader>
        <span>{t('myOrderIntentions.detailsModal.detailsHeader')}</span>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.order')}</b>{' '}
              {selectedOrderIntentions[0]?.orderNumber ?? '-'}
            </p>
          </Col>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.deliveryDate')}</b>{' '}
              {selectedOrderIntentions[0]?.deliveryDate
                ? `${moment(selectedOrderIntentions[0]?.deliveryDate).format(
                    'DD/MM/YYYY - HH:mm',
                  )}`
                : '-'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.accord')}</b> -
            </p>
          </Col>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.commercialDate')}</b>{' '}
              {selectedOrderIntentions[0]?.commercialDate
                ? `${moment(selectedOrderIntentions[0]?.commercialDate).format(
                    'DD/MM/YYYY',
                  )}`
                : '-'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.buyOrder')}</b>{' '}
              {selectedOrderIntentions[0]?.orderNumber ? '-' : selectedOrderIntentions[0]?.id}
            </p>
          </Col>
          <Col lg={12}>
            <S.siteNameContent>
              <b>{t('myOrderIntentions.detailsModal.customer')}</b>{' '}
              {selectedOrderIntentions[0]?.siteName ? (
                <S.siteName>
                  {selectedOrderIntentions[0]?.siteCode} -{' '}
                  {selectedOrderIntentions[0]?.siteName}
                </S.siteName>
              ) : (
                '-'
              )}
            </S.siteNameContent>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.origin')}</b>{' '}
              {selectedOrderIntentions[0]?.origin ?? '-'}
            </p>
          </Col>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.publishDate')}</b>{' '}
              {selectedOrderIntentions[0]?.creationDate
                ? `${moment(selectedOrderIntentions[0]?.creationDate).format(
                    'DD/MM/YYYY - HH:mm',
                  )}`
                : '-'}
            </p>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.publishedBy')}</b>{' '}
              {selectedOrderIntentions[0]?.publishedBy ?? '-'}
            </p>
          </Col>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.answeredBy')}</b>{' '}
              {selectedOrderIntentions[0]?.answeredBy ?? '-'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.canceledDate')}</b>{' '}
              {selectedOrderIntentions[0]?.canceledBy &&
              selectedOrderIntentions[0]?.modificatioDate
                ? `${moment(selectedOrderIntentions[0]?.modificatioDate).format(
                    'DD/MM/YYYY - HH:mm',
                  )}`
                : '-'}
            </p>
          </Col>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.canceledBy')}</b>{' '}
              {selectedOrderIntentions[0]?.canceledBy ?? '-'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p>
              <b>{t('myOrderIntentions.detailsModal.cancelReason')}</b>{' '}
              {selectedOrderIntentions[0]?.cancelationJustification ?? '-'}
            </p>
          </Col>
        </Row>
      </ModalBody>
    </ModalDefault>
  );
}
