import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import { Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';

import {
  AppstoreOutlined,
  BarsOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  ProfileOutlined,
  PushpinOutlined,
} from '@ant-design/icons';

import Breadcrumb from 'components/UI/Breadcrumb';
import OrderSelectComponent from 'components/UI/OrderSelect';
import Filter from 'components/UI/Filter';

import {
  getOrderByProductList,
  setLetterSelectedProductList,
  setModeListProductList,
  setOrderByProductList,
} from 'services/config';

import { offerFiltersActions } from 'store/state/slices';
import { OfferFiltersState } from 'store/state/offerFilters/offerFiltersSlice';

import { ModeType } from 'screens/Products/utils/enums/ModeType.enum';
import { getShowPinnedFilter } from 'store/state/offerFilters/offerFiltersSelectors';

import {
  Container,
  HeaderLine1,
  GroupFilter,
  FilterButton,
  SeparateFilters,
  SelectedFilters,
  ClearSelectedFilters,
  HeaderLine2,
  HeaderLine2LeftGroup,
  HeaderLine2RightGroup,
  ShowTotalResults,
  ICMSNotIncludedInValues,
  ProductViewButton,
  HeaderLine2CenterGroup,
  AlphabetButtonSelect,
} from './styles';

type modeList = 0 | 1 | 2;

interface Props {
  offerFilters: OfferFiltersState;
  totalProducts: number;
  isAuthenticated: boolean;
  modeList: modeList;
  changeModeList(modeListType: 0 | 1 | 2): void;
  selectedLetter: string;
  onSelectLetter(letter: string): void;
  runInMobile: boolean;
}

interface ProductViewProps {
  title: string;
  isActive: boolean;
  onClick: () => void;
  icon: JSX.Element;
}

function ProductView({ title, isActive, onClick, icon }: ProductViewProps) {
  return (
    <Tooltip title={title}>
      <ProductViewButton active={isActive ? 1 : 0} onClick={onClick}>
        {icon}
      </ProductViewButton>
    </Tooltip>
  );
}

function Header({
  offerFilters,
  totalProducts,
  isAuthenticated,
  modeList,
  changeModeList,
  selectedLetter,
  onSelectLetter,
  runInMobile,
}: Props) {

  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'productList' });
  const { t: tRoot } = useTranslation();

  const showPinnedFilter = useSelector(getShowPinnedFilter);

  const [numberFilters, setNumberFilter] = useState<number>(0);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [orderBy, setOrderBy] = useState<string>(
    getOrderByProductList() || 'AZ',
  );

  const handleChangeOrder = useCallback(
    (orderFilter: SelectValue) => {
      const orderBy = orderFilter as string;
      setOrderByProductList(orderBy);
      setOrderBy(orderBy);
      dispatch(
        offerFiltersActions.filter({
          ...offerFilters,
          orderBy,
        }),
      );
    },
    [offerFilters, dispatch],
  );

  const handleClickModeList = useCallback(
    (modeListType: 0 | 1 | 2) => {
      setModeListProductList(modeListType);
      changeModeList(modeListType);
      localStorage.setItem('ModeListStorage', modeListType.toString());
      dispatch(offerFiltersActions.setTypeMenu(modeListType));
    },
    [changeModeList],
  );

  const handleClickCloseFilter = () => {
    setVisibleFilter(false);
  };

  const handleClickFilterButton = useCallback(() => {
    if (!showPinnedFilter) {
      setVisibleFilter(true);
    } else {
      dispatch(offerFiltersActions.setShowPinnedFilter(false));
    }
  }, [setVisibleFilter, showPinnedFilter, dispatch]);

  const handleClickClearFilters = useCallback(() => {
    dispatch(offerFiltersActions.setClearFilter(true));
  }, [dispatch]);

  const handleSelectLetter = (letter: string) => {
    setLetterSelectedProductList(letter);
    onSelectLetter(letter);
  };

  const breadcrumbFC = useMemo(
    () => (
      <Breadcrumb
        levels={[
          { description: tRoot('breadcrumb.store') },
          { description: tRoot('breadcrumb.products') },
        ]}
      />
    ),
    [tRoot],
  );

  const filterButtonFC = useMemo(
    () => (
      <Tooltip title="Filtros avançados">
        <FilterButton
          onClick={() => handleClickFilterButton()}
          type="primary"
          icon={<MenuOutlined />}
        >
          {t('filter')}
        </FilterButton>
        {showPinnedFilter && (
          <FilterButton
            onClick={() => {
              dispatch(offerFiltersActions.setShowPinnedFilter(false));
              setVisibleFilter(true);
            }}
            type="primary"
            icon={<PushpinOutlined />}
          />
        )}
      </Tooltip>
    ),
    [handleClickFilterButton, t, showPinnedFilter, dispatch],
  );

  const orderSelectFC = useMemo(
    () => (
      <OrderSelectComponent changeOrder={handleChangeOrder} orderBy={orderBy} />
    ),
    [orderBy, handleChangeOrder],
  );

  const numberFiltersFC = useMemo(
    () => (
      <>
        <SeparateFilters>|</SeparateFilters>
        <SelectedFilters>
          Filtros Selecionados ({numberFilters})
        </SelectedFilters>
        <ClearSelectedFilters onClick={handleClickClearFilters}>
          X
        </ClearSelectedFilters>
      </>
    ),
    [numberFilters, handleClickClearFilters],
  );

  const productViewsFC = useMemo(
    () => (
      <>
        <ProductView
          title="Exibição em grade"
          isActive={modeList === ModeType.Grid}
          onClick={() => handleClickModeList(ModeType.Grid)}
          icon={<AppstoreOutlined />}
        />

        {isAuthenticated && (
          <>
            <ProductView
              title="Exibição em lista"
              isActive={modeList === ModeType.List}
              onClick={() => handleClickModeList(ModeType.List)}
              icon={<BarsOutlined />}
            />
            {(!isMobile || !runInMobile) && (
              <ProductView
                title="Exibição em lista minificada"
                isActive={modeList === ModeType.MinifyList}
                onClick={() => handleClickModeList(ModeType.MinifyList)}
                icon={<ProfileOutlined />}
              />
            )}
          </>
        )}
      </>
    ),
    [modeList, runInMobile, handleClickModeList, isAuthenticated],
  );

  useEffect(() => {
    const count = [
      offerFilters.siteId?.length,
      offerFilters.commercialGroupIds?.length,
      offerFilters.productsIds?.length,
      offerFilters.categories?.length,
      offerFilters.offerTypes?.length,
      offerFilters.qualities?.length,
      offerFilters.colors?.length,
      offerFilters.dimensions?.length,
    ].filter((value) => !!value).length;
    setNumberFilter(count);
  }, [offerFilters]);

  return (
    <>
      <Container className="desktop">
        <HeaderLine1>
          <GroupFilter>
            {filterButtonFC}
            {!!numberFilters && numberFiltersFC}
          </GroupFilter>
          {breadcrumbFC}
        </HeaderLine1>
        <HeaderLine2>
          <HeaderLine2LeftGroup>
            {orderSelectFC}
            <ShowTotalResults>
              {t('showingResults', { total: totalProducts })}
            </ShowTotalResults>
          </HeaderLine2LeftGroup>
          <HeaderLine2CenterGroup>
            <AlphabetButtonSelect
              selected={selectedLetter === '' ? 1 : 0}
              key="all-letters"
              onClick={() => handleSelectLetter('')}
            >
              Todas
            </AlphabetButtonSelect>
            {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => (
              <AlphabetButtonSelect
                selected={selectedLetter === letter ? 1 : 0}
                key={letter}
                onClick={() => handleSelectLetter(letter)}
              >
                {letter}
              </AlphabetButtonSelect>
            ))}
          </HeaderLine2CenterGroup>
          <HeaderLine2RightGroup>
            <ICMSNotIncludedInValues>
              <InfoCircleOutlined />
              <span>{t('icmsDisclamer')}</span>
            </ICMSNotIncludedInValues>
            {productViewsFC}
          </HeaderLine2RightGroup>
        </HeaderLine2>
      </Container>

      <Container className="mobile">
        <HeaderLine1>{breadcrumbFC}</HeaderLine1>
        <HeaderLine2>
          <HeaderLine2LeftGroup>
            {filterButtonFC}
            {orderSelectFC}
          </HeaderLine2LeftGroup>
          <HeaderLine2RightGroup>{productViewsFC}</HeaderLine2RightGroup>
        </HeaderLine2>
        <HeaderLine1>
          <ICMSNotIncludedInValues>
            <InfoCircleOutlined />
            <span>{t('icmsDisclamer')}</span>
          </ICMSNotIncludedInValues>
        </HeaderLine1>
        <HeaderLine1>
          <ShowTotalResults>
            {t('showingResults', { total: totalProducts })}
          </ShowTotalResults>
        </HeaderLine1>
      </Container>
      {!showPinnedFilter && (
        <Filter
          eventFilterEmited={() => {
            // do nothing.
          }}
          onClose={handleClickCloseFilter}
          visible={visibleFilter}
        />
      )}
    </>
  );
}

export default memo(Header);
