import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import styled, { css } from 'styled-components';

interface CategoryProps {
  active: number;
}
interface SubMenuOfferType {
  active: number;
}

interface OfferTypeMenuProps {
  active: number;
}

export const Root = styled.div`
  display: flex;
  background-color: #f5f5f5;
  border-top: 1px solid #d9d9d9;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .ant-layout-header {
    width: 100%;
    background-color: transparent;
  }

  .ant-menu {
    background-color: transparent;
    justify-content: center;
  }
  .ant-menu-title-content::after {
    content: none !important;
  }
  .ant-menu-title-content {
    :hover {
      color: #ffac47 !important;
    }

    transition: none !important;
  }
  .ant-menu-title-content,
  .ant-menu-submenu-title {
    :hover {
      color: #ffac47 !important;
    }

    .-title-content {
      :hover {
        color: #ffac47 !important;
      }
    }
  }

  .ant-menu-item-selected.active-filter {
    color: #f78e1e !important;
  }

  .ant-menu-item-selected:not(.active-filter) {
    color: #f78e1e !important;
  }

  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    content: none !important;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected.active-filter {
    color: #ffac47;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:not(.active-filter) {
    color: rgba(0,0,0,0.85) !important;
  }

  .ant-menu-submenu-title {
    > span {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
`;

export const Container = styled(Menu)`
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  // > * {
  //   margin: 0px 16px;
  // }
`;

export const Category = styled(Menu.Item) <CategoryProps>`
  cursor: pointer;
  :hover {
    color: #ffac47;
  }

  ${(props) =>
    css`
      color: ${props.active ? '#ffac47' : 'inherit'} !important;
      &.ant-menu-item-selected.active-filter {
        color: ${props.active ? '#ffac47' : 'inherit'} !important;
      }
      &.ant-menu-item-selected:not(.active-filter) {
        color: ${props.active ? 'gba(0,0,0,0.85)' : 'inherit'} !important;
      }
    `}

`;

export const OfferTypeMenu = styled(Menu.Item) <OfferTypeMenuProps>`
  ${(props) =>
    props.active &&
    css`
      color: #ffac47 !important;
    `}
`;

export const SubMenuOfferType = styled(SubMenu) <SubMenuOfferType>`
  ${(props) =>
    props.active &&
    css`
      .ant-menu-submenu-title > span {
        color: #ffac47 !important;
      }
    `}
`;
