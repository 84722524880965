import { createSelector } from '@reduxjs/toolkit';
import { productsState } from './productsSlice';

export const getProducts = (state: { products: productsState }) =>
  state.products as productsState;

export const getOfferId = createSelector(
  [getProducts],
  (state) => state.offerId,
);

export const getInfinity = createSelector(
  [getProducts],
  (state) => state.infinity,
);
