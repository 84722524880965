import React, { CSSProperties, useEffect, useRef } from 'react';
import ReactCurrencyInput from 'react-currency-input';
import { stringToFloat } from 'utils/number';

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  onKeyDown?: (e: any) => void;
  focusCurrent?: boolean;
  style?: CSSProperties;
  disabledOption?: boolean;
}

export default function CurrencyInput({
  focusCurrent,
  onChange,
  ...props
}: Props) {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (focusCurrent && inputRef.current?.theInput) {
      inputRef.current.theInput.focus();
    }
  }, [focusCurrent]);

  return (
    <ReactCurrencyInput
      disabled={props.disabledOption}
      {...props}
      ref={inputRef}
      decimalSeparator=","
      maxLength={7}
      style={{ width: '100%', minWidth: 65, ...props.style }}
      thousandSeparator="."
      onChange={(value: string) => {
        onChange(stringToFloat(value));
      }}
    />
  );
}
