import { useMemo, useCallback } from 'react';

import { DatePicker, Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import {
  useGetProducersQuery,
  useGetProductsQuery,
} from 'services/ecommerceApi';
import { QrCodeHistoryParam } from 'model/types';
import { FilterComponent, LabelFormItem } from './styles';

const { Option } = Select;

type Props = {
  setFilters: (filters: QrCodeHistoryParam) => void;
};

export default function Filters({ setFilters }: Props) {
  const [formFilter] = useForm();

  const { data: products } = useGetProductsQuery({ keyword: '' });
  const { data: producers } = useGetProducersQuery({ keyword: '' });

  const changeFilterValue = useCallback(() => {
    setFilters({
      date: formFilter.getFieldValue('date'),
      productId: formFilter.getFieldValue('productId'),
      siteId: formFilter.getFieldValue('siteId')
  });
  }, [formFilter, setFilters]);

  useMemo(() => {
    formFilter.setFieldsValue({ productId: 0, siteId: 0 });
  }, []);

  return (
    <FilterComponent>
      <Form form={formFilter}>
        <Form.Item
          label={<LabelFormItem>Data:</LabelFormItem>}
          name="date"
          key="date"
        >
          <DatePicker
            onChange={() => changeFilterValue()}
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <Form.Item
          label={<LabelFormItem>Produto:</LabelFormItem>}
          name="productId"
          key="productId"
        >
          <Select
            onChange={() => changeFilterValue()}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option key="Todos os produtos" title="Todos os produtos" value={0}>
              TODOS OS PRODUTOS
            </Option>
            {products?.map((product) => (
              <Option
                key={product.id}
                title={product.longDescription}
                value={product.id}
              >
                {product.longDescription}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={<LabelFormItem>Produtor:</LabelFormItem>}
          name="siteId"
          key="siteId"
        >
          <Select
            onChange={() => changeFilterValue()}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option
              key="Todos os produtores"
              title="Todos os produtores"
              value={0}
            >
              TODOS OS PRODUTORES
            </Option>
            {producers?.map((producer) => (
              <Option
                key={producer.id}
                title={`${producer.account} - ${producer.corporateName}`}
                value={producer.id}
              >
                {`${producer.account} - ${producer.corporateName}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FilterComponent>
  );
}
