import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetProductCategoriesQuery } from 'services/ecommerceApi';

interface Props extends SelectProps<number | ''> {
  allowEmpty?: boolean;
  siteId: number;
}
export default function ProductCategoriesSelect({
  allowEmpty = false,
  siteId,
  ...props
}: Props) {
  const { t } = useTranslation();

  const { data: productCategories, isLoading } = useGetProductCategoriesQuery({
    siteId,
  });

  return (
    <Select
      showSearch
      allowClear
      loading={isLoading}
      placeholder={t('generals.selectPlaceholder')}
      optionFilterProp="children"
      filterOption
      {...props}
    >
      {allowEmpty ? (
        <Select.Option key="all" value="">
          Todas
        </Select.Option>
      ) : null}
      {productCategories?.map((productCategory) => (
        <Select.Option key={productCategory.id} value={productCategory.id}>
          {productCategory.description}
        </Select.Option>
      ))}
    </Select>
  );
}
