import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 1010;
    width: 100%;
    pointer-events: none;
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
    padding: 20px;
`;
