import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ProductCardListComponent = styled(Row)`
  padding: 8px 32px 8px 32px;
  background: #fff;
`;

export const ProductCardComponentImage = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .image-gallery {
      width: 100%;
      
      img {
        padding: 8px;
      }
    }

    .arrow-button {
      width: 24px;
      height: 24px;
      

      svg {
        font-size: 12px;
      }
    }
  }
  .image-info {
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
  }
`;
export const ProductCardCheck = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ProductCardButtonDelete = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ProductCardComponentDetails = styled(Col)`
  .icon-information {
    color: #f78e1e;
  }

  padding: 21px 32px;

  .row-details {
    margin-top: 8px;
    height: 100%;
  }

  .col-details-color {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #353839;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
      }

      .producer-name {
        color: #f78e1e;
      }
    }
  }

  @media (max-width: 992px) {
    margin-bottom: 60px;
  }
`;

export const ProductCardComponentActions = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  .line-actions,
  .line-actions-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .line-actions {
    justify-content: flex-end;
  }

  .line-actions-quantity {
    display: flex;
    justify-content: flex-end;
  }

  .line-actions-input {
    justify-content: space-between;
    .button-cart {
      margin-right: 8px;
    }
  }

  .line-actions {
    .value-product {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        padding: 0;
        margin: 0 0 4px 0;
      }
    }
    justify-content: flex-end !important;
    flex-wrap: wrap;
  }

  @media (max-width: 992px) {
    .line-actions {
      justify-content: center;
    }
  }

  @media (max-width: 992px) {
    .line-actions-quantity {
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    .line-actions-quantity {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      margin-top: 12px;
      margin-bottom: 12px;
      justify-content: center;
    }
  }

  .line-actions-input {
    .button-cart {
      margin-right: 8px;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;

      .input-quantity {
        margin-bottom: 8px;
      }
    }
  }
`;

export const ShippingFeeValue = styled.span`
  b {
    color: #f78e1e;
  }
`

export const ProductInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  .icon {
    color: #f78e1e;
  }

  .group-information {
    display: block;

    .title{
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #353839;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #353839;
    }

    .package-select {
     .ant-select-selector {
    padding: 0 !important;
    z-index: 9999;
  }
    }
    
  }
`
export const ProductNameType = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .remove-display-calendar {
    > span > span {
      display: none;
    }
  }

  .name-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-right: 2rem;

    > span {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #353839;
    }

    .code-product {
      font-weight: 400;
      padding-top: 6px;
      font-size: 12px;
      line-height: 12px;
      color: #8c8c8c;
      display: flex;
      flex-direction: row;

      .number-product {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    .name-product {
      padding: 12px 0;
    }
  }
`;

export const ValueProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;

  .value-per-unity {
    margin-top: 4px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #8c8c8c;
  }

  span.value {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
  }

  span.currency,
  span.value {
    color: #f78e1e;
  }
`;

export const GroupTypeSaleProduct = styled.div`
  display: flex;
  justify-content: space-between;

  .button-type-sale {
    display: flex;
    flex-direction: column;
    height: 32px;
    padding: 0 16px;
    align-items: center;
    justify-content: center;
    
    span.title {
      font-size: 12px;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #353839;
    }

    span.value > span {
      font-size: 14px;
      line-height: 18px;
      color: #f78e1e;
    }

    &.focus {
      background-color: #f78e1e;
      border-color: #f78e1e;

      span > span,
      span {
        color: #fff;
      }
    }
  }

  .no-border-left {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .no-border-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
`;

export const MinQuantityProduct = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #353839;

  > span {
    color: #8c8c8c;
  }
`;

export const AvailableQuantity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #353839;

  .calc-available {
    color: #8c8c8c;
  }
`;
