import { useEffect, useMemo, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import CartItem from 'model/CartItem';
import Countdown from 'antd/lib/statistic/Countdown';
import { Button, Collapse, Tooltip } from 'antd';
import { Tag } from 'screens/ShoppingCart/styles';
import { dateUtils } from 'utils/date';
import ProducerProductCardCartInComposition from '../ProducerProductCardCartInComposition';
import * as S from './styles';

const { Panel } = Collapse;

type Props = {
  onClickRemoveItem: (id: number) => void;
  cartItems: CartItem[];
  onExpireInFront: (id: number) => void;
};

export default function ProducerProductCardCartComposition({
  onClickRemoveItem,
  cartItems,
  onExpireInFront,
}: Props) {
  const expired = useMemo(() => cartItems[0].isExpired, [cartItems]);
  const isExpiredInFront = useMemo(() => cartItems[0].isExpiredInFront, [cartItems]);
  const [error, setError] = useState<{ status: string, message: string }>();

  const handleExpireTime = (id: number) => {
    onExpireInFront(id);
  };

  useEffect(() => {
    if (expired) {
      cartItems.forEach(item => {
        if(item.status === 'E'){
          setError(prevState =>({ status: item.status, message: `${prevState?.message ? `${prevState?.message}<br>${item.message}`: item.message}` }));
        }
      })
    }
  }, [cartItems]);

  return (
    <S.GroupCardComposition
      className={`${expired || isExpiredInFront ? 'expired' : ''}`}
    >
      {(isExpiredInFront || expired) && (
        <Tooltip title={`${error?.status === 'E' ? error.message : ''}`}>
          <Tag status={error?.status ? `${error.status === 'E' ? 'error' : 'success'}` : ''}>
            {(expired) ? `${error?.status === 'E' ? 'Erro' : 'Produto confirmado'}` : 'Confirmando produto'}
          </Tag>
        </Tooltip>
      )}
      <Collapse defaultActiveKey={['1']} ghost>
        <Panel
          header={
            <S.HeaderCollapse>
              {`Composição - ${cartItems[0].description?.substring(
                0,
                cartItems[0].description.indexOf(' '),
              )} ${cartItems[0].dimension} - ${cartItems[0].producerName}`}
              <S.GroupRemoveAndExpireTime>
                <S.ExpireTimeComponent>
                  <S.LabelExpireTime
                    className={`${expired || isExpiredInFront ? 'expired' : ''}`}
                  >
                    Expira
                  </S.LabelExpireTime>
                  <S.LabelExpireTime
                    className={`${expired || isExpiredInFront ? 'expired' : ''}`}
                  >
                    em
                  </S.LabelExpireTime>
                  <S.TimerExpireTime
                    className={`${expired || isExpiredInFront ? 'expired' : ''}`}
                  >
                    {!expired ? (
                      <Countdown
                        format="mm:ss"
                        onFinish={() => handleExpireTime(cartItems[0].id)}
                        value={dateUtils.AddTimeZoneInExpirationDate(cartItems[0].expirationDate).replace('Z', '')}
                      />
                    ) : (
                      '00:00'
                    )}
                  </S.TimerExpireTime>
                </S.ExpireTimeComponent>
                <S.DeleteButtonComponent>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => onClickRemoveItem(cartItems[0].id)}
                  />
                </S.DeleteButtonComponent>
              </S.GroupRemoveAndExpireTime>
            </S.HeaderCollapse>
          }
          key="1"
        >
          <S.GroupComposition>
            <S.GroupCards
              className={`${expired || isExpiredInFront ? 'expired' : ''}`}
            >
              {cartItems &&
                cartItems.map((cartItem: CartItem) => (
                  <ProducerProductCardCartInComposition
                    cartItem={cartItem}
                    expired={expired || isExpiredInFront}
                    key={`${cartItem.id}-product-listedInComposition-${cartItem.compositionKey}`}
                  />
                ))}
            </S.GroupCards>
          </S.GroupComposition>
        </Panel>
      </Collapse>
    </S.GroupCardComposition>
  );
}
