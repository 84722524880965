import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

interface ContextProps {
  loading: boolean;
  setLoading: any;
}
export const myOrdersContext = createContext<ContextProps>({
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
});

interface MyOrdersContextProps {
  children: React.ReactNode;
}
export default function MyOrdersContext(props: MyOrdersContextProps) {
  const { children } = props;
  const [loading, setLoading] = useState(false);
  const value = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);

  return (
    <myOrdersContext.Provider value={value}>
      {children}
    </myOrdersContext.Provider>
  );
}
