import { Table } from 'antd';
import styled from 'styled-components';

export const PrintableMyOrdersTable = styled(Table)`
  .ant-table table {
    font-size: 10px !important;
  }
  .ant-table {
    background-color: red;
  }
  border: 1px solid;
`;
export const PrintableTitlesStyle = styled.span`
  font-size: 10px !important;
`;
