import { CheckCircleOutlined,
  HistoryOutlined,
  CloseCircleOutlined,
  ShrinkOutlined,
  ReloadOutlined,
  UnlockOutlined } from '@ant-design/icons';
import QrCodeHistoryItem from 'model/QrCodeHistoryItem';
import { formatDecimal } from 'utils/number';
import {
  NameProduct,
  ProductHistoryComponent,
  ProductValues,
  InfoText,
  InfoBuyer,
  InformationsGroup,
  InformationProduct,
  InfoStatus,
} from './styles';

type Props = {
  sale: QrCodeHistoryItem
}

export default function ProductHistory({sale}: Props) {
  return (
    <ProductHistoryComponent>
      <NameProduct>{sale.productDescription}</NameProduct>
      <ProductValues>{sale.qe} x {sale.qpE} x R${formatDecimal(sale.unitaryValue)} = R${sale.amount}</ProductValues>
      <InformationsGroup>
        <InfoBuyer>
          <InfoText key="nomedoprodutor">{sale.producer}</InfoText>
          <InfoText key="codigodocliente">{sale.customerCode} - {sale.customerName.substring(0, 25)} {sale.customerName.length > 25 && '...'}</InfoText>
        </InfoBuyer>
        <InfoStatus>
        {(() => {
  let status;
  switch (sale.status) {
    case 'F':
      status = <CheckCircleOutlined style={{ color: '#52c41a' }} />
      break;
    case 'P':
      status = <HistoryOutlined style={{ color: '#faad14' }} />
      break;
    case 'E':
      status = <ShrinkOutlined style={{ color: '#fa541c' }}/>
      break;
    case 'C':
      status = <CloseCircleOutlined style={{ color: '#8c8c8c' }} />
      break;
    case 'R':
      status = <ReloadOutlined style={{ color: '#0f2aa1' }} />
      break;
    case 'A':
      status = <UnlockOutlined style={{ color: '#530346' }} />
      break;
    default: status = '';
    break
  }
  return status;
  })()}
        </InfoStatus>
      </InformationsGroup>
      <InformationProduct>
        <InfoText key="qualidade">Qualidade: {sale.quality}</InfoText>
        <InfoText key="embalagem">Cód. emb.: {sale.packingCode}</InfoText>
      </InformationProduct>
    </ProductHistoryComponent>
  );
}