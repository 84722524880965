import styled from 'styled-components';
import { Modal as ModalAntd } from 'antd';

export const Modal = styled(ModalAntd)`
  .ant-modal-header {
    background-color: #f78e1e;

    .ant-modal-title {
      color: #ffffff;
    }
  }
`;
