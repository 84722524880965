import { Form, Modal, Input, Button } from 'antd';

import { ComponentInputXped, TextExped, Actions } from './styles';

interface Props {
  onCancel: () => void;
  visible: boolean;
  onSubmit: (xped: string) => void;
}

function ModalXped({ onCancel, visible, onSubmit }: Props) {
  const [form] = Form.useForm();
  return (
    <Modal
      closable
      onCancel={onCancel}
      visible={visible}
      width="414px"
      footer={null}
    >
      <Form form={form} onFinish={onSubmit}>
        <ComponentInputXped>
          <TextExped>Por favor, informe o XPED:</TextExped>
          <Form.Item
            name="xped"
            rules={[
              {
                required: true,
                message: 'O campo é obrigatório.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Actions>
            <Button onClick={() => form.submit()} type="primary">
              Confirmar
            </Button>
          </Actions>
        </ComponentInputXped>
      </Form>
    </Modal>
  );
}

export default ModalXped;
