import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import ProductQualitiesSelect from 'components/UI/ProductQualitiesSelect';
import ProductGroupsSelect from 'components/UI/ProductGroupsSelect';
import { useMyOrderIntentionsContext } from 'contexts/MyOrderIntentionsContext';
import ProductCategoriesSelect from 'components/UI/ProductCategoriesSelect';
import { OrderIntentionOriginEnum } from 'enums/orderIntentionOrigin.enum';
import ProductSizesSelect from 'components/UI/ProductRecipientsSelect';
import OrderOpNaturesSelect from 'components/UI/OrderOpNaturesSelect';
import ProductsSelect from 'components/UI/ProductsSelect';
import OrderPublishersSelect from 'components/UI/OrderPublishersSelect';
import SitesSelect from 'components/UI/SitesSelect';
import { useState } from 'react';
import { ProductSelectOption } from 'model/types';

export default function HideFilters() {
  const { formFilter, filters, setFilters } = useMyOrderIntentionsContext();
  const { t } = useTranslation();
  const [filteredProducts, setFilteredProducts] = useState<
    ProductSelectOption[]
  >([]);

  const { dataTable } = useMyOrderIntentionsContext();

  return (
    <Row gutter={16}>
      <Col lg={8}>
        <Form.Item
          label="Chave / Razão social / Nome fantasia:"
          name="Data.SiteId"
          key="Data.SiteId"
        >
          <SitesSelect
            onChange={(value) => {
              setFilters((oldValue) => ({
                ...oldValue,
                'Data.SiteId': Number(value),
                'Data.ProductCategoryId': undefined,
                'Data.ProductGroupId': undefined,
                'Data.ProductId': undefined,
                'Data.Recipient': undefined,
              }));
              formFilter.resetFields([
                'Data.ProductCategoryId',
                'Data.ProductGroupId',
                'Data.ProductId',
                'Data.Recipient',
              ]);
            }}
            onClear={() => {
              setFilters((oldValue) => ({
                ...oldValue,
                'Data.SiteId': undefined,
                'Data.ProductCategoryId': undefined,
                'Data.ProductGroupId': undefined,
                'Data.ProductId': undefined,
                'Data.Recipient': undefined,
              }));
              formFilter.resetFields([
                'Data.ProductCategoryId',
                'Data.ProductGroupId',
                'Data.ProductId',
                'Data.Recipient',
              ]);
            }}
          />
        </Form.Item>
      </Col>
      <Col lg={4}>
        <Form.Item name="Data.ProductCategoryId" label={t('generals.category')}>
          <ProductCategoriesSelect
            siteId={Number(filters['Data.SiteId'])}
            onChange={(value) => {
              setFilters((oldValue) => ({
                ...oldValue,
                'Data.ProductCategoryId': Number(value),
                'Data.ProductGroupId': undefined,
                'Data.ProductId': undefined,
                'Data.Recipient': undefined,
              }));
              formFilter.resetFields([
                'Data.ProductGroupId',
                'Data.ProductId',
                'Data.Recipient',
              ]);
            }}
            onClear={() => {
              setFilters((oldValue) => ({
                ...oldValue,
                'Data.ProductCategoryId': undefined,
                'Data.ProductGroupId': undefined,
                'Data.ProductId': undefined,
                'Data.Recipient': undefined,
              }));
              formFilter.resetFields([
                'Data.ProductGroupId',
                'Data.ProductId',
                'Data.Recipient',
              ]);
            }}
          />
        </Form.Item>
      </Col>
      <Col lg={4}>
        <Form.Item name="Data.ProductGroupId" label={t('generals.group')}>
          <ProductGroupsSelect
            onChange={(value) => {
              setFilters((oldValue) => ({
                ...oldValue,
                'Data.ProductGroupId': Number(value),
                'Data.ProductId': undefined,
                'Data.Recipient': undefined,
              }));
              formFilter.resetFields(['Data.ProductId', 'Data.Recipient']);
            }}
            onClear={() => {
              setFilters((oldValue) => ({
                ...oldValue,
                'Data.ProductGroupId': undefined,
                'Data.ProductId': undefined,
                'Data.Recipient': undefined,
              }));
              formFilter.resetFields(['Data.ProductId', 'Data.Recipient']);
            }}
            siteId={Number(filters['Data.SiteId'])}
            categoryId={Number(filters['Data.ProductCategoryId'])}
          />
        </Form.Item>
      </Col>
      <Col lg={8}>
        <Form.Item name="Data.ProductId" label={t('generals.product')}>
          <ProductsSelect
            categoryId={Number(filters['Data.ProductCategoryId'])}
            groupId={Number(filters['Data.ProductGroupId'])}
            siteId={Number(filters['Data.SiteId'])}
            onChange={(value) => {
              setFilters((oldValue) => ({
                ...oldValue,
                'Data.ProductId': Number(value),
                'Data.Recipient': undefined,
              }));
              formFilter.resetFields(['Data.Recipient']);
            }}
            onClear={() => {
              setFilters((oldValue) => ({
                ...oldValue,
                'Data.ProductId': undefined,
                'Data.Recipient': undefined,
              }));
              formFilter.resetFields(['Data.Recipient']);
            }}
            onFilterProducts={(value) => setFilteredProducts(value)}
          />
        </Form.Item>
      </Col>
      <Col lg={4}>
        <Form.Item name="Data.Recipient" label={t('generals.size')}>
          <ProductSizesSelect
            filteredProducts={filteredProducts}
            productId={Number(filters['Data.ProductId'])}
            siteId={Number(filters['Data.SiteId'])}
          />
        </Form.Item>
      </Col>
      <Col lg={4}>
        <Form.Item name="Data.ProductQualityId" label={t('generals.quality')}>
          <ProductQualitiesSelect />
        </Form.Item>
      </Col>
      <Col lg={4}>
        <Form.Item name="Data.NatureOperationId" label={t('generals.opNature')}>
          <OrderOpNaturesSelect />
        </Form.Item>
      </Col>
      <Col lg={4}>
        <Form.Item name="Data.Origin" label={t('generals.origin')}>
          <Select
            allowClear
            filterOption
            optionFilterProp="children"
            placeholder={t('generals.selectPlaceholder')}
            showSearch
          >
            <Select.Option
              key={OrderIntentionOriginEnum.ORDER_INTENTION}
              value={OrderIntentionOriginEnum.ORDER_INTENTION}
            >
              {t('myOrderIntentions.formFilter.originOptions.orderIntention')}
            </Select.Option>
            <Select.Option
              key={OrderIntentionOriginEnum.DIRECT_SALE}
              value={OrderIntentionOriginEnum.DIRECT_SALE}
            >
              {t('myOrderIntentions.formFilter.originOptions.directSale')}
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col lg={4}>
        <Form.Item name="Data.PublishedBy" label={t(`generals.publishBy`)}>
          <OrderPublishersSelect
            disabled={!dataTable?.length}
            publishersOnFilteredItems={
              dataTable
                ?.map((i) => i.publishedBy)
                .filter((i) => i !== undefined) ?? []
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
