import { AxiosError, AxiosResponse } from "axios";
import { apiInternal } from "../config";


export const checkIndisponibility = () => {
    return new Promise<AxiosResponse<void>>((resolve, reject) => {
        apiInternal
          .get<void, AxiosResponse<void>>('/api/indisponibility/check', {params: {
            key: 'ecommerce'
          }})
          .then((response: AxiosResponse<void>) => {
            resolve(response);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      });
}