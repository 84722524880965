import { Collapse, Divider, Image } from 'antd';
import styled from 'styled-components';
import { GenericModal } from '../GenericModal';

export const ModalUnavailableDates = styled(GenericModal)`
  .anticon-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const ModalEditDelivery = styled(GenericModal)`
  .anticon-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const ModalEditVouchers = styled(GenericModal)`
  .anticon-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const ModalHeader = styled.div`
  background-color: #f78e1e;

  padding: 24px;
  span {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
`;
export const ModalBody = styled.div`
  padding: 24px;
`;
export const Frame = styled(Collapse)`
  font: 16px 'Roboto', sans-serif;
  font-weight: 400;
  .ant-collapse-header {
    display: flex;
  }
`;
export const DividerFrame = styled(Divider)`
  &.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 2% !important;
    top: 0% !important;
  }
  &.ant-divider-horizontal.ant-divider-with-text-left::after {
    top: 0% !important;
  }
  border-top-color: #d9d9d9 !important;
  margin-top: 0 !important;
`;
export const ImageModal = styled(Image)`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;
