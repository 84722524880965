const ErrorMessageMap = {
  E_INSUFFICIENT_CREDIT: 'Desculpe, o produto não possui estoque suficiente.',
};
const formatError = (errorObject: any, errorMessage?: string): string => {
  const defaultErrorMessage = errorMessage || 'Ocorreu um erro inesperado.';
  console.log(`${defaultErrorMessage}:: `, errorObject);

  if (!errorObject) {
    return defaultErrorMessage;
  }

  if (typeof errorObject === 'string') {
    return errorObject;
  }

  if (
    errorObject.response &&
    errorObject.response.data
  ) {
    if(typeof errorObject.response.data === 'string'){
      return errorObject.response.data;
    }
    if(errorObject.response.data.errors){
      const errorMessages = errorObject.response.data.errors;
  
      if (typeof errorMessages === 'string') {
        return errorMessages;
      }
  
      if (Array.isArray(errorMessages)) {
        return (
          errorMessages.join('<br/><br/>')
        );
      }
  
      if (typeof errorMessages === 'object') {
        const errorKeys = Object.keys(errorMessages);
        return (
          errorKeys
            .map(key => `${key}: ${errorMessages[key]}`)
            .join('<br/><br/>') ||
          defaultErrorMessage
        );
      }
    }
  }

  if (errorObject.error && typeof errorObject.error === 'string') {
    return errorObject.error;
  }

  if (errorObject.message && typeof errorObject.message === 'string') {
    if(ErrorMessageMap[errorObject.message as keyof typeof ErrorMessageMap]){
      return ErrorMessageMap[errorObject.message as keyof typeof ErrorMessageMap]
    }
    return errorObject.message;
  }

  return defaultErrorMessage;
};

export const generateErrorMessage = (
  errorObject: any,
  errorMessage?: string
): string => {
  const formatedErrorMessage = formatError(errorObject, errorMessage);
  console.log('formatedErrorMessage:: ', formatedErrorMessage);
  return formatedErrorMessage;
};
