import styled, { css } from 'styled-components';

import { ModeType } from 'screens/DirectedOffers/utils/enums/ModeType.enum';

interface ContentProps {
  listMode: ModeType;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.listMode !== ModeType.Grid ? 'column' : 'row'};
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1;

  padding: 12px 24px;
  background: #f5f5f5;
  position: relative;
  max-width: 100vw;

  @media (max-width: 768px) {
    padding: 16px;
    align-items: center;
  }

  &.empty-data {
    align-items: center !important;
  }

  .ant-empty {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  ${(props) =>
    props.listMode === ModeType.Grid &&
    css`
      gap: 16px;
    `};
`;

export const TextLoadMore = styled.div`
  width: 100%;
  margin: 12px 0;
  text-align: center;
  p {
    color: #f78e1e;
  }
`;

export const LastRef = styled.div`
  width: 100%;
  background-color: transparent;
`;

export const HideContainer = styled.div`
  .image-gallery {
    display: none !important;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;