export const insertInSessionStorageByKey = (key: string, value: string) => {
  localStorage.removeItem(key);

  localStorage.setItem(key, value);
};

export const getSessionStorageValueByKey = (key: string) =>
  localStorage.getItem(key) ?? '';

export const insertInSessionStorageByKeyNotArray = (
  key: string,
  value: string,
) => {
  sessionStorage.removeItem(key);
  sessionStorage.setItem(key, value);
};

export const getSessionStorageValueByKeyNotArray = (key: string) =>
  sessionStorage.getItem(key) ?? '';

export const setLogoutSession = (keys: Array<string>) => {
  keys.forEach((key) => sessionStorage.removeItem(key));
};

export const setLogoutLocal = (keys: Array<string>) => {
  keys.forEach((key) => localStorage.removeItem(key));
};
