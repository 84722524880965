import styled from 'styled-components';

interface Props {
  border?: string;
  size: number;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: ${({border}) => (border)};
  overflow: hidden;
  width: ${({size}) => (`${size}px`)};
  margin: 2px;

  img {  
    height: ${({size}) => (`${size}px`)};
    max-height: ${({size}) => (`${size}px`)};
    width: ${({size}) => (`${size}px`)};
    object-fit: contain;
  }
`;

export const Label = styled.div`
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #8c8c8c;
  margin-top: 12px;
`;