import { useCallback, useEffect, useRef, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';

import _, { forEach } from 'lodash';

import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';

import {
  useGetFilteredOffersQuery,
  useGetFilteredOffersByIdQuery,
} from 'services/ecommerceApi';
import {
  getAuthorizationToken,
  getLetterSelectedProductList,
  getModeListProductList,
  getPageSizeProductList,
  parseJwt,
  getModePagination,
} from 'services/config';
import { showAlertError } from 'services/alertService';

import ProductOffer from 'model/ProductOffer';
import { JwtToken } from 'model/types';

import {
  offerFiltersSelectors,
  sessionSelectors,
  productsSelectors,
} from 'store/state/selectors';
import { OfferFiltersState } from 'store/state/offerFilters/offerFiltersSlice';

import useIsElementVisible from 'hooks/useIsElementVisible';
import useWindowSize from 'hooks/window-size';

import { UserTypeEnum } from 'enums/userType';
import { offerTypeMap } from 'enums/offerTypeMap';
import OffersContext from 'contexts/OffersContext';

import {
  DEFAULT_MODE_TYPE_DESKTOP,
  DEFAULT_MODE_TYPE_MOBILE,
  DEFAULT_MODE_TYPE_NOT_AUTHENTICATED,
} from './utils/consts/ModeTypes.const';
import { ModeType } from './utils/enums/ModeType.enum';
import { TOTAL_PAGE } from './utils/consts/Pagination.const';

import Header from './components/Header';
import ProductList from './components/ProductList';
import { Root, Divider } from './styles';

interface FilterParams extends OfferFiltersState {
  page: number;
  initial: string;
  siteId: number[];
  customerId: number;
  totalPage: number;
}

export function verifyIfChanged(obj: any, objToCompare: any) {
  return Object.keys(obj)
    .map((key) => _.isEqual(obj[key], objToCompare[key]))
    .some((value) => !value);
}

export default function ProductContainer() {
  const tokenObject: JwtToken = parseJwt(getAuthorizationToken() ?? '');
  const modePaginationForDesktop = getModePagination();
  const dispatch = useDispatch();
  const lastRef = useRef<HTMLDivElement>(null);

  const deliveryDates = useSelector(sessionSelectors.getSelectedDeliveryDates);
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const offerFilters = useSelector(offerFiltersSelectors.getOfferFilters);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const siteId = useSelector(sessionSelectors.getSitesIds);
  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );
  const infinityList = useSelector(productsSelectors.getInfinity);

  const [width] = useWindowSize();
  const [runInMobile, setRunInMobile] = useState<boolean>(false);

  const [products, setProducts] = useState<ProductOffer[]>([]);
  const [productsRendered, setProductsRendered] = useState(false);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    initial: getLetterSelectedProductList(),
    siteId,
    customerId,
    totalPage: getPageSizeProductList() ?? TOTAL_PAGE,
    ...offerFilters,
    ...deliveryDates,
  });

  const [showMoreItens, setShowMoreItens] = useState<boolean>(false);
  const [offerInserted, setOfferInserted] =
    useState<{ offerId: number; offerType: offerTypeMap }>();
  const [modeList, setModeList] = useState<ModeType>(() => {
    if (!isAuthenticated) {
      return DEFAULT_MODE_TYPE_NOT_AUTHENTICATED;
    }
    const modeListStorage = getModeListProductList();
    return (
      modeListStorage ??
      (isMobile ? DEFAULT_MODE_TYPE_MOBILE : DEFAULT_MODE_TYPE_DESKTOP)
    );
  });
  const [pagesCount, setPagesCount] = useState(0);

  const isLastVisible = useIsElementVisible(lastRef.current, {
    root: null,
    rootMargin: '0px 0px 800px 0px',
    threshold: 0,
  });

  const { data, isError, refetch } = useGetFilteredOffersQuery(
    {
      ...filterParams,
    },
    {
      skip:
        isAuthenticated &&
        !filterParams.customerId &&
        +tokenObject.veiling_claim_type_role !==
          UserTypeEnum.VEILING_EMPLOYEE &&
        !isProducerAuthenticated,
    },
  );
  const { data: updatedOfferInsertedArray, originalArgs } =
    useGetFilteredOffersByIdQuery(
      {
        ...filterParams,
        offerId: offerInserted?.offerId,
        offerTypes: [offerTypeMap[Number(offerInserted?.offerType)]],
        page: 1,
        totalPage: TOTAL_PAGE,
      },
      {
        skip: !offerInserted,
      },
    );

  const handleSelectLetter = useCallback((initial: string) => {
    setFilterParams((oldValue) => ({
      ...oldValue,
      page: 1,
      initial,
    }));
  }, []);

  const handleOfferInserted = useCallback(
    (offerId: number, offerType: offerTypeMap) => {
      setOfferInserted({ offerId, offerType });
    },
    [],
  );

  const handleChangePagination = useCallback(
    (page: number, totalPage: number) => {
      setFilterParams((oldValue) => ({
        ...oldValue,
        page,
        totalPage,
      }));
    },
    [],
  );

  const handleChangeModeList = useCallback(
    (value: number) => setModeList(value),
    [],
  );

  // Effect for prepare list
  useEffect(() => {
    const offers = data?.offers;
    if (offers?.length) {
      setProducts((oldValue) => [...oldValue, ...offers]);
    }
    setProductsRendered(true);
    setShowMoreItens(offers?.length === TOTAL_PAGE);
    setPagesCount(offers?.length ? offers[0].pagesCount : 0);
  }, [data]);

  // Effect for update quantity after add in cart
  useEffect(() => {
    if (updatedOfferInsertedArray?.offers.length) {
      // refetch();
      const updatedOfferInserted = updatedOfferInsertedArray.offers[0];
      setProducts((oldValue) =>
        oldValue.map((product) => {
          if (
            product.offerId === updatedOfferInserted.offerId &&
            product.offerType === updatedOfferInserted.offerType
          ) {
            return {
              ...updatedOfferInserted,
            };
          }
          return {
            ...product,
          };
        }),
      );
    } else {
      const offertypeValidation = originalArgs?.offerTypes
        ? originalArgs.offerTypes.includes('inTransit')
        : false;
      if (offertypeValidation) {
        setProducts((oldValue) => oldValue.filter((offer) => offer.offerId));
      } else {
        setProducts((oldValue) =>
          // oldValue.filter((offer) => offer.offerId !== originalArgs?.offerId),
          oldValue.filter((offer) => offer.offerId),
        );
      }
    }
  }, [updatedOfferInsertedArray, originalArgs?.offerId]);

  // Effect for show infinite scroll
  useEffect(() => {
    if (isLastVisible) {
      if (isError) {
        refetch();
      } else {
        setFilterParams((oldValue) => ({
          ...oldValue,
          page: oldValue.page + 1,
        }));
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        setProductsRendered(false);
      }
    }
  }, [isLastVisible, refetch, isError]);

  // Effect for show error
  useEffect(() => {
    if (isError) {
      showAlertError(
        dispatch,
        'Não foi possível carregar a lista de oferta no momento, por favor tente novamente mais tarde.',
      );
    }
  }, [isError, dispatch]);

  // Effect to catch store change - Offer filters
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { deliveryDate, businessDate, ...rest } = offerFilters;
    if (verifyIfChanged(rest, filterParams)) {
      setFilterParams((oldValue) => ({
        ...oldValue,
        page: 1,
        ...rest,
      }));
    }
  }, [offerFilters]);

  // Effect to catch store change - Delivery dateS
  useEffect(() => {
    if (verifyIfChanged(deliveryDates, filterParams)) {
      setFilterParams((oldValue) => ({
        ...oldValue,
        page: 1,
        ...deliveryDates,
      }));
    }
  }, [deliveryDates]);

  // Effect to catch store change - Site Id
  useEffect(() => {
    if (siteId.length) {
      setFilterParams((oldValue) => ({
        ...oldValue,
        page: 1,
        siteId,
      }));
    }
  }, [siteId]);

  // Effect to catch store change - Customer Id
  useEffect(() => {
    setFilterParams((oldValue) => ({
      ...oldValue,
      page: 1,
      customerId,
    }));
  }, [customerId]);

  useEffect(() => {
    console.log(
      `filterParams:: `,
      filterParams,
      'ok',
      modePaginationForDesktop,
    );
    if (!infinityList && !isMobile && modePaginationForDesktop !== 'false') {
      setProducts([]);
    }
    if (!isLastVisible) {
      setProducts([]);
    }
    setShowMoreItens(false);
    setProductsRendered(false);
    refetch();
  }, [filterParams]);

  // Effect to control resize
  useEffect(() => {
    if (width && width <= 1200) {
      setRunInMobile(true);
      setModeList(1);
    } else {
      setRunInMobile(false);
    }
  }, [width]);
  const [showShipping, setShowSipping] = useState(false);
  useEffect(() => {
    setShowSipping(data?.hasBox ?? false);
  }, [data]);
  return (
    <Screen
      content={
        <Root>
          <Divider />
          <Header
            isAuthenticated={isAuthenticated}
            offerFilters={offerFilters}
            totalProducts={products?.length}
            changeModeList={handleChangeModeList}
            modeList={modeList}
            selectedLetter={filterParams.initial}
            onSelectLetter={handleSelectLetter}
            runInMobile={runInMobile}
          />

          <OffersContext hasBox={showShipping}>
            <ProductList
              products={products}
              mode={modeList}
              productsRendered={productsRendered}
              showMoreItens={showMoreItens}
              lastRef={lastRef}
              offerInserted={handleOfferInserted}
              runInMobile={runInMobile}
              page={filterParams.page}
              pageSize={filterParams.totalPage}
              pagesCount={pagesCount}
              onChangePagination={handleChangePagination}
            />
          </OffersContext>
        </Root>
      }
    />
  );
}
