import { PackingTypeSale } from "enums/packingTypeSale";

export const validatorUtils = {
  validMultiple(
    value: number,
    multiple: number,
    selectedTypeSale: string,
    maxAvailable: string,
    valueTypeSale: number,
    label?: string,
  ) {
    if (
      (value * parseInt(maxAvailable, 10)) % multiple > 0 &&
      selectedTypeSale === PackingTypeSale.Packing
    ) {
      return Promise.reject(
        new Error(
          `A quantidade não é múltipla de ${valueTypeSale ?? multiple} ${
            label ?? 'unidades'
          }`,
        ),
      );
    }

    const values = maxAvailable.split('x');
    const totalUnits =
      parseInt(values[0], 10) * parseInt(values[1], 10) * value;
    if (totalUnits % multiple > 0) {
      return Promise.reject(
        new Error(
          `Quantidade não e múltipla de ${valueTypeSale ?? multiple} ${
            label ?? 'unidades'
          }`,
        ),
      );
    }
    return Promise.resolve();
  },
};
