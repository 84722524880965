export const BreakpointSizes = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1600,
  };

export const breakAt = (size: number) => `@media (min-width: ${size}px)`;

export interface MaxWidthProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}
export const getMaxWidth = ({ size = 'xl' }: MaxWidthProps): number => {
    switch(size) {
        case 'xs':
            return BreakpointSizes.xs
        case 'sm':
            return BreakpointSizes.sm
        case 'md':
            return BreakpointSizes.md
        case 'lg':
            return BreakpointSizes.lg
        case 'xl':
            return BreakpointSizes.xl
        default:
            return 1200;
    }
};

