import { memo } from 'react';

import { Col, Row } from 'antd';
import {
  BgColorsOutlined,
  ExpandOutlined,
  GiftOutlined,
  GroupOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

import Badge from 'components/UI/Badge';
import Quality from 'components/UI/Quality';
import ShowDetails from 'components/UI/ShowDetails';

import ProductActions from 'screens/DirectedOffers/components/ProductActions';
import SelectPacking from 'components/BusinessLogic/Product/SelectPacking';
import { offerTypeColor } from 'screens/DirectedOffers/utils/consts/OfferType.const';
import { getShowPinnedFilter } from 'store/state/offerFilters/offerFiltersSelectors';

import InfoDirected from 'screens/Products/components/ProductItem/components/InfoDirected';
import InfoBarCode from '../../../components/InfoBarCode';
import InfoDelivery from '../../../components/InfoDelivery';
import InfoNew from '../../../components/InfoNew';
import InfoObservation from '../../../components/InfoObservation';
import InfoProductName from '../../../components/InfoProductName';
import InfoDirectedTag from '../../../components/InfoDirectedTag';
import ProductCardImage from '../../../components/ProductCardImage';

import { ModesProps } from '../../props';

import * as S from './styles';

function ListDesktop({
  product,
  packingSelected,
  isProducerAuthenticated,
  handleOnSelectPacking,
  openImage,
  addInCart,
  buy,
  showModalComposition,
  producerUrl,
  selectedTypeSale,
  onChangePackingTypeSale
}: ModesProps) {

  const showPinnedFilter = useSelector(getShowPinnedFilter);

  return (
    <S.ProductCardListComponent>
      {product.isNewOffer && (
        <S.ComponentInfoNew>
          <InfoNew />
        </S.ComponentInfoNew>
      )}
      {product.offerType === 3 && (
        <S.ComponentInfoDirected>
          <InfoDirectedTag specialPrice={product.specialPrice} />
        </S.ComponentInfoDirected>
      )}
      <S.ProductCardImageContainer>
        <ProductCardImage
          images={product.imagesFormatted}
          imageByProducer={product.images.length > 0 && !product.isLkpOffer}
          maxHeight="150px"
          open={openImage}
        />
      </S.ProductCardImageContainer>

      <S.MainContent>
        <S.ProductCardComponentDetails lg={14} xl={14}>
          <Row className="row-details-name">
            <Col className="col-details" xs={24}>
              <S.ProductNameType>
                <InfoDelivery
                  isLkpOffer={product.isLkpOffer}
                  endDateFormatted={product.endDateFormatted}
                  endDate={product.endDate}
                  startDate={product.startDate}
                  siteDeliveryPatterns={product.siteDeliveryPatterns}
                />
                <S.InformationGroup>
                  <InfoProductName size="large" longName={product.longName} />
                  <InfoBarCode
                    size="large"
                    barcode={product.barcode}
                    gtincode={product.gtincode}
                  />
                </S.InformationGroup>
                <S.BadgeContainer>
                  <Badge type="medium" {...offerTypeColor[product.offerType]} />
                  <ShowDetails
                    producerUrl={producerUrl}
                    offerId={product.offerId}
                    offerType={product.offerType}
                    packingSelectedId={packingSelected.id}
                    selectedTypeSale={selectedTypeSale}
                  />
                </S.BadgeContainer>
              </S.ProductNameType>
            </Col>
          </Row>
          <Row className="row-details">
            <Col className="col-details" md={24} lg={14} xl={14}>
              <Row>
                <S.ContentColDetails md={24} lg={12} xl={7}>
                  <S.InformationProduct>
                    <TrophyOutlined className="icon-information" />
                    <div className="group-information no-value">
                      <p className="title-information">Qualidade:</p>
                      <span>
                        <Quality quality={product.quality} />
                      </span>
                    </div>
                  </S.InformationProduct>

                  <S.InformationProduct>
                    <GroupOutlined className="icon-information" />
                    <div className="group-information">
                      <p className="title-information">Múltiplo:</p>
                      <span>{packingSelected?.multiple}</span>
                    </div>
                  </S.InformationProduct>
                </S.ContentColDetails>
                <S.ContentColDetails md={24} lg={12} xl={8}>
                  <S.InformationProduct>
                    <BgColorsOutlined className="icon-information" />
                    <div className="group-information">
                      <p className="title-information">Cores:</p>
                      <span>{product.colors}</span>
                    </div>
                  </S.InformationProduct>

                  <S.InformationProduct>
                    <GiftOutlined className="icon-information" />
                    <div className="group-information">
                      <p className="title-information">Embalagem:</p>
                      <SelectPacking
                        offerId={product.offerId}
                        onSelect={handleOnSelectPacking}
                        packings={product.packings}
                        value={packingSelected.id}
                        type="small"
                      />
                    </div>
                  </S.InformationProduct>
                </S.ContentColDetails>
                <Col md={24} lg={12} xl={9}>
                  <S.InformationProduct>
                    <ExpandOutlined className="icon-information" />
                    <div className="group-information">
                      <p className="title-information">Dimensão/Altura:</p>
                      <span>{product.dimension}</span>
                    </div>
                  </S.InformationProduct>
                </Col>
              </Row>
            </Col>
            <S.ColDetails className="col-details-color" md={24} lg={10} xl={10}>
              <p>
                <span className="producer-name"> {product.siteName}</span>
              </p>
              {!!product.specialPrice && !!product.minimumQuantity && (
                <InfoDirected
                  directedMessage={
                    product.minimumQuantity === 1
                      ? `*Para aplicação do valor promocional, a compra mínima deverá ser de 1 unidade`
                      : `*Para aplicação do valor promocional, a compra mínima deverá ser de ${product.minimumQuantity} unidades`
                  }
                />
              )}

              {!!product.observation && (
                <div className="break-word">
                  <InfoObservation observation={product.observation} />
                </div>
              )}
            </S.ColDetails>
          </Row>
        </S.ProductCardComponentDetails>
       
        <S.Actions pinned={showPinnedFilter}>
          <ProductActions
            product={product}
            packingSelected={packingSelected}
            isProducerAuthenticated={isProducerAuthenticated}
            openComposition={showModalComposition}
            onClickBuy={addInCart}
            onClickFastBuy={buy}
            actionsButtonType="medium"
            theme="light"
            selectedTypeSale={selectedTypeSale}
            onChangePackingTypeSale={onChangePackingTypeSale}
          />
        </S.Actions>
      </S.MainContent>
    </S.ProductCardListComponent>
  );
}

export default memo(ListDesktop);
