import { Button, DatePicker } from 'antd';
import UnavailableDate from 'model/UnavailableDate';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUnavailableDatesQuery } from 'services/ecommerceApi';
import { Container, Inputs, ContainerDatePicker, Actions } from './styles';

interface DatePickerProps {
  label?: string;
  disabled: boolean;
  disabledDate: (date: Moment) => boolean;
  inputReadOnly?: boolean;
  value?: Moment | null;
  open: boolean;
  onOpenChange: (event: boolean) => void;
  onChange: (value: Moment | null) => void;
  placeholder?: string;
  defaultValue?: moment.Moment;
}

interface Props {
  initialDatePicker: DatePickerProps;
  endDatePicker: DatePickerProps;
  format?: string;
  onConfirmation: () => void;
  producerUnavailableDates: UnavailableDate[];
}

function DeliveryDate({
  initialDatePicker,
  endDatePicker,
  format = 'DD/MM/YYYY',
  onConfirmation,
  producerUnavailableDates
}: Props) {
  const { t: translate } = useTranslation();

  const endDatePickerRef = useRef<any>(null);

  const [initialOpen, setInitialOpen] = useState<boolean>();
  const [endOpen, setEndOpen] = useState<boolean>();

  const { data: unavailableDates } = useGetUnavailableDatesQuery();

  useEffect(() => {
    setInitialOpen(initialDatePicker.open);
  }, [initialDatePicker.open]);

  useEffect(() => {
    setEndOpen(endDatePicker.open);
  }, [endDatePicker.open]);

  useEffect(() => {
    if (!initialOpen && initialDatePicker.value) {
      if (endDatePickerRef) {
        endDatePickerRef.current.focus();
      }
    }
  }, [initialOpen]);

  const disableBillingDates = useCallback(
    (value: Moment) => {
      const deliveryDateValue = initialDatePicker.value;
      if (deliveryDateValue) {
        if (value.isBefore(deliveryDateValue, 'day')) {
          return true;
        }
        if(producerUnavailableDates?.some(item => moment(item.unavailableDate).isSame(value, 'day'))){
          return true;
        }
        if (
          unavailableDates?.some((element) => moment(element).isSame(value, 'day'))
        ) {
          return true;
        }
        const saturday = Boolean(deliveryDateValue?.weekday() === 6);
        if (saturday) {
          const lastDay = moment(deliveryDateValue).add(2, 'days');
          return value.isAfter(lastDay, 'day');
        }
        const lastDay = moment(deliveryDateValue).add(1, 'days');
        return value.isAfter(lastDay, 'day');
      }
      return false;
    },
    [initialDatePicker.value, producerUnavailableDates, unavailableDates],
  );

  return (
    <Container>
      <Inputs>
        <ContainerDatePicker>
          {initialDatePicker.label || translate('header.deliveryDate')}

          <DatePicker
            disabled={initialDatePicker.disabled}
            disabledDate={(value: Moment) => {
              if(initialDatePicker.disabledDate(value)){
                return true
              }
              if (
                unavailableDates?.some((element) => {
                  if (moment(element).isSame(value, 'day')) {
                    return true;
                  }
                  return false;
                })
              ) {
                return true;
              }
              return false;
            }}
            format={format}
            inputReadOnly={initialDatePicker.inputReadOnly ?? true}
            value={initialDatePicker.value}
            open={initialOpen}
            onOpenChange={(ev) => {
              setInitialOpen(ev);
              initialDatePicker.onOpenChange(ev);
            }}
            autoFocus
            onChange={initialDatePicker.onChange}
            placeholder={
              initialDatePicker.placeholder ||
              translate('generic.inputDatePlaceholder')
            }
          />
        </ContainerDatePicker>
        <ContainerDatePicker>
          {endDatePicker.label || translate('header.billingDate')}
          <DatePicker
            ref={endDatePickerRef}
            disabled={endDatePicker.disabled}
            disabledDate={disableBillingDates}
            format={format}
            inputReadOnly={endDatePicker.inputReadOnly ?? true}
            value={endDatePicker.value ?? endDatePicker?.defaultValue}
            open={endOpen}
            onOpenChange={(ev) => {
              setEndOpen(ev);
              endDatePicker.onOpenChange(ev);
            }}
            onChange={endDatePicker.onChange}
            placeholder={
              endDatePicker.placeholder ||
              translate('generic.inputDatePlaceholder')
            }
          />
        </ContainerDatePicker>
      </Inputs>
      <Actions>
        <Button onClick={onConfirmation} type="primary">
          Confirmar
        </Button>
      </Actions>
    </Container>
  );
}

export default DeliveryDate;
