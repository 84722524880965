import { useMemo } from 'react';
import Monetary from 'components/UI/Data/Monetary';
import Packing from 'model/Packing';
import { ButtonProps } from 'antd';
import { PackingTypeSale, PackingTypeSaleMap } from 'enums/packingTypeSale';
import * as S from './styles';

interface Props extends ButtonProps {
  packingType: PackingTypeSale;
  selectedTypeSale: PackingTypeSale;
  packingSelected: Packing | any;
  price: number;
  packingCost: number;
  quantityPacking: number;
  minimumQuantity: number;
  specialPrice: number;
  handleTypeSale(packingType: PackingTypeSale): void;
}

export function ButtonPacking({
  packingType,
  packingSelected,
  selectedTypeSale,
  price,
  packingCost,
  quantityPacking,
  minimumQuantity,
  specialPrice,
  handleTypeSale,
  ...props
}: Props) {
  const calculatedQuantity = useMemo(() => {
    if (quantityPacking) {
      if (packingType === PackingTypeSale.Packing) {
        const calculated = quantityPacking * packingSelected?.unitsPerPackage;
        return calculated;
      }
      if (packingType === PackingTypeSale.Layer) {
        const calculated =
          quantityPacking *
          (packingSelected?.packagingByLayer *
            packingSelected?.unitsPerPackage);
        return calculated;
      }
      if (packingType === PackingTypeSale.Trolley) {
        const calculated =
          quantityPacking *
          (packingSelected?.packagingByLayer *
            packingSelected?.layersByTrolley *
            packingSelected?.unitsPerPackage);
        return calculated;
      }
    }
    return 1;
  }, [packingSelected, packingType, quantityPacking]);

  const currentPrice = useMemo(() => {
    if (!quantityPacking || !specialPrice) {
      return price;
    }
    return calculatedQuantity >= minimumQuantity ? specialPrice : price;
  }, [
    calculatedQuantity,
    minimumQuantity,
    specialPrice,
    price,
    quantityPacking,
  ]);

  const disabled = useMemo(
    () => !price || !packingSelected[`maxAvailable${packingType}sQuantity`],
    [packingSelected, packingType, price],
  );

  const className = useMemo(() => {
    const classes = ['button-type-sale'];
    switch (packingType) {
      case PackingTypeSale.Packing:
        classes.push('no-border-right');
        break;
      case PackingTypeSale.Layer:
        classes.push('no-border-right');
        classes.push('no-border-left');
        break;
      case PackingTypeSale.Trolley:
        classes.push('no-border-left');
        break;

      default:
        break;
    }
    if (selectedTypeSale === packingType) {
      classes.push('focus');
    }
    if (!!minimumQuantity && calculatedQuantity >= minimumQuantity) {
      classes.push('minimumQuantity');
    }
    return classes.join(' ');
  }, [minimumQuantity, packingType, calculatedQuantity, selectedTypeSale]);

  const value = useMemo(() => {
    let newValue = 0;
    if (currentPrice > 0 && packingSelected?.additionalCost) {
      newValue = currentPrice + packingCost;
    } else {
      newValue = currentPrice;
    }
    return newValue;
  }, [currentPrice, packingSelected?.additionalCost, packingCost]);

  return (
    <S.Button
      disabled={disabled}
      onClick={() => handleTypeSale(packingType)}
      className={className}
      {...props}
    >
      {PackingTypeSaleMap[packingType]}
      <span>
        <Monetary symbol value={value} />
      </span>
    </S.Button>
  );
}
