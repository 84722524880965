import styled from "styled-components";

export const LegendComponent = styled.div`
  border-radius: 8px;
  background-color: #f5f5f5;
  width: 100%;
  min-height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleCard = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-top: 16px;
`;

export const GroupInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 330px;
`;

export const LegendListHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemList = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const TextList = styled.span`
  color: #595959;
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
`;

export const TextInfo = styled.span`
  color: #8c8c8c;
  font-size: 10px;
  font-weight: 400;
  margin-left: 4px;
`;
