import { FormInstance } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { OrderIntention } from 'model/OrderIntention';
import {
  OrderIntentionsFiltersParam,
  OrderIntentionsFormFiltersInstance,
} from 'model/types';
import { createContext, useContext, useMemo, useState } from 'react';

interface ContextProps {
  dataTable: OrderIntention[] | undefined;
  setDataTable: React.Dispatch<
    React.SetStateAction<OrderIntention[] | undefined>
  >;
  filters: OrderIntentionsFiltersParam;
  setFilters: React.Dispatch<React.SetStateAction<OrderIntentionsFiltersParam>>;
  activeFilters: OrderIntentionsFormFiltersInstance;
  setActiveFilters: React.Dispatch<
    React.SetStateAction<OrderIntentionsFormFiltersInstance>
  >;
  formFilter: FormInstance<OrderIntentionsFormFiltersInstance>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  selectedOrderIntentions: OrderIntention[];
  setSelectedOrderIntentions: React.Dispatch<
    React.SetStateAction<OrderIntention[]>
  >;
  selectedOrderIntentionsKeys: React.Key[];
  setSelectedOrderIntentionsKeys: React.Dispatch<
    React.SetStateAction<React.Key[]>
  >;
  showDetailsModal: boolean;
  setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  showJustificationModal: boolean;
  setShowJustificationModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const MyOrderIntentionsContext = createContext<ContextProps>(
  {} as ContextProps,
);

interface ProviderProps {
  children: React.ReactNode;
}

const initialFiltersState = {
  'Data.FilterBy': undefined,
  'Data.StartDate': undefined,
  'Data.EndDate': undefined,
  'Data.Status': undefined,
  'Data.Origin': undefined,
  'Data.ProductCategoryId': undefined,
  'Data.ProductGroupId': undefined,
  'Data.ProductId': undefined,
  'Data.ProductQualityId': undefined,
  'Data.SiteId': undefined,
  'Data.NatureOperationId': undefined,
  'Data.Recipient': undefined,
  'Data.PublishedBy': undefined,
  'Data.CustomerId': undefined,
  'Data.Sort': undefined,
  'Data.OrderBy': undefined,
  PageNumber: undefined,
  PageSize: undefined,
};

export function MyOrderIntentionsContextProvider(props: ProviderProps) {
  const { children } = props;
  const [filters, setFilters] =
    useState<OrderIntentionsFiltersParam>(initialFiltersState);
  const [activeFilters, setActiveFilters] =
    useState<OrderIntentionsFormFiltersInstance>({
      'Data.CustomerId': 0,
      'Data.FilterBy': null,
      'Data.Status': null,
      date: null,
      OnlyPending: null,
    });
  const [formFilter] = useForm();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedOrderIntentions, setSelectedOrderIntentions] = useState<
    OrderIntention[]
  >([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showJustificationModal, setShowJustificationModal] = useState(false);
  const [selectedOrderIntentionsKeys, setSelectedOrderIntentionsKeys] =
    useState<React.Key[]>([]);
  const [dataTable, setDataTable] = useState<OrderIntention[] | undefined>([]);
  const value = useMemo(
    () => ({
      dataTable,
      setDataTable,
      filters,
      setFilters,
      activeFilters,
      setActiveFilters,
      formFilter,
      loading,
      setLoading,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      selectedOrderIntentions,
      setSelectedOrderIntentions,
      selectedOrderIntentionsKeys,
      setSelectedOrderIntentionsKeys,
      showDetailsModal,
      setShowDetailsModal,
      showJustificationModal,
      setShowJustificationModal,
    }),
    [
      dataTable,
      setDataTable,
      filters,
      setFilters,
      formFilter,
      loading,
      setLoading,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      selectedOrderIntentions,
      setSelectedOrderIntentions,
      selectedOrderIntentionsKeys,
      setSelectedOrderIntentionsKeys,
      showDetailsModal,
      setShowDetailsModal,
      showJustificationModal,
      setShowJustificationModal,
      activeFilters,
      setActiveFilters,
    ],
  );

  return (
    <MyOrderIntentionsContext.Provider value={value}>
      {children}
    </MyOrderIntentionsContext.Provider>
  );
}

export const useMyOrderIntentionsContext = () =>
  useContext(MyOrderIntentionsContext);
