import React, { memo } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import * as S from './styles';

export type ShowOrHideValuesProps = {
  showValue: boolean;
  setShowValue: () => void;
};

function ShowOrHideValues({ showValue, setShowValue }: ShowOrHideValuesProps) {
  return (
    <S.DisplayIconShowOrHideValue>
      {showValue ? (
        <EyeOutlined onClick={() => setShowValue()} />
      ) : (
        <EyeInvisibleOutlined onClick={() => setShowValue()} />
      )}
    </S.DisplayIconShowOrHideValue>
  );
}

export default memo(ShowOrHideValues);
