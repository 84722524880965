import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgb(247, 142, 30);
  color: #fff;
  border-radius: 2px;
  font-size: 12px;

  padding: 2px 10px;
  margin-bottom: 8px;
`;
