import { Modal, Input, Form, Spin } from 'antd';
import styled from 'styled-components';

export const ModalOrderSummary = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
`;

export const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
`;

export const ModalHeaderOrderSummary = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #f78e1e;

  span {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 22px;
    line-height: 30px;
  }
`;

export const ModalBodyOrderSummary = styled.div`
  .ant-divider.ant-divider-horizontal {
    margin-top: 0;
    margin-bottom: 24px;
  }
`;

export const FormXped = styled(Form)``;

export const ComponentInformationvalue = styled.div`
  p {
    margin-bottom: 0;
  }
`;

export const ComponentInputXped = styled.div`
  display: flex;
  align-items: center;
  .ant-form-item-explain {
    min-height: 0;
    height: 0;
  }

  p {
    margin-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const TextValue = styled.p`
  color: #8c8c8c;
`;
export const InputXped = styled(Input)`
  max-width: 180px;
`;
export const TextExped = styled.p`
  color: #8c8c8c;
  margin-right: 8px;
`;
export const Value = styled.span`
  margin-left: 8px;
  font-weight: 700;
  font-size: 24px;
  color: #353839;
`;

export const LabelDate = styled.label`
  margin-bottom: 0px;
`;

export const ModalSubheaderOrderSumary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 24px;
  flex-wrap: wrap;
  gap: 16px;
`;
export const GroupDateInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;
export const GroupInformationDate = styled.div`
  display: flex;
  align-items: center;
`;
export const LabelInformationDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #8c8c8c;
  font-size: 14px;
`;
export const ValueInformationDate = styled.div`
  margin-left: 4px;
  color: #353839;
  font-weight: 700;
  font-size: 14px;
`;
export const GroupAction = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  .margin-right {
    margin-right: 8px;
  }
`;

export const ModalFooterOrderSummary = styled.div`
  flex-wrap: wrap;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
