import { memo } from 'react';

import VoucherTag from 'screens/Products/components/VoucherTag';

import { Container } from './styles';

interface Props {
  reajustmentAmount: number;
  voucherReajustmentType: string;
}

function InfoVoucherTag({ reajustmentAmount, voucherReajustmentType }: Props) {
  return (
    <Container>
      <VoucherTag
        reajustmentAmount={reajustmentAmount}
        voucherReajustmentType={voucherReajustmentType}
      />
    </Container>
  );
}

export default memo(InfoVoucherTag);
