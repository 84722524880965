import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useCallback, useState } from 'react';
import ImageGallery from '../ImageGallery';
import {
  IllustrativeImage,
  ImageComponentsRow,
  ProductCardImage,
} from './styles';

type ProductCardComponentImageProps = {
  arrayImage: string[];
  images: string[];
  isLkpOffer: boolean;
  maxHeight: string;
  md?: number;
  lg?: number;
  xl?: number;
};
function ProductCardComponentImage(props: ProductCardComponentImageProps) {
  const { arrayImage, images, isLkpOffer, maxHeight, md, lg, xl } = props;
  const [imageDisplay, setImageDisplay] = useState<number>(0);

  const handleImageDisplay = useCallback(
    (value: number) => {
      if (value < 0) {
        setImageDisplay(arrayImage.length - 1);
      } else if (value >= arrayImage.length) {
        setImageDisplay(0);
      } else {
        setImageDisplay(value);
      }
    },
    [arrayImage.length, setImageDisplay],
  );

  return (
    <ProductCardImage md={md ?? 24} lg={lg ?? 24} xl={xl ?? 2}>
      <ImageComponentsRow justify="space-between">
        {images.length > 1 && (
          <Button
            style={{ fontSize: '8px' }}
            type="text"
            onClick={() => handleImageDisplay(imageDisplay - 1)}
            icon={<LeftOutlined />}
          />
        )}
        <ImageGallery
          images={arrayImage}
          currentImage={arrayImage[imageDisplay]}
          alt="imagem do produto"
          maxHeight={maxHeight}
        />
        {images.length > 1 && (
          <Button
            type="text"
            onClick={() => handleImageDisplay(imageDisplay + 1)}
            icon={<RightOutlined />}
          />
        )}
      </ImageComponentsRow>
      <IllustrativeImage>
        Imagem{' '}
        {images.length > 0 && !isLkpOffer ? 'do produtor' : 'ilustrativa'}
      </IllustrativeImage>
    </ProductCardImage>
  );
}

export default ProductCardComponentImage;
