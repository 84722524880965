import React from 'react';
import { useSelector } from 'react-redux';
import { Carousel as AntdCarousel, Image } from 'antd';
import { sessionSelectors } from 'store/state/selectors';
import { useGetBannersQuery } from 'services/ecommerceApi';
import Banner from 'model/Banner';
import * as S from './styles';

export default function Carousel() {
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);

  const { data: banners } = useGetBannersQuery(producerUrl) ?? {};

  return (
    <AntdCarousel autoplay style={{ textAlign: 'center' }}>
      {banners ? (
        banners
          .filter((banner: Banner) => banner.bannerType === 1)
          .map((banner: Banner) => (
            <S.CarouselContent key={`banner-image-id-key-${banner.imageUrl}`}>
              <Image width="100%" key={`banner-image-id-key-${banner.id}`} preview={false} src={banner.imageUrl} />
            </S.CarouselContent>
          ))
      ) : null}
    </AntdCarousel>
  );
}
