import {
  CheckCircleOutlined,
  HistoryOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  ShrinkOutlined,
  ReloadOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import {
  LegendComponent,
  TitleCard,
  GroupInformation,
  LegendListHorizontal,
  ItemList,
  TextList,
  Info,
  TextInfo,
} from './styles';

export default function Legend() {
  return (
    <LegendComponent>
      <GroupInformation>
        <TitleCard>Legenda</TitleCard>

        <LegendListHorizontal>
          <ItemList key="item-gerado">
            <CheckCircleOutlined style={{ color: '#52c41a' }} />
            <TextList>Gerado</TextList>
          </ItemList>
          <ItemList key="item-Programado">
            <HistoryOutlined style={{ color: '#faad14' }} />
            <TextList>Programado</TextList>
          </ItemList>
          <ItemList key="item-Cancelado">
            <CloseCircleOutlined style={{ color: '#8c8c8c' }} />
            <TextList>Cancelado</TextList>
          </ItemList>
          </LegendListHorizontal>
         <LegendListHorizontal>
         <ItemList key="item-DivergenciaFinanceira">
            <ShrinkOutlined style={{ color: '#fa541c' }}/>
            <TextList>Divergência Financeira</TextList>
          </ItemList>
          <ItemList key="item-Processando">
            <ReloadOutlined style={{ color: '#0f2aa1' }} />
            <TextList>Processando</TextList>
          </ItemList>
          <ItemList key="item-Aberto">
            <UnlockOutlined style={{ color: '#530346' }} />
            <TextList>Aberto</TextList>
          </ItemList>
         </LegendListHorizontal>
        <Info>
          <InfoCircleOutlined  style={{ color: '#8c8c8c' }}/>
          <TextInfo>Os valores exibidos não incluem o ICMS.</TextInfo>
        </Info>
      </GroupInformation>
    </LegendComponent>
  );
}
