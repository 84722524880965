import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useGetPostQrCodeProductMutation } from 'services/ecommerceApi';
import { validatorUtils } from 'utils/validator';
import { PrePostBodyQrCodeProduct } from 'model/types';
import { showAlertError } from 'services/alertService';
import { Loader, Overlay } from 'components/Wired/DefaultLoader/styles';
import {
  ModalShoppingByQrCodeComponent,
  ModalShoppingByQrCodeComponentBody,
  TitlePrincipal,
  Information,
  ButtonFinish,
} from './styles';

type Props = {
  modalIsVisible: boolean;
  postItem?: PrePostBodyQrCodeProduct;
  refetchGetQrCodeData: () => void;
  setModalIsVisible: (value: boolean) => void;
};

export default function ModalShoppingByQrCode({
  modalIsVisible,
  refetchGetQrCodeData,
  postItem,
  setModalIsVisible,
}: Props) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [finish, setFinish] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [getPostQrCodeProduct] = useGetPostQrCodeProductMutation();

  const insertProductInCart = async (values: any) => {
    if (postItem) {
      setIsLoading(true);
      try {
        const result = await getPostQrCodeProduct({
          bolLotHeaderId: postItem.bolLotHeaderId,
          customerId: postItem?.customerId,
          gfpNumber: postItem?.gfpNumber,
          lkpOfferId: postItem.lkpOfferId,
          lineId: postItem.lineId,
          productId: postItem.productId,
          siteId: postItem?.siteId,
          quantity: +values.quantity,
        });
        setFinish(true);
        refetchGetQrCodeData();
        if ((result as any)?.error) {
          throw ((result as any)?.error?.data);
        }
      } catch (error) {
        setModalIsVisible(false);
        setFinish(false);
        if (typeof error === 'string' && error) {
          showAlertError(dispatch, error);
        } else {
          showAlertError(
            dispatch,
            'Ocorreu um erro inesperado, tente novamente mais tarde.',
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ModalShoppingByQrCodeComponent
      className="remove-button-close"
      closeIcon={null}
      centered
      footer={null}
      onCancel={() => {
        setModalIsVisible(false);
        setFinish(false);
      }}
      visible={modalIsVisible}
      width="350px"
    >
      <ModalShoppingByQrCodeComponentBody>
        {!finish ? (
          <Form
            form={form}
            onFinish={(values) => {
              insertProductInCart(values);
            }}
          >
            <TitlePrincipal>{postItem?.productDescription}</TitlePrincipal>
            <Information>
              Entre com a quantidade que você deseja comprar:
            </Information>
            <Form.Item

              name="quantity"
              rules={[
                {
                  required: true,
                  message: 'A quantidade é obrigatória.',
                },
                {
                  validator: (_, value) =>
                    !value || Number(value) <= (postItem?.quantityPacking ?? 0)
                      ? Promise.resolve()
                      : Promise.reject(
                        new Error(
                          `A quantidade máxima é ${postItem?.quantityPacking} ${postItem?.quantityPacking && postItem?.quantityPacking > 1 ? 'Embalagens' : 'Embalagem'}`,
                        ),
                      ),
                },
                {
                  validator: (_, value) =>
                    !value ||
                      Number(value) * (postItem?.quantityUnitPerPacking ?? 0) >=
                      (postItem?.minimumQuantity ?? 0)
                      ? Promise.resolve()
                      : Promise.reject(
                        new Error(
                          `A quantidade mínima é ${postItem?.minimumQuantity && postItem?.quantityUnitPerPacking ? postItem?.minimumQuantity / postItem?.quantityUnitPerPacking : 0
                          } ${postItem?.minimumQuantity && postItem?.quantityUnitPerPacking && postItem?.minimumQuantity / postItem?.quantityUnitPerPacking > 1 ? 'Embalagens' : 'Embalagem'
                        }`,
                        ),
                      ),
                },
                {
                  validator: (_, value) =>
                    validatorUtils.validMultiple(
                      value,
                      postItem?.multiple ?? 0,
                      'Packing',
                      postItem?.quantityUnitPerPacking
                        ? `${postItem?.quantityUnitPerPacking}`
                        : '',
                      postItem?.multiple && postItem?.quantityUnitPerPacking ? postItem?.multiple / postItem?.quantityUnitPerPacking : 0,
                      postItem?.multiple && postItem?.quantityUnitPerPacking && postItem?.multiple / postItem?.quantityUnitPerPacking > 1 ? 'Embalagens' : 'Embalagem'
                    ),
                },
              ]}
            >
              <Input
                inputMode='numeric'
                autoComplete={undefined}
                addonAfter="Embalagens"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <ButtonFinish type="primary" onClick={() => form.submit()}>
              Comprar
            </ButtonFinish>
          </Form>
        ) : (
          <TitlePrincipal>COMPRA REALIZADA COM SUCESSO!</TitlePrincipal>
        )}
      </ModalShoppingByQrCodeComponentBody>
      {isLoading && (
        <Overlay>
          <Loader spinning={isLoading} size="large" />
        </Overlay>
      )}
    </ModalShoppingByQrCodeComponent>
  );
}
