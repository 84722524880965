import { createSelector } from '@reduxjs/toolkit';
import { SessionState } from './sessionSlice';

export const getRoot = (state: { session: SessionState }) =>
  state.session;

export const getAuthenticated = createSelector(
  [getRoot],
  (state) => state.authenticated,
);

export const getSelectedCustomerId = createSelector(
  [getRoot],
  (state) => state.selectedCustomerId,
);

export const getSelectedCustomerIdFilter = createSelector(
  [getRoot],
  (state) => state.selectedCustomerIdFilter,
);

export const getSelectedCustomer = createSelector([getRoot], (state) => {
  const selectedCustomer = state.customers.find(
    (customer) => customer.id === state.selectedCustomerId,
  );
  return selectedCustomer;
});

export const getSelectedCustomerAccountCode = createSelector(
  [getRoot],
  (state) => {
    const selectedCustomer = state.customers.find(
      (customer) => customer.id === state.selectedCustomerId,
    );
    return selectedCustomer?.accountCode;
  },
);

export const getFailedLogin = createSelector(
  [getRoot],
  (state) => state.failedLogin,
);

export const getSelectedDeliveryDate = createSelector(
  [getRoot],
  (state) => state.deliveryDate,
);

export const getRefreshToken = createSelector(
  [getRoot],
  (state) => state.refreshToken,
);

export const getSelectedBillingDate = createSelector(
  [getRoot],
  (state) => state.billingDate,
);

export const getSelectedDeliveryDates = createSelector([getRoot], (state) => ({
  businessDate: state.billingDate,
  deliveryDate: state.deliveryDate,
}));

export const isProducerAuthenticated = createSelector(
  [getRoot],
  (state) => state.isProducer,
);

export const getUrlsProducer = createSelector(
  [getRoot],
  (state) => state.producerUrls,
);

export const getProducerUrl = createSelector(
  [getRoot],
  (state) => state.producerUrl,
);

export const getSitesIds = createSelector([getRoot], (state) => state.sitesIds);

export const getCustomer = createSelector([getRoot], (state) => state.customer);

export const getUserAction = createSelector([getRoot], (state) => state.userAction);

export const getTimeLeft = createSelector([getRoot], (state) => state.timeLeft);


