import styled from 'styled-components';

export const Root = styled.div`
  padding: 48px 96px;
`;

export const Header = styled.div``;

export const SectionTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
`;

export const ProductCards = styled.div`
    .hover-card-product {
      width: auto !important;
      margin: 16px;
    }

    .ant-empty {
      width: 100%;
      height: 80px;
    }
`;

export const Banner = styled.div`
  align-self: stretch;
  width: 1440px;
  height: 200px;
`;
