import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
  width: 300px;
  max-width: calc(100vw - 40px);

  @media screen and (min-width: 769px) {
    width: 350px;
    height: 130px;
  }
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  gap: 8px;

  .ant-picker-today-btn {
    color: red;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px 0;

    :nth-child(1n) {
      margin-bottom: 10px;
    }
  }
`;

export const ContainerDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 0 8px;
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  padding: 0 8px 8px 8px;
  width: 100%;
`;
