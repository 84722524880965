import styled from 'styled-components';

export const FastBuyContainer = styled.div`
  width: 100%;
  min-height: 100%;
  margin: 4px;
  padding-left: 8px;
  padding-right: 4px;

  > button {
    width: 100% !important;
    font-size: 14px;
    border: 0;
    color: #fff;
    background: linear-gradient(
      0deg,
      rgba(0, 91, 167, 1) 0%,
      rgba(39, 65, 116, 1) 100%
    );
    
    &:hover,
    &:active {
      color: #fff;
      background: linear-gradient(
        0deg,
        rgba(0, 91, 167, 1) 0%,
        rgba(39, 65, 116, 1) 100%
      );
    }
  }
`;
