/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';
import Breadcrumb from 'components/UI/Breadcrumb';
import { useGetOfferByIdQuery } from 'services/ecommerceApi';
import { getPageProductList } from 'services/config';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelectors } from 'store/state/selectors';
import { isMobile } from 'react-device-detect';
import ModalCreateComposition from 'components/Modals/CreateComposition';
import { Loader } from 'components/Wired/DefaultLoader/styles';
import Packing from 'model/Packing';
import { showAlertError } from 'services/alertService';
import ProductOfferDetails from 'components/BusinessLogic/Product/ProductOfferDetails';
import ProductOffer from 'model/ProductOffer';
import { Empty } from 'antd';

import * as S from './styles';

export default function Offer() {
  const dispatch = useDispatch();
  const { offerId, offerType } = useParams<{
    offerId: string;
    offerType: string;
  }>();
  const [productOffer, setProductOffer] = useState<ProductOffer>();
  const [page] = useState<number>(getPageProductList() ?? 1);
  const { t } = useTranslation('translation', { keyPrefix: 'productList' });
  const { t: tRoot } = useTranslation();
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);

  const [packingSelected, setPackingSelected] = useState<Packing>(
    {} as Packing,
  );

  const onCloseModalComposition = () => {
    setModalIsVisibleComposition(false);
  };

  const lastRef = useRef<HTMLDivElement>(null);

  const [productOfferOpened, setProductOfferOpened] = useState<
    ProductOffer | undefined
  >(undefined);
  const [modalIsVisibleComposition, setModalIsVisibleComposition] =
    useState(false);
  const [offerIdModalComposition, setOfferIdModalComposition] = useState<
    number | undefined
  >();

  const onShowModalComposition = (
    productOfferSelected: ProductOffer,
    packingSelected: Packing,
  ) => {
    setModalIsVisibleComposition(true);
    setProductOfferOpened(productOfferSelected);
    setPackingSelected(packingSelected);
  };

  const {
    data: offerWithBox,
    isError,
    isLoading,
    isFetching,
  } = useGetOfferByIdQuery(
    {
      customerId,
      offerId: Number(offerId),
      offerTypes: [offerType ?? ''],
    },
    {
      skip: !offerId,
    },
  );

  useEffect(() => {
    if (isError) {
      showAlertError(
        dispatch,
        'Não foi possível carregar a oferta no momento, por favor tente novamente mais tarde.',
      );
    }
  }, [isError]);

  useEffect(() => {
    const offer = offerWithBox?.offers;
    if (offer && offer?.length > 0) {
      setProductOffer(offer[0]);
    } else {
      setProductOffer(undefined);
    }
  }, [offerWithBox, offerId]);

  const breadcrumbLevels = [
    { description: tRoot('breadcrumb.store') },
    { description: tRoot('breadcrumb.products') },
  ];
  if (productOffer) {
    breadcrumbLevels.push({
      description: tRoot(
        productOffer.longName.slice(0, 40) +
          (productOffer.longName.length > 40 ? '...' : ''),
      ),
    });
  }
  return (
    <Screen
      content={
        <S.Root>
          <S.Divider />
          <S.Header className="desktop">
            <S.HeaderLine1>
              <Breadcrumb levels={breadcrumbLevels} />
              <S.ICMSNotIncludedInValues>
                <InfoCircleOutlined />
                <span>{t('icmsDisclamer')}</span>
              </S.ICMSNotIncludedInValues>
            </S.HeaderLine1>
          </S.Header>
          <S.Header className="mobile">
            <S.HeaderLine1>
              <Breadcrumb levels={breadcrumbLevels} />
            </S.HeaderLine1>
            <S.HeaderLine1>
              <S.ICMSNotIncludedInValues>
                <InfoCircleOutlined />
                <span>{t('icmsDisclamer')}</span>
              </S.ICMSNotIncludedInValues>
            </S.HeaderLine1>
          </S.Header>
          <S.Content className={productOffer ? '' : 'empty-data'}>
            <div>
              {productOffer ? (
                <ProductOfferDetails
                  key={productOffer.id}
                  setOfferIdModalComposition={setOfferIdModalComposition}
                  setModalIsVisibleComposition={onShowModalComposition}
                  productOffer={productOffer}
                />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </S.Content>
          <div ref={lastRef} />
          <ModalCreateComposition
            productOfferOpened={productOfferOpened}
            offerId={offerIdModalComposition}
            setModalIsVisible={onCloseModalComposition}
            modalIsVisible={modalIsVisibleComposition}
            packingSelected={packingSelected}
          />
          {(isLoading || isFetching) &&
            isAuthenticated &&
            !customerId &&
            (page === 1 || !isMobile) && (
              <Loader spinning={isLoading || isFetching} size="large" />
            )}
        </S.Root>
      }
    />
  );
}
