import {
  MutableRefObject,
  useEffect,
  memo,
  useState,
  useCallback,
} from 'react';
import { Gallery, Item, useGallery } from 'react-photoswipe-gallery';

import { Button } from './styles';

interface PropsImageGallery {
  images: string[];
  currentImage: string;
  alt?: string;
  maxHeight?: string;
  open?: MutableRefObject<() => void | null>;
}

function GalleryContent({
  images,
  currentImage,
  maxHeight = '100px',
  alt,
  open,
}: PropsImageGallery) {
  const { open: openGallery } = useGallery();
  const [reload, setReload] = useState<boolean>(false);

  setTimeout(() => {
    setReload(!reload);
  }, 1000);

  function buildImage(image: string) {
    const img = new Image();
    img.src = image;

    return (
      <Item
        key={image}
        original={image}
        width={img?.naturalWidth === 0 ? 1000 : img?.naturalWidth}
        height={img?.naturalHeight === 0 ? 1000 : img?.naturalHeight}
      >
        {({ ref, open }) => (
          <div
            className="image-gallery"
            style={{ display: image === currentImage ? 'block' : 'none' }}
          >
            <Button type="button" onClick={open}>
              <img
                style={{ cursor: 'pointer', maxHeight, maxWidth: '100%' }}
                src={image}
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                alt={alt}
                loading="lazy"
              />
            </Button>
          </div>
        )}
      </Item>
    );
  }

  useEffect(() => {
    if (open) {
      open.current = openGallery;
    }
  }, [open, open?.current]);

  return <>{images.map((image) => buildImage(image))}</>;
}

function ImageGallery(args: PropsImageGallery) {
  return (
    <Gallery
      id="image-gallery"
      shareButton={false}
      fullscreenButton={false}
      zoomButton={false}
      options={{
        bgOpacity: 0.8,
      }}
    >
      <GalleryContent {...args} />
    </Gallery>
  );
}

export default memo(ImageGallery);
