import styled from 'styled-components';

export const SwitchComponent = styled.span`
  .ant-switch {
    background-color: #bbb;
  }

  .ant-switch-checked {
    background-color: #f78e1e;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 5px;
`;
