import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import { sessionSelectors } from 'store/state/selectors';
import Breadcrumb from 'components/UI/Breadcrumb';
import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';
import MyOrdersContext from 'contexts/MyOrdersContext';
import TabVol from './Tab';
import {
  Root,
  Divider,
  Header,
  HeaderLine1,
  HeaderLine1GroupLeft,
  Title,
  Legend,
  HeaderLine2,
  ContinueShoppingButton,
  TypeProductsContainer,
} from './styles';

export default function MyOrders() {
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);

  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  return (
    <MyOrdersContext>
      <Screen
        content={
          <Root>
            <Divider />

            <Header>
              <HeaderLine1>
                <HeaderLine1GroupLeft>
                  <Title>{translate('myOrders.title')}</Title>
                  <Legend>{translate('myOrders.legend')}</Legend>
                </HeaderLine1GroupLeft>
                <Breadcrumb
                  levels={[{ description: translate('breadcrumb.myOrders') }]}
                />
              </HeaderLine1>
              <HeaderLine2>
                <ContinueShoppingButton
                  icon={<LeftOutlined />}
                  onClick={() => navigate(`/${producerUrl}/products`)}
                >
                  {translate('shoppingCart.continueShopping')}{' '}
                </ContinueShoppingButton>
              </HeaderLine2>
            </Header>

            <TypeProductsContainer>
              <TabVol customerId={customerId} />
            </TypeProductsContainer>
          </Root>
        }
      />
    </MyOrdersContext>
  );
}
