import { memo } from 'react';
import { ReajustmentAmountType } from 'services/offerService';

import { Label, Value } from './styles';

interface Props {
  reajustmentAmount: number;
  voucherReajustmentType: string;
}

function VoucherTag({ reajustmentAmount, voucherReajustmentType }: Props) {
  return (
    <>
      <Label>
        {reajustmentAmount < 0
          ? ReajustmentAmountType.Discount
          : ReajustmentAmountType.Additional}
      </Label>
      <Value>
        {voucherReajustmentType === 'P'
          ? `${Math.abs(reajustmentAmount)}%`
          : `R$${Math.abs(reajustmentAmount)}`}
      </Value>
    </>
  );
}

export default memo(VoucherTag);
