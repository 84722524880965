import axios, { AxiosRequestConfig } from 'axios';
import { Console } from 'console';
import { UserTypeEnum } from 'enums/userType';
import { JwtToken } from 'model/types';
import {
  getSessionStorageValueByKey,
  getSessionStorageValueByKeyNotArray,
  insertInSessionStorageByKey,
  insertInSessionStorageByKeyNotArray,
  setLogoutLocal,
  setLogoutSession,
} from 'storage/session-storage';
// Application

const tokenKey = 'token';
const refreshTokenKey = 'refreshToken';
const expireInKey = 'expireIn';
const scopeKey = 'scope';
const tokenTypeKey = 'tokenType';

// General

const producerUrlKey = 'producerUrl';
const customerIdKey = 'customerId';
const billingDateKey = 'billingDate';
const deliveryDateKey = 'deliveryDate';
const pageStateKey = 'page';

// DirectedOffers

const modeListDirectedOffersKey = 'modeListDirectedOffers';

// MyOffers

const modeListMyOffersKey = 'modeListMyOffers';

// ShoppingCart

const typeOfferSelectedCartKey = 'typeOfferSelectedCart';

// MyOrders

const typeOfferSelectedMyOrdersKey = 'typeOfferSelectedMyOrders';

// ProductList

const modeListProductListKey = 'modeListProductList';
const letterSelectedProductListKey = 'letterSelectedProductList';
const filterStateKey = 'filterState';
const pageProductListKey = 'pageProductList';
const pageSizeProductListKey = 'pageSizeProductList';
const orderByProductListKey = 'orderByProductList';
const offerTypeProductListKey = 'offerTypeProductList';
const termProductListKey = 'termProductList';
const modePagination = 'modePagination';

// Local Storage

export const getAuthorizationToken = () =>
  getSessionStorageValueByKey(tokenKey);

export const setAuthorizationToken = (tokenValue: string) => {
  insertInSessionStorageByKey(tokenKey, tokenValue);
};

export const getScope = () => getSessionStorageValueByKey(scopeKey);

export const setScope = (scope: string) => {
  insertInSessionStorageByKey(scopeKey, scope);
};
export const getRefreshTokenService = () =>
  getSessionStorageValueByKey(refreshTokenKey);

export const setRefreshToken = (refreshTokenValue: string) =>
  insertInSessionStorageByKey(refreshTokenKey, refreshTokenValue);

export const getTokenType = () => getSessionStorageValueByKey(tokenTypeKey);

export const setTokenType = (tokenTypeValeu: string) =>
  insertInSessionStorageByKey(tokenTypeKey, tokenTypeValeu);

export const getExpireIn = () => getSessionStorageValueByKey(expireInKey);

export const setExpireIn = (expireInValue: string) =>
  insertInSessionStorageByKey(expireInKey, expireInValue);

// Session Storage

export const getTermProductList = () =>
  getSessionStorageValueByKeyNotArray(termProductListKey);

export const setTermProductList = (termProductList: string) =>
  insertInSessionStorageByKeyNotArray(termProductListKey, termProductList);

export const getLetterSelectedProductList = () =>
  getSessionStorageValueByKeyNotArray(letterSelectedProductListKey);

export const setLetterSelectedProductList = (letterSelected: string) =>
  insertInSessionStorageByKeyNotArray(
    letterSelectedProductListKey,
    letterSelected,
  );

export const getCustomerIdSelected = () =>
  getSessionStorageValueByKeyNotArray(customerIdKey);

export const setProducerUrl = (producerUrl: string) =>
  insertInSessionStorageByKey(producerUrlKey, producerUrl);

export const getProducerUrl = (): string =>
  getSessionStorageValueByKey(producerUrlKey);

export const setCustomerIdSelected = (customerId: any) =>
  insertInSessionStorageByKeyNotArray(customerIdKey, String(customerId));

export const getPageState = () => getSessionStorageValueByKey(pageStateKey);

export const setPageState = (page: string) => {
  insertInSessionStorageByKey(pageStateKey, page);
};
export const getOrderByProductList = () =>
  getSessionStorageValueByKeyNotArray(orderByProductListKey);

export const setOrderByProductList = (orderByProductList: string) =>
  insertInSessionStorageByKeyNotArray(
    orderByProductListKey,
    orderByProductList,
  );

export const getDeliveryDate = () =>
  getSessionStorageValueByKeyNotArray(deliveryDateKey);

export const setDeliveryDate = (deliveryDateValue: string) =>
  insertInSessionStorageByKeyNotArray(deliveryDateKey, deliveryDateValue);

export const getPageSizeProductList = () => {
  if (getSessionStorageValueByKeyNotArray(pageSizeProductListKey)) {
    return +getSessionStorageValueByKeyNotArray(pageSizeProductListKey);
  }
  return undefined;
};

export const setPageSizeProductList = (page: number) =>
  insertInSessionStorageByKeyNotArray(pageSizeProductListKey, String(page));

export const getPageProductList = () => {
  if (getSessionStorageValueByKeyNotArray(pageProductListKey)) {
    return +getSessionStorageValueByKeyNotArray(pageProductListKey);
  }
  return undefined;
};

export const setPageProductList = (page: number) =>
  insertInSessionStorageByKeyNotArray(pageProductListKey, String(page));

export const getModeListProductList = () => {
  if (getSessionStorageValueByKeyNotArray(modeListProductListKey)) {
    return +getSessionStorageValueByKeyNotArray(modeListProductListKey);
  }
  return 2;
};

export const setModeListProductList = (modeList: number) =>
  insertInSessionStorageByKeyNotArray(modeListProductListKey, String(modeList));

export const setFilterState = (filter: any) =>
  insertInSessionStorageByKeyNotArray(filterStateKey, JSON.stringify(filter));

export const getFilterState = () =>
  getSessionStorageValueByKeyNotArray(filterStateKey)
    ? JSON.parse(getSessionStorageValueByKeyNotArray(filterStateKey))
    : '';

export const getBillingDate = () =>
  getSessionStorageValueByKeyNotArray(billingDateKey);

export const setBillingDate = (billingDateValue: string) =>
  insertInSessionStorageByKeyNotArray(billingDateKey, billingDateValue);

export const getTypeOfferSelectedCart = () =>
  getSessionStorageValueByKeyNotArray(typeOfferSelectedCartKey);

export const setTypeOfferSelectedCart = (typeOfferSelectedCart: string) =>
  insertInSessionStorageByKeyNotArray(
    typeOfferSelectedCartKey,
    typeOfferSelectedCart,
  );

export const getTypeOfferSelectedMyOrders = () =>
  getSessionStorageValueByKeyNotArray(typeOfferSelectedMyOrdersKey);

export const setTypeOfferSelectedMyOrders = (
  typeOfferSelectedMyOrders: string,
) =>
  insertInSessionStorageByKeyNotArray(
    typeOfferSelectedMyOrdersKey,
    typeOfferSelectedMyOrders,
  );

export const getModeListMyOffers = () => {
  if (getSessionStorageValueByKeyNotArray(modeListMyOffersKey)) {
    return +getSessionStorageValueByKeyNotArray(modeListMyOffersKey);
  }
  return 1;
};

export const setModeListMyOffers = (modeListMyOffers: number) =>
  insertInSessionStorageByKeyNotArray(
    modeListMyOffersKey,
    String(modeListMyOffers),
  );

export const getModeListDirectedOffers = () => {
  if (getSessionStorageValueByKeyNotArray(modeListDirectedOffersKey)) {
    return +getSessionStorageValueByKeyNotArray(modeListDirectedOffersKey);
  }
  return 1;
};

export const setModeListDirectedOffers = (modeListDirectedOffers: number) =>
  insertInSessionStorageByKeyNotArray(
    modeListDirectedOffersKey,
    String(modeListDirectedOffers),
  );

export const getOfferTypeProductList = () =>
  parseValidating(
    getSessionStorageValueByKeyNotArray(offerTypeProductListKey),
    'array',
  ) as string[];

export const setOfferTypeProductList = (offerTypeProductList: string[]) =>
  insertInSessionStorageByKeyNotArray(
    offerTypeProductListKey,
    JSON.stringify(offerTypeProductList),
  );

export const setModePagination = (pagination: boolean) =>
  insertInSessionStorageByKeyNotArray(modePagination, `${pagination}`);

export const getModePagination = () =>
  getSessionStorageValueByKeyNotArray(modePagination);

// Remove Items

export const setLogout = () => {
  setLogoutSession([
    customerIdKey,
    billingDateKey,
    deliveryDateKey,
    pageStateKey,
    modeListDirectedOffersKey,
    modeListMyOffersKey,
    typeOfferSelectedCartKey,
    typeOfferSelectedMyOrdersKey,
    modeListProductListKey,
    filterStateKey,
    orderByProductListKey,
    letterSelectedProductListKey,
    pageProductListKey,
    pageSizeProductListKey,
    offerTypeProductListKey,
    termProductListKey,
    modePagination,
  ]);
  setLogoutLocal([
    tokenKey,
    scopeKey,
    expireInKey,
    tokenTypeKey,
    refreshTokenKey,
  ]);
};

export const removeItensProductList = () => {
  setLogoutSession([
    filterStateKey,
    orderByProductListKey,
    offerTypeProductListKey,
    termProductListKey,
    modePagination,
  ]);
};

export const removeItensShoppingCart = () => {
  setLogoutSession([typeOfferSelectedCartKey]);
};

export const removeItensDirectedOffers = () => {
  setLogoutSession([modeListDirectedOffersKey]);
};

export const removeItensMyOffers = () => {
  setLogoutSession([modeListMyOffersKey]);
};

export const removeItensMyOrders = () => {
  setLogoutSession([typeOfferSelectedMyOrdersKey]);
};

// Functions

export const parseValidating = (value: string, typeParse: 'array' | any) => {
  if (!value) {
    return null;
  }
  try {
    return JSON.parse(value);
  } catch {
    if (typeParse === 'array') {
      return JSON.parse(`["${value}"]`);
    }
    return null;
  }
};

export const verifyIsProducer = (token: JwtToken): boolean => {
  if (token) {
    const roles = token?.veiling_claim_type_role;

    if (Array.isArray(roles)) {
      const containsRolesDiffProducer = roles.filter(
        (role) =>
          +role !== UserTypeEnum.PRODUCER &&
          +role !== UserTypeEnum.PRODUCER_EMPLOYEE,
      );
      return (
        !containsRolesDiffProducer || containsRolesDiffProducer.length <= 0
      );
    }

    return (
      +roles === UserTypeEnum.PRODUCER ||
      +roles === UserTypeEnum.PRODUCER_EMPLOYEE
    );
  }
  return false;
};

export const parseJwt = (token: string) => {
  try {
    const base64String = token.split('.')[1];
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    return JSON.parse(rawData);
  } catch (e) {
    return null;
  }
};

const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_ECOMMERCE_URL,
});
baseApi.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getAuthorizationToken();
  if (config.headers) {
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
});

export default baseApi;
