import styled from 'styled-components';
import logo from 'assets/logos/invertedLogo.svg';
import { DatePicker, Spin } from 'antd';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
  >div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 1140px) {
    flex-direction: column;
    height: 270px;
    align-items: center;
    &.is-authenticated {
      height: 270px !important;
    }
  }
`;



export const UserName = styled.div`
  cursor: pointer;

  span {
    margin: 4px;
    margin-top: 4px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SearchDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 305px;

  @media (max-width:1200px) {
      width: 300px;
  }
  @media (max-width:992px) {
      width: 250px;
  }
  @media (max-width:768px) {
      width: 100%;
  }
  button { 
    margin-left: -2px;
    min-width: 32px;
  }
  `;

export const PopoverTextLink = styled.div`
display: flex;
align-items: center;
cursor: pointer;
color: #F78E1E;
> * {
  margin-left: 10px;
  color: #F78E1E;
}
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 448px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 320px;
`;

export const LogoDiv = styled.div``;
export const ClientsBalanceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px 16px;
  gap: 16px;
`;

export const SelectUrls = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px 20px;
  min-width: 200px;

  .ant-select {
    min-width: 100% !important;
  }
`;

export const DeliveryBillingDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 250px;
`;

export const UserActionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 174px;
`;

export const BalanceAmoutContainer = styled.div`
display: flex;
justify-content:flex-start;
align-items: center;
  width: 150px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const UserSectionContainer = styled.div``;

export const ActionsSectionContainer = styled.div``;

export const Logo = styled.a`
  display: block;
  width: 124px;
  height: 48px;
  background-image: url(${logo});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  text-decoration: none;
`;

const pickerColor = '#F78E1E';
const HeaderDatePicker = styled(DatePicker)`
  .ant-picker-input {
    width: inherit;
    margin: 0;
    input {
      text-align: center;
      color: ${pickerColor};
      ::placeholder {
        color: ${pickerColor};
      }
    }
  }
  .ant-picker-input-active {
    input {
      color: ${pickerColor};
    }
  }
  .ant-picker-suffix {
    color: ${pickerColor};
  }
  .ant-picker-footer-extra:not(:last-child) {
    border: 0;
  }
`;
export const LoadingSpin = styled(Spin)`
  display: flex;
`;

export { HeaderDatePicker as DeliveryDatePicker };
export const BillingDatePicker = styled(HeaderDatePicker)`
  .ant-picker-input {
  }
`;

export const SignIn = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #f78e1e;
  cursor: pointer;
`;

export const DivGroupDataAndButtons = styled.div`
  display: flex;
  justify-content: center !important;
  flex-wrap: wrap;
  align-items: center;

  > div {
    @media (max-width: 1366px) {
      margin-top: 16px;
    }
  }
`;

export const DivButtonContent = styled.div`
  background-image: url(${logo});
`;

export const ImageAndCustomersDiv = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1366px) {
    margin-bottom: 16px;
  }
`;