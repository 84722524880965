/* eslint-disable no-nested-ternary */
import { memo, useCallback, useMemo, useState } from 'react';
import { Col, Form, Popover, Row } from 'antd';
import { ApartmentOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { FastCartIcon } from 'components/UI/Icons';
import { PackingTypeSale, PackingTypeSaleMap } from 'enums/packingTypeSale';
import Packing from 'model/Packing';
import ProductOffer from 'model/ProductOffer';
import { useSelector } from 'react-redux';
import { getShowPinnedFilter } from 'store/state/offerFilters/offerFiltersSelectors';
import { KeyPress } from 'utils/keypress';
import { calculateMinBySaleType } from 'utils/util';
import { validatorUtils } from 'utils/validator';
import { ButtonPacking } from 'components/BusinessLogic/Product/ButtonPacking';
import ProductShippingFee from 'components/UI/ProductShippingFee';
import {
  FastBuyContainer,
  ProductCardActions,
  PriceOptions,
  GroupTypeSaleProduct,
  AmountInfo,
  MinimumPurchase,
  MinimumPurchaseLabel,
  MinimunAmount,
  AvailableInfo,
  AvailableAmount,
  AvailableInfoLabel,
  AvailablePer,
  AvailablePackage,
  OrderActions,
  CardBottomContainer,
  PackagesDiv,
  ButtonNetwork,
  ButtonsNetworkCol,
  PackagesInput,
  PopoverContainer,
  TextPopoverComposition,
  FormItem,
  ButtonType,
  ThemeProps,
  MainContent,
  ModeType,
  FormType,
  Content,
  Available,
} from './styles';

interface Props {
  packingSelected: Packing;
  product: ProductOffer;
  isProducerAuthenticated: boolean;
  openComposition: () => void;
  onClickBuy: (
    quantityPacking: number,
    selectedTypeSale: PackingTypeSale,
  ) => void;
  onClickFastBuy: (
    quantityPacking: number,
    selectedTypeSale: PackingTypeSale,
  ) => void;
  actionsButtonType: ButtonType;
  formType?: FormType;
  mode?: ModeType;
  theme?: ThemeProps;
  selectedTypeSale: PackingTypeSale;
  onChangePackingTypeSale: (packingTypeSale: PackingTypeSale) => void;
}

function ProductActions({
  product,
  packingSelected,
  isProducerAuthenticated,
  actionsButtonType,
  mode = 'column',
  theme = 'default',
  formType = 'medium',
  openComposition,
  onClickBuy,
  onClickFastBuy,
  selectedTypeSale,
  onChangePackingTypeSale,
}: Props) {
  const showPinnedFilter = useSelector(getShowPinnedFilter);

  const validationComposition =
    product.isCompositionEnabled && product.productCategoryId === 1;

  const validationQuantity = validationComposition
    ? calculateMinBySaleType(
        selectedTypeSale,
        packingSelected,
        product.minimunQuantityCutProductComposition,
        validationComposition,
      )
    : packingSelected?.minimumQuantity;

  const formValidation = {
    quantityPacking: [
      {
        required: true,
        message: 'A quantidade é obrigatória.',
      },
      {
        validator: (_: any, value: string) =>
          !value || Number(value) <= getMaxAvailable.max
            ? Promise.resolve()
            : Promise.reject(
                new Error(
                  `A quantidade máxima é ${getMaxAvailable.max} ${textInputTypeSale}`,
                ),
              ),
      },
      {
        validator: (_: any, value: string) =>
          !value ||
          (validationComposition
            ? Number(value)
            : Number(value) * getMaxAvailable.min) >= validationQuantity
            ? Promise.resolve()
            : Promise.reject(new Error(getErrorMinMessage())),
      },
      {
        validator: (_: any, value: number) =>
          validatorUtils.validMultiple(
            value,
            packingSelected?.multiple ?? 0,
            selectedTypeSale,
            getMaxAvailableLabel,
            getMultipleCalc(),
            textInputTypeSale,
          ),
      },
    ],
  };

  const [form] = Form.useForm();

  const [fastBuy, setFastBuy] = useState(false);
  const [quantityPacking, setQuantityPacking] = useState(0);

  const textInputTypeSale = useMemo(
    () => `${PackingTypeSaleMap[selectedTypeSale]}(s)`,
    [selectedTypeSale],
  );

  const getMaxAvailableLabel = useMemo(() => {
    const prop = `maxAvailable${selectedTypeSale}sLabel` as keyof Packing;
    return packingSelected[prop] as string;
  }, [packingSelected, selectedTypeSale]);

  const getMaxAvailable = useMemo(() => {
    if (selectedTypeSale === PackingTypeSale.Packing) {
      return {
        label: packingSelected?.maxAvailablePackingsLabel || '',
        max: packingSelected?.maxAvailablePackingsQuantity || 0,
        min: packingSelected?.unitsPerPackage,
      };
    }
    if (selectedTypeSale === PackingTypeSale.Layer) {
      return {
        label: packingSelected?.maxAvailableLayersLabel || '',
        max: packingSelected?.maxAvailableLayersQuantity || 0,
        min:
          packingSelected?.packagingByLayer * packingSelected?.unitsPerPackage,
      };
    }
    return {
      label: packingSelected?.maxAvailableTrolleysLabel || '',
      max: packingSelected?.maxAvailableTrolleysQuantity || 0,
      min:
        packingSelected?.packagingByLayer *
        packingSelected?.layersByTrolley *
        packingSelected?.unitsPerPackage,
    };
  }, [packingSelected, selectedTypeSale]);

  const amountInfoFC = useMemo(
    () => (
      <AmountInfo className="row-info" mode={mode}>
        <div className="container">
          <Col>
            <MinimumPurchase>
              <MinimumPurchaseLabel>Compra mínima:</MinimumPurchaseLabel>
              <br/>
              <MinimunAmount>
                {calculateMinBySaleType(
                  selectedTypeSale,
                  packingSelected,
                  product.minimunQuantityCutProductComposition,
                  product.isCompositionEnabled &&
                    product.productCategoryId === 1,
                )}{' '}
                {textInputTypeSale}
              </MinimunAmount>
            </MinimumPurchase>
          </Col>
          <Col>
            <Available>
              <AvailableInfo>
                <AvailableAmount>{getMaxAvailable.max}</AvailableAmount>
                <AvailableInfoLabel>disponíveis</AvailableInfoLabel>
              </AvailableInfo>
              <AvailablePer>
                <AvailablePackage>{getMaxAvailable.label}</AvailablePackage>
              </AvailablePer>
            </Available>
          </Col>
        </div>
      </AmountInfo>
    ),
    [
      getMaxAvailable.label,
      getMaxAvailable.max,
      mode,
      packingSelected,
      selectedTypeSale,
      textInputTypeSale,
      product,
    ],
  );

  const getMin = (
    selectedTypeSale: PackingTypeSale,
    packingSelected: Packing,
    calcField: 'multiple' | 'minimumQuantity',
    prefrerQuantity?: number,
    preferQauntityActive?: boolean,
  ) => {
    const minimum =
      preferQauntityActive && prefrerQuantity
        ? prefrerQuantity * +packingSelected?.maxAvailablePackingsLabel
        : packingSelected[calcField];
    if (selectedTypeSale === PackingTypeSale.Packing) {
      const calc = minimum / +packingSelected?.maxAvailablePackingsLabel;
      return calc < 1 ? 1 : calc;
    }
    const values = (
      selectedTypeSale === PackingTypeSale.Layer
        ? packingSelected.maxAvailableLayersLabel
        : packingSelected.maxAvailableTrolleysLabel
    )
      .split('x')
      .map((x) => +x)
      .reduce((a, b) => +a * +b);
    const calc = minimum / values;
    return calc < 1 ? 1 : Math.ceil(calc);
  };

  const handleTypeSale = (packingType: PackingTypeSale) => {
    onChangePackingTypeSale(packingType);
  };

  const getErrorMinMessage = useCallback(
    () =>
      `Quantidade mínima: ${getMin(
        selectedTypeSale,
        packingSelected,
        'minimumQuantity',
        product.minimunQuantityCutProductComposition,
        validationComposition,
      )} ${textInputTypeSale}`,
    [
      selectedTypeSale,
      packingSelected,
      textInputTypeSale,
      product.minimunQuantityCutProductComposition,
      validationComposition,
    ],
  );

  const getMultipleCalc = useCallback(
    () => getMin(selectedTypeSale, packingSelected, 'multiple'),
    [selectedTypeSale, packingSelected],
  );

  const onSubmit = useCallback(
    (data: { quantityPacking: string }) => {
      console.log(`onSubmit:: `, data);
      const quantityPacking = parseInt(data.quantityPacking, 10);
      if (fastBuy) {
        onClickFastBuy(quantityPacking, selectedTypeSale);
        setFastBuy(false);
      } else {
        onClickBuy(quantityPacking, selectedTypeSale);
      }
    },
    [fastBuy, onClickBuy, onClickFastBuy, selectedTypeSale],
  );

  const handleClickFastBuy = useCallback(() => {
    setFastBuy(true);
    form.submit();
  }, [form]);

  return (
    <ProductCardActions
      themeType={theme}
      pinned={showPinnedFilter}
      typeOffer={product.offerType}
    >
      {mode === 'row' && amountInfoFC}
      <Content direction={mode}>
        <MainContent>
          <PriceOptions>
            <GroupTypeSaleProduct type={actionsButtonType}>
              <ButtonPacking
                handleTypeSale={handleTypeSale}
                selectedTypeSale={selectedTypeSale}
                packingSelected={packingSelected}
                price={product.packagingPrice}
                packingCost={product.packingCost}
                packingType={PackingTypeSale.Packing}
                quantityPacking={quantityPacking}
                specialPrice={product.specialPrice}
                minimumQuantity={product.minimumQuantity}
              />
              <ButtonPacking
                handleTypeSale={handleTypeSale}
                selectedTypeSale={selectedTypeSale}
                packingSelected={packingSelected}
                price={product.layerPrice}
                packingCost={product.packingCost}
                packingType={PackingTypeSale.Layer}
                quantityPacking={quantityPacking}
                specialPrice={product.specialPrice}
                minimumQuantity={product.minimumQuantity}
              />
              <ButtonPacking
                handleTypeSale={handleTypeSale}
                selectedTypeSale={selectedTypeSale}
                packingSelected={packingSelected}
                price={product.trolleyPrice}
                packingCost={product.packingCost}
                packingType={PackingTypeSale.Trolley}
                quantityPacking={quantityPacking}
                specialPrice={product.specialPrice}
                minimumQuantity={product.minimumQuantity}
              />
            </GroupTypeSaleProduct>

            {product.shippingFeeFilials.length > 0 && (
              <ProductShippingFee product={product} />
            )}

            {mode === 'column' && amountInfoFC}
          </PriceOptions>
          <OrderActions>
            <Form form={form} onFinish={onSubmit}>
              <CardBottomContainer>
                <PackagesDiv size={formType}>
                  <FormItem
                    name="quantityPacking"
                    rules={formValidation.quantityPacking}
                  >
                    <PackagesInput
                      id="quantityPacking"
                      onChange={({ target }) => {
                        const value = Number(target.value);
                        if (value >= 0) {
                          setQuantityPacking(value);
                          form.setFieldsValue({ quantityPacking: value });
                        }
                      }}
                      autoComplete="off"
                      onKeyPress={KeyPress.onlyNumber}
                      addonAfter={textInputTypeSale}
                      type="tel"
                    />
                  </FormItem>
                </PackagesDiv>
                <ButtonsNetworkCol size={formType}>
                  {!isProducerAuthenticated && (
                    <Popover
                    content={
                      <div style={{ maxWidth: quantityPacking <= 0 ? '200px' : '180px' }}>
                          <span>
                            {getMaxAvailable.max <
                            calculateMinBySaleType(
                              selectedTypeSale,
                              packingSelected,
                            )
                              ? 'Utilize a composição'
                              : quantityPacking <= 0 ? 'Preencha a(s) quantidades' : 'Adicionar ao carrinho'}
                          </span>
                        </div>
                      }
                    >
                      <ButtonNetwork
                        disabled={
                          quantityPacking <= 0 ||
                          getMaxAvailable.max <
                          calculateMinBySaleType(
                            selectedTypeSale,
                            packingSelected,
                          )
                        }
                        onClick={form.submit}
                        type="primary"
                        icon={<ShoppingCartOutlined />}
                      />
                    </Popover>
                  )}
                  {product.isCompositionEnabled && (
                    <Popover
                      content={
                        <PopoverContainer>
                          <TextPopoverComposition>
                            Utilize produtos diferentes para preencher uma mesma
                            camada.
                          </TextPopoverComposition>
                        </PopoverContainer>
                      }
                      title="Criar composição"
                    >
                      <ButtonNetwork
                        type="primary"
                        icon={<ApartmentOutlined />}
                        onClick={openComposition}
                      />
                    </Popover>
                  )}
                </ButtonsNetworkCol>
              </CardBottomContainer>
            </Form>
          </OrderActions>
        </MainContent>
        {product.isLkpOffer && (
          <FastBuyContainer mode={mode}>
            <ButtonNetwork
            disabled={quantityPacking <= 0}
              type="default"
              icon={<FastCartIcon />}
              onClick={handleClickFastBuy}
            >
              Comprar agora
            </ButtonNetwork>
          </FastBuyContainer>
        )}
      </Content>
    </ProductCardActions>
  );
}

export default memo(ProductActions);
