import React, { useEffect, useState } from 'react';
import { Button, Empty, Steps } from 'antd';
import ProductCardListComposition from 'components/BusinessLogic/Product/ProductCardListComposition';
import {
  useGetOffersCompositionMutation,
  useGetPostProductInCartMutation,
} from 'services/ecommerceApi';
import ProductOffer from 'model/ProductOffer';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelectors } from 'store/state/selectors';
import moment from 'moment';
import { generateErrorMessage } from 'utils/error';
import {
  OfferCompositionFilterParam,
  PostBodyProductInCart,
} from 'model/types';
import ModalConfirmation from 'components/Modals/Confirmation';
import { showAlertError, showAlertSuccess } from 'services/alertService';
import Packing from 'model/Packing';
import * as S from './styles';

type PropsCreateComposition = {
  modalIsVisible?: boolean;
  setModalIsVisible: () => void;
  offerId?: number;
  productOfferOpened?: ProductOffer;
  packingSelected: Packing;
};

const { Step } = Steps;

export default function ModalCreateComposition({
  modalIsVisible,
  setModalIsVisible,
  offerId,
  productOfferOpened,
  packingSelected,
}: PropsCreateComposition) {
  const dispatch = useDispatch();
  const customer = useSelector(sessionSelectors.getCustomer);
  const billingDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [previousDeliveryDate, setPreviousDeliveryDate] = useState<
    moment.Moment | undefined
  >(undefined);
  const [labelDeliveryDate, setLabelDeliveryDate] = useState<string>('');
  const [labelTimeDeliveryDate, setLabelTimeDeliveryDate] =
    useState<string>('');

  const [selectedsProducts, setSelectedsProducts] = useState<
    PostBodyProductInCart[]
  >([]);
  const [modalConfirmationIsVisible, setModalConfirmationIsVisible] =
    useState<boolean>(false);
  const [messageModalInformation, setMessageModalInformation] =
    useState<string>('');
  const [
    modalInformationQuantityIsVisible,
    setModalInformationQuantityIsVisible,
  ] = useState<boolean>(false);

  const [getPostProductInCart, { isLoading: isLoadingPostProduct }] =
    useGetPostProductInCartMutation();

  useEffect(() => {
    if (productOfferOpened && modalIsVisible) {
      const filterByOfferComposition: OfferCompositionFilterParam = {
        producerId: productOfferOpened?.producerId,
        categoryId: productOfferOpened?.productCategoryId,
        commercialGroupIds:
          productOfferOpened?.productCategoryId === 1
            ? undefined
            : [productOfferOpened?.commercialGroupId],
        deliveryDate,
        offerType: productOfferOpened?.offerType,
        packagingByLayer: packingSelected?.packagingByLayer,
        customerId: customer?.id,
      };
      if (productOfferOpened?.productCategory !== '01') {
        Object.assign(filterByOfferComposition, {
          dimension: productOfferOpened?.dimension,
          groupId: productOfferOpened?.groupId,
        });
      }

      executeGet(filterByOfferComposition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOfferOpened, modalIsVisible]);

  useEffect(() => {
    const arrayAux: PostBodyProductInCart[] = [];
    selectedsProducts?.forEach((product) =>
      arrayAux.push({ ...product, billingDate, deliveryDate }),
    );
    setSelectedsProducts(arrayAux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, billingDate, deliveryDate]);

  const [executeGet, { isLoading, data: productOffersWithBox }] =
    useGetOffersCompositionMutation();

  const clickButtonPrimaryFirstStep = () => {
    setCurrentStep(1);
  };

  const clickButtonPrimarySecondStep = async () => {
    if (!billingDate || !deliveryDate) {
      setMessageModalInformation(
        'É necessário que selecione uma data para entrega e faturamento.',
      );
      setModalInformationQuantityIsVisible(true);
      return;
    }

    const messages = ['Para adicionar ao carrinho é necessário informar.'];

    const isQuantityInvalid = selectedsProducts.some(
      (product) => !product.quantity,
    );
    const isPackingIdInvalid = selectedsProducts.some(
      (product) => !product.packingId,
    );

    const quantityTotal = selectedsProducts.reduce(
      (value, key) => value + key.quantity,
      0,
    );

    const errorMessages: string[] = [];

    if (isPackingIdInvalid) {
      errorMessages.push('o tipo de embalagem');
    }

    if (isQuantityInvalid) {
      errorMessages.push('a quantidade');
    }

    messages.push(errorMessages.join(' e '));
    messages.push(' de todos os produtos.');

    setMessageModalInformation(messages.join(' '));

    if (isQuantityInvalid || isPackingIdInvalid) {
      setModalInformationQuantityIsVisible(true);
      return;
    }

    const minimunComposition =
      productOfferOpened?.minimunQuantityCutProductComposition
        ? productOfferOpened.minimunQuantityCutProductComposition
        : 0;

    const isTotalQuantityInvalid =
      quantityTotal % packingSelected.packagingByLayer !== 0;

    const isTotalQuantityInvalidbyComposition =
      quantityTotal % minimunComposition !== 0;

    if (
      productOfferOpened?.isCompositionEnabled &&
      productOfferOpened?.productCategoryId === 1
    ) {
      if (isTotalQuantityInvalidbyComposition) {
        setMessageModalInformation(
          `A quantidade minima deve ser ${minimunComposition} embalagens. A quantidade total de embalagens informadas foi ${quantityTotal}, não permitindo completar 100% da(s) camada(s).`,
        );
        setModalInformationQuantityIsVisible(true);
        return;
      }
    }
    if (isTotalQuantityInvalid && productOfferOpened?.productCategoryId !== 1) {
      setMessageModalInformation(
        `A quantidade minima deve ser ${packingSelected.packagingByLayer} embalagens. A quantidade total de embalagens informadas foi ${quantityTotal}, não permitindo completar 100% da(s) camada(s).`,
      );
      setModalInformationQuantityIsVisible(true);
      return;
    }
    try {
      const results: any = await getPostProductInCart({
        customerId,
        cartItems: selectedsProducts,
      });
      if (results?.data) {
        showAlertSuccess(dispatch, 'Produto inserido ao carrinho sucesso!');
        setModalIsVisible();
        setCurrentStep(0);
        return;
      }
      throw new Error(results.error.data || results);
    } catch (err: Error | any) {
      showAlertError(
        dispatch,
        generateErrorMessage(
          err,
          'Ocorreu um erro ao inserir o produto no carrinho.',
        ),
      );
    }
  };

  const clickButtonPrimary = async () => {
    if (offerId && currentStep === 0) {
      clickButtonPrimaryFirstStep();
    } else {
      clickButtonPrimarySecondStep();
    }
  };

  const clickButtonCancel = () => {
    setModalConfirmationIsVisible(false);
  };

  const clickButtonOk = () => {
    setModalConfirmationIsVisible(false);
    setSelectedsProducts([]);
    setTimeout(() => {
      setModalIsVisible();
    }, 0);
  };

  const clickButtonOkModalInformationQuantity = () => {
    setModalInformationQuantityIsVisible(false);
  };

  const clickButtonSecondary = () => {
    if (offerId && currentStep === 1) {
      setCurrentStep(0);
    } else if (selectedsProducts?.length) {
      setModalConfirmationIsVisible(true);
    } else {
      setModalIsVisible();
    }
  };

  useEffect(() => {
    const idSelecteds = selectedsProducts.map(
      (selectedProduct) => selectedProduct.volOfferId,
    );
    const productOffers = productOffersWithBox?.offers;
    const itemsSelecteds = productOffers?.filter((product) =>
      idSelecteds.includes(product.offerId),
    );
    if (itemsSelecteds?.length) {
      itemsSelecteds?.forEach((itemSelected) => {
        const timeDelivery = itemSelected.siteDeliveryPatterns.find(
          (deliveryPatttern) =>
            deliveryPatttern.deliveryDayNumber ===
            moment(deliveryDate).weekday(),
        );

        if (previousDeliveryDate) {
          setPreviousDeliveryDate(
            moment(
              `${moment(deliveryDate).format('YYYY/MM/DD')} ${
                timeDelivery?.deliveryTime
              }`,
            ),
          );
          setLabelDeliveryDate(timeDelivery?.deliveryDayName ?? '');
          setLabelTimeDeliveryDate(timeDelivery?.deliveryTime ?? '');
          return;
        }
        if (previousDeliveryDate === undefined) {
          setPreviousDeliveryDate(
            moment(
              `${moment(deliveryDate).format('YYYY/MM/DD')} ${
                timeDelivery?.deliveryTime
              }`,
            ),
          );
          setLabelDeliveryDate(timeDelivery?.deliveryDayName ?? '');
          setLabelTimeDeliveryDate(timeDelivery?.deliveryTime ?? '');
        }
      });
      return;
    }
    setPreviousDeliveryDate(undefined);
    setLabelDeliveryDate('');
    setLabelTimeDeliveryDate('');
  }, [deliveryDate, selectedsProducts, productOffersWithBox]);

  const contentFirstStep = (
    <div>
      {productOffersWithBox?.offers &&
      productOffersWithBox?.offers.length > 0 ? (
        productOffersWithBox?.offers.map((productOffer: ProductOffer) => (
          <ProductCardListComposition
            // selectedOfferId={offerId}
            returnFistStep={setCurrentStep}
            key={`${productOffer.offerId}-product-step-1`}
            productOffer={productOffer}
            step="first-step"
            selectedsProducts={selectedsProducts}
            setSelectedsProducts={setSelectedsProducts}
          />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
  const contentSecondStep = (
    <div>
      {productOffersWithBox && productOffersWithBox?.offers.length > 0 ? (
        productOffersWithBox?.offers.map(
          (productOffer: ProductOffer) =>
            selectedsProducts.find(
              (product) =>
                product.volOfferId === productOffer.offerId ||
                product.lkpOfferId === productOffer.offerId,
            ) && (
              <ProductCardListComposition
                returnFistStep={setCurrentStep}
                key={`${productOffer.offerId}-product-step-2`}
                step="second-step"
                productOffer={productOffer}
                selectedsProducts={selectedsProducts}
                setSelectedsProducts={setSelectedsProducts}
              />
            ),
        )
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );

  const steps = [
    {
      title: 'Selecionar produtos',
      content: contentFirstStep,
    },
    {
      title: 'Quantificar produtos',
      content: contentSecondStep,
    },
  ];

  const handleCloseComposition = () => {
    setSelectedsProducts([]);
    setModalIsVisible();
  };

  return (
    <S.ModalCreateComposition
      visible={modalIsVisible}
      closable={false}
      centered
      keyboard={false}
      width="110em"
      onCancel={handleCloseComposition}
      footer={
        <S.ModalFooterCreateComposition>
          <S.ModalFooterInformations>
            <S.ModalFooterInformationText>
              <p>Previsão de entrega na CVH:</p>
              {previousDeliveryDate ? (
                <span>{`${labelDeliveryDate}, ${previousDeliveryDate?.format(
                  'DD/MM/YYYY',
                )} - ${labelTimeDeliveryDate.substring(0, 5)}h`}</span>
              ) : (
                <span>-</span>
              )}
            </S.ModalFooterInformationText>
            <S.ModalFooterInformationText>
              <p>Quantidade de itens</p>
              <span>
                {selectedsProducts?.length}/
                {productOffersWithBox?.offers.length}
              </span>
            </S.ModalFooterInformationText>
          </S.ModalFooterInformations>
          <S.ModalFooterActions>
            <Button style={{ marginBottom: 8 }} onClick={clickButtonSecondary}>
              {currentStep === 0 ? 'Cancelar composição' : 'Voltar '}
            </Button>
            {(!isProducerAuthenticated || currentStep === 0) && (
              <Button
                disabled={!selectedsProducts || selectedsProducts?.length <= 0}
                type="primary"
                style={{ marginLeft: 16, marginBottom: 8 }}
                onClick={clickButtonPrimary}
              >
                {currentStep === 0 ? 'Avançar' : 'Adicionar ao carrinho'}
              </Button>
            )}
          </S.ModalFooterActions>
        </S.ModalFooterCreateComposition>
      }
    >
      <S.ModalHeaderCreateComposition>
        <span>Criar composição ({productOfferOpened?.siteName})</span>
        <p>
          Compra mínima:{' '}
          {productOfferOpened?.productCategoryId !== 1
            ? packingSelected.packagingByLayer
            : productOfferOpened?.minimunQuantityCutProductComposition ||
              0}{' '}
          embalagens
        </p>
      </S.ModalHeaderCreateComposition>
      <S.ModalBodyCreateComposition>
        <Steps
          type="navigation"
          current={currentStep}
          className="site-navigation-steps"
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="cards">
          <div className="steps-content">{steps[currentStep].content}</div>
        </div>
      </S.ModalBodyCreateComposition>
      <ModalConfirmation
        modalIsVisible={modalConfirmationIsVisible}
        textTitle="Cancelar composição"
        textDescription="Tem certeza que deseja cancelar a composição? Todos os itens adicionados serão removidos."
        textButtonOk="Cancelar composição"
        textButtonCancel="Fechar"
        actionButtonOk={clickButtonOk}
        actionButtonCancel={clickButtonCancel}
      />
      <ModalConfirmation
        modalIsVisible={modalInformationQuantityIsVisible}
        textTitle="Atenção"
        textDescription={messageModalInformation}
        textButtonOk="Ok"
        actionButtonOk={clickButtonOkModalInformationQuantity}
      />
      {(isLoading || isLoadingPostProduct) && (
        <S.Overlay>
          <S.Loader spinning={isLoading || isLoadingPostProduct} size="large" />
        </S.Overlay>
      )}
    </S.ModalCreateComposition>
  );
}
