import { useSelector } from 'react-redux';
import * as BuyIntentionSelectors from 'store/state/buyIntention/buyIntenteionSelectors';
import AddBuyIntentionTable from '../List/AddBuyIntentionTable';

export default function Step02Resume() {
  const tableData = useSelector(BuyIntentionSelectors.getOverviewEdit);
  return (
    <div key="1">
      <AddBuyIntentionTable tableData={tableData} />
    </div>
  );
}
