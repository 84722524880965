import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductOfferFilterParam, Customer } from 'model/types';
import { getOrderByProductList, getTermProductList } from 'services/config';

export type OfferFiltersState = {
  name?: string;
  deliveryDate?: string;
  businessDate?: string;
  categories?: number[];
  offerTypes?: string[];
  productsIds?: number[];
  commercialGroupIds?: number[];
  qualities?: string[];
  colors?: string[];
  heights?: string[];
  dimensions?: string[];
  producerIds?: number[];
  siteId?: number[];
  clearProductList?: boolean;
  clearFilter?: boolean;
  orderBy?: string;
  filterByHeader: {
    categories?: number[];
  };
  showPinnedFilter: boolean;
  typeMenu: number;
  customer: Customer | undefined;
};

export const initialState: OfferFiltersState = {
  name: getTermProductList() !== '' ? getTermProductList() : undefined,
  deliveryDate: undefined,
  businessDate: undefined,
  categories: undefined,
  offerTypes: undefined,
  productsIds: undefined,
  commercialGroupIds: undefined,
  qualities: undefined,
  colors: undefined,
  heights: undefined,
  dimensions: undefined,
  producerIds: undefined,
  siteId: undefined,
  filterByHeader: {
    categories: undefined,
  },
  orderBy: getOrderByProductList() !== '' ? getOrderByProductList() : 'AZ',
  showPinnedFilter: false,
  typeMenu: 2,
  customer: undefined
};

const offerFiltersSlice = createSlice({
  name: 'offerFilters',
  initialState,
  reducers: {
    clear(state) {
      Object.assign(state, {
        ...initialState,
        showPinnedFilter: state.showPinnedFilter,
      });
    },
    filter(state, action: PayloadAction<ProductOfferFilterParam>) {
      Object.assign(state, {
        ...initialState,
        showPinnedFilter: state.showPinnedFilter,
      });
      Object.assign(state, { ...action.payload });
    },
    setClearProductList(state, action: PayloadAction<boolean>) {
      state.clearProductList = action.payload;
    },
    setClearFilter(state, action: PayloadAction<boolean>) {
      state.clearFilter = action.payload;
    },
    setFilterByHeader(state, action: PayloadAction<{ categories?: number[] }>) {
      state.filterByHeader = action.payload;
    },
    setShowPinnedFilter(state, action: PayloadAction<boolean>) {
      state.showPinnedFilter = action.payload;
    },
    setTypeMenu(state, action: PayloadAction<number>) {
      state.typeMenu = action.payload;
    },
    setCustomer: (
      state,
      action: PayloadAction<Customer | undefined>,
    ) => {
      state.customer = action.payload;
    },
  },
  extraReducers: {},
});

export const { actions, reducer } = offerFiltersSlice;
