import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export type Size = 'small' | 'medium' | 'large';

interface ContainerProps {
  size: Size;
}

const containerMap: Record<Size, FlattenSimpleInterpolation> = {
  small: css`
    font-size: 8px;
    > span + span {
      margin-left: 8px;
    }
  `,
  medium: css`
    font-size: 10px;
    display: flex;
    gap: 0.5em;
    flex-flow: row wrap;
    > span:first-child {
      order: 2;
    }
  `,
  large: css`
    font-size: 11px;
    display: flex;
    gap: 0.5em;
    flex-flow: row wrap;
  `,
};

export const Container = styled.span<ContainerProps>`
  color: #8c8c8c;
  ${(props) => containerMap[props.size]};
`;
