import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const InfoValue = styled.div`
  color: #353839;
`;

export const Label = styled.p`
  font-size: 8px;
  margin-bottom: 2px;
  color: inherit;
  line-height: 8px;
  margin-block: 0;
`;

export const Value = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: inherit;
  line-height: 12px;
`;
