import React, { useEffect, useMemo, useState } from 'react';
import Screen from 'components/BusinessLogic/ScreenTemplates/LoggedScreen';
import Carousel from 'components/UI/Carousel';
import { useGetBannersQuery } from 'services/ecommerceApi';
import ModalConfirmation from 'components/Modals/Confirmation';
import { Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from 'store/state/slices';
import { sessionSelectors } from 'store/state/selectors';
import { useAuth } from 'hooks/useAuth';
import * as S from './styles';
import LastVisitSection from './LastVisitSection';
import MostSearchedSection from './MostSearchedSection';
import MostBuyByCategory from './MostBuyByCategory';

export default function Home() {
  const dispatch = useDispatch();
  const [bannerDisplay, setBannerDisplay] = useState<string | undefined>('');
  const [modalInformationFailedLogin, setModalInformationFailedLogin] =
    useState<boolean>(false);
  const failedLogin = useSelector(sessionSelectors.getFailedLogin);
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);
  const { data: banners } = useGetBannersQuery(producerUrl) ?? {};
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (banners && banners?.find((banner) => banner.bannerType === 2)) {
      setBannerDisplay(
        banners?.find((banner) => banner.bannerType === 2)?.imageUrl,
      );
    }
  }, [banners]);

  useEffect(() => {
    if (failedLogin) {
      setModalInformationFailedLogin(true);
    }
  }, [failedLogin]);

  const clickButtonOkModalInformationFailedLogin = () => {
    setModalInformationFailedLogin(false);
    dispatch(sessionActions.setFailedLogin(false));
  };

  const showBanner = useMemo(() => {
    if (bannerDisplay) {
      return (
        <div className="image-static">
          <Image
            preview={false}
            width="100%"
            src={bannerDisplay}
            alt="Banner estatico"
          />
        </div>
      );
    }
    return null;
  }, [bannerDisplay]);

  return (
    <Screen
      content={
        <S.Root>
          <Carousel />
          {isAuthenticated && (
            <>
              <LastVisitSection />
              {showBanner}
              <MostSearchedSection />
            </>
          )}
          {!isAuthenticated && (
            <>
              <MostBuyByCategory />
              {showBanner}
              <MostSearchedSection />
            </>
          )}

          <ModalConfirmation
            widthModal={600}
            modalIsVisible={modalInformationFailedLogin}
            textTitle="Aviso"
            textDescription="A sua visão ainda está construção. Por favor aguarde, logo teremos um visão especialmente para você!"
            textButtonOk="Ok"
            actionButtonOk={clickButtonOkModalInformationFailedLogin}
          />
        </S.Root>
      }
    />
  );
}
