import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Packing from 'model/Packing';
import ProductOffer from 'model/ProductOffer';
import { PostProductInCart, SummaryCart } from 'model/types';

import { PackingTypeEnum } from 'enums/packingType';

import {
  cartSelectors,
  sessionSelectors,
  buyIntenteionSelectors,
  productsSelectors,
} from 'store/state/selectors';
import {
  cartActions,
  buyIntentionActions,
  productsActions,
} from 'store/state/slices';

import {
  showAlertError,
  showAlertSuccess,
  showAlertWarning,
} from 'services/alertService';
import {
  useGetDeleteCartMutation,
  useGetPostCheckoutMutation,
  useGetPostProductInCartMutation,
} from 'services/ecommerceApi';

import ModalCreateComposition from 'components/Modals/CreateComposition';
import ModalExpiredItemsInCart from 'components/Modals/ExpiredItemsInCart';
import ModalXped from 'components/Modals/Xped';
import { PackingTypeSale } from 'enums/packingTypeSale';

import { ModeType } from 'screens/Products/utils/enums/ModeType.enum';
import { offerTypeMap } from 'enums/offerTypeMap';
import { ERROR } from 'screens/Products/utils/consts/Errors.const';

import ModalDeliveryDate from '../ModalDeliveryDate';

import Grid from './Modes/Grid';
import List from './Modes/List';
import MinifyList from './Modes/MinifyList';
import BuyIntentionList from './Modes/BuyIntentionOfferList';
import MobileForIC from './Modes/List/MobileForIC';

type ModalDeliveryDate = 'submit' | 'composition';
interface Props {
  product: ProductOffer;
  mode?: ModeType;
  openImage(product: ProductOffer): void;
  offerInserted(offerId: number, offerType: offerTypeMap): void;
  runInMobile: boolean;
  isBuyIntention?: boolean;
  isBuyIntentionMobile?: boolean;
}

function getPayloadToAddInCart(
  product: ProductOffer,
  quantityPacking: number,
  selectedTypeSale: PackingTypeSale,
  packingId: number,
  customerId: number,
  date: { deliveryDate: string; billingDate: string },
) {
  return {
    customerId,
    cartItems: [
      {
        packingId,
        volOfferId:
          product.offerType === 0 || product.offerType === 3
            ? product.offerId
            : null,
        lkpOfferId:
          product.offerType === 1 || product.offerType === 2
            ? product.offerId
            : null,
        volumeType: selectedTypeSale,
        offerType: offerTypeMap[product.offerType],
        quantity: quantityPacking,
        productColorId: product.productColorId,
        voucherId: product.voucherId,
        productId: product.productId,
        productQualityId: product.productQualityId,
        siteId: product.siteId,
        producerId: product.producerId,
        billingDate:
          product.offerType === 1 || product.offerType === 2
            ? product.lkpAuctionDate
            : date.billingDate,
        deliveryDate:
          product.offerType === 1 || product.offerType === 2
            ? product.lkpAuctionDate
            : date.deliveryDate,
        lkpAuctionDate:
          product.offerType === 1 || product.offerType === 2
            ? product.lkpAuctionDate
            : undefined,
        shippingFee: product.shippingFee,
      },
    ],
  } as PostProductInCart;
}

function ProductItem({
  product,
  mode,
  runInMobile,
  openImage,
  offerInserted,
  isBuyIntention,
  isBuyIntentionMobile
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [postCheckout] = useGetPostCheckoutMutation();
  const [getPostProductInCart] = useGetPostProductInCartMutation();
  const [getDeleteCart] = useGetDeleteCartMutation();

  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const businessDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const totalLkpItems = useSelector(cartSelectors.getTotalLkpItems);
  const totalVolItems = useSelector(cartSelectors.getTotalVolItems);
  const lkpOfferId = useSelector(cartSelectors.getLkpOfferId);
  const volOfferId = useSelector(cartSelectors.getVolOfferId);
  const { customers } = useSelector(sessionSelectors.getRoot);
  const idProductAdded = useSelector(buyIntenteionSelectors.getIdProductAdded);
  const subPriceAdded = useSelector(buyIntenteionSelectors.getSubPriceAdded);
  const offerId = useSelector(productsSelectors.getOfferId);

  const [addInCartWithDates, setAddInCartWithDate] =
    useState<{ quantityPacking: number; selectedTypeSale: PackingTypeSale }>();

  const submitXped = useRef<(xped: string) => void>(() => {
    // do nothing.
  });

  const [showModalComposition, setShowModalComposition] = useState(false);
  const [showModalDeliveryDate, setShowModalDeliveryDate] =
    useState<ModalDeliveryDate | null>(null);
  const [showModalExpiredItemsInCart, setShowModalExpiredItemsInCart] =
    useState(false);
  const [showModalXped, setShowModalXped] = useState(false);
  const [selectedTypeSale, setSelectedTypedSale] = useState<PackingTypeSale>(
    () => {
      if (product.packagingPrice) {
        return PackingTypeSale.Packing;
      }
      if (product.layerPrice) {
        return PackingTypeSale.Layer;
      }
      return PackingTypeSale.Trolley;
    },
  );
  const [packingSelected, setPackingSelected] = useState<Packing>(
    () =>
      product.packings.find(
        (packing) =>
          packing.type === PackingTypeEnum.RETORNAVEL || packing.code === '800',
      ) || product.packings[0],
  );

  const getLowPrice = useMemo(() => {
    const priceQuantities = [
      {
        price: product.packagingPrice,
        quantity: packingSelected.maxAvailablePackingsQuantity,
      },
      {
        price: product.layerPrice,
        quantity: packingSelected?.maxAvailableLayersQuantity,
      },
      {
        price: product.trolleyPrice,
        quantity: packingSelected.maxAvailableTrolleysQuantity,
      },
    ];

    const ordered = priceQuantities.sort((priceQuantity, nextPriceQuantity) =>
      priceQuantity.price < nextPriceQuantity.price ? -1 : 1,
    );

    const firstPrice = ordered.find(
      (orderedPrice) => orderedPrice.quantity > 0 && orderedPrice.price > 0,
    );

    return firstPrice ? firstPrice.price : ordered[0].price;
  }, [
    product.trolleyPrice,
    product.layerPrice,
    product.packagingPrice,
    packingSelected?.maxAvailableLayersQuantity,
    packingSelected.maxAvailablePackingsQuantity,
    packingSelected.maxAvailableTrolleysQuantity,
  ]);

  const Template = useMemo(() => {
    if (isBuyIntention && !isBuyIntentionMobile) {
      return BuyIntentionList;
    }
    if (isBuyIntention && isBuyIntentionMobile) {
      return MobileForIC;
    }
    if (mode === ModeType.Grid) {
      return Grid;
    }
    if (mode === ModeType.List) {
      return List;
    }

    return MinifyList;
  }, [mode]);

  const handleOnSelectPacking = useCallback(
    (id: number) => {
      const selected = product.packings.find((packing) => packing.id === id);
      if (selected) {
        setPackingSelected(selected);
      }
    },
    [product],
  );

  const insertProductInCartFetch = useCallback(
    async (payload: PostProductInCart) => {
      const result = (await getPostProductInCart(payload)) as {
        data?: SummaryCart;
        error?: any;
      };
      if (result?.data) {
        dispatch(cartActions.setSummaryCart(result.data));
        offerInserted(product.offerId, product.offerType);
        return result.data;
      }
      if (result?.error.data === ERROR.EXPIRED_ITEMS) {
        setShowModalExpiredItemsInCart(true);
      }
      throw result?.error?.data || result;
    },
    [
      dispatch,
      getPostProductInCart,
      offerInserted,
      product.offerId,
      product.offerType,
    ],
  );

  const handleClickAddInCart = useCallback(
    async (quantityPacking: number, selectedTypeSale: PackingTypeSale) => {
      if (
        (product.offerType === 0 || product.offerType === 3) &&
        (!deliveryDate || !businessDate)
      ) {
        setShowModalDeliveryDate('submit');
        setAddInCartWithDate({ quantityPacking, selectedTypeSale });
      } else {
        try {
          const payload = getPayloadToAddInCart(
            product,
            quantityPacking,
            selectedTypeSale,
            packingSelected.id,
            customerId,
            { deliveryDate, billingDate: businessDate },
          );
          await insertProductInCartFetch(payload);
          if (payload?.cartItems[0]?.volOfferId && totalVolItems <= 0) {
            showAlertWarning(
              dispatch,
              'Atenção! Após o tempo de expiração do carrinho ou do produto a compra será confirmada.',
              true,
              10000,
            );
          } else if (payload?.cartItems[0]?.lkpOfferId && totalLkpItems <= 0) {
            showAlertWarning(
              dispatch,
              'Atenção! Após a expiração do carrinho a compra será confirmada.',
              true,
              10000,
            );
          } else {
            showAlertSuccess(dispatch, 'Produto inserido ao carrinho sucesso!');
          }
        } catch (err: Error | any) {
          showAlertError(
            dispatch,
            typeof err === 'string'
              ? err
              : 'Ocorreu um erro ao inserir o produto no carrinho.',
          );
        }
      }
    },
    [
      product,
      deliveryDate,
      businessDate,
      packingSelected,
      customerId,
      insertProductInCartFetch,
      totalVolItems,
      totalLkpItems,
      dispatch,
    ],
  );

  const onCloseModalComposition = () => {
    setShowModalComposition(false);
  };

  const onCloseModalDeliveryDate = () => {
    setShowModalDeliveryDate(null);
  };

  const onCloseModalXped = () => {
    setShowModalXped(false);
  };

  const handleClickButtonGoToCart = () => {
    navigate('/veiling/shopping-cart');
    setShowModalExpiredItemsInCart(false);
  };

  const handleClickButtonDeleteCart = useCallback(() => {
    const isLkpOffer =
      product.offerType !== offerTypeMap.onSite &&
      product.offerType !== offerTypeMap.directed;
    const cartId = isLkpOffer ? lkpOfferId : volOfferId;
    getDeleteCart({ cartId });
    setShowModalExpiredItemsInCart(false);
  }, [getDeleteCart, lkpOfferId, product.offerType, volOfferId]);
  const handleClickShowModalComposition = useCallback(() => {
    if (!deliveryDate || !businessDate) {
      setShowModalDeliveryDate('composition');
      dispatch(productsActions.setOfferId(product.offerId));
      return;
    }
    setShowModalComposition(true);
  }, [deliveryDate, businessDate]);

  const handleSubmitModalDeliveryDate = useCallback(
    (type: ModalDeliveryDate) => {
      setShowModalDeliveryDate(null);
      if (type === 'composition') {
        setShowModalComposition(true);
        return;
      }
      if (type === 'submit' && addInCartWithDates) {
        const { quantityPacking, selectedTypeSale } = addInCartWithDates;
        handleClickAddInCart(quantityPacking, selectedTypeSale);
      }
    },
    [addInCartWithDates, handleClickAddInCart],
  );

  const handleSubmitModalXped = useCallback(
    (xpedValue: string | { xped: string }) => {
      const xped = typeof xpedValue === 'string' ? xpedValue : xpedValue.xped;
      setShowModalXped(false);

      submitXped.current(xped);
    },
    [],
  );

  const insertProductInCartAndBuy = useCallback(
    async (payload: PostProductInCart, xped?: string) => {
      try {
        const { lkpOfferId, volOfferId } = await insertProductInCartFetch(
          payload,
        );
        const result: any = await postCheckout({
          cartId: Number(lkpOfferId || volOfferId),
          xped,
          isVolOffer:
            payload.cartItems[0].offerType ===
              offerTypeMap[offerTypeMap.onSite] ||
            payload.cartItems[0].offerType ===
              offerTypeMap[offerTypeMap.directed],
          isLkpOffer:
            payload.cartItems[0].offerType !==
              offerTypeMap[offerTypeMap.onSite] &&
            payload.cartItems[0].offerType !==
              offerTypeMap[offerTypeMap.directed],
        });
        if (result?.error) {
          throw result?.error?.data || result;
        }
        showAlertSuccess(dispatch, 'Compra realizada com sucesso!');
        if (idProductAdded) {
          dispatch(
            buyIntentionActions.setPropertyValue({
              value: true,
              property: 'bought',
              id: idProductAdded,
            }),
          );
          dispatch(
            buyIntentionActions.setDeleteTotalPriceOffers({
              id: idProductAdded,
              value: subPriceAdded,
            }),
          );
          dispatch(buyIntentionActions.setIdProductAdded(undefined));
        }
        dispatch(buyIntentionActions.setIsLoading(false));
      } catch (error) {
        dispatch(buyIntentionActions.setIsLoading(false));
        showAlertError(
          dispatch,
          `Erro ao efetuar compra: ${String(error)}`,
          true,
          10000,
        );
      }
    },
    [dispatch, insertProductInCartFetch, postCheckout],
  );

  const handleClickBuy = useCallback(
    async (quantityPacking: number, selectedTypeSale: PackingTypeSale) => {
      const customerSelected = customers.find(
        (customer) => customer.id === customerId,
      );
      const payload = getPayloadToAddInCart(
        product,
        quantityPacking,
        selectedTypeSale,
        packingSelected.id,
        customerId,
        { deliveryDate, billingDate: businessDate },
      );
      const fn = (xped = '') =>
        insertProductInCartAndBuy({
          ...payload,
          buyNow: true,
          xped,
        });
      if (customerSelected?.hasXmlOrder) {
        setShowModalXped(true);
        submitXped.current = fn;
      } else {
        await fn();
      }
    },
    [
      customers,
      product,
      packingSelected.id,
      customerId,
      deliveryDate,
      businessDate,
      insertProductInCartAndBuy,
    ],
  );

  const handleOpenImage = useCallback(() => {
    openImage(product);
  }, [product, openImage]);

  const handleTypeSaleChange = useCallback(
    (packingTypeSale: PackingTypeSale) => {
      setSelectedTypedSale(packingTypeSale);
    },
    [],
  );

  useEffect(() => {
    if (deliveryDate && businessDate && product.offerId === offerId) {
      setShowModalComposition(true);
      dispatch(productsActions.setOfferId(undefined));
    }
  }, []);

  return (
    <>
      <Template
        product={product}
        openImage={handleOpenImage}
        packingSelected={packingSelected}
        getLowPrice={getLowPrice}
        handleOnSelectPacking={handleOnSelectPacking}
        isAuthenticated={isAuthenticated}
        isProducerAuthenticated={isProducerAuthenticated}
        showModalComposition={handleClickShowModalComposition}
        addInCart={handleClickAddInCart}
        buy={handleClickBuy}
        runInMobile={runInMobile}
        producerUrl={producerUrl}
        selectedTypeSale={selectedTypeSale}
        onChangePackingTypeSale={handleTypeSaleChange}
      />

      {showModalXped && (
        <ModalXped
          onCancel={onCloseModalXped}
          visible={showModalXped}
          onSubmit={handleSubmitModalXped}
        />
      )}

      {showModalExpiredItemsInCart && (
        <ModalExpiredItemsInCart
          modalIsVisible={showModalExpiredItemsInCart}
          actionButtonCancel={handleClickButtonGoToCart}
          actionButtonOk={handleClickButtonDeleteCart}
        />
      )}

      {showModalDeliveryDate && (
        <ModalDeliveryDate
          startDate={product.startDate}
          endDate={product.endDate}
          siteDeliveryPatterns={product.siteDeliveryPatterns}
          onCancel={onCloseModalDeliveryDate}
          onSubmit={() => handleSubmitModalDeliveryDate(showModalDeliveryDate)}
          producerUnavailableDates={product.unavailableDates}
        />
      )}

      {showModalComposition && (
        <ModalCreateComposition
          productOfferOpened={product}
          offerId={product.offerId}
          setModalIsVisible={onCloseModalComposition}
          modalIsVisible={showModalComposition}
          packingSelected={packingSelected}
        />
      )}
    </>
  );
}

export default memo(ProductItem);
