import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalDefault = styled(Modal)`
  .ant-modal-close {
    color: #fff;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const ModalHeader = styled.div`
  background-color: #f78e1e;
  padding: 24px;
  > span {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
  .ant-col {
    > span {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }
    button {
      font-size: 14px;
      max-width: 98px;
      background-color: #f78e1e;
      border-color: #fff;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #f78e1e;
      }
    }
  }
`;

export const ModalBody = styled.div`
  padding: 24px;
`;

export const ModalFooter = styled.div`
  padding: 24px;
  border-top: 1px solid #d9d9d9;
`;