import { memo, useEffect, useState } from 'react';
import DefaultLoader from 'components/Wired/DefaultLoader';
import {
  Routes,
  Route,
  useLocation,
  useNavigate
} from 'react-router-dom';
import Home from 'screens/Home';
import Landing from 'screens/Landing';
import { useGetRefreshTokenMutation } from 'services/identityApi';
import {
  useGetLinkSiteByUrlMutation,
  useGetLinkSiteMutation,
  useGetUserWithCustomersQuery,
} from 'services/ecommerceApi';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'store/state/selectors';
import DirectedOffers from 'screens/DirectedOffers';
import MyOffers from 'screens/MyOffers/index';
import Offer from 'screens/Offer';
import Products from 'screens/Products';
import ShoppingLkpByQRCode from 'screens/ShoppingLkpByQRCode';
import ShoppingCart from 'screens/ShoppingCart';
import MyOrders from 'screens/MyOrders';
import MyOrderIntentions from 'screens/MyOrderIntentions';
import { useAppDispatch } from 'store/hooks';
import {
  getAuthorizationToken,
  getBillingDate,
  getDeliveryDate,
  getCustomerIdSelected,
  setAuthorizationToken,
  getScope,
  getRefreshTokenService,
  setExpireIn,
  setRefreshToken,
  setScope,
  setTokenType,
  setPageState,
  getPageState,
  getFilterState,
  getTermProductList,
  setLogout,
  removeItensProductList,
  removeItensShoppingCart,
  getOfferTypeProductList,
  removeItensMyOffers,
  removeItensMyOrders,
  setProducerUrl,
  parseJwt,
  removeItensDirectedOffers,
} from 'services/config';
import {
  errorActions,
  offerFiltersActions,
  sessionActions,
} from 'store/state/slices';
import { RedirectProvider } from 'hooks/redirect';
import { verifyIsProducer } from '../services/config';
import Logout from './Logout';
import PrivateRoute from './PrivateRoute';
import CheckIndisponibility from '@core/application/useCases/indisponibility/checkIndisponibility';

const UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST';

function Router() {
  const token = getAuthorizationToken();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getLinkSite] = useGetLinkSiteMutation();
  const [refetchCustomers, setRefetchCustomers] = useState<boolean>(false);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const refreshToken = useSelector(sessionSelectors.getRefreshToken);
  const [getRefreshToken] = useGetRefreshTokenMutation();
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);

  const [getLinkSiteByUrl] = useGetLinkSiteByUrlMutation();

  const getRefreshTokenApi = async () => {
    const refreshToken = getRefreshTokenService();
    const scope = getScope();
    const result: any = await getRefreshToken({
      refresh_token: refreshToken,
      client_id: 'veiling-online',
      client_secret: '9be425c1-cac1-46ba-a89b-2b564f9ad474',
      scope: scope.replaceAll(',', ' '),
      grant_type: 'refresh_token',
    });
    dispatch(sessionActions.setRefreshToken(false));
    if (result?.data) {
      setAuthorizationToken(result?.data.access_token);
      setRefreshToken(result?.data.refresh_token);
      setScope(result?.data.scope.toString());
      setExpireIn(result?.data.expires_in);
      setTokenType(result?.data.token_type);
      setRefetchCustomers(true);
    }
  };

  useEffect(() => {
    if (refreshToken) {
      getRefreshTokenApi();
    }
  }, [refreshToken]);

  useEffect(() => {
    if (token && token.length > 0 && token[0] !== '') {
      dispatch(sessionActions.loginSuccess());
    } else {
      dispatch(sessionActions.loginError());
    }
  }, [dispatch, token]);

  const {
    data: user,
    isSuccess,
    isError,
    isLoading,
    refetch
  } = useGetUserWithCustomersQuery(undefined, {
    skip: !isAuthenticated || !token || token?.length <= 0 || token[0] === '',
  });

  useEffect(() => {
    if (refetchCustomers) {
      refetch();
      setRefetchCustomers(false);
    }
  }, [refetchCustomers]);

  useEffect(() => {
    if (isSuccess && user) {
      // if (user.customers.length <= 0) { //
      //   dispatch(errorActions.throw(UNAUTHORIZED_REQUEST));
      // };
      //
      dispatch(sessionActions.update({ user }));
      const deliveryDates = {
        deliveryDate: getDeliveryDate(),
        billingDate: getBillingDate(),
      };
      const selectedCustomer = getCustomerIdSelected();

      dispatch(sessionActions.setDates(deliveryDates));

      if (selectedCustomer) {
        dispatch(sessionActions.setSelectedCustomer(+selectedCustomer));
      }

      if (isAuthenticated) {
        const pageState = getPageState();
        const filterState = getFilterState();

        if (pageState) {
          navigate(pageState);
        }

        const offerTypes = getOfferTypeProductList();
        const name = getTermProductList();

        if (filterState) {
          if (offerTypes?.length) {
            dispatch(
              offerFiltersActions.filter({
                ...getFilterState(),
                name,
                offerTypes,
              }),
            );
          } else {
            dispatch(
              offerFiltersActions.filter({
                ...getFilterState(),
                name,
              }),
            );
          }
        }
      }
    } else if (isError && !isSuccess && !isLoading) {
      navigate(producerUrl || 'veiling');
    }
  }, [isSuccess, user, isLoading]);

  useEffect(() => {
    dispatch(errorActions.clear());
    if (
      !location.pathname.includes('logout') &&
      !location.pathname.includes('auth') &&
      location.pathname !== '/'
    ) {
      setPageState(location.pathname);
    }
    if (
      !location.pathname.includes('/products') &&
      !getPageState().includes('/products')
    ) {
      removeItensProductList();
    }
    if (location.pathname.includes('/logout')) {
      setLogout();
    }
    if (
      !location.pathname.includes('/shopping-cart') &&
      !getPageState().includes('/shopping-cart')
    ) {
      removeItensShoppingCart();
    }
    if (
      !location.pathname.includes('/my-offers') &&
      !getPageState().includes('/my-offers')
    ) {
      removeItensMyOffers();
    }
    if (
      !location.pathname.includes('/directed-offers') &&
      !getPageState().includes('/directed-offers')
    ) {
      removeItensDirectedOffers();
    }
    if (
      !location.pathname.includes('/my-orders') &&
      !getPageState().includes('/my-orders')
    ) {
      removeItensMyOrders();
    }
  }, [dispatch, location]);

  const getSiteLink = async (paramUrl: string, path: string) => {
    console.log(
      '--------------------------------------------------------------',
    );
    console.log('Entrou no Get site:');
    console.log('o param url: ', paramUrl);
    console.log('o path: ', path);

    const pathValidate = path.split('/');

    console.log('pathValidate', pathValidate);

    if (
      pathValidate.filter((pathValid) => pathValid)?.length <= 1 &&
      (!paramUrl || paramUrl === '/')
    ) {
      console.log('o param url esta vazio');
      dispatch(
        sessionActions.setProducerPage({
          producerUrl: 'veiling',
          sitesIds: [],
        }),
      );
      setProducerUrl('veiling');
      navigate('/veiling');
      return;
    }

    const paramSession = localStorage.getItem('producerUrl') ?? '';

    if (paramUrl === 'producerUndefined') {
      paramUrl = paramSession;
    }

    console.log('ParamUrl post producerUndefined', paramUrl);
    console.log('paramSession post producerUndefined', paramSession);
    console.log('producerUrl', producerUrl);

    if (!producerUrl && paramSession) {
      dispatch(
        sessionActions.setProducerPage({
          producerUrl: paramSession,
          sitesIds: [],
        }),
      );
    }

    if (
      paramUrl !== producerUrl &&
      verifyIsProducer(parseJwt(getAuthorizationToken()))
    ) {
      const resultProducerUrls: any = await getLinkSite();
      if (resultProducerUrls?.data) {
        dispatch(
          sessionActions.setProducerInformations({
            isProducerAuthenticated: true,
            urls: resultProducerUrls?.data,
          }),
        );
      } else {
        dispatch(
          sessionActions.setProducerInformations({
            isProducerAuthenticated: true,
            urls: [],
          }),
        );
      }
    }

    if (
      paramUrl &&
      paramUrl !== '/' &&
      paramUrl !== producerUrl &&
      !paramUrl.includes('veiling')
    ) {
      const result: any = await getLinkSiteByUrl(paramUrl);
      if (result?.data) {
        dispatch(
          sessionActions.setProducerPage({
            producerUrl: result?.data?.url,
            sitesIds: result?.data?.producerIds ?? [],
          }),
        );

        setProducerUrl(result?.data?.url);
        if (pathValidate.filter((pathValid) => pathValid)?.length > 1) {
          navigate(`${path.replace(paramUrl, result?.data?.url)}`);
        } else {
          navigate(`${result?.data?.url}/products`);
        }
      } else {
        dispatch(
          sessionActions.setProducerPage({
            producerUrl: 'veiling',
            sitesIds: [],
          }),
        );

        navigate('/veiling');
        setProducerUrl('veiling');
      }
    } else if (
      (!producerUrl && !paramSession) ||
      (!producerUrl.includes('veiling') && paramUrl.includes('veiling'))
    ) {
      dispatch(
        sessionActions.setProducerPage({
          producerUrl: 'veiling',
          sitesIds: [],
        }),
      );
      setProducerUrl('veiling');

      if (pathValidate.filter((pathValid) => pathValid)?.length > 1) {
        navigate(`${path}`);
      } else {
        navigate('/veiling');
      }
    }
  };

  useEffect(() => {
    let paramUrl = '';
    let path = '';

    path = location.pathname;
    console.log('path', path);
    const lastBarIndex = path.indexOf('/', 2);
    console.log('lastBarIndex', lastBarIndex);
    paramUrl = path.substring(1, lastBarIndex > 0 ? lastBarIndex : undefined);
    console.log('paramUrl', paramUrl);
    if (paramUrl !== 'oauth' && paramUrl !== 'logout') {
      getSiteLink(paramUrl, path);
    }
  }, [dispatch, location, navigate]);

  useEffect(() => {
    const checkIndisponibility = new CheckIndisponibility()
    checkIndisponibility.execute()
  }, [])
  
  return (
    <>
      <DefaultLoader />
      {(producerUrl ||
        location.pathname.includes('oauth') ||
        location.pathname.includes('logout')) && (
        <RedirectProvider>
          <Routes>
            <Route path="/:producer/" element={<Home />} />
            <Route path="/oauth" element={<Landing />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/:producer/products" element={<Products />} />

            <Route path="/:producer/products/:slug" element={<Products />} />
            
            <Route path="/:producer/products/:slug/:siteId" element={<Products />} />

            <Route
              path="/:producer/my-offers"
              element={<PrivateRoute element={<MyOffers />} />}
            />
            <Route
              path="/:producer/directed-offers"
              element={<PrivateRoute element={<DirectedOffers />} />}
            />
            <Route
              path="/:producer/offer/:offerId/:offerType"
              element={<PrivateRoute element={<Offer />} />}
            >
              <Route path=":packingSelectedId/:packingTypeSale" element={<PrivateRoute element={<Offer />} />} />
            </Route>
            <Route
              path="/:producer/shopping-cart"
              element={<PrivateRoute element={<ShoppingCart />} />}
            />
            <Route
              path="/:producer/read-qrcode"
              element={<PrivateRoute element={<ShoppingLkpByQRCode />} />}
            />
            <Route
              path="/:producer/my-orders"
              element={<PrivateRoute element={<MyOrders />} />}
            />
            <Route
              path="/:producer/my-order-intentions"
              element={<PrivateRoute element={<MyOrderIntentions />} />}
            />
          </Routes>
        </RedirectProvider>
      )}
    </>
  );
}

export default memo(Router);
