import { Button, Select } from 'antd';
import styled from 'styled-components';

interface ContentProps {
  PinnedFilter?: boolean;
}

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block !important;
      height: 150px;
    }
  }
`;

export const Divider = styled.div`
  background: #d9d9d9;
  height: 1px;
`;

export const TitleGroup = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  color: #fff;
  `;

export const DescriptionGroup = styled.p`
  margin-bottom: 4px;
  color: #fff;
`;

export const ComponentGroupText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
`;

export const Header = styled.div`

&.mobile {
  display: none;
}

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f78e1e;
  padding: 12px 0px;
  min-height: 104px;
`;

export const HeaderLine1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;
  
  @media (max-width: 768px) {
    justify-content: center;
    margin: 8px 0;
  }
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0px 4px;
  }
`;

export const FilterButton = styled(Button)`
  box-shadow: none;
`;

export const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0px 8px;
  }
`;

export const HeaderLine2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;

  @media (max-width: 768px) {
    justify-content: center;
    margin: 8px 0;
  }
`;

export const HeaderLine2LeftGroup = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0px 8px;
  }
`;

export const Sorter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px;
`;

export const OrderSelect = styled(Select)`
  width: 120px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

export const ShowTotalResults = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
`;

export const HeaderLine2RightGroup = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ICMSNotIncludedInValues = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
  > * {
    margin: 0px 4px;
  }
`;

export const ProductViewButton = styled(Button)`
margin-left: 8px;
  background-color: transparent;
  border: none;
  color: white;
  :focus {
    background-color: white;
    color: black;
  }
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${(props) => (props.PinnedFilter? '16px 40px 0px 0px' : '16px 40px 0px 40px')};
  background: #f5f5f5;

  @media (max-width: 768px) {
    align-items: center;

    .product-cards {
      margin: 24px 0 0 0;
    }
  }

  &.empty-data {
    align-items: center !important;
  }

  .ant-empty {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

`;

export const ProductCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ProductCardsList = styled.div`
  width: 100%;
`;

export const GroupFilter = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: -50px;
`;

export const SeparateFilters = styled.span`
  font-weight: 500;
  color: #fff;
  margin-left: 4px;
`;

export const SelectedFilters = styled.span`
  font-weight: 500;
  color: #fff;
  margin-left: 16px;
`;

export const ClearSelectedFilters = styled.span`
  font-weight: 500;
  color: #fff;
  margin-left: 8px;
  padding: 0;
  cursor: pointer;
`;
