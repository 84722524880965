import styled, { css } from 'styled-components';

import {
  Layout,
  Row,
  Col,
  Space,
  Button,
  Image,
  Input,
  Popover,
  Form,
  InputProps,
} from 'antd';

interface ProductCardProps {
  pinned: 1 | 0;
}

const cardHovered = css`
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

  .cardExpanded {
    display: block !important;
  }
`;

export const CardProduct = styled(Col).attrs({
  xs: 24,
  md: 12,
  lg: 8,
  xl: 6,
  xxl: 6,
})`
  display: flex;
  justify-content: center;
`;

export const ProductCard = styled(Layout)<ProductCardProps>`
  max-width: 310px;
  min-width: 310px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 32px 16px;
  position: relative;

  ${(props) =>
    props.pinned
      ? cardHovered
      : css`
          :hover,
          &.hover {
            ${cardHovered}
          }
        `};
`;

export const FormStyled = styled(Form)``;

export const ComponentInfoNew = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #353839;
  color: #fff;
  z-index: 2;
  border-radius: 16px;
  height: 22px;
  width: 50px;

  top: -11px;
  right: -8px;

  &.voucher-active {
    top: -18px;
    left: 0px;
  }
`;

export const ComponentInfoVoucher = styled.span`
  position: absolute;
  top: -28px;
  z-index: 1;
  padding: 2px;
  right: 0;
  text-align: center;
  background-color: #f78e1e;
  color: #fff;
  border-radius: 4px;
  word-break: break;
  width: 68px;
  height: 35px;

  span.valeu {
    font-weight: bold;
    width: 68px;
    font-size: 14px;
  }
`;

export const GroupTypeSaleProduct = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin-bottom: 8px;
  .button-type-sale {
    display: flex;
    width: 33%;
    flex-direction: column;
    height: 45px;
    padding: 0 16px;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #353839;
    }

    span.value > span {
      font-size: 14px;
      line-height: 18px;
      color: #f78e1e;
    }

    &.focus {
      background-color: #f78e1e;
      border-color: #f78e1e;

      span > span,
      span {
        color: #fff;
      }
    }
  }

  .no-border-left {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .no-border-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
`;

export const InformationVoucher = styled.p`
  margin-bottom: 0;
  font-size: 11px;
  color: #f78e1e;
  padding: 4px 16px 8px 16px;
`;

export const ProductCardExpanded = styled(Col).attrs({ xs: 24 })`
  box-shadow: 0px 9px 28px -28px rgba(0, 0, 0, 0.05),
    0px 17px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  top: 99%;
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 2;
  padding: 0 !important;

  &.not-display-none {
    display: block !important;
  }

  &.cardExpanded {
    display: none;
  }
`;

export const TextPopoverComposition = styled.span`
  max-width: 20px;
  word-break: break-word;
`;

export const PopoverComposition = styled(Popover)``;

export const ProductCardHeader = styled(Row).attrs({
  gutter: [8.0, 0],
  justify: 'space-between',
})`
  padding: 16px 16px 16px 16px;
`;

export const ProductOfferType = styled(Row)`
  padding: 4px 8px 4px 8px;
  background-color: #fbae15;
  border-radius: 4px;
  margin-left: 6px;
`;

export const Produtonositio = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  text-align: center;
`;

export const ProductImage = styled(Col).attrs({ xs: 24 })`
  flex: 0 0 auto !important;
`;

export const ProductImageRow = styled.div`
  margin-top: 12px;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
`;

export const ProductImageSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 12.0,
})`
  width: 100%;
  text-align: center;
`;
export const PhotoCarouselWide = styled(Image)`
  max-height: 100px !important;
`;

export const ImageCaption = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #8d8d8d;
  text-align: center;
`;

export const ProductCardFooter = styled(Col).attrs({ xs: 24 })`
  padding: 4px 16px 0px 16px !important;
  flex: 0 0 auto !important;
`;

export const ProductCardFooterSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 4.0,
})`
  width: 100%;
  padding: 8px 0;
  justify-content: flex-start;
`;
export const MainInfo = styled(Row).attrs({
  gutter: [4.0, 0],
  justify: 'space-between',
  align: 'bottom',
})``;

export const ProductName = styled.h1`
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #36393a;
  text-align: left;
`;

export const SecondaryInfo = styled(Row).attrs({
  justify: 'space-between',
})``;

export const ProductCodes = styled(Col).attrs({ md: 9, xs: 11 })``;

export const ProductCodesSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 4.0,
})`
  width: 100%;
  text-align: left;
`;
export const ProductCode = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #8d8d8d;
`;

export const BarCode = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #8d8d8d;
`;

export const ProducedBy = styled(Col).attrs({ md: 10, xs: 11 })``;

export const ProducedBySpacing = styled(Space).attrs({
  direction: 'vertical',
})`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
`;

export const ProducedByLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #8d8d8d;
  text-align: center;
  margin-bottom: 0px !important;
`;

export const ProducedByValue = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: right;
`;

export const ProductInfo = styled(Col).attrs({ xs: 24 })`
  padding-left: 16px !important;
  padding-bottom: 4px;
  padding-right: 16px;
`;

export const ProductNote = styled(Col).attrs({ xs: 24 })`
  padding-left: 0 !important;
  margin-bottom: 8px;
`;

export const ProductNoteLabel = styled.h1`
  font-weight: 400;
  font-size: 12px;
  color: #36393a;
  text-align: left;
  margin-bottom: 0 !important;
`;

export const ProductNoteValue = styled.h1`
  font-weight: bold;
  font-size: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductDetails = styled(Row).attrs({
  gutter: [8.0, 0],
  justify: 'space-between',
})``;

export const ProductDetailLeft = styled(Col).attrs({ md: 12, xs: 12 })``;

export const ProductQuality = styled(Row).attrs({
  gutter: [8.0, 0],
  //   justify: 'space-between',
  align: 'middle',
})``;

export const QualityIcon = styled(Row)`
  border-radius: 4px;
  margin-right: 8px;
`;

export const QualityInfo = styled(Col).attrs({ md: 15, xs: 12 })``;

export const QualityInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const QualityInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const QualityInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductSize = styled(Row).attrs({
  gutter: [8.0, 0],
  //   justify: 'space-between',
  align: 'middle',
})``;

export const SizeIcon = styled(Row)`
  border-radius: 4px;
  margin-right: 8px;
`;

export const SizeInfo = styled(Col).attrs({ md: 15, xs: 12 })``;

export const SizeInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const SizeInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const SizeInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductMultiple = styled(Row).attrs({
  gutter: [8.0, 8.0],
  //   justify: 'space-between',
  align: 'middle',
})``;

export const MultipleIcon = styled(Row)`
  border-radius: 4px;
  margin-right: 8px;
`;

export const MultipleInfo = styled(Col).attrs({ md: 15, xs: 12 })``;

export const MultipleInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const MultipleLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const MultipleValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductDetailRight = styled(Col).attrs({ md: 12, xs: 12 })``;

export const ProductColor = styled(Row).attrs({
  gutter: [8.0, 0],
  //   justify: 'space-between',
  align: 'middle',
})``;

export const ColorIcon = styled(Row)`
  border-radius: 4px;
  margin-right: 8px;
`;

export const ColorInfo = styled(Col).attrs({ md: 9, xs: 12 })``;

export const ColorInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const ColorInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ColorInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const ProductPackage = styled(Col).attrs({ md: 24, xs: 24 })`
  margin-top: 8px;
  max-height: 40px;
`;

export const PackageIcon = styled(Row)``;

export const PackageInfo = styled(Col).attrs({ md: 22, xs: 22 })``;

export const PackageInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  display: flex;
  flex-direction: column;

  .ant-select-selection-item {
    width: 100%;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px !important;
    color: #36393a;
    text-align: left;
    position: relative;
    margin-right: 8px;
  }

  .ant-select-arrow {
    user-select: none;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    top: 7px;
    line-height: 12px;
    color: #36393a;
    text-align: left;
  }
`;

export const PackageInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
  padding: 0 11px;
`;

export const PackageInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: left;
`;

export const PackageGroupSelect = styled.div`
  display: flex;
  justify-content: flex-start;

  span {
    margin-left: 10px;
  }
`;

export const ProductCardActions = styled(Col).attrs({ xs: 24 })`
  padding: 8px 16px 0 16px;
  background-color: #f6f6f6;
  min-height: fit-content;
  max-height: fit-content;
`;

export const PriceOptions = styled(Col).attrs({ xs: 24 })``;

export const RadioGroupCustom = styled(Row)``;

export const RadioCustomFirst = styled(Col).attrs({ md: 8, xs: 24 })`
  padding: 0px 16px 0px 16px;
  background-color: #f88f1f;
  > * {
    text-align: center;
  }
`;

export const RadioCustomFirstLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  color: #ffffff;
`;

export const RadioCustomFirstPrice = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
`;

export const RadioCustomCenter = styled(Col).attrs({ md: 8, xs: 24 })`
  padding: 0px 16px 0px 16px;
  background-color: #ffffff;
  > * {
    text-align: center;
  }
`;

export const RadioCustomCenterLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  color: #36393a;
`;

export const RadioCustomCenterPrice = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #f88f1f;
`;

export const RadioCustomLast = styled(Col).attrs({ md: 8, xs: 24 })`
  padding: 0 16px 0 16px;
  background-color: #ffffff;
  > * {
    text-align: center;
  }
`;

export const RadioCustomLastLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  color: #36393a;
`;

export const RadioCustomLastPrice = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #f88f1f;
`;

export const AmountInfo = styled(Row).attrs({
  gutter: [8.0, 0]
})`
  align-items: 'center';
  padding: 0 16px;
  margin-bottom: 8px;
`;

export const MinimumPurchase = styled(Row).attrs({
  gutter: [4.0, 0],
  justify: 'space-between',
  align: 'middle',
})`
  height: 100%;
`;

export const MinimumPurchaseLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: right;
`;

export const MinimunAmount = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8d8d8d;
  text-align: right;
`;

export const AvailableInfo = styled(Row).attrs({
  gutter: [4.0, 0],
  justify: 'space-between',
})``;

export const AvailableAmount = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: right;
`;

export const AvailableInfoLabel = styled.h1`
  margin-left: 3px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: right;
`;

export const AvailablePer = styled(Row).attrs({
  gutter: [16.0, 0],
  justify: 'center',
})``;

export const AvailablePackage = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8d8d8d;
  text-align: center;
`;

export const OrderActions = styled(Row).attrs({
  gutter: [8.0, 0],
  justify: 'center',
})`
  width: 100%;
`;

export const CardBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  .ant-input {
    width: 65px !important;
  }
  &.display-center {
    width: 31% !important;
  }
`;

export const PackagesDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
`;

export const PackagesInput = styled(Input)<InputProps>`
  width: 80px !important;
`;

export const ButtonsNetworkCol = styled(Col).attrs({ xs: 8 })`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const ButtonNetwork = styled(Button)`
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-right: 5px;
`;

export const WarningLabel = styled.p`
  padding: 0 8px;
`;

export const PinContainer = styled.div``;
