import styled from 'styled-components';

export const ProductCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > span > span, > span > div {
    margin: 32px 16px;
  }

  @media (max-width: 500px) {
    justify-content: center;
    > span {
      margin-top: 12px;
      flex-direction: column;
      > span, > div {
        margin-bottom: 16px;
        margin-top: 8px;
      }
  }
  }
`;

export const ProducerProductCard = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 300px;
  max-width: 300px;
  height: fit-content;
  margin: 16px 16px;
 
  &.hover-card-product {
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 2px 16px rgba(38, 38, 38, 0.2);
      border: 0px solid #cccccc;
      border-radius: 8px;
      background-color: white;
      transform-origin: 50% 50%;
      z-index: -1;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const ProductCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 24px;
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    > div {
      margin-bottom: 12px;
      align-items: center;
    }
  }
`;

export const ProductProducer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ProductImage = styled.img`
  align-self: center;
  margin-top: 8px;
  width: 144px;
  height: 144px;
`;

export const Product = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

export const By = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-right: 4px;
`;
export const ProducedBy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Producer = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
`;

export const ProductPrice = styled.div`
  align-self: flex-end;
  height: 21px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const CurrencySymbol = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #f78e1e;
  margin-right: 4px;
`;

export const MonetaryValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 100%;
  color: #f78e1e;
`;
