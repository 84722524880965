import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f78e1e;
`;

export const HeaderTitleText = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 20px 0px 16px 16px;
  margin-bottom: 0;
`;

export const Body = styled.div`
  padding: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;