import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { isMobile } from 'react-device-detect';

import { Button, Row, Tooltip } from 'antd';
import {
  BgColorsOutlined,
  ExpandOutlined,
  GiftOutlined,
  GroupOutlined,
  PushpinOutlined,
  TrophyOutlined,
} from '@ant-design/icons';

import Badge from 'components/UI/Badge';
import Quality from 'components/UI/Quality';

import ProductActions from 'screens/DirectedOffers/components/ProductActions';
import SelectPacking from 'components/BusinessLogic/Product/SelectPacking';
import { offerTypeColor } from 'screens/DirectedOffers/utils/consts/OfferType.const';

import ProductOfferPrice from 'components/BusinessLogic/Product/ProductOfferPrice';
import ShowDetails from 'components/UI/ShowDetails';
import { PackingTypeSale } from 'enums/packingTypeSale';

import InfoDirected from 'screens/Products/components/ProductItem/components/InfoDirected';
import InfoBarCode from '../../components/InfoBarCode';
import InfoDelivery from '../../components/InfoDelivery';
import InfoNew from '../../components/InfoNew';
import InfoObservation from '../../components/InfoObservation';
import InfoProductName from '../../components/InfoProductName';
import InfoDirectedTag from '../../components/InfoDirectedTag';
import ProductCardImage from '../../components/ProductCardImage';

import { ModesProps } from '../props';

import {
  CardProduct,
  ProductCard,
  ComponentInfoNew,
  ComponentInfoDirected,
  ProductCardHeader,
  PinContainer,
  ProductImage,
  ProductCardFooter,
  ProductCardFooterSpacing,
  MainInfo,
  SecondaryInfo,
  ProductCodes,
  ProductCodesSpacing,
  ProducedBy,
  ProducedBySpacing,
  ProducedByValue,
  ProductCardExpanded,
  ProductInfo,
  ProductNote,
  ProductNoteLabel,
  ProductNoteValue,
  ProductDetails,
  ProductDetailLeft,
  ProductQuality,
  QualityIcon,
  QualityInfo,
  QualityInfoSpacing,
  QualityInfoLabel,
  SizeIcon,
  SizeInfoSpacing,
  SizeInfoLabel,
  SizeInfoValue,
  SizeInfo,
  ProductSize,
  ProductDetailRight,
  ProductColor,
  ColorIcon,
  ColorInfo,
  ColorInfoSpacing,
  ColorInfoLabel,
  ColorInfoValue,
  ProductMultiple,
  MultipleIcon,
  MultipleInfo,
  MultipleInfoSpacing,
  MultipleLabel,
  MultipleValue,
  ProductPackage,
  PackageIcon,
  PackageInfo,
  PackageInfoSpacing,
  PackageInfoLabel,
  Actions,
  BadgeAndInfo,
} from './styles';

function Grid({
  product,
  packingSelected,
  isAuthenticated,
  isProducerAuthenticated,
  getLowPrice,
  addInCart,
  buy,
  showModalComposition,
  handleOnSelectPacking,
  openImage,
  producerUrl,
  selectedTypeSale,
  onChangePackingTypeSale,
}: ModesProps) {
  const sectionRef = useRef<HTMLDivElement>(null);

  const [cardPinned, setCardPinned] = useState(false);
  const [cardHovered, setCardHovered] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleClickPinned = () => {
    setCardPinned((value) => !value);
  };

  const onMouseEnterEvent = useCallback(() => {
    if (!dropdownVisible) {
      setCardHovered(true);
    }
  }, [dropdownVisible]);

  const onMouseLeaveEvent = useCallback(() => {
    if (!dropdownVisible) {
      setCardHovered(false);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    sectionRef.current?.addEventListener('mouseenter', onMouseEnterEvent);
    sectionRef.current?.addEventListener('mouseleave', onMouseLeaveEvent);
    return () => {
      sectionRef.current?.removeEventListener('mouseenter', onMouseEnterEvent);
      sectionRef.current?.removeEventListener('mouseleave', onMouseLeaveEvent);
    };
  }, [onMouseEnterEvent, onMouseLeaveEvent, sectionRef]);

  return (
    <CardProduct ref={sectionRef}>
      <ProductCard
        className="product-cards"
        pinned={cardPinned || cardHovered ? 1 : 0}
      >
        {product.isNewOffer && (
          <ComponentInfoNew>
            <InfoNew />
          </ComponentInfoNew>
        )}
        {product.offerType === 3 && (
          <ComponentInfoDirected>
            <InfoDirectedTag specialPrice={product.specialPrice} />
          </ComponentInfoDirected>
        )}
        <>
          {isAuthenticated && (
            <ProductCardHeader>
              <BadgeAndInfo>
                <InfoDelivery
                  isLkpOffer={product.isLkpOffer}
                  endDateFormatted={product.endDateFormatted}
                  endDate={product.endDate}
                  startDate={product.startDate}
                  siteDeliveryPatterns={product.siteDeliveryPatterns}
                />
                <Badge type="medium" {...offerTypeColor[product.offerType]} />
              </BadgeAndInfo>
              {!isMobile && (
                <PinContainer>
                  <Tooltip title="Manter informações visíveis">
                    <Button
                      type="text"
                      onClick={handleClickPinned}
                      shape="circle"
                      icon={
                        <PushpinOutlined
                          style={{
                            fontSize: '14px',
                            color: cardPinned ? '#faad14' : 'initial',
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </PinContainer>
              )}
            </ProductCardHeader>
          )}

          <ProductImage>
            <ProductCardImage
              images={product.imagesFormatted}
              imageByProducer={product.images.length > 0 && !product.isLkpOffer}
              maxHeight="150px"
              open={openImage}
            />
          </ProductImage>

          <ProductCardFooter>
            <ProductCardFooterSpacing>
              <MainInfo>
                <InfoProductName longName={product.longName} />
                <ProductOfferPrice
                  price={
                    packingSelected?.additionalCost
                      ? product.packingCost + getLowPrice
                      : getLowPrice
                  }
                  hasSavingPrice={!!product.hasSavingPrice}
                  savingPrice={product.savingPrice}
                />
              </MainInfo>
              <SecondaryInfo>
                <ProductCodes>
                  <ProductCodesSpacing>
                    <InfoBarCode
                      barcode={product.barcode}
                      gtincode={product.gtincode}
                      size="medium"
                    />
                  </ProductCodesSpacing>
                </ProductCodes>
                <ProducedBy>
                  <ProducedBySpacing>
                    <ProducedByValue>{product.siteName}</ProducedByValue>
                  </ProducedBySpacing>
                </ProducedBy>
              </SecondaryInfo>
            </ProductCardFooterSpacing>
          </ProductCardFooter>

          {isAuthenticated && (cardHovered || cardPinned) && (
            <ProductCardExpanded className="cardExpanded">
              <ProductInfo>
                {!!product.observation && (
                  <ProductNote>
                    <ProductNoteLabel>Observação:</ProductNoteLabel>
                    <ProductNoteValue>
                      <InfoObservation observation={product.observation} />
                    </ProductNoteValue>
                  </ProductNote>
                )}
                <ProductDetails>
                  <ProductDetailLeft>
                    <ProductQuality>
                      <QualityIcon>
                        <TrophyOutlined
                          style={{ fontSize: '16px', color: '#f88f1f' }}
                        />
                      </QualityIcon>
                      <QualityInfo>
                        <QualityInfoSpacing>
                          <QualityInfoLabel>Qualidade:</QualityInfoLabel>
                          <Quality quality={product.quality} />
                        </QualityInfoSpacing>
                      </QualityInfo>
                    </ProductQuality>
                    <ProductSize>
                      <SizeIcon>
                        <ExpandOutlined
                          style={{ fontSize: '16px', color: '#f88f1f' }}
                        />
                      </SizeIcon>
                      <SizeInfo>
                        <SizeInfoSpacing>
                          <SizeInfoLabel>Dimensão/Altura:</SizeInfoLabel>
                          <SizeInfoValue>{product.dimension}</SizeInfoValue>
                        </SizeInfoSpacing>
                      </SizeInfo>
                    </ProductSize>
                  </ProductDetailLeft>
                  <ProductDetailRight>
                    <ProductColor>
                      <ColorIcon>
                        <BgColorsOutlined
                          style={{ fontSize: '16px', color: '#f88f1f' }}
                        />
                      </ColorIcon>
                      <ColorInfo>
                        <ColorInfoSpacing>
                          <ColorInfoLabel>Cores:</ColorInfoLabel>
                          <ColorInfoValue>{product.colors}</ColorInfoValue>
                        </ColorInfoSpacing>
                      </ColorInfo>
                    </ProductColor>
                    <ProductMultiple>
                      <MultipleIcon>
                        <GroupOutlined
                          style={{ fontSize: '16px', color: '#f88f1f' }}
                        />
                      </MultipleIcon>
                      <MultipleInfo>
                        <MultipleInfoSpacing>
                          <MultipleLabel>Múltiplo:</MultipleLabel>
                          <MultipleValue>
                            {packingSelected?.multiple}
                          </MultipleValue>
                        </MultipleInfoSpacing>
                      </MultipleInfo>
                    </ProductMultiple>
                  </ProductDetailRight>
                  <ProductPackage>
                    <Row style={{ marginLeft: '-4px' }}>
                      <PackageIcon>
                        <GiftOutlined
                          style={{
                            fontSize: '16px',
                            color: '#f88f1f',
                            paddingTop: '8px',
                          }}
                        />
                      </PackageIcon>
                      <PackageInfo>
                        <PackageInfoSpacing id="packingSpace">
                          <PackageInfoLabel>Embalagem:</PackageInfoLabel>
                          <SelectPacking
                            offerId={product.offerId}
                            onDropdownVisibleChange={setDropdownVisible}
                            onSelect={handleOnSelectPacking}
                            packings={product.packings}
                            value={packingSelected.id}
                            type="small"
                          />
                        </PackageInfoSpacing>
                      </PackageInfo>
                    </Row>
                  </ProductPackage>
                </ProductDetails>
              </ProductInfo>
              {!!product.specialPrice && !!product.minimumQuantity && (
                <InfoDirected
                  directedMessage={
                    product.minimumQuantity === 1
                      ? `*Para aplicação do valor promocional, a compra mínima deverá ser de 1 unidade`
                      : `*Para aplicação do valor promocional, a compra mínima deverá ser de ${product.minimumQuantity} unidades`
                  }
                />
              )}
              {isAuthenticated && (
                <Actions>
                  <ProductActions
                    product={product}
                    packingSelected={packingSelected}
                    isProducerAuthenticated={isProducerAuthenticated}
                    openComposition={showModalComposition}
                    onClickBuy={addInCart}
                    onClickFastBuy={buy}
                    actionsButtonType="medium"
                    selectedTypeSale={selectedTypeSale}
                    onChangePackingTypeSale={onChangePackingTypeSale}
                  />
                  <ShowDetails
                    producerUrl={producerUrl}
                    offerId={product.offerId}
                    offerType={product.offerType}
                    selectedTypeSale={selectedTypeSale as PackingTypeSale}
                    packingSelectedId={packingSelected.id}
                  />
                </Actions>
              )}
            </ProductCardExpanded>
          )}
        </>
      </ProductCard>
    </CardProduct>
  );
}

export default memo(Grid);
