import { Dispatch } from 'react';
import { alertActions } from 'store/state/slices';

export const showAlertSuccess = (dispatch: Dispatch<any>, message: string, showIcon = true, timeout = 5000) => {
    dispatch(alertActions.alertRequest({message, type: 'success', showIcon, timeout}));
}

export const showAlertError = (dispatch: Dispatch<any>, message: string, showIcon = true, timeout = 5000) => {
    dispatch(alertActions.alertRequest({message, type: 'error', showIcon, timeout}));
}

export const showAlertInfo = (dispatch: any, message: string, showIcon = true, timeout = 5000) => {
    dispatch(alertActions.alertRequest({message, type: 'info', showIcon, timeout}));
}

export const showAlertWarning = (dispatch: any, message: string, showIcon = true, timeout = 5000) => {
    dispatch(alertActions.alertRequest({message, type: 'warning', showIcon, timeout}));
}
