import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Button, Form, Input } from 'antd';

export type ButtonType = 'small' | 'medium';

export type FormType = 'small' | 'medium';

export type ThemeProps = 'default' | 'light';

export type ModeType = 'column' | 'row';

interface GroupTypeSaleProductProps {
  type?: ButtonType;
}

interface ProductCardActionsProps {
  themeType: ThemeProps;
  typeOffer?: number;
  pinned?: boolean;
}

interface AmountInfoProps {
  mode: ModeType;
}

interface ContentProps {
  direction: ModeType;
}

interface FastBuyContainerProps {
  mode: ModeType;
}

interface PackageDivProps {
  size: FormType;
}

interface ButtonsNetworkColProps {
  size: FormType;
}

const groupTypeSaleProductMap: Record<ButtonType, FlattenSimpleInterpolation> =
  {
    medium: css`
      > button {
        > span {
          &:first-child {
            font-size: 12px;
          }
        }
      }
    `,
    small: css`
      > button {
        height: 30px;
        > span {
          &:first-child {
            font-size: 8px;
          }
        }
      }
    `,
  };

const productCardActionsMap: Record<ThemeProps, FlattenSimpleInterpolation> = {
  default: css`
    background-color: #f6f6f6;
  `,
  light: css`
    background-color: transparent;
  `,
};

export const FastBuyContainer = styled.div<FastBuyContainerProps>`
  > button {
    width: 100%;
    font-size: 14px;
    border: 0;
    color: #fff;
    background: linear-gradient(
      0deg,
      rgba(0, 91, 167, 1) 0%,
      rgba(39, 65, 116, 1) 100%
    );
    &:hover,
    &:active {
      color: #fff;
      background: linear-gradient(
        0deg,
        rgba(0, 91, 167, 1) 0%,
        rgba(39, 65, 116, 1) 100%
      );
    }
    ${(props) =>
      props.mode === 'row' &&
      css`
        height: 100%;
        width: 70px;
        font-size: 13px;
        white-space: normal;
        > .anticon + span {
          margin-left: 0px;
        }
      `}
  }
`;

/* eslint-disable */
export const ProductCardActions = styled.div<ProductCardActionsProps>`
  display: flex;
  gap: 8px;
  padding: 8px;
  ${(props) => productCardActionsMap[props.themeType]};
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  justify-content: center;
  margin-left: 12%;

  @media screen and (max-width: 1200px) {
    margin-left: ${(props) => (props.pinned ? '0%' : '0%')};
  }
`;

export const PriceOptions = styled.div`
  width: 100%;
`;

export const GroupTypeSaleProduct = styled.div<GroupTypeSaleProductProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  ${(props) => groupTypeSaleProductMap[props.type || 'medium']};
`;

export const AmountInfo = styled.div<AmountInfoProps>`
  width: 100%;
  display: flex;
  .container {
    display: flex;
    margin-bottom: ${(props) => props.mode === 'column' && '8px'};
    flex-direction: ${(props) => (props.mode === 'row' ? 'column' : 'row')};

    ${(props) =>
      props.mode === 'row'
        ? css`
            align-items: start;
            max-width: 120px;
            justify-content: center;
            ${MinimumPurchase} {
              display: block;
              text-align: start;
            }
            ${MinimumPurchaseLabel}, ${MinimunAmount} {
              display: inline;
            }
            ${Available} {
              flex-direction: column;
              margin-top: 8px;
            }
            ${AvailablePackage} {
              text-align: left;
            }
          `
        : css`
            width: 100%;
            align-items: center;
            justify-content: space-between;
            ${Available} {
              flex-direction: row;
            }
          `}
  }
`;

export const MinimumPurchase = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-right: 10px;
`;

export const MinimumPurchaseLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-right: 10px;
  color: #36393a;
  text-align: right;
`;

export const MinimunAmount = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8d8d8d;
  text-align: center;
`;

export const Available = styled.div`
  display: flex;
`;

export const AvailableInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //gap: 4px;
  justify-content: center;
`;

export const AvailableAmount = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #36393a;
  text-align: right;
  margin-bottom: 1px;
`;

export const AvailableInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-right: 4px;
  color: #36393a;
  text-align: right;
`;

export const AvailablePer = styled.div`
  gap: 16px;
  justify-content: center;
`;

export const AvailablePackage = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8d8d8d;
  text-align: center;
`;

export const OrderActions = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  flex-direction: column;

  width: 100%;
`;

export const CardBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 4px;

  .ant-input {
    width: 155px !important;
  }
`;

export const PackagesDiv = styled.div<PackageDivProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.size === 'small' &&
    css`
      .ant-form-item-control-input {
        min-height: 29px;
        input {
          line-height: 1.358;
        }
      }
    `}
`;

export const ButtonsNetworkCol = styled.div<ButtonsNetworkColProps>`
  display: flex;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-end;

  ${(props) =>
    props.size === 'small' &&
    css`
      button {
        width: 29px;
        height: 29px;
      }
    `}
`;

export const ButtonNetwork = styled(Button)`
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
`;

export const PackagesInput = styled(Input)``;

export const PopoverContainer = styled.div`
  max-width: 180px;
`;

export const TextPopoverComposition = styled.span`
  max-width: 20px;
  word-break: break-word;
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
  .ant-form-item-control-input-content {
    flex: initial;
  }
`;

export const MainContent = styled.div`
  flex: 1;
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: 8px;
  ${(props) =>
    props.direction === 'row' &&
    css`
      min-width: 350px;
      justify-content: start;
      flex: 0;
      > div:first-child {
        width: 270px;
        max-width: 270px;
      }
    `};
`;
