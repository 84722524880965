import React, { useState } from 'react';
import FileImage from '../../../../assets/antIcons/fileImage.svg';
import * as S from './styles';

export type BackgroundImageProps = React.ComponentPropsWithRef<'img'> & {
  src: string;
  border?: string;
  label?: string;
  size: number;
  imageNumber?: string;
};

export function Image({ 
  border = 'none', 
  label, 
  size, 
  imageNumber, 
  src = FileImage, 
  ...rest 
}: BackgroundImageProps) {
  const [errorImage, setErrorImage] = useState<string | undefined>();

  return (
    <S.Container border={border} size={size}>
      <img
        alt='imagem'
        src={errorImage ?? src}
        onError={({ currentTarget }) => {
          setErrorImage(FileImage);
          currentTarget.onerror = null;
        }}
        {...rest}
      />
      {imageNumber && <span>{imageNumber}</span>}
      {label && <S.Label>{label}</S.Label>}
    </S.Container>
  );
}
