/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Input, InputNumber, Table, Tooltip, Select } from 'antd';
import Monetary from 'components/UI/Data/Monetary';
import CurrencyInput from 'components/UI/CurrencyInputNew';
import { currencyMask } from 'components/UI/CurrencyInput';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as MyOrderIntentionEditCalls from 'store/state/myOrderIntentionEdit/apiCalls';
import BuyIntentionOffers from 'components/Modals/BuyIntentionOffers';
import ConfirmationModal from 'components/Modals/Confirmation';
import * as BuyIntentionModels from 'model/BuyIntention';
import ObservationsOffer from 'components/Modals/BuyIntention/ObservationsOffer';
import { actions as MyOrderIntentionEditActions } from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSlice';
import * as MyOrderIntentionEditSelectors from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSelectors';
import * as buyIntenteionSelectors from 'store/state/buyIntention/buyIntenteionSelectors';
import * as S from './styles';

interface Props {
  tableData?: any;
}

const { Option } = Select;

export default function AddBuyIntentionTable({ tableData }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deliveryDate = useSelector(
    MyOrderIntentionEditSelectors.getDeliveryDate,
  );
  const businessDate = useSelector(
    MyOrderIntentionEditSelectors.getComercialDate,
  );
  const offersList = useSelector(MyOrderIntentionEditSelectors.getOffersList);
  const idSelected = useSelector(
    MyOrderIntentionEditSelectors.getIdSelectedNow,
  );
  const isLoading = useSelector(MyOrderIntentionEditSelectors.getIsLoading);
  const showModalOffers = useSelector(
    MyOrderIntentionEditSelectors.getShowModalOffers,
  );
  const currentStep = useSelector(MyOrderIntentionEditSelectors.getCurrentStep);
  const reloadOffers = useSelector(
    MyOrderIntentionEditSelectors.getReloadOffers,
  );
  const customer = useSelector(buyIntenteionSelectors.getCustomer);

  const [showRegisterConfirmationModal, setShowRegisterConfirmationModal] =
    useState<boolean>(false);
  const [showObservationModal, setShowObservationModal] =
    useState<boolean>(false);
  const [idOffer, setIdOffer] = useState<string | undefined>(undefined);
  const [obsText, setObsText] = useState<string>('');
  const [recordData, setRecordData] = useState<any>(undefined);
  const [priceValue, setPriceValue] = useState<number>(0);
  const [totalpriceProduct, setTotalPriceProduct] = useState<number>(0);
  const [totalpriceActual, setTotalPriceActual] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [nameProduct, setNameProduct] = useState<string>('');

  const choicePacking = (packingCode: string, offer: any) => {
    const composition = offer.productPreference.find(
      (packing: BuyIntentionModels.Packing) => packing.code === packingCode,
    );
    changeOfferInput(composition, 'packingSelected', offer);
    changeOfferInput(composition.qe, 'quantityPacking', offer);
    changeOfferInput(composition.qpe, 'quantityUnit', offer);
  };

  const choiceProducer = (producerId: number, record: any) => {
    const producer = record.siteList.find(
      (item: BuyIntentionModels.Producer) => item.id === producerId,
    );
    changeOfferInput(producer, 'site', record);
  };

  const changeOfferInput = (value: any, property: string, record: any) => {
    if (record.id) {
      if (currentStep === 1) {
        dispatch(
          MyOrderIntentionEditActions.setOverview({
            value,
            property,
            id: record.id,
          }),
        );
      }
      if (!record.productPreference && record.site) {
        dispatch(MyOrderIntentionEditActions.setIdSelectedNow(record.id));
        dispatch(
          MyOrderIntentionEditCalls.fetchPacking({
            siteId: record.site.id,
            productId: record.productId,
          }),
        );
        dispatch(MyOrderIntentionEditActions.setIsLoading(true));
      }
    }
  };

  const handleChange = (e: number, record: any) => {
    changeOfferInput(e, 'unitPrice', record);
  };

  const showOffers = () => {
    dispatch(MyOrderIntentionEditActions.setShowModalOffers(true));
    setShowRegisterConfirmationModal(false);
  };

  const addIntention = () => {
    setShowRegisterConfirmationModal(false);
    dispatch(
      MyOrderIntentionEditActions.setPropertyValue({
        value: true,
        property: 'intention',
        id: idOffer,
      }),
    );
  };

  useEffect(() => {
    if (reloadOffers) {
      if (offersList.length && idSelected === idOffer) {
        setShowRegisterConfirmationModal(true);
      }
      if (offersList.length === 0 || !offersList) {
        if (idOffer) {
          dispatch(
            MyOrderIntentionEditActions.setPropertyValue({
              value: true,
              property: 'intention',
              id: idOffer,
            }),
          );
          setTotalPriceActual(totalpriceProduct);
        }
      }
      dispatch(MyOrderIntentionEditActions.setReloadOffers(false));
    }
  }, [offersList, reloadOffers]);

  useEffect(() => {
    // if (tableData.length) {
    //   changeOfferInput(parseFloat(tableData[0].unitPrice), 'unitPriceNumber', tableData[0]);
    // }
    // if (
    //   tableData.length &&
    //   tableData[0].packingSelected?.additionalCost === 'S' &&
    //   tableData[0].unitPrice
    // ) {
    //   const price =
    //     parseFloat(tableData[0].unitPrice) -
    //     parseFloat(tableData[0].packingSelected?.packingCost);
    //   changeOfferInput(parseFloat(price.toFixed(2)), 'unitPrice', tableData[0]);
    //   changeOfferInput(parseFloat(price.toFixed(2)), 'unitPriceNumber', tableData[0]);
    // }
  }, []);

  function getPopupContainer(triggerNode: any) {
    return triggerNode.parentElement;
  }

  const validatorAlign = (isDisabled: boolean, isValue: boolean) => {
    if (isDisabled) {
      return 0;
    }
    if (isValue && !isDisabled) {
      return 0;
    }
    return 9;
  };

  return (
    <>
      <ObservationsOffer
        onSubmit={changeOfferInput}
        visible={showObservationModal}
        setVisible={setShowObservationModal}
        valueObs={obsText}
        valueRecord={recordData}
      />

      <ConfirmationModal
        modalIsVisible={showDeleteModal}
        textTitle=" Excluir"
        textDescription={`Deseja excluir o Produto ${nameProduct}?`}
        textButtonOk="Sim"
        textButtonCancel="Não"
        actionButtonOk={() => {
          dispatch(MyOrderIntentionEditActions.setDeleteOffer(idOffer ?? ''));
          setShowDeleteModal(false);
        }}
        actionButtonCancel={() => setShowDeleteModal(false)}
      />

      <BuyIntentionOffers
        modalIsVisible={showModalOffers}
        setModalIsVisible={() =>
          dispatch(MyOrderIntentionEditActions.setShowModalOffers(false))
        }
      />

      <Table
        bordered
        dataSource={tableData}
        pagination={{ hideOnSinglePage: true }}
        rowKey="id"
      >
        <Table.Column
          key="productName"
          dataIndex="productName"
          title={t('buyIntention.table.productDescription')}
          render={(value, record: any) => (
            <S.GroupTitleInformation descroption>
              <Tooltip title={value}>
                <S.TitleTextWrap>{value}</S.TitleTextWrap>
                <S.DescriptionText>{record.productCode}</S.DescriptionText>
              </Tooltip>
            </S.GroupTitleInformation>
          )}
        />

        <Table.Column
          width="25%"
          key="site"
          dataIndex="site"
          title={t('buyIntention.table.producer')}
          render={(value, record: BuyIntentionModels.BuyIntentionData) => (
            <Select
              dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
              placement="bottomLeft"
              getPopupContainer={(v) => getPopupContainer(v)}
              disabled={record.siteList === null}
              value={
                record.site !== null
                  ? `${record.site?.account} - ${record.site?.tradingName}`
                  : undefined
              }
              style={{ width: 250 }}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption
              placeholder={
                record.siteList === null
                  ? `${record.site?.account} - ${record.site?.tradingName}`
                  : 'Selecione'
              }
              onChange={(v) => choiceProducer(Number(v), record)}
            >
              {record.siteList !== undefined &&
                record.siteList !== null &&
                Array.isArray(record.siteList) &&
                record.siteList.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {`${item.account} - ${item.tradingName}`}
                  </Option>
                ))}
            </Select>
          )}
        />

        <Table.Column
          key="quality"
          dataIndex="quality"
          title={t('buyIntention.table.quality')}
          render={(value, record: any) => (
            <Select
              getPopupContainer={(v) => getPopupContainer(v)}
              disabled={!record.site}
              style={{ width: 70 }}
              value={record.qualitySelect}
              allowClear={false}
              onChange={(v) => changeOfferInput(v, 'qualitySelect', record)}
            >
              {record.qualityList.map((item: any) => (
                <Option key={item.id} value={item.code}>
                  {item.description}
                </Option>
              ))}
            </Select>
          )}
        />

        <Table.Column
          dataIndex="productPreference"
          key="productPreferences"
          title={t('buyIntention.table.packing')}
          render={(
            productPreference: BuyIntentionModels.PackingFull[],
            record: BuyIntentionModels.BuyIntentionData,
          ) => (
            <>
              <Select
                getPopupContainer={(v) => getPopupContainer(v)}
                disabled={!productPreference}
                style={{
                  display: 'flex',
                  width: 80,
                  marginTop: validatorAlign(
                    !productPreference,
                    record.packingSelected?.additionalCost !== 'S',
                  ),
                }}
                bordered
                onChange={(v) => choicePacking(v, record)}
                value={record.packingSelected?.code || ''}
                allowClear={false}
                showSearch
                optionFilterProp="children"
                filterOption
                dropdownStyle={{ minWidth: '200px' }}
              >
                {productPreference &&
                  productPreference.map(
                    (packing: BuyIntentionModels.Packing) => (
                      <Option value={packing.code} key={packing.id}>
                        {packing.code} - {packing.abbrev}
                      </Option>
                    ),
                  )}
              </Select>
              {record.packingSelected?.additionalCost === 'S' && (
                <S.SubTextBody>
                  <Monetary symbol value={record.packingSelected.packingCost} />
                  /un.
                </S.SubTextBody>
              )}
            </>
          )}
        />

        <Table.Column
          width="10%"
          dataIndex="quantityPacking"
          key="quantityPacking"
          title={
            <S.CenterContent>{t('buyIntention.table.qe')}</S.CenterContent>
          }
          render={(value, _record: BuyIntentionModels.BuyIntentionData) => (
            <>
              <InputNumber
                style={{
                  width: 70,
                  marginTop: !_record.packingSelected ? 0 : 10,
                }}
                disabled={!_record.packingSelected}
                inputMode="none"
                value={value}
                min={1}
                step={_record.packingSelected?.qe || 1}
                onChange={(value_: any) => {
                  if (_record.unitPrice > 0) {
                    const price = _record.unitPrice;
                    const totalpriceCalc =
                      value_ *
                      _record.quantityUnit *
                      (Number(price) +
                        Number(
                          _record.packingSelected.additionalCost === 'S'
                            ? _record.packingSelected.packingCost
                            : 0,
                        ) +
                        Number(
                          _record.shippingFeeFilials !== null
                            ? _record.shippingFeeFilials.productShippingValue
                            : 0,
                        ));
                    changeOfferInput(totalpriceCalc, 'totalPrice', _record);
                    changeOfferInput(price, 'unitPriceNumber', _record);
                    setTotalPriceProduct(totalpriceCalc);
                    if (currentStep === 1) {
                      dispatch(
                        MyOrderIntentionEditActions.setTotalPriceOverview({
                          id: _record.id,
                          value: totalpriceCalc,
                        }),
                      );
                    }
                  } else {
                    changeOfferInput(0, 'totalPrice', _record);
                    setTotalPriceProduct(0);
                  }

                  changeOfferInput(value_, 'quantityPacking', _record);
                }}
              />
              {_record.packingSelected && (
                <S.SubTextBody>
                  {`Múltiplo ${_record.packingSelected?.qe || ''}`}
                </S.SubTextBody>
              )}
            </>
          )}
        />

        <Table.Column
          width="10%"
          dataIndex="quantityUnit"
          key="quantityUnit"
          title={
            <S.CenterContent>{t('buyIntention.table.qpe')}</S.CenterContent>
          }
          render={(value, _record: BuyIntentionModels.BuyIntentionData) => (
            <InputNumber
              style={{ width: 70 }}
              disabled={!_record.packingSelected}
              value={value}
              min={1}
              step={1}
              type="number"
              onChange={(value_: any) => {
                if (_record.unitPrice > 0) {
                  const price = _record.unitPrice;
                  const totalpriceCalc =
                    value_ *
                    _record.quantityPacking *
                    (Number(price) +
                      Number(
                        _record.packingSelected.additionalCost === 'S'
                          ? _record.packingSelected.packingCost
                          : 0,
                      ) +
                      Number(
                        _record.shippingFeeFilials !== null
                          ? _record.shippingFeeFilials.productShippingValue
                          : 0,
                      ));
                  changeOfferInput(totalpriceCalc, 'totalPrice', _record);
                  changeOfferInput(price, 'unitPriceNumber', _record);
                  setTotalPriceProduct(totalpriceCalc);
                  if (currentStep === 1) {
                    dispatch(
                      MyOrderIntentionEditActions.setTotalPriceOverview({
                        id: _record.id,
                        value: totalpriceCalc,
                      }),
                    );
                  }
                } else {
                  changeOfferInput(0, 'totalPrice', _record);
                  setTotalPriceProduct(0);
                }

                changeOfferInput(value_, 'quantityUnit', _record);
              }}
            />
          )}
        />

        <Table.Column
          width="10%"
          dataIndex="unitPrice"
          key="unitPrice"
          title={
            <S.GroupTitleInformation>
              <S.TitleText>{t('buyIntention.table.unitValue')}</S.TitleText>
              <S.SubText>{t('buyIntention.table.noICMS')}</S.SubText>
            </S.GroupTitleInformation>
          }
          render={(
            priceByPacking,
            _record: BuyIntentionModels.BuyIntentionData,
          ) => (
            <CurrencyInput
              disabledOption={!_record.packingSelected}
              id="0"
              value={parseFloat(priceByPacking)}
              onChange={(e) => {
                handleChange(e, _record);
                const valuePrice = e;
                if (valuePrice > 0) {
                  const price = valuePrice;
                  setPriceValue(price);
                  const totalpriceCalc =
                    _record.quantityUnit *
                    _record.quantityPacking *
                    (price +
                      (_record.packingSelected?.additionalCost === 'S'
                        ? _record.packingSelected?.packingCost
                        : 0) +
                      (_record.shippingFeeFilials !== null
                        ? _record.shippingFeeFilials.productShippingValue
                        : 0));
                  changeOfferInput(totalpriceCalc, 'totalPrice', _record);
                  changeOfferInput(price, 'unitPriceNumber', _record);
                  setTotalPriceProduct(totalpriceCalc);
                  if (currentStep === 1) {
                    dispatch(
                      MyOrderIntentionEditActions.setTotalPriceOverview({
                        id: _record.id,
                        value: totalpriceCalc,
                      }),
                    );
                  }
                } else {
                  changeOfferInput(0, 'totalPrice', _record);
                  setTotalPriceProduct(0);
                }
              }}
            />
          )}
        />

        {customer?.branch === 'CVH_UB' && (
          <Table.Column
            width="10%"
            dataIndex="shippingFeeFilials"
            key="shippingFeeFilials"
            title={
              <S.GroupTitleInformation>
                <S.TitleText>{t('buyIntention.table.shippingFee')}</S.TitleText>
                <S.SubText>
                  {t('buyIntention.table.shippingTotalFee')}
                </S.SubText>
              </S.GroupTitleInformation>
            }
            render={(value, _record: BuyIntentionModels.BuyIntentionData) => (
              <S.CenterContent>
                <Monetary
                  symbol
                  value={value !== null ? value.productShippingValue : 0}
                />
                <S.SubTextBody>
                  <Monetary
                    symbol
                    value={
                      _record.quantityPacking *
                      _record.quantityUnit *
                      (value !== null ? value.productShippingValue : 0)
                    }
                  />
                </S.SubTextBody>
              </S.CenterContent>
            )}
          />
        )}

        <Table.Column
          width="10%"
          dataIndex="totalPrice"
          key="totalPrice"
          title={
            <S.CenterContent>
              {t('buyIntention.table.totalValue')}
            </S.CenterContent>
          }
          render={(value, _record: BuyIntentionModels.BuyIntentionData) => (
            <S.CenterContent>
              <Monetary symbol value={value} />
              {_record.packingSelected?.additionalCost === 'S' &&
                _record.unitPrice > 0 && (
                  <S.SubTextBody>
                    <Monetary
                      symbol
                      value={
                        _record.packingSelected?.packingCost +
                          _record.unitPriceNumber || 0
                      }
                    />
                  </S.SubTextBody>
                )}
            </S.CenterContent>
          )}
        />

        <Table.Column
          dataIndex="observationText"
          key="observationText"
          title={
            <S.CenterContent>
              {t('buyIntention.table.observation')}
            </S.CenterContent>
          }
          render={(observation, record: any) => (
            <S.ObsContent>
              <Input
                value={observation}
                style={{
                  width: 100,
                }}
                onDoubleClick={() => {
                  setShowObservationModal(true);
                  setObsText(observation);
                  setRecordData(record);
                }}
                onChange={(e) =>
                  changeOfferInput(e.target.value, 'observationText', record)
                }
              />
            </S.ObsContent>
          )}
        />
      </Table>

      {isLoading && (
        <S.Overlay>
          <S.Loader spinning={isLoading} size="large" />
        </S.Overlay>
      )}
    </>
  );
}
