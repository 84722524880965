/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-plusplus */
import {
  CloseOutlined,
  CommentOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  Table,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import ConfirmationModal from 'components/Modals/Confirmation';
import { CountdownTimer } from 'components/UI/CountdownTimer';
import Monetary from 'components/UI/Data/Monetary';
import { useMyOrderIntentionsContext } from 'contexts/MyOrderIntentionsContext';
import { OfferTypeEnum } from 'enums/offerType';
import { OrderIntentionOriginEnum } from 'enums/orderIntentionOrigin.enum';
import { OrderIntentionStatusEnum } from 'enums/orderIntentionStatus.enum';
import { OrderIntention } from 'model/OrderIntention';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as MyOrderIntentionEditCalls from 'store/state/myOrderIntentionEdit/apiCalls';
import {
  buyIntenteionSelectors,
  myOrderIntentionEditSelectors,
  sessionSelectors,
} from 'store/state/selectors';
import { myOrderIntentionEditActions } from 'store/state/slices';
import { dateUtils } from 'utils/date';
import DetailsModal from '../DetailsModal';
import JustificationModal from '../JustificationModal';
import ObservationModal from '../ObservationModal';
import StatusTag from './StatusTag';
import TableRowDropdown from './TableRowDropdown';
import TableTopDropdown from './TableTopDropdown';
import * as S from './styles';

interface Props {
  offerType: OfferTypeEnum;
  isLoading?: boolean;
  data: OrderIntention[] | undefined;
}

export default function List({ data, offerType, isLoading = false }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedCustomerIdFilter = useSelector(
    sessionSelectors.getSelectedCustomerIdFilter,
  );
  const { PageSize, PageNumber } = useSelector(
    myOrderIntentionEditSelectors.getPagination,
  );
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const showModalCancel = useSelector(
    myOrderIntentionEditSelectors.getShowModalCancel,
  );
  const idCancel = useSelector(myOrderIntentionEditSelectors.getIdCancel);
  const showModalRepublish = useSelector(
    myOrderIntentionEditSelectors.getShowModalRepublish,
  );
  const showModalNoRepublish = useSelector(
    myOrderIntentionEditSelectors.getShowModalNoRepublish,
  );
  const showModalNoJustification = useSelector(
    myOrderIntentionEditSelectors.getShowModalNoJustification,
  );
  const justificationText = useSelector(
    myOrderIntentionEditSelectors.getJustificationText,
  );
  const idRepublish = useSelector(myOrderIntentionEditSelectors.getIdRepublish);
  const showModalAprove = useSelector(
    myOrderIntentionEditSelectors.getShowModalAprove,
  );
  const showModalReprove = useSelector(
    myOrderIntentionEditSelectors.getShowModalReprove,
  );
  const idsBulkAprove = useSelector(
    myOrderIntentionEditSelectors.getIdsBulkAprove,
  );
  const idsBulkReprove = useSelector(
    myOrderIntentionEditSelectors.getIdsBulkReprove,
  );
  const idsJustification = useSelector(
    myOrderIntentionEditSelectors.getIdsJustification,
  );
  const clearCheckbox = useSelector(
    myOrderIntentionEditSelectors.getClearCheckbox,
  );
  const customer = useSelector(buyIntenteionSelectors.getCustomer);
  const filterExport = useSelector(
    myOrderIntentionEditSelectors.getFilterExport,
  );

  const {
    setDataTable,
    formFilter,
    setPageNumber,
    setPageSize,
    setSelectedOrderIntentions,
    selectedOrderIntentionsKeys,
    setSelectedOrderIntentionsKeys,
    setShowJustificationModal,
  } = useMyOrderIntentionsContext();

  const onChangePage = useCallback(
    (page: number, pageSize: number) => {
      setPageNumber(page);
      setPageSize(pageSize);
      formFilter.submit();
    },
    [formFilter, setPageNumber, setPageSize],
  );

  const [selectedStatusAndOrigin, setSelectedStatusAndOrigin] = useState<any>();
  const [showModalObservation, setShowModalObservation] =
    useState<boolean>(false);
  const [observationText, setObservationText] = useState<string>('');

  const onChangeSelecteds = useCallback(
    (
      selectedRowKeys: React.Key[],
      selectedOrderIntentions: OrderIntention[],
    ) => {
      setSelectedOrderIntentionsKeys(selectedRowKeys);
      setSelectedOrderIntentions(selectedOrderIntentions);
      if (selectedOrderIntentions.length) {
        setSelectedStatusAndOrigin({
          status: selectedOrderIntentions[0].status,
          origin: selectedOrderIntentions[0].origin,
        });
        return;
      }
      setSelectedStatusAndOrigin(undefined);
    },
    [setSelectedOrderIntentions, setSelectedOrderIntentionsKeys],
  );

  const handlePageChange = useCallback(
    (pagination: TablePaginationConfig) => {
      dispatch(
        myOrderIntentionEditActions.setPagination({
          PageSize: pagination.pageSize ?? 10,
          PageNumber: pagination.current ?? 1,
        }),
      );
    },
    [dispatch],
  );

  const validatorJustificationTable = (
    origin: string,
    status: string,
    justification: any,
  ) => {
    if (origin === OrderIntentionOriginEnum.DIRECT_SALE) {
      return true;
    }
    if (
      origin === OrderIntentionOriginEnum.ORDER_INTENTION &&
      (status === OrderIntentionStatusEnum.RECUSADO ||
        status === OrderIntentionStatusEnum.CONFIRMADO_PARCIAL)
    ) {
      return justification !== null;
    }
    return false;
  };

  const checkBoxValidator = (status: string, origin: string) => {
    if (
      origin === OrderIntentionOriginEnum.ORDER_INTENTION &&
      (status === OrderIntentionStatusEnum.EXPIRADO ||
        status === OrderIntentionStatusEnum.AGUARDANDO_PRODUTOR)
    ) {
      return false;
    }
    if (
      origin === OrderIntentionOriginEnum.DIRECT_SALE &&
      status === OrderIntentionStatusEnum.PENDENTE
    ) {
      return false;
    }
    return true;
  };

  const disableCheckbox = useCallback(
    (orderSelected: OrderIntention) => {
      if (
        selectedStatusAndOrigin &&
        (orderSelected.status !== selectedStatusAndOrigin.status ||
          orderSelected.origin !== selectedStatusAndOrigin.origin)
      ) {
        return true;
      }
      return checkBoxValidator(orderSelected.status, orderSelected.origin);
    },
    [selectedStatusAndOrigin],
  );

  const getCheckboxProps = useCallback(
    (orderSelected: OrderIntention) => ({
      disabled: disableCheckbox(orderSelected),
    }),
    [disableCheckbox],
  );

  const selecAllValidator = useCallback(() => {
    let countDiference = 0;
    let firstOriginOrder = '';
    let firstStatusOrder = '';

    if (data) {
      firstOriginOrder = data.length > 0 ? data[0].origin : '';
      firstStatusOrder = data.length > 0 ? data[0].status : '';
    }
    data?.forEach((order) => {
      if (
        order.origin !== firstOriginOrder ||
        order.status !== firstStatusOrder
      ) {
        countDiference += 1;
      }
    });

    if (countDiference === 0) {
      return false;
    }
    return true;
  }, [data]);

  useEffect(() => {
    if (clearCheckbox) {
      setSelectedOrderIntentionsKeys([]);
      setSelectedOrderIntentions([]);
      setSelectedStatusAndOrigin(undefined);
      dispatch(myOrderIntentionEditActions.setClearCheckbox(false));
    }
  }, [clearCheckbox]);

  return (
    <>
      <DetailsModal />
      <JustificationModal
        onSubmit={(value) => {
          const addJustification: any = data?.map((item: any) => {
            if (idsJustification.includes(item.id)) {
              return {
                ...item,
                justification: value,
              };
            }
            return item;
          });
          setDataTable(addJustification);
        }}
      />
      <ObservationModal
        isVisible={showModalObservation}
        observationText={observationText}
        onCancel={() => setShowModalObservation(false)}
      />
      <ConfirmationModal
        modalIsVisible={showModalAprove}
        textTitle="Aprovar Venda Direta"
        textDescription="Tem certeza que deseja aprovar esse pedido?"
        textButtonOk="Sim, aprovar"
        textButtonCancel="Voltar"
        actionButtonOk={() => {
          dispatch(
            MyOrderIntentionEditCalls.aproveOrReproveDirectSale({
              approve: true,
              justification: null,
              ids: idsBulkAprove,
            }),
          );
          dispatch(myOrderIntentionEditActions.setShowModalAprove(false));
        }}
        actionButtonCancel={() =>
          dispatch(myOrderIntentionEditActions.setShowModalAprove(false))
        }
      />
      <ConfirmationModal
        modalIsVisible={showModalReprove}
        textTitle="Recusar Venda Direta"
        textDescription="Tem certeza que deseja recusar esse pedido?"
        textButtonOk="Sim, recusar"
        textButtonCancel="Voltar"
        actionButtonOk={() => {
          dispatch(
            MyOrderIntentionEditCalls.aproveOrReproveDirectSale({
              approve: false,
              justification: justificationText,
              ids: idsBulkReprove,
            }),
          );
          dispatch(myOrderIntentionEditActions.setShowModalReprove(false));
        }}
        actionButtonCancel={() =>
          dispatch(myOrderIntentionEditActions.setShowModalReprove(false))
        }
      />
      <ConfirmationModal
        modalIsVisible={showModalCancel}
        textTitle="Cancelar"
        textDescription="Tem certeza que deseja cancelar essa intenção de compra (IC)?"
        textButtonOk="Sim, cancelar IC"
        textButtonCancel="Voltar"
        actionButtonOk={() => {
          dispatch(MyOrderIntentionEditCalls.cancelBuyIntention(idCancel));
          dispatch(myOrderIntentionEditActions.setShowModalCancel(false));
        }}
        actionButtonCancel={() =>
          dispatch(myOrderIntentionEditActions.setShowModalCancel(false))
        }
      />
      <ConfirmationModal
        modalIsVisible={showModalRepublish}
        textTitle="Republicar"
        textDescription="Tem certeza que deseja republicar essa intenção de compra (IC)?"
        textButtonOk="Sim, republicar"
        textButtonCancel="Voltar"
        actionButtonOk={() => {
          dispatch(
            MyOrderIntentionEditCalls.republishBuyIntention(idRepublish),
          );
          dispatch(myOrderIntentionEditActions.setShowModalRepublish(false));
        }}
        actionButtonCancel={() =>
          dispatch(myOrderIntentionEditActions.setShowModalRepublish(false))
        }
      />

      <ConfirmationModal
        modalIsVisible={showModalNoRepublish}
        textTitle="Republicar"
        textDescription={t('myOrderIntentions.subtitleNoRepublish')}
        textButtonOk="Voltar"
        actionButtonOk={() => {
          dispatch(myOrderIntentionEditActions.setShowModalNoRepublish(false));
        }}
        widthModal={500}
      />

      <ConfirmationModal
        modalIsVisible={showModalNoJustification}
        textTitle="Aviso"
        textDescription="É necessário preencher uma justificativa para recusar."
        textButtonOk="Voltar"
        actionButtonOk={() => {
          dispatch(
            myOrderIntentionEditActions.setShowModalNoJustification(false),
          );
        }}
        widthModal={500}
      />

      <Table
        loading={isLoading}
        dataSource={data}
        pagination={{
          current: PageNumber,
          pageSize: PageSize,
          showSizeChanger: true,
        }}
        onChange={(pagination) => handlePageChange(pagination)}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selectedOrderIntentionsKeys,
          onChange: onChangeSelecteds,
          getCheckboxProps,
          hideSelectAll: selecAllValidator(),
        }}
      >
        <Table.Column
          title={() => (
            <Dropdown
              overlay={() => <TableTopDropdown />}
              placement="bottomLeft"
              arrow
            >
              <Button style={{ border: 'none' }}>
                <MoreOutlined />
              </Button>
            </Dropdown>
          )}
          render={(_, record: OrderIntention) => (
            <Dropdown
              overlay={() => <TableRowDropdown record={record} />}
              placement="bottomLeft"
              arrow
            >
              <Button style={{ border: 'none' }}>
                <MoreOutlined />
              </Button>
            </Dropdown>
          )}
        />
        <Table.Column
          title=""
          dataIndex="status"
          key="status"
          align="center"
          render={(
            status: OrderIntentionStatusEnum,
            record: OrderIntention,
          ) => {
            return (
              <>
                <StatusTag
                  status={status}
                  statusDescription={record.statusDescription}
                  message={record.message}
                />
                {status === OrderIntentionStatusEnum.PENDENTE && (
                  <CountdownTimer
                    expirationTime={moment(record.expirationTime)}
                    onExpire={() => console.log('Expirou!')}
                    expiringSecondsAlert={1800}
                    onExpiring={() => console.log('Perto de expirar!')}
                  />
                )}
              </>
            );
          }}
        />
        {!selectedCustomerIdFilter && (
          <Table.Column
            title="Chave"
            dataIndex="customerCode"
            key="customerCode"
            align="center"
            render={(value, order: OrderIntention) => (
              <Tooltip title={`${order.customerCode} - ${order.customerName}`}>
                <S.customerContent>{value}</S.customerContent>
              </Tooltip>
            )}
          />
        )}

        <Table.Column
          title={t('myOrderIntentions.table.order')}
          dataIndex="orderNumber"
          key="orderNumber"
          align="left"
        />
        <Table.Column
          title={t('myOrderIntentions.table.origin')}
          dataIndex="origin"
          key="origin"
          align="left"
        />
        <Table.Column
          title={() => (
            <>
              {t('myOrderIntentions.table.deliveryDate.line01')} <br />{' '}
              {t('myOrderIntentions.table.deliveryDate.line02')} <br />{' '}
              {t('myOrderIntentions.table.deliveryDate.time')}
            </>
          )}
          dataIndex="deliveryDate"
          key="deliveryDate"
          align="left"
          render={(deliveryDate: string, record: any) =>
            record.orderNumber
              ? dateUtils.formatDate(deliveryDate, 'DD/MM/YYYY HH:mm')
              : dateUtils.formatDate(deliveryDate, 'DD/MM/YYYY')
          }
        />
        <Table.Column
          title={() => (
            <>
              {t('myOrderIntentions.table.orderDate.line01')} <br />{' '}
              {t('myOrderIntentions.table.orderDate.line02')}
            </>
          )}
          dataIndex="commercialDate"
          key="commercialDate"
          align="left"
          render={(orderDate: string) =>
            dateUtils.formatDate(orderDate, 'DD/MM/YYYY')
          }
        />
        <Table.Column
          title={t('myOrderIntentions.table.producer')}
          dataIndex="siteName"
          key="siteName"
          align="left"
          render={(siteName: string, order: OrderIntention) => (
            <>
              {siteName} <br /> {order.siteCode}
            </>
          )}
        />

        <Table.Column
          title={t('myOrderIntentions.table.productDescription')}
          dataIndex="description"
          key="description"
          align="left"
          render={(_, order: OrderIntention) => (
            <>
              <Tooltip title={order.productDescription}>
                <S.productName>{order.productDescription}</S.productName>
              </Tooltip>{' '}
              {order.productCode}
            </>
          )}
        />

        <Table.Column
          title={t('myOrderIntentions.table.quality')}
          dataIndex="qualityCode"
          key="qualityCode"
          align="left"
        />
        <Table.Column
          title={t('myOrderIntentions.table.packing')}
          dataIndex="packing"
          key="packing"
          render={(_, order: OrderIntention) => (
            <>
              {order.packingCode} <br />{' '}
              <Tooltip
                title={`${order.packingCode} - ${order.packingDescription}`}
              >
                <S.packingName>{order.packingDescription}</S.packingName>
              </Tooltip>
            </>
          )}
        />
        <Table.Column
          title={() => <>{t('myOrderIntentions.table.qtdUnit')}</>}
          dataIndex="packingQuantity"
          key="packingQuantity"
          align="left"
          render={(_, order: OrderIntention) =>
            `${order.quantityPacking} x ${order.quantityUnit}`
          }
        />

        <Table.Column
          title={() => (
            <>
              QE <br />
              Atendida
            </>
          )}
          dataIndex="quantityAttended"
          key="quantityAttended"
          align="left"
          render={(quantityAttended: number) => (
            <Input value={quantityAttended} disabled />
          )}
        />

        <Table.Column
          title={() => (
            <>
              {t('myOrderIntentions.table.vlrUnit')}
              <br />
              {t('myOrderIntentions.table.vlrTotal')}
            </>
          )}
          dataIndex="unitPrice"
          key="unitPrice"
          align="left"
          render={(unitPrice: number, order: OrderIntention) => (
            <>
              <Monetary symbol value={unitPrice + order.shippingFee} /> <br />
              <Monetary
                symbol
                value={
                  order.totalPrice +
                  (customer?.branch === 'CVH_UB' ? order.shippingFee : 0) *
                    order.quantityPacking *
                    order.quantityUnit
                }
              />
            </>
          )}
        />
        {customer?.branch === 'CVH_UB' && (
          <Table.Column
            title={() => (
              <>
                {t('myOrderIntentions.table.shippingFee')}
                <br />
                {t('myOrderIntentions.table.shippingFeeTotal')}
              </>
            )}
            dataIndex="shippingFee"
            key="shippingFee"
            align="left"
            render={(shippingFee: number, order: OrderIntention) => (
              <S.shipingFeeContent>
                <S.shipingFeeItem>
                  <Monetary symbol value={shippingFee} /> <br />
                </S.shipingFeeItem>
                <S.shipingFeeItem>
                  <Monetary
                    symbol
                    value={
                      shippingFee * order.quantityPacking * order.quantityUnit
                    }
                  />
                </S.shipingFeeItem>
              </S.shipingFeeContent>
            )}
          />
        )}

        <Table.Column
          title={() => (
            <>
              Natureza Op. <br />
              Retirada
            </>
          )}
          dataIndex="natureOperation"
          key="natureOperation"
          align="center"
          render={(_, order: OrderIntention) => (
            <>
              {order.natureOperationDescription} <br />
              {order.removeLocation}
            </>
          )}
        />
        <Table.Column
          title="Obs."
          dataIndex="observation"
          key="observation"
          align="center"
          render={(observation: string) =>
            observation ? (
              <Tooltip title={observation}>
                <EyeOutlined
                  onClick={() => {
                    setShowModalObservation(true);
                    setObservationText(observation);
                  }}
                  style={{ color: '#f78e1e' }}
                />
              </Tooltip>
            ) : (
              <CloseOutlined />
            )
          }
        />
        <Table.Column
          title="Just."
          dataIndex="justification"
          key="justification"
          align="center"
          render={(justification: string, record: OrderIntention) =>
            validatorJustificationTable(
              record.origin,
              record.status,
              record.justification,
            ) ? (
              <Tooltip title={justification}>
                <CommentOutlined
                  onClick={() => {
                    setSelectedOrderIntentions([record]);
                    setSelectedOrderIntentionsKeys([record.id]);
                    setShowJustificationModal(true);
                    dispatch(
                      myOrderIntentionEditActions.setIdsJustification([
                        record.id,
                      ]),
                    );
                    dispatch(
                      myOrderIntentionEditActions.setItemsJustification([
                        record,
                      ]),
                    );
                  }}
                  style={{ color: '#000' }}
                />
              </Tooltip>
            ) : (
              <CloseOutlined />
            )
          }
        />
        <Table.Column
          title={t('myOrderIntentions.table.publishedBy')}
          dataIndex="publishedBy"
          key="publishedBy"
        />
      </Table>
    </>
  );
}
