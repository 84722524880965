/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApartmentOutlined,
  BgColorsOutlined,
  ExpandOutlined,
  GiftOutlined,
  GroupOutlined,
  ShoppingCartOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import Monetary from 'components/UI/Data/Monetary';
import Packing from 'model/Packing';
import PopoverCalendar from 'components/UI/PopoverCalendarDates';
import ProductOffer from 'model/ProductOffer';
import { useGetDeleteCartMutation } from 'services/ecommerceApi';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { cartSelectors, sessionSelectors } from 'store/state/selectors';
import moment, { Moment } from 'moment';
import DeliveryDate from 'components/UI/DeliveryDate';
import { sessionActions } from 'store/state/slices';
import { deliveryDatePattern } from 'services/deliveryDatePattern';
import { useNavigate } from 'react-router-dom';
import { validatorUtils } from 'utils/validator';
import { PackingTypeEnum } from 'enums/packingType';
import { FastCartIcon } from 'components/UI/Icons';
import Quality from 'components/UI/Quality';
import ShowDetails from 'components/UI/ShowDetails';
import ProductCardComponentImage from 'components/UI/ProductCardComponentImage.tsx';
import { PackingTypeSale } from 'enums/packingTypeSale';
import { calculateMinBySaleType } from 'utils/util';
import ModalExpiredItemsInCart from '../../../Modals/ExpiredItemsInCart/index';
import imageComum from '../../../../assets/default/image.png';
import OfferTypeBadge from '../OfferTypeBadge';
import { FastBuyContainer } from '../ProductActions/styles';
import * as S from './styles';
import { ProductBuy, ProductBuyHandle } from '../ProductBuy';

type ProductOfferProps = {
  productOffer: ProductOffer;
  isNewOffer?: boolean;
  setModalIsVisibleComposition: (
    productOfferSelected: ProductOffer,
    packingSelected: Packing,
  ) => void;
  setOfferIdModalComposition: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setOfferIdInserted: (offerId: number, offerType: number) => void;
};

const { Option } = Select;

export default function ProductCardListSimplified({
  productOffer,
  setModalIsVisibleComposition,
  isNewOffer,
  setOfferIdModalComposition,
  setOfferIdInserted,
}: ProductOfferProps) {
  const {
    offerId,
    offerType,
    voucherId,
    longName,
    defaultImage,
    images,
    siteName,
    barcode,
    gtincode,
    voucherQuantityType,
    voucherReajustmentType,
    quality,
    packings,
    colors,
    dimension,
    packingCost,
    minimumUnitQuantityVoucher,
    reajustmentAmount,
    layerPrice,
    packagingPrice,
    startDate,
    trolleyPrice,
    endDate,
    lkpAuctionDate,
    observation,
    siteDeliveryPatterns,
    isCompositionEnabled,
    unavailableDates,
  } = productOffer;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fastBuy, setFastBuy] = useState(false);
  const [selectedTypeSale, setSelectedTypeSale] = useState<string>(() => {
    if (packagingPrice) {
      return 'Packing';
    }
    if (layerPrice) {
      return 'Layer';
    }
    return 'Trolley';
  });
  const dispatch = useDispatch();

  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );
  const [getDeleteCart] = useGetDeleteCartMutation();
  const [arrayImage, setArrayImage] = useState<string[]>([
    defaultImage || './../../../../assets/veilingonline-logo.png',
  ]);
  const [showModalExpiredItemsInCart, setShowModalExpiredItemsInCart] =
    useState<boolean>(false);
  const [
    displayModalInformationComposition,
    setDisplayModalInformationComposition,
  ] = useState<boolean>(false);

  const [packingSelected, setPackingSelected] = useState<Packing>(
    (): Packing =>
      packings.find((packing) => packing.type === PackingTypeEnum.RETORNAVEL) ??
      packings.find((packing) => packing.code === '800') ??
      packings[0],
  );
  const [displayModalInformation, setDisplayModalInformation] =
    useState<boolean>(false);
  const [textInputTypeSale, setTextInputTypeSale] = useState<string>(() => {
    if (packagingPrice) {
      return 'Embalagem(s)';
    }
    if (layerPrice) {
      return 'Camada(s)';
    }
    return 'Carrinho(s)';
  });

  const lkpOfferId = useSelector(cartSelectors.getLkpOfferId);
  const volOfferId = useSelector(cartSelectors.getVolOfferId);
  const billingDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);

  const isLkpOffer = useMemo(
    () => offerType === 1 || offerType === 2,
    [offerType],
  );

  const handleTypeSale = (value: string) => {
    if (value === 'Packing') {
      setSelectedTypeSale('Packing');
      setTextInputTypeSale('Embalagem(s)');
    } else if (value === 'Layer') {
      setSelectedTypeSale('Layer');
      setTextInputTypeSale('Camada(s)');
    } else if (value === 'Trolley') {
      setSelectedTypeSale('Trolley');
      setTextInputTypeSale('Carrinho(s)');
    }
  };

  const clickCloseModalInformationComposition = () => {
    setDisplayModalInformationComposition(false);
  };

  const handleClickButtonGoToCart = () => {
    navigate('/veiling/shopping-cart');
    setShowModalExpiredItemsInCart(false);
  };

  const handleClickButtonDeleteCart = () => {
    const cartId = isLkpOffer ? lkpOfferId : volOfferId;
    getDeleteCart({ cartId });
    setShowModalExpiredItemsInCart(false);
  };

  const handleVisibleModalComposition = () => {
    if (deliveryDate && billingDate) {
      setModalIsVisibleComposition(productOffer, packingSelected);
      setOfferIdModalComposition(offerId);
    } else {
      setDisplayModalInformation(true);
    }
  };

  const clickCloseModalInformation = () => {
    setDisplayModalInformation(false);
  };

  useEffect(() => {
    if (packings && packings.length > 0) {
      form.setFieldsValue({
        packingSelectedId:
          packings.find(
            (packing) => packing.type === PackingTypeEnum.RETORNAVEL,
          )?.id ??
          packings.find((packing) => packing.code === '800')?.id ??
          packings[0]?.id,
      });
    }
  }, [form, packings, productOffer]);

  const getMultipleCalc = useCallback(() => {
    if (selectedTypeSale === 'Packing') {
      return (
        packingSelected?.multiple / +packingSelected?.maxAvailablePackingsLabel
      );
    }
    if (selectedTypeSale === 'Layer') {
      const values = packingSelected?.maxAvailableLayersLabel
        .split('x')
        .map((x) => +x);
      const calc = packingSelected?.multiple / values.reduce((a, b) => +a * +b);
      return calc < 1 ? 1 : calc;
    }
    const values = packingSelected?.maxAvailableTrolleysLabel
      .split('x')
      .map((x) => +x);
    const calc = packingSelected?.multiple / values.reduce((a, b) => +a * +b);
    return calc < 1 ? 1 : calc;
  }, [
    selectedTypeSale,
    packingSelected?.multiple,
    packingSelected.maxAvailableTrolleysLabel,
    packingSelected.maxAvailableLayersLabel,
    packingSelected.maxAvailablePackingsLabel,
  ]);

  const getErrorMinMessage = useCallback(() => {
    if (selectedTypeSale === 'Packing') {
      return `Quantidade mínima: ${
        packingSelected?.minimumQuantity /
        +packingSelected?.maxAvailablePackingsLabel
      } ${textInputTypeSale}`;
    }
    if (selectedTypeSale === 'Layer') {
      const values = packingSelected?.maxAvailableLayersLabel
        .split('x')
        .map((x) => +x);
      const calc =
        packingSelected?.minimumQuantity / values.reduce((a, b) => +a * +b);
      return `Quantidade mínima: ${calc < 1 ? 1 : calc} ${textInputTypeSale}`;
    }
    const values = packingSelected?.maxAvailableTrolleysLabel
      .split('x')
      .map((x) => +x);
    const calc =
      packingSelected?.minimumQuantity / values.reduce((a, b) => +a * +b);
    return `Quantidade mínima: ${calc < 1 ? 1 : calc} ${textInputTypeSale}`;
  }, [
    selectedTypeSale,
    packingSelected?.minimumQuantity,
    packingSelected.maxAvailableTrolleysLabel,
    packingSelected.maxAvailableLayersLabel,
    packingSelected.maxAvailablePackingsLabel,
  ]);

  const getLabel = useCallback(() => {
    if (selectedTypeSale === 'Packing') {
      return packingSelected?.maxAvailablePackingsLabel || '';
    }
    if (selectedTypeSale === 'Layer') {
      return packingSelected?.maxAvailableLayersLabel || '';
    }
    return packingSelected?.maxAvailableTrolleysLabel || '';
  }, [
    selectedTypeSale,
    packingSelected.maxAvailableTrolleysLabel,
    packingSelected.maxAvailableLayersLabel,
    packingSelected.maxAvailablePackingsLabel,
  ]);

  const getMaxAvailable = useMemo(() => {
    if (selectedTypeSale === 'Packing') {
      return {
        label: packingSelected?.maxAvailablePackingsLabel || '',
        max: packingSelected?.maxAvailablePackingsQuantity || 0,
        min: packingSelected?.unitsPerPackage,
      };
    }
    if (selectedTypeSale === 'Layer') {
      return {
        label: packingSelected?.maxAvailableLayersLabel || '',
        max: packingSelected?.maxAvailableLayersQuantity || 0,
        min:
          packingSelected?.packagingByLayer * packingSelected?.unitsPerPackage,
      };
    }
    return {
      label: packingSelected?.maxAvailableTrolleysLabel || '',
      max: packingSelected?.maxAvailableTrolleysQuantity || 0,
      min:
        packingSelected?.packagingByLayer *
        packingSelected?.layersByTrolley *
        packingSelected?.unitsPerPackage,
    };
  }, [packingSelected, selectedTypeSale]);

  useEffect(() => {
    const quantityPackingValue = form.getFieldValue(['quantityPacking']);
    if (quantityPackingValue) {
      form.validateFields(['quantityPacking']);
    }
  }, [getMaxAvailable]);

  const handleOnSelect = (id: number) => {
    setPackingSelected(packings.find((packing) => packing.id === id)!);
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setArrayImage(images);
    } else {
      setArrayImage([defaultImage || imageComum]);
    }
  }, [images]);

  // Modal para data
  const [deliveryDateValue, setDeliveryDateValue] = useState<Moment | null>(
    null,
  );
  const [showDatePickerDelivery, setShowDatePickerDelivery] =
    useState<boolean>(true);
  const [showDatePickerBilling, setShowDatePickerBilling] =
    useState<boolean>(false);
  const [billingDateValue, setBillingDateValue] = useState<Moment | null>(null);

  const disableDatesDelivery = (value: Moment) => {
    const calendarValue = moment(value).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });
    const dateNow = moment().set({
      hour: 0,
      minute: 0,
      second: 1,
      millisecond: 0,
    });
    const endDateFormatted = moment(lkpAuctionDate).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });

    const deliveryDays = siteDeliveryPatterns.map(
      (date) => date.deliveryDayNumber,
    );
    const deliveryDate = deliveryDatePattern(
      siteDeliveryPatterns,
    ).getDeliveryDateByDatePatterns(dateNow.toDate().getDay());
    return (
      calendarValue.isBefore(startDate) ||
      calendarValue.isBefore(deliveryDate) ||
      value.isAfter(endDateFormatted) ||
      !deliveryDays.includes(calendarValue.day())
    );
  };

  const disableDatesBilling = (value: Moment) => {
    if (deliveryDateValue !== null) {
      const initalDay = moment(deliveryDateValue);
      const lastDay = moment(deliveryDateValue).add(2, 'days');

      return (
        value.isBefore(startDate) ||
        value.isAfter(lastDay) ||
        value.isBefore(initalDay)
      );
    }
    return false;
  };

  const handleOnOpenChangeDelivery = (event: boolean) => {
    setShowDatePickerDelivery(event);

    if (deliveryDateValue !== null) {
      setShowDatePickerBilling(true);
    }
  };

  const handleOnOpenChangeBilling = (event: boolean) => {
    setShowDatePickerBilling(event);
  };

  const handleOnChangeDelivery = (value: Moment | null) => {
    setBillingDateValue(null);
    setDeliveryDateValue(value);

    if (value) {
      setShowDatePickerBilling(true);
    }
  };

  const handleOnChangeBilling = (value: Moment | null) => {
    setBillingDateValue(value);
  };

  const handleOnConfirmationDeliveryDate = () => {
    dispatch(
      sessionActions.setDates({
        billingDate:
          billingDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
        deliveryDate:
          deliveryDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
      }),
    );
    clickCloseModalInformation();
    form.submit();
  };

  const handleOnConfirmationDeliveryDateComposition = () => {
    dispatch(
      sessionActions.setDates({
        billingDate:
          billingDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
        deliveryDate:
          deliveryDateValue?.set('hour', 0).format('YYYY-MM-DD') ?? '',
      }),
    );
    clickCloseModalInformationComposition();
    setModalIsVisibleComposition(productOffer, packingSelected);
    setOfferIdModalComposition(offerId);
  };

  const infoVoucherLabel = useMemo(
    () => (
      <>
        {reajustmentAmount < 0 ? 'Desconto ' : 'Adicional '}
        <span className="valeu">
          {voucherReajustmentType === 'P'
            ? `${Math.abs(reajustmentAmount)}%`
            : `R$${Math.abs(reajustmentAmount)}`}
        </span>
      </>
    ),
    [reajustmentAmount],
  );

  const infoVoucher = useMemo(
    () =>
      `*Para aplicação do ${
        reajustmentAmount < 0 ? 'desconto' : 'adicional'
      } a quantidade mínima deve ser de ${minimumUnitQuantityVoucher} ${
        voucherQuantityType === 'P'
          ? minimumUnitQuantityVoucher > 1
            ? 'embalagens'
            : 'embalagem'
          : voucherQuantityType === 'L'
          ? minimumUnitQuantityVoucher > 1
            ? 'camadas'
            : 'camada'
          : minimumUnitQuantityVoucher > 1
          ? 'carrinhos'
          : 'carrinho'
      }. O valor ${
        reajustmentAmount < 0 ? 'do desconto' : 'adicional'
      } será aplicado sobre o preço do produto`,
    [reajustmentAmount, minimumUnitQuantityVoucher, voucherQuantityType],
  );

  const handleClickFastBuy = useCallback(() => {
    setFastBuy(true);
    form.submit();
  }, []);

  const productBuyRef = useRef<ProductBuyHandle>(null);
  const handleSubmit = (formValues: any) => {
    productBuyRef.current?.verifyXpedAndBuyFunc(formValues);
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <S.ProductCardListComponent>
        {isNewOffer && <S.ComponentInfoNew>Novo</S.ComponentInfoNew>}

        {voucherId && (
          <S.ComponentInfoVoucher>{infoVoucherLabel}</S.ComponentInfoVoucher>
        )}
        <ProductCardComponentImage
          arrayImage={arrayImage}
          images={images}
          isLkpOffer={isLkpOffer}
          maxHeight="50px"
        />

        {/* informações de dimensão e altura */}
        <S.ProductCardComponentDetails xs={4} sm={8} md={10} lg={13} xl={13}>
          <Row className="row-details-name">
            <Col className="col-details" xs={24}>
              <S.ProductNameType>
                <div className="name-product-div">
                  {offerType === 0 || offerType === 3 ? (
                    <PopoverCalendar
                      startDate={startDate}
                      endDate={endDate}
                      siteDeliveryPatterns={siteDeliveryPatterns}
                    />
                  ) : (
                    <span className="date-lkp-offer">
                      {moment(endDate).format('DD/MM')}
                    </span>
                  )}
                  <Tooltip title={longName}>
                    <div className="name-product">
                      <span>{longName}</span>
                      <div className="code-product">
                        <div className="number-product">{barcode}</div>
                        <div>{gtincode}</div>
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className="remove-display-calendar">
                  <OfferTypeBadge
                    siteDeliveryPatterns={siteDeliveryPatterns}
                    offerType={offerType}
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <ShowDetails
                    producerUrl={producerUrl}
                    offerId={offerId}
                    offerType={offerType}
                    selectedTypeSale={selectedTypeSale as PackingTypeSale}
                    packingSelectedId={packingSelected.id}
                  />
                </div>
              </S.ProductNameType>
            </Col>
          </Row>
          <Row className="row-details">
            <Col className="col-details" md={24} lg={14} xl={19}>
              <Row>
                <S.InformationProduct>
                  <TrophyOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Qualidade:</p>
                    <Quality quality={quality} />
                  </div>
                </S.InformationProduct>

                <S.InformationProduct>
                  <GroupOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Múltiplo:</p>
                    <span>{packingSelected?.multiple}</span>
                  </div>
                </S.InformationProduct>
                <S.InformationProduct>
                  <BgColorsOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Cores:</p>
                    <span>{colors}</span>
                  </div>
                </S.InformationProduct>

                <S.InformationProduct>
                  <GiftOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Embalagem:</p>
                    {packings && packings.length > 0 ? (
                      <Form.Item name="packingSelectedId">
                        <Select
                          style={{ display: 'flex' }}
                          bordered={false}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          onSelect={handleOnSelect}
                        >
                          {packings &&
                            packings.map((packing: Packing) => (
                              <Option
                                value={packing.id}
                                key={`${offerId}-${packing.id}`}
                              >
                                {packing.code} - {packing.abbrev}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                  </div>
                </S.InformationProduct>
                <S.InformationProduct>
                  <ExpandOutlined className="icon-information" />
                  <div className="group-information">
                    <p className="title-information">Dimensão/Altura:</p>
                    <span>{dimension}</span>
                  </div>
                </S.InformationProduct>
              </Row>
            </Col>
            <Col className="col-details-color" md={24} lg={10} xl={5}>
              <p>
                <span className="producer-name"> {siteName}</span>
              </p>
              {observation?.length && (
                <p className="break-word">
                  Observação:
                  <span>
                    {' '}
                    {observation?.length > 40 ? (
                      <Tooltip title={observation}>
                        {observation?.length > 40
                          ? `${observation.substring(0, 40)}...`
                          : observation}
                      </Tooltip>
                    ) : (
                      observation
                    )}
                  </span>
                </p>
              )}
              {voucherId && (
                <S.InformationVoucher>{infoVoucher}</S.InformationVoucher>
              )}
            </Col>
          </Row>
        </S.ProductCardComponentDetails>

        {/* Informações de compra minima */}
        <S.ProductCardComponentInformations lg={1} xl={2}>
          <S.GroupQuantityAvailable>
            <S.MinQuantityProduct>
              Compra mínima: <br/>
              <span>
                {' '}
                {calculateMinBySaleType(selectedTypeSale, packingSelected)}{' '}
                {textInputTypeSale}
              </span>
            </S.MinQuantityProduct>
            <S.AvailableQuantity>
              <span className="value-available">
                {getMaxAvailable.max} disponíveis <br/>
                <span className="calc-available">{getMaxAvailable.label}</span>
              </span>
            </S.AvailableQuantity>
          </S.GroupQuantityAvailable>
        </S.ProductCardComponentInformations>

        {/* Ações e inputs */}
        <S.ProductCardComponentActions lg={6} xl={7}>
          <div className="button-group">
            <Row className="line-actions">
              {isAuthenticated && (
                <S.GroupTypeSaleProduct>
                  <Button
                    disabled={
                      !packagingPrice ||
                      !packingSelected?.maxAvailablePackingsQuantity
                    }
                    onClick={() => handleTypeSale('Packing')}
                    className={`button-type-sale no-border-right ${
                      selectedTypeSale === 'Packing' ? 'focus' : ''
                    }`}
                  >
                    Embalagem{' '}
                    <span className="value">
                      <Monetary
                        symbol
                        value={
                          packagingPrice > 0 && packingSelected?.additionalCost
                            ? packingCost + packagingPrice
                            : packagingPrice
                        }
                      />
                    </span>
                  </Button>
                  <Button
                    disabled={
                      !layerPrice ||
                      !packingSelected?.maxAvailableLayersQuantity
                    }
                    onClick={() => handleTypeSale('Layer')}
                    className={`button-type-sale no-border-right no-border-left ${
                      selectedTypeSale === 'Layer' ? 'focus' : ''
                    }`}
                  >
                    Camada{' '}
                    <span className="value">
                      <Monetary
                        symbol
                        value={
                          layerPrice > 0 && packingSelected?.additionalCost
                            ? packingCost + layerPrice
                            : layerPrice
                        }
                      />
                    </span>
                  </Button>
                  <Button
                    disabled={
                      !trolleyPrice ||
                      !packingSelected?.maxAvailableTrolleysQuantity
                    }
                    onClick={() => handleTypeSale('Trolley')}
                    className={`button-type-sale no-border-left ${
                      selectedTypeSale === 'Trolley' ? 'focus' : ''
                    }`}
                  >
                    Carrinho{' '}
                    <span className="value">
                      <Monetary
                        symbol
                        value={
                          trolleyPrice > 0 && packingSelected?.additionalCost
                            ? packingCost + trolleyPrice
                            : trolleyPrice
                        }
                      />
                    </span>
                  </Button>
                </S.GroupTypeSaleProduct>
              )}
            </Row>

            <Row className="line-actions-input">
              <Form.Item
                name="quantityPacking"
                rules={[
                  {
                    required: true,
                    message: 'A quantidade é obrigatória.',
                  },
                  {
                    validator: (_, value) =>
                      !value || Number(value) <= getMaxAvailable.max
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              `Quantidade máxima: ${getMaxAvailable.max} ${textInputTypeSale}`,
                            ),
                          ),
                  },
                  {
                    validator: (_, value) =>
                      !value ||
                      Number(value) * getMaxAvailable.min >=
                        packingSelected?.minimumQuantity
                        ? Promise.resolve()
                        : Promise.reject(new Error(getErrorMinMessage())),
                  },
                  {
                    validator: (_, value) =>
                      validatorUtils.validMultiple(
                        value,
                        packingSelected?.multiple,
                        selectedTypeSale,
                        getLabel(),
                        getMultipleCalc(),
                        textInputTypeSale,
                      ),
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  className="input-quantity"
                  addonAfter={textInputTypeSale}
                />
              </Form.Item>

              {!isProducerAuthenticated && (
                <Popover
                  content={
                    <div style={{ maxWidth: '180px' }}>
                      <span>
                        {getMaxAvailable.max <
                        calculateMinBySaleType(
                          selectedTypeSale,
                          packingSelected,
                        )
                          ? 'Utilize a composição'
                          : 'Adicionar ao carrinho'}
                      </span>
                    </div>
                  }
                >
                  <Button
                    disabled={
                      getMaxAvailable.max <
                      calculateMinBySaleType(selectedTypeSale, packingSelected)
                    }
                    type="primary"
                    className="button-cart"
                    onClick={() => form.submit()}
                  >
                    <ShoppingCartOutlined />
                  </Button>
                </Popover>
              )}
              {isCompositionEnabled && (
                <Popover
                  content={
                    <div style={{ maxWidth: '180px' }}>
                      <span>
                        Utilize produtos diferentes para preencher uma mesma
                        camada.
                      </span>
                    </div>
                  }
                  title="Criar composição"
                >
                  <Button
                    type="primary"
                    className="button-composition"
                    onClick={() => handleVisibleModalComposition()}
                  >
                    <ApartmentOutlined />
                  </Button>
                </Popover>
              )}
            </Row>
          </div>

          {/* Botão comprar agora */}
          <div style={{ minWidth: '74px' }}>
            {isLkpOffer && !isProducerAuthenticated && (
              <FastBuyContainer
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingLeft: '0',
                  paddingRight: '1px',
                }}
              >
                <S.ButtonNetwork
                  style={{
                    lineHeight: '16px',
                    fontSize: '13px',
                    maxWidth: '69px',
                    minWidth: '65px',
                    minHeight: '60px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  type="default"
                  icon={<FastCartIcon />}
                  onClick={() => handleClickFastBuy()}
                >
                  <span>Comprar</span>
                  <span>agora</span>
                </S.ButtonNetwork>
              </FastBuyContainer>
            )}
          </div>
        </S.ProductCardComponentActions>
      </S.ProductCardListComponent>
      <Modal
        closable
        onCancel={clickCloseModalInformationComposition}
        visible={displayModalInformationComposition}
        width="414px"
        footer={null}
      >
        <S.WarningLabel>
          <b>Atenção!</b> É necessário que selecione uma data para entrega e
          faturamento.
        </S.WarningLabel>
        <DeliveryDate
          initialDatePicker={{
            disabled: false,
            disabledDate: disableDatesDelivery,
            value: deliveryDateValue,
            open: showDatePickerDelivery && displayModalInformationComposition,
            onOpenChange: handleOnOpenChangeDelivery,
            onChange: handleOnChangeDelivery,
          }}
          endDatePicker={{
            disabled: deliveryDateValue === null,
            disabledDate: disableDatesBilling,
            value: billingDateValue,
            open:
              showDatePickerBilling &&
              displayModalInformationComposition &&
              !showDatePickerDelivery,
            onOpenChange: handleOnOpenChangeBilling,
            onChange: handleOnChangeBilling,
          }}
          onConfirmation={handleOnConfirmationDeliveryDateComposition}
          producerUnavailableDates={unavailableDates}
        />
      </Modal>
      <Modal
        closable
        onCancel={clickCloseModalInformation}
        visible={displayModalInformation}
        width="414px"
        footer={null}
      >
        <S.WarningLabel>
          <b>Atenção!</b> É necessário que selecione uma data para entrega e
          faturamento.
        </S.WarningLabel>
        <DeliveryDate
          initialDatePicker={{
            disabled: false,
            disabledDate: disableDatesDelivery,
            value: deliveryDateValue,
            open: showDatePickerDelivery && displayModalInformation,
            onOpenChange: handleOnOpenChangeDelivery,
            onChange: handleOnChangeDelivery,
          }}
          endDatePicker={{
            disabled: deliveryDateValue === null,
            disabledDate: disableDatesBilling,
            value: billingDateValue,
            open:
              showDatePickerBilling &&
              displayModalInformation &&
              !showDatePickerDelivery,
            onOpenChange: handleOnOpenChangeBilling,
            onChange: handleOnChangeBilling,
          }}
          onConfirmation={handleOnConfirmationDeliveryDate}
          producerUnavailableDates={unavailableDates}
        />
      </Modal>
      <ModalExpiredItemsInCart
        modalIsVisible={showModalExpiredItemsInCart}
        actionButtonCancel={handleClickButtonGoToCart}
        actionButtonOk={handleClickButtonDeleteCart}
      />
      <ProductBuy
        ref={productBuyRef}
        billingDateValue={billingDateValue}
        deliveryDateValue={deliveryDateValue}
        fastBuy={fastBuy}
        productOffer={productOffer}
        selectedTypeSale={selectedTypeSale}
        onFinish={() => setFastBuy(false)}
        onResetFields={form.resetFields}
        onSetDisplayModalInformation={setDisplayModalInformation}
      />
    </Form>
  );
}
