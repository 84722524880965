import { EyeOutlined } from '@ant-design/icons';
import { PackingTypeSale } from 'enums/packingTypeSale';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

export type ShowDetailsProps = {
  producerUrl: string;
  offerId: number;
  offerType: number;
  packingSelectedId: number;
  selectedTypeSale: PackingTypeSale;
};

export default function ShowDetails({
  producerUrl,
  offerId,
  offerType,
  packingSelectedId,
  selectedTypeSale
}: ShowDetailsProps) {
  const navigate = useNavigate();
  
  const handleClickShowDetails = useCallback(() => {
    console.log(`navigate('/${producerUrl}/offer/${offerId}/${offerType}/${packingSelectedId}/${selectedTypeSale}')`);
    navigate(`/${producerUrl}/offer/${offerId}/${offerType}/${packingSelectedId}/${selectedTypeSale}`);;
  }, [navigate, offerId, offerType, packingSelectedId, producerUrl, selectedTypeSale]);

  return (
    <S.Container onClick={handleClickShowDetails}>
      <S.Text>
        <EyeOutlined />
        Ver detalhes
      </S.Text>
    </S.Container>
  );
}
