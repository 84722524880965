import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Empty, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Product } from 'model';
import ProductCard from 'components/BusinessLogic/Product/ProductCard';

import { useGetProductsMostSearchedQuery } from 'services/ecommerceApi';
import { sessionSelectors } from 'store/state/selectors';

import * as S from './styles';

export default function MostSearchedSection() {
  const { t } = useTranslation();
  const siteId = useSelector(sessionSelectors.getSitesIds);
  const { data: mostSearchedProducts } =
    useGetProductsMostSearchedQuery(siteId);
  return (
    <S.Root>
      <Row>
        <Col xs={24}>
          <S.Header>
            <S.SectionTitle>{t('home.mostSearchProduts')} </S.SectionTitle>
          </S.Header>
        </Col>
        <Col xs={24}>
          <S.ProductCards>
            <Row gutter={24}>
              {mostSearchedProducts?.length ? (
                mostSearchedProducts.map((product: Product) => (
                  <Col
                    key={`col${product.id}`}
                    xs={24}
                    md={8}
                    lg={6}
                    xl={4}
                    xxl={3}
                    className="center-in-mobile"
                  >
                    <ProductCard
                      key={`mostSearched-${product.id}`}
                      product={product}
                      cardClassName="hover-card-product"
                    />
                  </Col>
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Row>
          </S.ProductCards>
        </Col>
      </Row>
    </S.Root>
  );
}
