import styled from 'styled-components';

export type Size = 'medium' | 'large';

interface ProductNameProps {
  size: Size;
}

export const ProductName = styled.p<ProductNameProps>`
  font-size: ${(props) => (props.size === 'medium' ? '12px' : '18px')};
  font-weight: bold;

  margin-bottom: 4px;

  -webkit-line-clamp: 1;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
