import { useState, useEffect } from 'react';

import { Button } from 'antd';
import QrReader from 'react-qr-reader';

import { ComponentViewQrCode } from 'enums/componentViewQrCode';
import { Body, Footer, Root, ComponentScan } from './styles';

type props = {
  setComponentView: (value: ComponentViewQrCode) => void;
  setCodeQrScan: (value: string) => void;
};

export default function ReadQrCode({ setComponentView, setCodeQrScan }: props) {
  const [messageError, setMessageError] = useState<string>(
    'Aponte para o QRCode localizado no carrinho do produto.',
  );
  const [valueScan, setValueScan] = useState<string | null>('');

  const handleError = (error: string | any) => {
    if (typeof error === 'string') {
      setMessageError(error);
    } else if (error.name ===  'NotAllowedError') {
        setMessageError('É necessário permitir o acesso à câmera para efetuar a leitura do QRCode. Acesse às configurações para permitir o acesso.');
      }
      else {
        setMessageError(`Ocorreu um erro inesperado. (${error.name})`);
      }
  }

  useEffect(() => {
    if (valueScan) {
      setComponentView(ComponentViewQrCode.PRODUCT_LIST);
      setCodeQrScan(valueScan);
    }
  }, [valueScan, setCodeQrScan, setComponentView]);

  return (
    <Root>
      <Body>
        <ComponentScan>
          <QrReader
            delay={600}
            resolution={1000}
            onScan={(value) => setValueScan(value)}
            onError={handleError}
            showViewFinder={false}
          />
        </ComponentScan>
        {messageError && <p>{messageError}</p>}
      </Body>
      <Footer>
        <Button onClick={() => setComponentView(ComponentViewQrCode.HOME)}>
          Cancelar
        </Button>
      </Footer>
    </Root>
  );
}
