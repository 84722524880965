import styled, { css } from 'styled-components';
import { Form, Select } from 'antd';
import { FlattenSimpleInterpolation } from 'styled-components';

export type SelectCustomType = 'small' | 'medium';

interface SelectCustomProps {
  type?: SelectCustomType;
}

const selectCustomMap: Record<SelectCustomType, FlattenSimpleInterpolation> = {
  medium: css``,
  small: css`
    font-size: 12px;
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 !important;
      height: auto !important;
      > span {
        line-height: initial;
        padding: 2px 0;
      }
      &:after {
        line-height: initial;
      }
    }
  `,
};

export const FormItem = styled(Form.Item)`
  margin-bottom: 4px;
`;

export const SelectCustom = styled(Select)<SelectCustomProps>`
  display: flex;
  ${(props) => selectCustomMap[props.type || 'medium']};
`;
