import { memo } from 'react';
import { QualityColorEnum } from 'enums/qualityColor.enum';

import { Container } from './styles';

type QualityProps = {
  quality: QualityColorEnum;
};

function Quality({ quality }: QualityProps) {
  return <Container quality={quality}>{quality}</Container>;
}

export default memo(Quality);
