import { isRejectedWithValue, Middleware, isRejected } from '@reduxjs/toolkit';
import { errorActions, sessionActions } from 'store/state/slices';


const UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST';

export const errorMiddleware: Middleware = () => (next) => (action) => {
  if (isRejected(action)) {
    next(errorActions.throw('error'));
  } else if (isRejectedWithValue(action)) {
    next(errorActions.throw('error with value'));
  }

  // next(sessionActions.setRefreshToken(true));
  if (action?.payload?.status === 401) {
    next(errorActions.throw(UNAUTHORIZED_REQUEST));
  }

  return next(action);
};
