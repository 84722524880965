import { OrdersParam } from 'model/types';
import baseApi from './config';

export function fetchMyOrders(params: OrdersParam) {
  return baseApi.get(`api/sale`, {
    params,
  });
}

export function generateOrdersExcel(params: OrdersParam) {
  return baseApi.get(`/api/sale/export`, {
    params,
  });
}
