import { Form, Col, Row } from 'antd';
import styled from 'styled-components';

interface STYLEDPROPS {
  lineSize?: number;
  statusSpreadsheet?: boolean;
  stepTwo?: boolean;
  isMobile?: boolean;
}

export const Step = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-row {
    margin-bottom: 0px;
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding: 0;
  }
  .ant-col {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-input-search {
    width: 100% !important;
  }
`;

export const ColStyled = styled(Col)<STYLEDPROPS>`
  padding: ${props => !props.isMobile ? 20 : 0}px;
`;

export const ContentTitleProducers = styled.div<STYLEDPROPS>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 5px 10px;
  padding-bottom: ${props => props.stepTwo ? 20 : 5}px;
`;

export const Line = styled.div<STYLEDPROPS>`
  background-color: #d9d9d9;
  height: 0.5px;
  width: ${props => props.lineSize}%;
`;

export const TitleProducers = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  margin-left: 8px;
  margin-right: 8px;
`;

export const searchContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

export const ContentAside = styled.div<STYLEDPROPS>`
  display: flex;
  flex-direction: row;
  position:  ${props => !props.isMobile ? 'absolute' : 'relative'};
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  padding-right: 20px;
`;

export const ContentButton = styled.div<STYLEDPROPS>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => !props.isMobile ? 15 : 0 }px;
  margin-bottom: 15px;
`;

export const RowStyled = styled(Row)`
  position: relative;
  padding-left: 10px;
`;

export const ContentMobileButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;
