import { Checkbox } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 200px;
  padding-left: 30px;
  padding-right: 15px;
  position: relative;
  .ant-row.ant-form-item {
    margin-bottom: 4px !important;
  }
`;

export const SpanSelectAllAndDeselectAll = styled.span`
  position: relative;
  color: #f78e1e;
  cursor: pointer;
  margin-right: 8px;
`;

export const SeparateSpans = styled.span`
  position: relative;
  color: #f78e1e;
  margin-right: 8px;
`;

export const GroupSelectAndDeselect = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxComponent = styled(Checkbox)`
  display: flex;
  flex-direction: row;

  label {
    font-size: 12px !important;
  }
  span {
    font-size: 12px !important;
  }
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px !important;
  }
`;

export const ContentClean = styled.span`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
`;
