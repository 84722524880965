import { memo } from 'react';

import { ModesProps } from '../props';

import ListMobile from './Mobile';
import ListDesktop from './Desktop';

// runInMobile

function List({ runInMobile, ...rest }: ModesProps) {
  return runInMobile ? (
    <ListMobile {...rest} runInMobile={runInMobile} />
  ) : (
    <ListDesktop {...rest} runInMobile={runInMobile} />
  );
}

export default memo(List);
