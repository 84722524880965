import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Space, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import Monetary from 'components/UI/Data/Monetary';
import * as BuyIntentionCalls from 'store/state/buyIntention/apiCalls';
import * as BuyIntentionModels from 'model/BuyIntention';
import { alertActions } from 'store/state/slices';
import * as BuyIntentionSelectors from 'store/state/buyIntention/buyIntenteionSelectors';
import { isMobile } from 'react-device-detect';
import { actions as buyIntentionActions } from '../../store/state/buyIntention/buyIntentionSlice';
import * as S from './styles';
import Step01AssembleOrder from './Steps/Step01AssembleOrder';
import Step02Resume from './Steps/Step02Resume';

import OrderForm from './Form';

const { Step } = Steps;

export default function BuyIntentionModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visible = useSelector(BuyIntentionSelectors.getShowIntentionModal);
  const currentStep = useSelector(BuyIntentionSelectors.getCurrentStep);
  const dataTable = useSelector(BuyIntentionSelectors.getBuyIntentionEdit);
  const customer = useSelector(BuyIntentionSelectors.getCustomer);
  const totalPriceOffers = useSelector(
    BuyIntentionSelectors.getTotalPriceOffers,
  );
  const totalPriceOffersOverview = useSelector(
    BuyIntentionSelectors.getTotalPriceOffersOverview,
  );
  const overviewList = useSelector(BuyIntentionSelectors.getOverviewEdit);
  const commercialDate = useSelector(
    BuyIntentionSelectors.getComercialDateFinish,
  );
  const deliveryDate = useSelector(BuyIntentionSelectors.getDeliveryDateFinish);

  const total = totalPriceOffers.reduce(getTotal, 0);
  const totalOverview = totalPriceOffersOverview.reduce(getTotal, 0);
  function getTotal(total: any, item: any) {
    return total + item.value;
  }

  const disabledNextStep = () => {
    const lengthChoice = dataTable.filter(
      (item: any) => item.intention === true,
    ).length;
    return lengthChoice === 0 && totalOverview === 0;
  };

  const handleClickButtonPrimary = useCallback(() => {
    if (currentStep === 0) {
      let validator = 0;
      if (commercialDate === '' || deliveryDate === '') {
        validator += 1;
        dispatch(
          alertActions.alertRequest({
            message: `É obrigatório informar as datas`,
            type: 'warning',
            showIcon: true,
            timeout: 3000,
          }),
        );
      }
      if (validator === 0) {
        dispatch(buyIntentionActions.setCurrentStep(1));
        dispatch(buyIntentionActions.setOverviewEdit());
      }
    } else {
      let validator = 0;
      if (commercialDate === '' || deliveryDate === '') {
        validator += 1;
        dispatch(
          alertActions.alertRequest({
            message: `É obrigatório informar as datas`,
            type: 'warning',
            showIcon: true,
            timeout: 3000,
          }),
        );
      }
      overviewList.forEach((offer: BuyIntentionModels.BuyIntentionData) => {
        if (offer.quantityUnit === null) {
          validator += 1;
          dispatch(
            alertActions.alertRequest({
              message: `É obrigatório informar o QpE do produto ${offer.productName}`,
              type: 'warning',
              showIcon: true,
              timeout: 3000,
            }),
          );
        }
        if (offer.quantityPacking === null) {
          validator += 1;
          dispatch(
            alertActions.alertRequest({
              message: `É obrigatório informar o QE do produto ${offer.productName}`,
              type: 'warning',
              showIcon: true,
              timeout: 3000,
            }),
          );
        }
        if (offer.unitPrice === 0) {
          validator += 1;
          dispatch(
            alertActions.alertRequest({
              message: `É obrigatório informar o Valor Unitário do produto ${offer.productName}`,
              type: 'warning',
              showIcon: true,
              timeout: 3000,
            }),
          );
        }
      });
      if (validator === 0) {
        const buyIntentionItems: any[] =
          overviewList.map((product: BuyIntentionModels.BuyIntentionData) => ({
            productId: product.productId,
            siteId: product.site?.id,
            qualityId: product.qualitySelect === 'A1' ? 1 : 2,
            quantityPacking: product.quantityPacking,
            packingId: product.packingSelected.id,
            quantityUnit: product.quantityUnit,
            unitPrice: product.unitPriceNumber,
            observation: product.observationText,
            statusId: 1,
          })) || [];
        const buyIntentionParams = {
          customerId: customer?.id,
          deliveryDate,
          commercialDate,
          buyIntentionItems,
        };
        dispatch(BuyIntentionCalls.postBuyIntention(buyIntentionParams));
        dispatch(buyIntentionActions.setIsLoading(true));
      }
    }
  }, [
    currentStep,
    dispatch,
    overviewList,
    commercialDate,
    deliveryDate,
    customer?.id,
  ]);

  const handleClickButtonSecondary = useCallback(() => {
    if (currentStep !== 0) {
      dispatch(buyIntentionActions.setCurrentStep(0));
    } else {
      dispatch(buyIntentionActions.setShowIntentionModal(false));
      dispatch(buyIntentionActions.setSClearTotalPriceOffers());
      dispatch(buyIntentionActions.setIdProductAdded(undefined));
      dispatch(buyIntentionActions.setResetValues());
    }
  }, [currentStep, dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(buyIntentionActions.setShowIntentionModal(false));
  }, [dispatch]);

  const steps = [
    {
      title: t('buyIntention.stepOne'),
      content: <Step01AssembleOrder />,
    },
    {
      title: t('buyIntention.stepTwo'),
      content: <Step02Resume />,
    },
  ];

  return (
    <S.ModalAdd
      title=""
      width={1450}
      visible={visible}
      onCancel={handleCancel}
      style={{ borderRadius: 8 }}
      footer={null}
      maskClosable={false}
    >
      <S.ModalHeader>
        <span>{t('buyIntention.titleModal')}</span>
      </S.ModalHeader>

      <S.ModalBody>
        <Steps
          type="navigation"
          current={currentStep}
          className="site-navigation-steps"
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <OrderForm />
        {currentStep === 0 && <Step01AssembleOrder />}
        {currentStep === 1 && <Step02Resume />}
      </S.ModalBody>
      <S.ModalFooter>
        {isMobile ? (
          <Row>
            <Col style={{ width: '100%' }}>
              <Space>
                <S.ModalFooterInformationText>
                  <p>{t('buyIntention.offers.totalPrice')}</p>
                  <span>
                    <Monetary
                      symbol
                      value={currentStep === 0 ? total : totalOverview}
                    />
                  </span>
                </S.ModalFooterInformationText>
              </Space>
              <S.ContentButtons>
                <Button
                  style={{ marginBottom: 8 }}
                  onClick={handleClickButtonSecondary}
                >
                  {t('buyIntention.cancel')}
                </Button>
                <Button
                  disabled={disabledNextStep()}
                  type="primary"
                  style={{ marginLeft: 8, marginBottom: 8 }}
                  onClick={handleClickButtonPrimary}
                >
                  {currentStep === 0 && t('buyIntention.continue')}
                  {currentStep === 1 && t('buyIntention.registerIntention')}
                </Button>
              </S.ContentButtons>
            </Col>
          </Row>
        ) : (
          <Row justify="end">
            <Col>
              <Space>
                <S.ModalFooterInformationText>
                  <p>{t('buyIntention.offers.totalPrice')}</p>
                  <span>
                    <Monetary
                      symbol
                      value={currentStep === 0 ? total : totalOverview}
                    />
                  </span>
                </S.ModalFooterInformationText>
                <Button
                  style={{ marginBottom: 8 }}
                  onClick={handleClickButtonSecondary}
                >
                  {t('buyIntention.cancel')}
                </Button>
                <Button
                  disabled={disabledNextStep()}
                  type="primary"
                  style={{ marginLeft: 8, marginBottom: 8 }}
                  onClick={handleClickButtonPrimary}
                >
                  {currentStep === 0 && t('buyIntention.continue')}
                  {currentStep === 1 && t('buyIntention.registerIntention')}
                </Button>
              </Space>
            </Col>
          </Row>
        )}
      </S.ModalFooter>
    </S.ModalAdd>
  );
}
