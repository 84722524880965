import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as S from './styles';

type ModalConfirmationProps = {
  modalIsVisible: boolean;
  actionButtonCancel: () => void;
  actionButtonOk: () => void;
};

export default function ModalExpiredItemsInCart({
  modalIsVisible,
  actionButtonOk,
  actionButtonCancel,
}: ModalConfirmationProps) {
  return (
    <S.ModalConfirmation
      closable={false}
      centered
      visible={modalIsVisible}
      width="400px"
      footer={
        <S.ButtonsActions>
          <S.ButtonCancel onClick={actionButtonCancel}>
            Ir até o carrinho
          </S.ButtonCancel>
          <S.ButtonOk onClick={actionButtonOk}>Remover carrinho</S.ButtonOk>
        </S.ButtonsActions>
      }
    >
      <S.ModalConfirmationHeader>
        <ExclamationCircleOutlined />
        <span className="title">
          Não foi possível adicionar item ao carrinho
        </span>
      </S.ModalConfirmationHeader>
      <S.ModalConfirmationBody>
        <p>
          Você possui um carrinho expirado, para adicionar mais itens remova o
          carrinho.
        </p>
      </S.ModalConfirmationBody>
    </S.ModalConfirmation>
  );
}