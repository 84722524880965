import React, { useCallback, useEffect, useState } from 'react';
import { Button, Collapse, Divider, Drawer, Form, Row, Space } from 'antd';
import { PushpinOutlined } from '@ant-design/icons';
import { useGetOfferFiltersQuery } from 'services/ecommerceApi';
import Search from 'antd/lib/input/Search';
import { RootState } from 'store/store';
import { offerTypeText } from 'components/BusinessLogic/Product/OfferTypeText';
import { useDispatch, useSelector } from 'react-redux';
import { offerFiltersActions } from 'store/state/slices';
import ProductColor from 'model/ProductColor';
import Producer from 'model/Producer';
import {
  getFilterState,
  getOfferTypeProductList,
  setFilterState,
  setOfferTypeProductList,
} from 'services/config';
import { ProductOfferFilterParam } from 'model/types';
import { offerFiltersSelectors, sessionSelectors } from 'store/state/selectors';
import { useForm } from 'antd/lib/form/Form';
import { Product, CommercialGroup } from 'model';
import { useParams } from 'react-router-dom';
import { Loader } from '../../Wired/DefaultLoader/styles';
import {
  CheckboxGroup,
  CheckboxComponent,
  Container,
  SpanSelectAllAndDeselectAll,
  GroupSelectAndDeselect,
  SeparateSpans,
} from './styles';

const { Panel } = Collapse;

type PropsFilter = {
  onClose: () => void;
  visible: boolean;
  eventFilterEmited: () => void;
};

export default function Filter(props: PropsFilter) {
  const [form] = useForm();
  const { siteId: producerCodeParam } = useParams();
  const { onClose, visible, eventFilterEmited } = props;
  const dispatch = useDispatch();
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );

  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const businessDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const siteId = useSelector(sessionSelectors.getSitesIds);
  const producerUrl = useSelector(sessionSelectors.getProducerUrl);
  const filterByHeader = useSelector(offerFiltersSelectors.getFilterByHeader);
  const { name: filterName } = useSelector(
    offerFiltersSelectors.getOfferFilters,
  );
  const { data, isLoading, isFetching } = useGetOfferFiltersQuery({
    customerId,
    deliveryDate,
    businessDate,
    siteId,
    ...filterByHeader,
  });

  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [filteredCommercialGroupIds, setFilteredCommercialGroupIds] = useState<
    CommercialGroup[]
  >([]);
  const [filteredDimensions, setFilteredDimensions] = useState<string[]>([]);
  const [filteredColors, setFilteredColors] = useState<ProductColor[]>([]);
  const [filteredSites, setFilteredSites] = useState<Producer[]>([]);
  const [changeFilter, setChangeFilter] = useState<boolean>(false);

  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedOfferTypes, setSelectedOfferTypes] = useState<string[]>(
    getOfferTypeProductList()?.length > 0 ? getOfferTypeProductList() : [],
  );
  const [selectedCommercialGroupIds, setSelectedCommercialGroupIds] = useState<
    number[]
  >([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [selectedQualities, setSelectedQualities] = useState<string[]>([]);
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const [selectedHeights, setSelectedHeights] = useState<string[]>([]);
  const [selectedDimensions, setSelectedDimensions] = useState<string[]>([]);
  const [selectedSites, setSelectedSites] = useState<number[]>([]);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const clearFilterStatus = useSelector(offerFiltersSelectors.getClearFilter);
  const { offerTypes: offerType = [], categories: categoriesFilter = [] } =
    useSelector((state: RootState) => state.offerFilters);

  function createCheckboxRecord(value: number | string, label: string) {
    return {
      value,
      label,
      checked: true,
      onchange: () => console.log('Teste'),
    };
  }

  const pinnedFilter = () => {
    dispatch(offerFiltersActions.setShowPinnedFilter(true));
    onClose();
  }

  useEffect(() => {
    if (offerType?.length && offerType !== selectedOfferTypes) {
      setChangeFilter(false);
      setSelectedOfferTypes(offerType);
    }
  }, [offerType]);

  useEffect(() => {
    if (categoriesFilter?.length && categoriesFilter !== selectedCategories) {
      setChangeFilter(false);
      setSelectedCategories(categoriesFilter);
    }
  }, [categoriesFilter]);

  const selectAllSite = useCallback(() => {
    if (filteredSites?.length) {
      const arrayAux: number[] = selectedSites?.map((option) => option);
      filteredSites.forEach((option) => {
        if (selectedSites.indexOf(option.id) < 0) {
          arrayAux.push(option.id);
        }
      });

      filterSites(arrayAux);
    }
  }, [selectedSites, filteredSites]);

  const selectAllCommercialGroupIds = useCallback(() => {
    if (filteredCommercialGroupIds?.length) {
      const arrayAux: number[] = selectedCommercialGroupIds?.map(
        (option) => option,
      );
      filteredCommercialGroupIds.forEach((option) => {
        if (selectedCommercialGroupIds.indexOf(option.id) < 0) {
          arrayAux.push(option.id);
        }
      });

      filterCommercialGroupIds(arrayAux);
    }
  }, [selectedCommercialGroupIds, filteredCommercialGroupIds]);

  const selectAllProducts = useCallback(() => {
    if (filteredProducts?.length) {
      const arrayAux: number[] = selectedProducts?.map((option) => option);
      filteredProducts.forEach((option) => {
        if (selectedProducts.indexOf(option.id) < 0) {
          arrayAux.push(option.id);
        }
      });

      filterProducts(arrayAux);
    }
  }, [selectedProducts, filteredProducts]);

  const selectAllColors = useCallback(() => {
    if (filteredColors?.length) {
      const arrayAux: string[] = selectedColors?.map((option) => option);
      filteredColors.forEach((option) => {
        if (selectedColors.indexOf(String(option.id)) < 0) {
          arrayAux.push(String(option.id));
        }
      });

      filterColors(arrayAux);
    }
  }, [selectedColors, filteredColors]);

  const selectAllDimensions = useCallback(() => {
    if (filteredDimensions?.length) {
      const arrayAux: string[] = selectedDimensions?.map((option) => option);
      filteredDimensions.forEach((option) => {
        if (selectedDimensions.indexOf(option) < 0) {
          arrayAux.push(option);
        }
      });

      filterDimensions(arrayAux);
    }
  }, [selectedDimensions, filteredDimensions]);

  const deselectAllSite = useCallback(() => {
    form.setFieldsValue({ inputSite: '' });
    filterSitesInList('');
    if (filteredSites?.length) {
      const arrayAux = filteredSites?.map((producer) => producer.id);
      filterSites(
        selectedSites.filter((producerId) => !arrayAux.includes(producerId)),
      );
    }
  }, [selectedSites, filteredSites]);

  const deselectAllCommercialGroupIds = useCallback(() => {
    form.setFieldsValue({ commercialGroupIds: '' });
    filterCommercialGroupIdsInList('');
    if (filteredCommercialGroupIds?.length) {
      const arrayAux: number[] = filteredCommercialGroupIds?.map(
        (option) => option.id,
      );
      filterCommercialGroupIds(
        selectedCommercialGroupIds.filter(
          (commercialGroupId) => !arrayAux.includes(commercialGroupId),
        ),
      );
    }
  }, [selectedCommercialGroupIds, filteredCommercialGroupIds]);

  const deselectAllProducts = useCallback(() => {
    form.setFieldsValue({ inputProducts: '' });
    filterProductInList('');
    if (filteredProducts?.length) {
      const arrayAux: number[] = filteredProducts?.map((option) => option.id);
      filterProducts(
        selectedProducts.filter((productsId) => !arrayAux.includes(productsId)),
      );
    }
  }, [selectedProducts, filteredProducts]);

  const deselectAllColors = useCallback(() => {
    form.setFieldsValue({ inputColors: '' });
    filterColorsInList('');
    if (filteredColors?.length) {
      const arrayAux: string[] = filteredColors?.map((option) =>
        String(option.id),
      );
      filterColors(
        selectedColors.filter((colorId) => !arrayAux.includes(colorId)),
      );
    }
  }, [selectedColors, filteredColors]);

  const deselectAllDimensions = useCallback(() => {
    form.setFieldsValue({ inputDimensions: '' });
    filterDimensionsInList('');
    if (filteredDimensions?.length) {
      filterDimensions(
        selectedDimensions.filter(
          (dimension) => !filteredDimensions.includes(dimension),
        ),
      );
    }
  }, [selectedDimensions, filteredDimensions]);

  const filterCategories = useCallback((checkedValues) => {
    setSelectedCategories(checkedValues);
    setTimeout(() => {
      setChangeFilter(true);
    }, 0);
  }, []);

  const filterOfferTypes = useCallback((checkedValues) => {
    setSelectedOfferTypes(checkedValues);
    setOfferTypeProductList([]);
    setTimeout(() => {
      setChangeFilter(true);
    }, 0);
  }, []);

  const filterCommercialGroupIds = useCallback((checkedValues) => {
    if (Array.isArray(checkedValues)) {
      setSelectedCommercialGroupIds(checkedValues);
    } else {
      setSelectedCommercialGroupIds((values) =>
        values.find((value) => +value === +checkedValues)
          ? values.filter((value) => +value !== +checkedValues)
          : [...values, ...[checkedValues]],
      );
    }
    setTimeout(() => {
      setChangeFilter(true);
    }, 0);
  }, []);

  const filterProducts = useCallback((checkedValues) => {
    if (Array.isArray(checkedValues)) {
      setSelectedProducts(checkedValues);
    } else {
      setSelectedProducts((values) =>
        values.find((value) => +value === +checkedValues)
          ? values.filter((value) => +value !== +checkedValues)
          : [...values, ...[checkedValues]],
      );
    }
    setTimeout(() => {
      setChangeFilter(true);
    }, 0);
  }, []);

  const filterQualities = useCallback((checkedValues) => {
    setSelectedQualities(checkedValues);
    setTimeout(() => {
      setChangeFilter(true);
    }, 0);
  }, []);

  const filterColors = useCallback((checkedValues) => {
    if (Array.isArray(checkedValues)) {
      setSelectedColors(checkedValues);
    } else {
      setSelectedColors((values) =>
        values.find((value) => value === checkedValues)
          ? values.filter((value) => value !== checkedValues)
          : [...values, ...[checkedValues]],
      );
    }
    setTimeout(() => {
      setChangeFilter(true);
    }, 0);
  }, []);

  const filterDimensions = useCallback((checkedValues) => {
    if (Array.isArray(checkedValues)) {
      setSelectedDimensions(checkedValues);
    } else {
      setSelectedDimensions((values) =>
        values.find((value) => value === checkedValues)
          ? values.filter((value) => value !== checkedValues)
          : [...values, ...[checkedValues]],
      );
    }
    setTimeout(() => {
      setChangeFilter(true);
    }, 0);
  }, []);

  const filterSites = useCallback((checkedValues) => {
    if (Array.isArray(checkedValues)) {
      setSelectedSites(checkedValues);
    } else {
      setSelectedSites((values) =>
        values.find((value) => +value === +checkedValues)
          ? values.filter((value) => +value !== +checkedValues)
          : [...values, ...[checkedValues]],
      );
    }
    setTimeout(() => {
      setChangeFilter(true);
    }, 0);
  }, []);

  const searchProducts = useCallback(() => {
    eventFilterEmited();
    dispatch(
      offerFiltersActions.filter({
        name: filterName,
        categories: selectedCategories,
        offerTypes: selectedOfferTypes,
        productsIds: selectedProducts,
        commercialGroupIds: selectedCommercialGroupIds,
        qualities: selectedQualities,
        colors: selectedColors,
        heights: selectedHeights,
        dimensions: selectedDimensions,
        siteId: selectedSites,
      }),
    );
    setFilterState({
      name: filterName,
      categories: selectedCategories,
      offerTypes: selectedOfferTypes,
      productsIds: selectedProducts,
      commercialGroupIds: selectedCommercialGroupIds,
      qualities: selectedQualities,
      colors: selectedColors,
      heights: selectedHeights,
      dimensions: selectedDimensions,
      siteId: selectedSites,
    });
  }, [
    eventFilterEmited,
    filterName,
    dispatch,
    selectedCategories,
    selectedOfferTypes,
    selectedProducts,
    selectedQualities,
    selectedColors,
    selectedHeights,
    selectedCommercialGroupIds,
    selectedDimensions,
    selectedSites,
  ]);

  useEffect(() => {
    if (changeFilter) {
      searchProducts();
      setChangeFilter(false);
    }
  }, [changeFilter, searchProducts]);

  const clearFilter = (filter: boolean) => {
    form.resetFields();
    filterSitesInList('');
    filterProductInList('');
    filterCommercialGroupIdsInList('');
    filterDimensionsInList('');
    filterColorsInList('');

    if (filter) {
      dispatch(offerFiltersActions.clear());
      setSelectedCategories([]);
      setSelectedColors([]);
      setSelectedDimensions([]);
      setSelectedHeights([]);
      setSelectedOfferTypes([]);
      setSelectedSites(producerUrl === 'veiling' ? [] : siteId);
      setSelectedProducts([]);
      setSelectedCommercialGroupIds([]);
      setSelectedQualities([]);
      setFilterState({
        categories: [],
        offerTypes: [],
        productsIds: [],
        commercialGroupIds: [],
        qualities: [],
        colors: [],
        heights: [],
        dimensions: [],
        producerIds: [],
        siteId: producerUrl === 'veiling' ? [] : siteId,
      });
      setOfferTypeProductList([]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    categories,
    offerTypes,
    products,
    commercialGroups,
    qualities,
    colors,
    sites,
    dimensions,
  } = data ?? {};

  useEffect(() => {
    if (products?.length) {
      setFilteredProducts(products);
    }
  }, [products]);

  useEffect(() => {
    if (commercialGroups && commercialGroups.length > 0) {
      setFilteredCommercialGroupIds(commercialGroups);
    }
  }, [commercialGroups]);
  

  useEffect(() => {
    const filterState = getFilterState() as ProductOfferFilterParam;
    setSelectedCategories(filterState?.categories ?? []);
    setSelectedColors(filterState?.colors ?? []);
    setSelectedDimensions(filterState?.dimensions ?? []);
    setSelectedHeights(filterState?.heights ?? []);
    if (!getOfferTypeProductList() || !!getOfferTypeProductList().length) {
      setSelectedOfferTypes(filterState?.offerTypes ?? []);
    }
    setSelectedSites(filterState?.siteId ?? []);
    setSelectedProducts(filterState?.productsIds ?? []);
    setSelectedProducts(filterState?.commercialGroupIds ?? []);
    setSelectedQualities(filterState?.qualities ?? []);
  }, []);

  const [siteIdViaParam, setSiteIdViaParam] = useState<string>()

  useEffect(() => {
    if(producerCodeParam !== undefined){
      setSiteIdViaParam(producerCodeParam)
    }

    if(data?.sites && siteIdViaParam){
      const site = data.sites.find(s => s.name.includes(siteIdViaParam))
      if(site?.id){
        setSelectedSites([site.id])
        setSelectedOfferTypes(['received', 'inTransit'])
        setChangeFilter(true);
      }
    }
  }, [data, siteIdViaParam])

  useEffect(() => {
    if (dimensions && dimensions.length > 0) {
      setFilteredDimensions(dimensions);
    }

  }, [dimensions]);

  useEffect(() => {
    if (clearFilterStatus) {
      dispatch(offerFiltersActions.setClearFilter(false));
      clearFilter(true);
    }
  }, [clearFilterStatus]);

  useEffect(() => {
    if (colors && colors.length > 0) {
      setFilteredColors(colors);
    }
  }, [colors]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      setFilteredSites(sites);
    }
  }, [sites]);

  const filterCommercialGroupIdsInList = useCallback(
    (term: string) => {
      if (!commercialGroups) return;
      if (term.length > 0) {
        setFilteredCommercialGroupIds(
          commercialGroups.filter((commercialGroup) =>
            commercialGroup.name.toUpperCase().startsWith(term.toUpperCase()),
          ),
        );
      } else {
        setFilteredCommercialGroupIds(commercialGroups);
      }
    },
    [filteredCommercialGroupIds, commercialGroups],
  );

  const filterProductInList = useCallback(
    (term: string) => {
      if (!products) return;
      if (term.length > 0) {
        setFilteredProducts(
          products.filter((product) =>
            product.name.toUpperCase().includes(term.toUpperCase()),
          ),
        );
      } else {
        setFilteredProducts(products);
      }
    },
    [filteredProducts, products],
  );

  const filterDimensionsInList = useCallback(
    (term: string) => {
      if (!dimensions) return;
      if (term.length > 0) {
        setFilteredDimensions(
          dimensions.filter((dimension) =>
            dimension.toUpperCase().startsWith(term.toUpperCase()),
          ),
        );
      } else {
        setFilteredDimensions(dimensions);
      }
    },
    [filteredProducts, products],
  );

  const filterSitesInList = useCallback(
    (term: string) => {
      if (!sites) return;
      if (term.length > 0) {
        setFilteredSites(
          sites.filter((site) =>
            site.name.toUpperCase().includes(term.toUpperCase()),
          ),
        );
      } else {
        setFilteredSites(sites);
      }
    },
    [filteredSites, sites],
  );

  const filterColorsInList = useCallback(
    (term: string) => {
      if (!colors) return;
      if (term.length > 0) {
        setFilteredColors(
          colors.filter((color) =>
            color.name.toUpperCase().startsWith(term.toUpperCase()),
          ),
        );
      } else {
        setFilteredColors(colors);
      }
    },
    [filteredColors, colors],
  );
  const arrayOfferTypes = offerTypes?.concat(['directed'])

  return (
    <Drawer
      title="Filtros"
      placement="left"
      className="drawer-filter"
      headerStyle={{ backgroundColor: '#f78e1e' }}
      bodyStyle={{ padding: '0 30px 0 24px' }}
      onClose={onClose}
      visible={visible}
      extra={
        <Button
          onClick={() => pinnedFilter()}
          type="primary"
          icon={<PushpinOutlined />}
        />
      }
      footer={
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => clearFilter(true)}> Limpar</Button>
          <Button
            type="primary"
            style={{ marginLeft: '15px' }}
            onClick={onClose}
          >
            Fechar
          </Button>
        </Row>
      } 
    >
      <Container>
        <Form form={form}>
          {isAuthenticated && (
            <>
              <Collapse ghost className="collapse-drawer">
                <Panel header="Tipo de Oferta" key="2">
                  {offerTypes ? (
                    <CheckboxGroup
                      onChange={filterOfferTypes}
                      options={arrayOfferTypes?.map((offerType) =>
                        createCheckboxRecord(
                          offerType,
                          offerTypeText[offerType],
                        ),
                      )}
                      value={selectedOfferTypes}
                    />
                  ) : (
                    <div />
                  )}
                </Panel>
              </Collapse>
              <Divider />
            </>
          )}
          {(producerUrl === 'veiling' ||
            !producerUrl ||
            isProducerAuthenticated) && (
            <>
              <Collapse ghost className="collapse-drawer-filter">
                <Panel header="Produtor" key="8">
                  <Form.Item name="inputSite">
                    <Search
                      onChange={(event) =>
                        filterSitesInList(event.target.value)
                      }
                    />
                  </Form.Item>
                  {(form.getFieldValue('inputSite')?.length >= 3 ||
                    selectedSites) && (
                    <GroupSelectAndDeselect>
                      {form.getFieldValue('inputSite')?.length >= 3 && (
                        <>
                          <SpanSelectAllAndDeselectAll onClick={selectAllSite}>
                            Selecionar todos
                          </SpanSelectAllAndDeselectAll>
                          {selectedSites?.length > 0 && (
                            <SeparateSpans>|</SeparateSpans>
                          )}
                        </>
                      )}
                      {selectedSites?.length > 0 && (
                        <SpanSelectAllAndDeselectAll onClick={deselectAllSite}>
                          Limpar tudo
                        </SpanSelectAllAndDeselectAll>
                      )}
                    </GroupSelectAndDeselect>
                  )}
                  {filteredSites ? (
                    filteredSites?.map(({ id, name }) => (
                      <CheckboxComponent
                        onChange={(event) => filterSites(event.target.value)}
                        name={name}
                        key={`checkbox-sites-${id}-${name}`}
                        value={id}
                        checked={!!selectedSites.find((x) => +x === +id)}
                      >
                        {name}
                      </CheckboxComponent>
                    ))
                  ) : (
                    <div />
                  )}
                </Panel>
              </Collapse>
              <Divider />
            </>
          )}
          <Collapse ghost className="collapse-drawer-filter">
            <Panel header="Grupo de Produtos" key="10">
              <Form.Item name="commercialGroupIds">
                <Search
                  onChange={(event) =>
                    filterCommercialGroupIdsInList(event.target.value)
                  }
                />
              </Form.Item>
              {(form.getFieldValue('commercialGroupIds')?.length >= 3 ||
                selectedCommercialGroupIds) && (
                <GroupSelectAndDeselect>
                  {form.getFieldValue('commercialGroupIds')?.length >= 3 && (
                    <>
                      <SpanSelectAllAndDeselectAll
                        onClick={selectAllCommercialGroupIds}
                      >
                        Selecionar todos
                      </SpanSelectAllAndDeselectAll>
                      {selectedCommercialGroupIds?.length > 0 && (
                        <SeparateSpans>|</SeparateSpans>
                      )}
                    </>
                  )}
                  {selectedCommercialGroupIds?.length > 0 && (
                    <SpanSelectAllAndDeselectAll
                      onClick={deselectAllCommercialGroupIds}
                    >
                      Limpar tudo
                    </SpanSelectAllAndDeselectAll>
                  )}
                </GroupSelectAndDeselect>
              )}
              <div className="group-options-filters">
                {filteredCommercialGroupIds ? (
                  filteredCommercialGroupIds?.map(({ id, name }) => (
                    <CheckboxComponent
                      onChange={(event) =>
                        filterCommercialGroupIds(event.target.value)
                      }
                      name={name}
                      key={`checkbox-filteredCommercialGroupIds-${id}-${name}`}
                      value={id}
                      checked={
                        !!selectedCommercialGroupIds.find((x) => +x === +id)
                      }
                    >
                      {name}
                    </CheckboxComponent>
                  ))
                ) : (
                  <div />
                )}
              </div>
            </Panel>
          </Collapse>
          <Divider />
          <Collapse ghost className="collapse-drawer-filter">
            <Panel header="Produto" key="3">
              <Form.Item name="inputProducts">
                <Search
                  onChange={(event) => filterProductInList(event.target.value)}
                />
              </Form.Item>
              {(form.getFieldValue('inputProducts')?.length >= 3 ||
                selectedProducts) && (
                <GroupSelectAndDeselect>
                  {form.getFieldValue('inputProducts')?.length >= 3 && (
                    <>
                      <SpanSelectAllAndDeselectAll onClick={selectAllProducts}>
                        Selecionar todos
                      </SpanSelectAllAndDeselectAll>
                      {selectedProducts?.length > 0 && (
                        <SeparateSpans>|</SeparateSpans>
                      )}
                    </>
                  )}
                  {selectedProducts?.length > 0 && (
                    <SpanSelectAllAndDeselectAll onClick={deselectAllProducts}>
                      Limpar tudo
                    </SpanSelectAllAndDeselectAll>
                  )}
                </GroupSelectAndDeselect>
              )}
              <div className="group-options-filters">
                {filteredProducts ? (
                  filteredProducts?.map(({ id, name }) => (
                    <CheckboxComponent
                      onChange={(event) => filterProducts(event.target.value)}
                      name={name}
                      key={`checkbox-filteredProducts-${id}-${name}`}
                      value={id}
                      checked={!!selectedProducts.find((x) => +x === +id)}
                    >
                      {name}
                    </CheckboxComponent>
                  ))
                ) : (
                  <div />
                )}
              </div>
            </Panel>
          </Collapse>
          <Divider />
          <Collapse ghost className="collapse-drawer">
            <Panel header="Categoria" key="1">
              {categories ? (
                <CheckboxGroup
                  onChange={filterCategories}
                  options={categories?.map(({ id, name }) =>
                    createCheckboxRecord(+id, name),
                  )}
                  value={selectedCategories}
                />
              ) : (
                <div />
              )}
            </Panel>
          </Collapse>
          <Divider />
          <Collapse ghost className="collapse-drawer">
            <Panel header="Dimensão/Altura" key="7">
              <Form.Item name="inputDimensions">
                <Search
                  onChange={(event) =>
                    filterDimensionsInList(event.target.value)
                  }
                />
              </Form.Item>
              {(form.getFieldValue('inputDimensions')?.length >= 3 ||
                selectedDimensions) && (
                <GroupSelectAndDeselect>
                  {form.getFieldValue('inputDimensions')?.length >= 3 && (
                    <>
                      <SpanSelectAllAndDeselectAll
                        onClick={selectAllDimensions}
                      >
                        Selecionar todos
                      </SpanSelectAllAndDeselectAll>
                      {selectedDimensions?.length > 0 && (
                        <SeparateSpans>|</SeparateSpans>
                      )}
                    </>
                  )}
                  {selectedDimensions?.length > 0 && (
                    <SpanSelectAllAndDeselectAll
                      onClick={deselectAllDimensions}
                    >
                      Limpar tudo
                    </SpanSelectAllAndDeselectAll>
                  )}
                </GroupSelectAndDeselect>
              )}
              {filteredDimensions ? (
                filteredDimensions?.map((dimension) => (
                  <CheckboxComponent
                    onChange={(event) => filterDimensions(event.target.value)}
                    name={dimension}
                    key={`checkbox-filteredDimensions-${dimension}`}
                    value={dimension}
                    checked={!!selectedDimensions.find((x) => x === dimension)}
                  >
                    {dimension}
                  </CheckboxComponent>
                ))
              ) : (
                <div />
              )}
            </Panel>
          </Collapse>
          <Divider />
          <Collapse ghost className="collapse-drawer">
            <Panel header="Qualidade" key="4">
              {qualities ? (
                <CheckboxGroup
                  onChange={filterQualities}
                  options={qualities?.map(({ id, name }) =>
                    createCheckboxRecord(String(id), name),
                  )}
                  value={selectedQualities}
                />
              ) : (
                <div />
              )}
            </Panel>
          </Collapse>
          <Divider />
          <Collapse ghost className="collapse-drawer-filter">
            <Panel header="Cor" key="5">
              <Form.Item name="inputColors">
                <Search
                  onChange={(event) => filterColorsInList(event.target.value)}
                />
              </Form.Item>
              {(form.getFieldValue('inputColors')?.length >= 3 ||
                selectedColors) && (
                <GroupSelectAndDeselect>
                  {form.getFieldValue('inputColors')?.length >= 3 && (
                    <>
                      <SpanSelectAllAndDeselectAll onClick={selectAllColors}>
                        Selecionar todos
                      </SpanSelectAllAndDeselectAll>
                      {selectedColors?.length > 0 && (
                        <SeparateSpans>|</SeparateSpans>
                      )}
                    </>
                  )}
                  {selectedColors?.length > 0 && (
                    <SpanSelectAllAndDeselectAll onClick={deselectAllColors}>
                      Limpar tudo
                    </SpanSelectAllAndDeselectAll>
                  )}
                </GroupSelectAndDeselect>
              )}
              <div className="group-options-filters">
                {filteredColors ? (
                  filteredColors?.map(({ id, name }) => (
                    <CheckboxComponent
                      onChange={(event) => filterColors(event.target.value)}
                      name={name}
                      key={`checkbox-filteredColors-${name}-${String(id)}`}
                      value={String(id)}
                      checked={!!selectedColors.find((x) => x === String(id))}
                    >
                      {name}
                    </CheckboxComponent>
                  ))
                ) : (
                  <div />
                )}
              </div>
            </Panel>
          </Collapse>
          <Divider />

          <Loader spinning={isLoading || isFetching} size="large" />
        </Form>
      </Container>
    </Drawer>
  );
}
