/* eslint-disable no-plusplus */
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Button } from 'antd';

import TextArea from 'antd/lib/input/TextArea';
import {
  ModalHeader,
  ModalEditDelivery,
  ModalBody
} from './style';

interface Props {
  onSubmit: (value: any, property: string, record: any) => void;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  valueObs: string;
  valueRecord: any;
}

export default function ObservationsOffer({
  onSubmit,
  visible,
  setVisible,
  valueObs,
  valueRecord
} : Props) {
  const { t } = useTranslation();
  const [formModal] = Form.useForm();

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onFinish = useCallback(
    (values: any) => {
      if (valueRecord) {
        onSubmit(values.observation, 'observationText', valueRecord);
      }
      setVisible(false);
    },
    [onSubmit, valueRecord, setVisible]
  );

  useEffect(() => {
    if (visible && valueObs) {
      formModal.setFieldsValue({ observation: valueObs });
    }
  }, [valueObs, visible, formModal]);

  return (
    <ModalEditDelivery
      title=""
      width={800}
      visible={visible}
      onCancel={handleCancel}
    >
      <ModalHeader>
        <span>{t('buyIntention.observationModalTitle')}</span>
      </ModalHeader>
      <ModalBody>
        <Form form={formModal} onFinish={onFinish} layout="vertical">
          <Row gutter={16}>
            <Col lg={24}>
              <Form.Item name="observation">
                <TextArea
                  autoFocus
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      formModal?.submit();
                    }
                  }}
                  placeholder={t(
                    'buyIntention.observationModalPlaceholder'
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20 }} justify="end">
            <Col xs={24} md={12}>
              <Button
                style={{ width: '100%', marginTop: 10 }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} md={12}>
              <Button
                style={{ width: '100%', marginTop: 10 }}
                type="primary"
                htmlType="submit"
              >
                Aplicar
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </ModalEditDelivery>
  );
};
