import moment from 'moment';
import { offerTypeMap } from 'enums/offerTypeMap';

type OfferInfo = {
  offerId: number;
  billingDateValue: moment.Moment | null;
  deliveryDateValue: moment.Moment | null;
  customerId: number;
  productColorId: number;
  voucherId: number;
  productId: number;
  productQualityId: number;
  siteId: number;
  producerId: number;
  offerType: number;
  selectedTypeSale: string;
  billingDate: string;
  deliveryDate: string;
  endDate: Date;
  lkpAuctionDate: Date;
};

export const prepareObjectToInsertInCart = (
  valueToInsert: any,
  offerInfo: OfferInfo,
) => {
  const {
    customerId,
    productColorId,
    voucherId,
    productId,
    productQualityId,
    siteId,
    producerId,
    offerType,
    selectedTypeSale,
    billingDate,
    deliveryDate,
    offerId,
    billingDateValue,
    deliveryDateValue,
    endDate,
    lkpAuctionDate,
  } = offerInfo;
  let billingDateTemp = '';
  let deliveryDateTemp = '';
  let endDateIso = new Date();
  if (offerType === 0 || offerType === 3) {
    billingDateTemp = billingDate || billingDateValue?.toISOString() || '';
    deliveryDateTemp = deliveryDate || deliveryDateValue?.toISOString() || '';
  } else {
    endDateIso = endDate;
  }

  const objectPayload = {
    customerId,
    cartItems: [
      {
        packingId: valueToInsert.packingSelectedId,
        volOfferId: offerType === 0 || offerType === 3 ? offerId : null,
        lkpOfferId: offerType === 1 || offerType === 2 ? offerId : null,
        volumeType: selectedTypeSale,
        offerType: offerTypeMap[offerType],
        quantity: +valueToInsert.quantityPacking,
        productColorId,
        voucherId,
        productId,
        productQualityId,
        siteId,
        producerId,
        billingDate:
          offerType === 1 || offerType === 2 ? billingDate : billingDateTemp,
        deliveryDate:
          offerType === 1 || offerType === 2 ? billingDate : deliveryDateTemp,
        lkpAuctionDate:
          offerType === 1 || offerType === 2 ? billingDate : undefined,
      },
    ],
  };
  return {
    billingDateTemp,
    deliveryDateTemp,
    objectPayload,
  };
};
