import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RefreshTokenParam } from 'model/types';
import UserByAccount from 'model/UserByAccount';
import { getAuthorizationToken } from './config';

export const identityApi = createApi({
  reducerPath: 'identityApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_SESSION_URL,
    prepareHeaders: (headers) => {
      const token = getAuthorizationToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getRefreshToken: builder.mutation<void, RefreshTokenParam>({
      query: (param: RefreshTokenParam) => {
        const formBody = `refresh_token=${param.refresh_token}&client_id=${param.client_id}&client_secret=${param.client_secret}&scope=offline_access openid profile&grant_type=${param.grant_type}`;
        return {
          url: `/connect/token`,
          method: 'POST',
          headers: { 'content-Type': 'application/x-www-form-urlencoded' },
          body: formBody,
        };
      },
    }),
    getUsersByAccountCode: builder.query<UserByAccount[], string>({
      query: (accountCode: string) => ({
        url: `/user/byaccount/${accountCode}`,
      }),
    }),
  }),
});

export const {
  useGetRefreshTokenMutation,
  useGetUsersByAccountCodeQuery,
  endpoints,
} = identityApi;
