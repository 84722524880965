import { memo } from 'react';

import { Container, Size } from './styles';

interface Props {
  barcode: string;
  gtincode: string;
  size?: Size;
}

function InfoBarCode({ barcode, gtincode, size = 'small' }: Props) {
  return (
    <Container size={size}>
      <span>{barcode}</span>
      <span>{gtincode}</span>
    </Container>
  );
}

export default memo(InfoBarCode);
