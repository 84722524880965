import { createSelector } from '@reduxjs/toolkit';
import { CartState } from './cartSlice';

export const getRoot = (state: {cart: CartState}) => state.cart as CartState;

export const getLkpOfferId = createSelector(
	[getRoot],
	(state) => state.lkpOfferId ?? 0,
);
export const getVolOfferId = createSelector(
	[getRoot],
	(state) => state.volOfferId ?? 0,
);
export const getCustomerId = createSelector(
	[getRoot],
	(state) => state.customerId,
);
export const getVolTotal = createSelector(
	[getRoot],
	(state) => state.volTotal,
);
export const getLkpTotal = createSelector(
	[getRoot],
	(state) => state.lkpTotal,
);

export const getTotalItems = createSelector(
	[getRoot],
	(state) => state.totalItems,
);
export const getUser = createSelector(
	[getRoot],
	(state) => state.user,
);
export const getDeliveryDate = createSelector(
	[getRoot],
	(state) => state.deliveryDate,
);
export const getBillingDate = createSelector(
	[getRoot],
	(state) => state.billingDate,
);
export const getVolExpiration = createSelector(
	[getRoot],
	(state) => state.volExpiration,
);
export const getLkpExpiration = createSelector(
	[getRoot],
	(state) => state.lkpExpiration,
);
export const getTotalLkpItems = createSelector(
	[getRoot],
	(state) => state.totalLkpItems,
);
export const getTotalVolItems = createSelector(
	[getRoot],
	(state) => state.totalVolItems,
);
export const getRefetchCartItems = createSelector(
	[getRoot],
	(state) => state.refetchRequestCartItems,
);
export const getLKpCreationDate = createSelector(
	[getRoot],
	(state) => state.lKpCreationDate,
);
export const getVolCreationDate = createSelector(
	[getRoot],
	(state) => state.volCreationDate,
);
