import { memo, useCallback, useState } from 'react';

import {
  BgColorsOutlined,
  ExpandOutlined,
  GiftOutlined,
  GroupOutlined,
  PictureOutlined,
  TrophyOutlined,
} from '@ant-design/icons';

import Quality from 'components/UI/Quality';
import Badge from 'components/UI/Badge';
import ProductOfferPrice from 'components/BusinessLogic/Product/ProductOfferPrice';
import ShowDetails from 'components/UI/ShowDetails';

import SelectPacking from 'components/BusinessLogic/Product/SelectPacking';
import { offerTypeColor } from 'screens/Products/utils/consts/OfferType.const';
import ProductActions from 'screens/Products/components/BuyIntentionActions';
import InfoNew from '../../../components/InfoNew';
import InfoDirectedTag from '../../../components/InfoDirectedTag';
import InfoVoucherTag from '../../../components/InfoVoucherTag';
import InfoDelivery from '../../../components/InfoDelivery';
import InfoProductName from '../../../components/InfoProductName';
import InfoBarCode from '../../../components/InfoBarCode';
import InfoObservation from '../../../components/InfoObservation';
import InfoVoucher from '../../../components/InfoVoucher';

import { ModesProps } from '../../props';

import {
  Container,
  ContainerFixed,
  ContainerFloat,
  ImageContainer,
  ButtonImage,
  IconContainer,
  ProductInfoContainer,
  ColContainer,
  InfoRow,
  PrimaryInfo,
  InformationIconContainer,
  InformationContainer,
  Information,
  InformationLabel,
  InformationValue,
  SecondaryInfo,
  BadgeContainer,
  UnitValueContainer,
  ProducerValue,
  ContainerComplementary,
  InfoComplementary,
  InfoValueContainerGroup,
  InfoValueContainer,
  InfoValue,
  Label,
  Value,
  Actions,
} from './styles';
import InfoDirected from '../../../components/InfoDirected';

function ListMobile({
  isAuthenticated,
  isProducerAuthenticated,
  product,
  openImage,
  packingSelected,
  getLowPrice,
  handleOnSelectPacking,
  showModalComposition,
  addInCart,
  buy,
  producerUrl,
  selectedTypeSale,
  onChangePackingTypeSale
}: ModesProps) {
  const [showComplement, setShowComplement] = useState(false);

  const toggleShowComplement = useCallback(() => {
    setShowComplement((value) => !value);
  }, []);

  return (
    <Container>
      <ContainerFixed isShowingComplement={showComplement ? 1 : 0}>
        <ContainerFloat>
          {product.isNewOffer && <InfoNew />}
          {product.offerType === 3 && (
            <InfoDirectedTag specialPrice={product.specialPrice} />
          )}
          {product.voucherId && (
            <InfoVoucherTag
              reajustmentAmount={product.reajustmentAmount}
              voucherReajustmentType={product.voucherReajustmentType}
            />
          )}
        </ContainerFloat>

        <ImageContainer isShowingComplement={showComplement ? 1 : 0}>
          <ButtonImage onClick={openImage}>
            <IconContainer>
              <PictureOutlined />
            </IconContainer>
          </ButtonImage>
        </ImageContainer>
        <ProductInfoContainer role="button" onClick={toggleShowComplement}>
          <ColContainer>
            <InfoRow>
              <PrimaryInfo>
                <InfoDelivery
                  isLkpOffer={product.isLkpOffer}
                  endDateFormatted={product.endDateFormatted}
                  endDate={product.endDate}
                  startDate={product.startDate}
                  siteDeliveryPatterns={product.siteDeliveryPatterns}
                />
                <InformationContainer>
                  <InfoProductName longName={product.longName} />
                  <InfoBarCode
                    barcode={product.barcode}
                    gtincode={product.gtincode}
                  />
                </InformationContainer>
              </PrimaryInfo>
            </InfoRow>
            <InfoRow>
              <PrimaryInfo>
                <InformationIconContainer>
                  <TrophyOutlined />
                </InformationIconContainer>
                <InformationContainer>
                  <Information>
                    <InformationLabel>Qualidade:</InformationLabel>
                    <InformationValue>
                      <Quality quality={product.quality} />
                    </InformationValue>
                  </Information>
                </InformationContainer>
              </PrimaryInfo>
              <SecondaryInfo>
                <Information inline={1}>
                  <ProducerValue>{product.siteName}</ProducerValue>
                </Information>
              </SecondaryInfo>
            </InfoRow>
          </ColContainer>
          <ColContainer alignItems="end">
            <BadgeContainer>
              <Badge type="small" {...offerTypeColor[product.offerType]} />
            </BadgeContainer>
            <UnitValueContainer>
              {isAuthenticated && (
                <ProductOfferPrice
                  price={
                    packingSelected?.additionalCost
                      ? product.packingCost + getLowPrice
                      : getLowPrice
                  }
                  hasSavingPrice={!!product.hasSavingPrice}
                  savingPrice={product.savingPrice}
                />
              )}
            </UnitValueContainer>
          </ColContainer>
        </ProductInfoContainer>
      </ContainerFixed>
      {showComplement && (
        <ContainerComplementary show={showComplement ? 1 : 0}>
          <InfoComplementary>
            {!!product.observation && (
              <InfoValueContainerGroup>
                <InfoObservation observation={product.observation} />
              </InfoValueContainerGroup>
            )}
            <InfoValueContainerGroup>
              <InfoValueContainer>
                <InformationIconContainer>
                  <ExpandOutlined />
                </InformationIconContainer>
                <InfoValue>
                  <Label>Dimensão:</Label>
                  <Value>{product.dimension}</Value>
                </InfoValue>
              </InfoValueContainer>
              <InfoValueContainer>
                <InformationIconContainer>
                  <GroupOutlined />
                </InformationIconContainer>
                <InfoValue>
                  <Label>Múltiplo:</Label>
                  <Value>{packingSelected?.multiple}</Value>
                </InfoValue>
              </InfoValueContainer>
              <InfoValueContainer>
                <InformationIconContainer>
                  <BgColorsOutlined />
                </InformationIconContainer>
                <InfoValue>
                  <Label>Cores:</Label>
                  <Value>{product.colors}</Value>
                </InfoValue>
              </InfoValueContainer>
            </InfoValueContainerGroup>

            <InfoValueContainerGroup>
              <InfoValueContainer>
                <InformationIconContainer>
                  <GiftOutlined />
                </InformationIconContainer>
                <InfoValue>
                  <Label>Embalagem:</Label>
                  <Value>
                    <SelectPacking
                      offerId={product.offerId}
                      onSelect={handleOnSelectPacking}
                      packings={product.packings}
                      value={packingSelected.id}
                      type="small"
                    />
                  </Value>
                </InfoValue>
              </InfoValueContainer>
            </InfoValueContainerGroup>

            {!!product.voucherId && (
              <InfoValueContainerGroup>
                <InfoVoucher voucher={product.infoVoucher || ''} />
              </InfoValueContainerGroup>
            )}
            {!!product.specialPrice && !!product.minimumQuantity && (
              <InfoDirected
                directedMessage={
                  product.minimumQuantity === 1
                    ? `*Para aplicação do valor promocional, a compra mínima deverá ser de 1 unidade`
                    : `*Para aplicação do valor promocional, a compra mínima deverá ser de ${product.minimumQuantity} unidades`
                }
              />
            )}
          </InfoComplementary>
          {isAuthenticated && (
            <Actions>
              <ProductActions
                product={product}
                packingSelected={packingSelected}
                isProducerAuthenticated={isProducerAuthenticated}
                onClickBuy={addInCart}
                onClickFastBuy={buy}
                actionsButtonType="small"
                selectedTypeSale={selectedTypeSale}
                onChangePackingTypeSale={onChangePackingTypeSale}
              />
              {/* <ShowDetails
                producerUrl={producerUrl}
                offerId={product.offerId}
                offerType={product.offerType}
                packingSelectedId={packingSelected.id}
                selectedTypeSale={selectedTypeSale}
              /> */}
            </Actions>
          )}
        </ContainerComplementary>
      )}
    </Container>
  );
}

export default memo(ListMobile);
