import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
} from 'antd';
import { SaleTypeFilterEnum } from 'enums/saleTypeFilter';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetSaleUsersByCustomerIdQuery } from 'services/ecommerceApi';
import { getSelectedCustomerId } from 'store/state/session/sessionSelectors';

const { Option } = Select;
interface MyOrdersFilterProps {
  form: FormInstance;
  onSubmit: () => void;
}
export default function MyOrdersFilter(props: MyOrdersFilterProps) {
  const { form, onSubmit } = props;
  const { t: translate } = useTranslation();
  const [showAdvancedFilters, setShowAdvancedFilters] =
    useState<boolean>(false);
  const selectedCustomerId = useSelector(getSelectedCustomerId);

  const { data: users, refetch: refetchUsers } =
    useGetSaleUsersByCustomerIdQuery(
      {
        customerId: selectedCustomerId,
      },
      { skip: !selectedCustomerId },
    );

  useEffect(() => {
    refetchUsers();
  }, [selectedCustomerId]);

  useEffect(() => {
    form.setFieldsValue({
      'Data.UserName': '',
    });
  }, [selectedCustomerId, form]);

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Row gutter={16}>
        <Col lg={5}>
          <Form.Item
            label={translate('myOrders.orderType.formFilter.saleDate')}
            name="Data.Date"
          >
            <DatePicker defaultValue={moment()} format="DD/MM/YYYY" />
          </Form.Item>
        </Col>

        <Col lg={5}>
          <Form.Item
            label={translate('myOrders.orderType.formFilter.filterDateBy')}
            name="Data.TypeDate"
          >
            <Select>
              <Option key="buyDate" value="buyDate">
                {translate('myOrders.orderType.formFilter.buyDate')}
              </Option>
              <Option key="deliveryDate" value="deliveryDate">
                {translate('myOrders.orderType.formFilter.deliveryDate')}
              </Option>
              <Option key="billingDate" value="billingDate">
                {translate('myOrders.orderType.formFilter.fatDate')}
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col lg={5}>
          <Form.Item
            label={translate('myOrders.orderType.formFilter.saleType')}
            name="Data.SaleTypes"
          >
            <Select>
              <Option
                key={SaleTypeFilterEnum.ALL}
                value={SaleTypeFilterEnum.ALL}
              >
                {translate('myOrders.orderType.formFilter.all')}
              </Option>
              <Option
                key={SaleTypeFilterEnum.DIRECTED}
                value={SaleTypeFilterEnum.DIRECTED}
              >
                {translate('myOrders.orderType.formFilter.directed')}
              </Option>
              <Option
                key={SaleTypeFilterEnum.VOL}
                value={SaleTypeFilterEnum.VOL}
              >
                {translate('myOrders.orderType.formFilter.vol')}
              </Option>
              <Option
                key={SaleTypeFilterEnum.LKP}
                value={SaleTypeFilterEnum.LKP}
              >
                {translate('myOrders.orderType.formFilter.lkp')}
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col lg={5}>
          <Form.Item
            label={translate('myOrders.orderType.formFilter.status')}
            name="Data.Status"
          >
            <Select allowClear>
              <Option key="all" value="">
                {translate('myOrders.orderType.formFilter.all')}
              </Option>
              <Option key="F" value="F">
                {translate('myOrders.orderType.formFilter.generate')}
              </Option>
              <Option key="E" value="E">
                {translate('myOrders.orderType.formFilter.notGenerate')}
              </Option>
              <Option key="A" value="A">
                {translate('myOrders.orderType.formFilter.open')}
              </Option>
              <Option key="R" value="R">
                {translate('myOrders.orderType.formFilter.process')}
              </Option>
              <Option key="P" value="P">
                {translate('myOrders.orderType.formFilter.programmed')}
              </Option>
              <Option key="C" value="C">
                {translate('myOrders.orderType.formFilter.canceled')}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          lg={2}
          style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          <Button
            icon={<FilterOutlined />}
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          />
          <Button icon={<SearchOutlined />} htmlType="submit" type="primary" />
        </Col>
      </Row>
      {showAdvancedFilters && (
        <Row gutter={16}>
          <Col lg={5}>
            <Form.Item
              label={translate('myOrders.orderType.formFilter.orderNumber')}
              name="Data.OrderNumber"
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col lg={5}>
            <Form.Item
              label={translate('myOrders.orderType.formFilter.producer')}
              name="Data.SiteTradingName"
            >
              <Input type="text" />
            </Form.Item>
          </Col>

          <Col lg={5}>
            <Form.Item
              label={translate(
                'myOrders.orderType.formFilter.productDescription',
              )}
              name="Data.ProductDescription"
            >
              <Input type="text" />
            </Form.Item>
          </Col>

          <Col lg={5}>
            <Form.Item
              label={translate('myOrders.orderType.formFilter.accounts')}
              name="Data.UserName"
            >
              <Select allowClear>
                {users?.map((user) => (
                  <Option key={user} value={user}>
                    {user}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
}
