import { Container } from './styles';

interface Props {
  specialPrice: number;
}

function InfoDirectedTag({ specialPrice } :Props) {
  return (
    <Container>
      { !Number.isNaN(specialPrice) && specialPrice > 0 ? `Oferta Direcionada R$${Math.abs(specialPrice)}` : 'Oferta Direcionada' }
    </Container>
  );
}

export default InfoDirectedTag;
