import { memo } from 'react';

import { Select } from 'antd';

import Packing from 'model/Packing';

import { SelectCustom, SelectCustomType } from './styles';

interface Props {
  onSelect(id: number): void;
  packings: Packing[];
  offerId: number;
  value: number;
  type?: SelectCustomType;
  onDropdownVisibleChange?: (value: boolean) => void;
}

function SelectPacking({
  onSelect,
  packings,
  offerId,
  value,
  type,
  onDropdownVisibleChange,
}: Props) {
  return (
    <SelectCustom
      bordered={false}
      onSelect={(value) => onSelect(parseInt(value!.toString(), 10))}
      value={value}
      type={type}
      onDropdownVisibleChange={onDropdownVisibleChange}
    >
      {packings.map((packing: Packing) => (
        <Select.Option value={packing.id} key={`${offerId}-${packing.id}`}>
          {packing.code} - {packing.abbrev}
        </Select.Option>
      ))}
    </SelectCustom>
  );
}

export default memo(SelectPacking);
