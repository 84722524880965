import { Tooltip } from 'antd';
import Select, { SelectValue } from 'antd/lib/select';

import { OrderSelect as OrderSelectContainer } from './styles';

interface PropsOrderSelect {
  orderBy: string;
  changeOrder: (value: SelectValue, option: any) => void;
}

const { Option } = Select;

export default function OrderSelect({
  orderBy,
  changeOrder,
}: PropsOrderSelect) {
  return (
    <Tooltip title="Ordenação das ofertas">
      <OrderSelectContainer
        value={orderBy}
        onChange={changeOrder}
        bordered={false}
      >
        <Option value="AZ">A - Z</Option>
        <Option value="ZA">Z - A</Option>
      </OrderSelectContainer>
    </Tooltip>
  );
}
