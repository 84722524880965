const palette = {
  white: '#FFFFFF',
  black: '#000000',
  grey: {
    '1': '#FFFFFF',
    '2': '#FAFAFA',
    '3': '#F5F5F5',
    '4': '#F0F0F0',
    '5': '#D9D9D9',
    '6': '#BFBFBF',
    '7': '#8C8C8C',
    '8': '#595959',
    '9': '#262626',
    '10': '#000000',
  },
  veiling: {
    '1': '#FFF9EB',
    '2': '#FFEAC2',
    '3': '#FFD899',
    '4': '#FFC370',
    '5': '#FFAC47',
    '6': '#F78E1E',
    '7': '#D16D0F',
    '8': '#AB4F03',
    '9': '#853700',
    '10': '#5E2400',
  },
  dustRed: {
    '50': '',
    '100': '',
    '200': '',
    '300': '',
    '400': '',
    '500': '',
    '600': '',
    '700': '',
    '800': '',
    '900': '',
  },
  volcano: {
    '50': '',
    '100': '',
    '200': '',
    '300': '',
    '400': '',
    '500': '',
    '600': '',
    '700': '',
    '800': '',
    '900': '',
  },
  sunsetOrange: {
    '50': '',
    '100': '',
    '200': '',
    '300': '',
    '400': '',
    '500': '',
    '600': '',
    '700': '',
    '800': '',
    '900': '',
  },
  calendulaGold: {
    '50': '',
    '100': '',
    '200': '',
    '300': '',
    '400': '',
    '500': '',
    '600': '',
    '700': '',
    '800': '',
    '900': '',
  },
};

const colors = {
  primary: {
    selectedBackground: palette.veiling['1'],
    border: palette.veiling['3'],
    hover: palette.veiling['5'],
    normal: palette.veiling['6'],
    click: palette.veiling['7'],
  },
};

const font = {
  family: {
    primary: 'Roboto',
  },
  style: 'normal',
  weight: {
    regular: 400,
    semiBold: 500,
    bold: 700,
  },
  size: {
    xs: '12px',
    sm: '14px',
    md1: '16px',
    md2: '20px',
    lg: '24px',
    xl: '30px',
    xxl: '38px',
    xxxl: '46px',
    xxxxl: '56px',
  },
  rem: {
    xs: 0.75,
    sm: 0.875,
    md1: 1,
    md2: 1.25,
    lg: 1.5,
    xl: 1.875,
    xxl: 2.375,
    xxxl: 2.875,
    xxxxl: 3.5,
  },
};

const layers = {
  negative: -1,
  layer1: 10,
  layer2: 20,
  layer3: 30,
  layer4: 40,
  layer5: 50,
  layer6: 60,
};

const Theme = { colors, font, layers };

export type ThemeVeiling = typeof Theme;

export default Theme;
