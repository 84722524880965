import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type productsState = {
  offerId: number | undefined;
  infinity: boolean;
};

export const initialState: productsState = {
  offerId: undefined,
  infinity: false,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setOfferId(state, action: PayloadAction<number | undefined>) {
      state.offerId = action.payload;
    },
    setInfinity(state, action: PayloadAction<boolean>) {
      state.infinity = action.payload;
    },
  },
});

export const { actions, reducer } = productsSlice;
