import Badge from 'components/UI/Badge';
import PopoverCalendar from 'components/UI/PopoverCalendarDates';
import { DeliveryPatterns } from 'model/DeliveryPatterns';
import moment from 'moment';
import { useMemo } from 'react';
import * as S from './styles';

type Props = {
  offerType: number;
  startDate?: Date;
  endDate?: Date;
  hideCalendar?: boolean;
  siteDeliveryPatterns?: DeliveryPatterns[];
};

export default function OfferTypeBadge({
  endDate,
  hideCalendar,
  startDate,
  offerType,
  siteDeliveryPatterns,
}: Props) {
  const date = moment(endDate).format('DD/MM');

  const prefixIcon: {
    prefix: JSX.Element;
    backgroundColor: string;
    text: string;
  } = useMemo(() => {
    switch (offerType) {
      case 0:
        return {
          prefix: (
            <PopoverCalendar
              startDate={startDate}
              endDate={endDate}
              siteDeliveryPatterns={siteDeliveryPatterns}
            />
          ),
          backgroundColor: '#FAAD14',
          text: 'estoque no produtor (ENP)',
        };
      case 1:
        return {
          prefix: <span>{date}</span>,
          backgroundColor: '#52C41A',
          text: 'recepcionado (LKP)',
        };
      case 2:
        return {
          prefix: <span>{date}</span>,
          backgroundColor: '#13C2C2',
          text: 'no sítio (LKP)',
        };
      case 3:
        return {
          prefix: (
            <PopoverCalendar
              startDate={startDate}
              endDate={endDate}
              siteDeliveryPatterns={siteDeliveryPatterns}
            />
          ),
          backgroundColor: '#FaAD14',
          text: 'estoque no produtor (ENP)',
        };
      default:
        return {
          prefix: <div />,
          backgroundColor: 'transparent',
          text: '',
        };
    }
  }, [date, endDate, offerType, siteDeliveryPatterns, startDate]);

  return (
    <S.Container>
      {!hideCalendar && prefixIcon ? prefixIcon?.prefix : null}
      <Badge
        backgroundColor={prefixIcon.backgroundColor}
        text={prefixIcon.text}
      />
    </S.Container>
  );
}
