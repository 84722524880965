import styled from "styled-components";

export const Container = styled.div`
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 2px;

    transform: translateY(-50%);
`;

export const PopoverContainer = styled.div``;

export const PopoverItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    + div {
        margin-top: 16px;
    }
`;

export const Label = styled.p`
    margin-bottom: 0;
`;

export const Title = styled.h1`
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 6px 0;

  margin-bottom: 12px;
`;
