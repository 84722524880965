import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useGetProductGroupsQuery } from 'services/ecommerceApi';
import { ProductGroup } from 'model/types';

interface Props extends SelectProps<number | ''> {
  allowEmpty?: boolean;
  siteId: number;
  categoryId: number;
}
export default function ProductGroupsSelect({
  allowEmpty = false,
  siteId,
  categoryId,
  ...props
}: Props) {
  const { t } = useTranslation();
  const [productGroups, setProductGroups] = useState<ProductGroup[]>();

  const { data, isLoading, error } = useGetProductGroupsQuery(siteId);

  useEffect(() => {
    if (categoryId) {
      const productGroupsFilteredByCategory =
        data?.filter((group) => group.categoryId === categoryId) ?? [];
      setProductGroups(productGroupsFilteredByCategory);
      return;
    }
    setProductGroups(data);
  }, [categoryId, data]);

  return (
    <Select
      showSearch
      allowClear
      loading={isLoading}
      placeholder={t('generals.selectPlaceholder')}
      optionFilterProp="children"
      filterOption
      {...props}
    >
      {allowEmpty ? (
        <Select.Option key="all" value="">
          Todos
        </Select.Option>
      ) : null}
      {!error
        ? productGroups?.map((productGroup) => (
            <Select.Option key={productGroup.id} value={productGroup.id}>
              {productGroup.description}
            </Select.Option>
          ))
        : null}
    </Select>
  );
}
