import styled from "styled-components";

export const ProductHistoryComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px 8px 24px;
`;

export const InfoBuyer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const InfoStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InformationProduct = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InformationsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;
`;

export const NameProduct = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1px;
`;

export const ProductValues = styled.p`
  font-size: 12px;
  font-weight: 400;
`;

export const InfoText = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
`;