import styled, { css } from 'styled-components';
import { Button } from 'antd';

interface ButtonCustomProps {
  infocus: number;
}

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  background-color: #f5f5f5;
  width: 100%;
`;

export const Divider = styled.div`
  background: #d9d9d9;
  height: 1px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f78e1e;
  padding: 16px 0px;
`;

export const HeaderLine1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;

  @media (max-width: 500px) {
    margin: 0px 30px;
  }
`;

export const HeaderLine1GroupLeft = styled.div``;

export const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  display: block;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const Legend = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;

  margin-top: 4px;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const HeaderLine2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 40px;

  @media (max-width: 500px) {
    margin: 0px 30px;
    flex-direction: column;
  }
`;

export const ContinueShoppingButton = styled(Button)`
  width: 167px;
  height: 32px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  padding: 8px 0px;
  align-items: center;
`;

export const TabsTypeProducts = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 16px;
`;

export const ButtonCustom = styled(Button)<ButtonCustomProps>`
  &:not(:first-child) {
    margin-left: 16px;
  }
  ${(props) =>
    props.infocus &&
    css`
      > span {
        color: #f78e1e;
        font-weight: 700;
      }
    `}
`;

export const TypeProductsContainer = styled.div`
  margin: 16px 40px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 5px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;

  margin-top: 16px;
`;

export const ButtonContainer = styled.div``;
