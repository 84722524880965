import React, { useState, useCallback, useRef } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Monetary from 'components/UI/Data/Monetary';
import { useTranslation } from 'react-i18next';
import { offerTypeMap } from 'enums/offerTypeMap';
import * as BuyIntentionSelectors from 'store/state/buyIntention/buyIntenteionSelectors';
import ProductList from 'screens/Products/components/BuyIntentionProductList';
import { actions as buyIntentionActions } from 'store/state/buyIntention/buyIntentionSlice';
import { isMobile } from 'react-device-detect';
import * as S from './styles';

type PropsCreateComposition = {
  modalIsVisible?: boolean;
  setModalIsVisible: () => void;
};

export default function ModalCreateComposition({
  modalIsVisible,
  setModalIsVisible,
}: PropsCreateComposition) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lastRef = useRef<HTMLDivElement>(null);

  const offersList = useSelector(BuyIntentionSelectors.getOffersList);
  const subTotal = useSelector(BuyIntentionSelectors.getSubTotalList);
  const haveProductsSelected = useSelector(
    BuyIntentionSelectors.getIdsProductSelected,
  );

  const total = subTotal.reduce(getTotal, 0);
  function getTotal(total: any, item: any) {
    return total + item.value;
  }

  const [offerInserted, setOfferInserted] =
    useState<{ offerId: number; offerType: offerTypeMap }>();

  const handleClose = () => {
    dispatch(buyIntentionActions.setSClearSubTotal());
    dispatch(buyIntentionActions.setClearIdsProduct());
    setModalIsVisible();
  };

  const handleConfirm = () => {
    dispatch(buyIntentionActions.setSubmitOffers(true));
  };

  const handleOfferInserted = useCallback(
    (offerId: number, offerType: offerTypeMap) => {
      setOfferInserted({ offerId, offerType });
    },
    [],
  );

  const handleChangePagination = useCallback(
    (page: number, totalPage: number) => {
      console.log('ok');
    },
    [],
  );

  return (
    <S.ModalCreateComposition
      visible={modalIsVisible}
      closable={false}
      centered
      keyboard={false}
      width="110em"
      onCancel={handleClose}
      footer={
        <S.ModalFooterCreateComposition isMobile={isMobile}>
          {isMobile ? (
            <S.ModalFooterActionsMObile>
              <S.ModalFooterInformationText>
                <p>{t('buyIntention.offers.totalPrice')}</p>
                <span>
                  <Monetary symbol value={total} />
                </span>
              </S.ModalFooterInformationText>

              <S.ContentButtons>
                <Button style={{ marginBottom: 8 }} onClick={handleClose}>
                  {t('buyIntention.offers.back')}
                </Button>

                <Button
                  disabled={haveProductsSelected.length < 1}
                  type="primary"
                  style={{ marginLeft: 16, marginBottom: 8 }}
                  onClick={() => handleConfirm()}
                >
                  {t('buyIntention.offers.checkout')}
                </Button>
              </S.ContentButtons>
            </S.ModalFooterActionsMObile>
          ) : (
            <S.ModalFooterActions>
              <S.ModalFooterInformationText>
                <p>{t('buyIntention.offers.totalPrice')}</p>
                <span>
                  <Monetary symbol value={total} />
                </span>
              </S.ModalFooterInformationText>
              <Button style={{ marginBottom: 8 }} onClick={handleClose}>
                {t('buyIntention.offers.back')}
              </Button>

              <Button
                disabled={haveProductsSelected.length < 1}
                type="primary"
                style={{ marginLeft: 16, marginBottom: 8 }}
                onClick={() => handleConfirm()}
              >
                {t('buyIntention.offers.checkout')}
              </Button>
            </S.ModalFooterActions>
          )}
        </S.ModalFooterCreateComposition>
      }
    >
      <S.ModalHeaderCreateComposition>
        <span>{t('buyIntention.offers.offersFound')}</span>
      </S.ModalHeaderCreateComposition>
      <S.ModalBodyCreateComposition>
        <ProductList
          products={offersList}
          mode={2}
          productsRendered={false}
          showMoreItens={false}
          lastRef={lastRef}
          offerInserted={handleOfferInserted}
          runInMobile={false}
          page={1}
          pageSize={100}
          pagesCount={1}
          onChangePagination={handleChangePagination}
        />
      </S.ModalBodyCreateComposition>
    </S.ModalCreateComposition>
  );
}
