import { Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOrderPublishersQuery } from 'services/ecommerceApi';

interface Props extends SelectProps<number | ''> {
  allowEmpty?: boolean;
  publishersOnFilteredItems: string[];
}

export default function OrderPublishersSelect({
  allowEmpty = false,
  publishersOnFilteredItems,
  ...props
}: Props) {
  const { t } = useTranslation();
  const [orderPublishers, setOrderPublishers] = useState<string[]>([]);

  const { data, isLoading } = useGetOrderPublishersQuery(undefined,{
    skip: props.disabled === true,
  });

  useEffect(() => {
    if (data?.length) {
      let filteredPublishers: string[] = [...data];
      if (publishersOnFilteredItems?.length) {
        filteredPublishers = filteredPublishers.filter((publisher) =>
          publishersOnFilteredItems.some((i) => i === publisher),
        );
      }
      setOrderPublishers(filteredPublishers);
    }
  }, [data, publishersOnFilteredItems]);

  return (
    <Select
      showSearch
      allowClear
      placeholder={t('generals.selectPlaceholder')}
      optionFilterProp="children"
      filterOption
      loading={isLoading}
      {...props}
    >
      {allowEmpty ? (
        <Select.Option key="all" value="">
          Todas
        </Select.Option>
      ) : null}
      {orderPublishers?.map((name) => (
        <Select.Option key={name} value={name}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
}
