import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;

  .ant-divider {
    margin: 12px 0 !important;
  }
`;

export const ComponentScan = styled.div`
  width: 100%;
  height: 100%;
  max-width: 470px;
    max-height: 472px;
  border-radius: 4px;
  padding: 4px;
  border: 2px solid rgb(247, 142, 30, 0.5);
  box-sizing: border-box;
  margin-bottom: 16px;

  > section {
    padding: 8px;
    border: 1px solid rgb(247, 142, 30, 0.5);
    box-sizing: border-box;
    border-radius: 8px !important;
    max-width: 470px;
    max-height: 450px;
  }
  video {
    max-width: 470px;
    max-height: 470px;
  }

`;

export const Body = styled.div`
  padding: 16px;
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    width: 100%;
  }
`;