import styled from 'styled-components';
import { Button } from 'antd';

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 2px;

  transform: translateY(-50%);
`;

export const PopoverContainer = styled.div``;

export const PopoverItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  + div {
    margin-top: 16px;
  }
`;

export const Label = styled.p`
  margin-bottom: 0;
`;

export const Title = styled.h1`
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 6px 0;

  margin-bottom: 12px;
`;

export const Line = styled.div`
  background-color: #d9d9d9;
  height: 0.5px;
  width: 100%;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

export const TextButton = styled.p`
  color: #f78e1e;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const ButtonBuyIntention = styled(Button)`
  border-color: #f78e1e;
`;
