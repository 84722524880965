import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { sessionSelectors } from 'store/state/selectors';
import { ComponentViewQrCode } from 'enums/componentViewQrCode';
import { useGetQrCodeDataQuery } from 'services/ecommerceApi';
import ModalShoppingByQrCode from 'components/Modals/ShoppingByQrCode';
import { PrePostBodyQrCodeProduct } from 'model/types';
import QrCodeDataCard from 'model/QrCodeDataCard';
import Monetary from 'components/UI/Data/Monetary';
import {
  Body,
  Footer,
  Root,
  PrimaryInformations,
  TitleInformation,
  InformationGroup,
  ButtonProduct,
  SecondaryInformation,
  Observation,
  ProductKey,
  InformationsProduct,
  InfoProduct,
  LineInformation,
  TitleProduct,
} from './styles';

type props = {
  setComponentView: (value: ComponentViewQrCode) => void;
  codeQrScan: string;
};

export default function ProductListQrCode({
  setComponentView,
  codeQrScan,
}: props) {
  const [modalShoppingByQrCodeIsVisible, setModalShoppingByQrCodeIsVisible] =
    useState<boolean>(false);

  const [postItem, setPostItem] = useState<
    PrePostBodyQrCodeProduct | undefined
  >();

  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);

  const { data: products, isError, refetch: refetchGetQrCodeData } = useGetQrCodeDataQuery(
    {
      lotAndbolNumber: codeQrScan
        ? codeQrScan.substring(0, codeQrScan.indexOf('/'))
        : '',
      standNumber: codeQrScan
        ? codeQrScan.substring(codeQrScan.indexOf('/') + 1, codeQrScan.length)
        : '',
    },
    { skip: codeQrScan === '' || !codeQrScan },
  );

  useEffect(() => {
    if (isError) {
      setComponentView(ComponentViewQrCode.SCAN);
    }
  }, [isError, setComponentView]);

  const openModalShoppingByQrCode = useCallback(
    (cardToOpen: QrCodeDataCard) => {
      if (products && cardToOpen) {
        setModalShoppingByQrCodeIsVisible(true);
        setPostItem({
          bolLotHeaderId: products?.bolLotHeaderId,
          minimumQuantity: cardToOpen.minimunQuantity,
          multiple: cardToOpen.multiple,
          avaiableQuantity: cardToOpen.avaiableQuantity,
          quantityPacking: cardToOpen.quantityPacking,
          quantityUnitPerPacking: cardToOpen.quantityUnit,
          customerId,
          gfpNumber: products?.gfpNumber,
          lkpOfferId: cardToOpen.lkpOfferId,
          lineId: cardToOpen.lineId,
          productId: cardToOpen.productId,
          siteId: products?.siteId,
          productDescription: cardToOpen.productDescription,
        });
      }
    },
    [customerId, products],
  );

  return (
    <Root>
      <Body>
        <PrimaryInformations>
          <SecondaryInformation>
            N° GFP {products?.gfpNumber}
          </SecondaryInformation>
          <TitleInformation>{products?.siteTradingName}</TitleInformation>
          <SecondaryInformation>
            Leilão: {moment(products?.emissionDate).format('L')}
          </SecondaryInformation>
          <Observation>
            <InfoCircleOutlined style={{ color: '#8c8c8c' }} />
            Os valores exibidos não incluem o ICMS.
          </Observation>
        </PrimaryInformations>

        {products?.cards.map((product) => (
          <ButtonProduct
            onClick={() => openModalShoppingByQrCode(product)}
            key={product.lkpOfferId}
          >
            <InformationGroup>
              <ProductKey>{product.lot}</ProductKey>
              <InformationsProduct>
                <LineInformation>
                  <InfoProduct>Cód. emb: {product.packingCode}</InfoProduct>
                  <InfoProduct>Cód. prod.: {product.productCode}</InfoProduct>
                </LineInformation>
                <TitleProduct>{product.productDescription}</TitleProduct>
                <LineInformation>
                  <InfoProduct>
                    Valor Unit.: <Monetary symbol value={product.unitPrice} />
                  </InfoProduct>
                  <InfoProduct>
                    Valor Emb.: <Monetary symbol value={product.packingPrice} />
                  </InfoProduct>
                </LineInformation>
                <LineInformation>
                  <InfoProduct>
                    {`Qtd. Disponível: ${product.avaiableQuantity} x
                    ${product.quantityUnit}`}
                  </InfoProduct>
                  <InfoProduct>
                    Qtd. Mínima: {product.minimunQuantity}
                  </InfoProduct>
                </LineInformation>
                <LineInformation>
                  <InfoProduct>Qualidade: {product.quality}</InfoProduct>
                  <InfoProduct>Múltiplo: {product.multiple}</InfoProduct>
                </LineInformation>
              </InformationsProduct>
            </InformationGroup>
          </ButtonProduct>
        ))}
      </Body>
      <Footer>
        <Button onClick={() => setComponentView(ComponentViewQrCode.SCAN)}>
          Escanear novamente
        </Button>
      </Footer>
      <ModalShoppingByQrCode
        postItem={postItem}
        refetchGetQrCodeData={refetchGetQrCodeData}
        modalIsVisible={modalShoppingByQrCodeIsVisible}
        setModalIsVisible={setModalShoppingByQrCodeIsVisible}
      />
    </Root>
  );
}
