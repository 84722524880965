import { memo, useMemo } from 'react';

import { Popover } from 'antd';

import moment from 'moment';

import { DeliveryPatterns } from 'model/DeliveryPatterns';

import DeliveryScheduleTable from '../DeliveryScheduleTable';

import {
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  CalendarIcon,
  Divider,
} from './styles';

interface Props {
  startDate?: Date;
  endDate?: Date;
  siteDeliveryPatterns?: DeliveryPatterns[];
}

function PopoverCalendar({ startDate, endDate, siteDeliveryPatterns }: Props) {
  const startDateFormatted = useMemo(
    () => moment(startDate).format('L'),
    [startDate],
  );
  const endDateFormatted = useMemo(
    () => moment(endDate).format('L'),
    [endDate],
  );

  return (
    <Popover
      placement="bottom"
      content={
        <PopoverContent>
          <PopoverHeader>
            Válido de {startDateFormatted} até {endDateFormatted}
          </PopoverHeader>
          <Divider />
          <PopoverBody>
            <DeliveryScheduleTable data={siteDeliveryPatterns ?? []} />
          </PopoverBody>
        </PopoverContent>
      }
      trigger="hover"
    >
      <CalendarIcon />
    </Popover>
  );
}

export default memo(PopoverCalendar);
