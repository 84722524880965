import { Col } from 'antd';
import styled, { css } from 'styled-components';

interface H1Props {
  hasSavingPrice: number;
}

interface PriceInfoLabelProps {
  hasSavingPrice: number;
}

export const PriceInfo = styled.div``;

export const PriceInfoLabel = styled.h1<PriceInfoLabelProps>`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #8d8d8d;
  text-align: center;

  ${props => props.hasSavingPrice && css`
    padding-top: 10px;
  `}
`;

export const PriceOld = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #f88f1f;
  text-align: center;
  text-decoration: line-through;
`;

export const PriceNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  > h1 {
    margin: 0;
  }
`;

const H1 = styled.h1<H1Props>`
  font-family: Roboto;
  font-weight: 700;
  color: #005ca8;
  text-align: center;

  ${props => !!props.hasSavingPrice && css`
    color: #f78e1e;
  `}
`;

export const Currency = styled(H1)`
  font-size: 10px;
  line-height: 10px;
`;

export const Price = styled(H1)`
  font-size: 16px;
  line-height: 16px;
`;

export const Unit = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8d8d8d;
  text-align: center;
`;
