import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ProductCardListComponent = styled(Row)`
  margin: 8px 28px 16px 28px;
  padding: 8px 16px;
  background: #fff;
  position: relative;
`;

export const ProductCardComponentImage = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  span {
    white-space: nowrap;
  }
`;

export const ProductCardComponentDetails = styled(Col)`
  .icon-information {
    color: #f78e1e;
  }
  padding: 8px 16px;

  .row-details {
    margin-top: 4px;
    height: 100%;
  }

  .col-details-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 200px;

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: #353839;

      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
      }

      .producer-name {
        color: #f78e1e;
      }
    }
  }

  @media (max-width: 992px) {
    margin-bottom: 60px;
  }
`;

export const ProductCardComponentActions = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  .line-actions-input {
    align-items: flex-start !important;
  }

  .line-actions,
  .line-actions-quantity,
  .line-actions-input {
    display: flex;
    align-items: center;
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }

  .line-actions-quantity,
  .line-actions-input {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .line-actions {
    .value-product {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    justify-content: flex-end !important;
    flex-wrap: wrap;
  }

  .line-actions-quantity,
  .line-actions-input {
    margin-top: 10px;
  }

  @media (max-width: 992px) {
    .line-actions {
      justify-content: center;
    }
  }

  @media (max-width: 992px) {
    .line-actions-quantity {
      justify-content: space-between;
    }
  }

  @media (max-width: 768px) {
    .line-actions-quantity {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      margin-top: 12px;
      margin-bottom: 12px;
      justify-content: center;
    }
  }

  .line-actions-input {
    .input-quantity {
      padding-right: 8px;
    }
    .button-cart {
      margin-right: 8px;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;

      .input-quantity {
        margin-bottom: 8px;
      }
    }
  }
`;
export const BuyNowCol = styled(Col)`
  display: flex;
  align-items: center;
`;
export const ComponentInfoNew = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -11px;
  right: -8px;
  background-color: #353839;
  color: #fff;
  border-radius: 16px;
  height: 22px;
  width: 50px;
`;

export const ComponentInfoVoucher = styled.span`
  position: absolute;
  top: 0px;
  z-index: 99;
  padding: 2px;
  left: 0;
  line-height: 18px;
  text-align: center;
  background-color: #f78e1e;
  color: #fff;
  border-radius: 4px;
  word-break: break;
  width: 68px;
  height: 40px;

  span.valeu {
    font-weight: bold;
    width: 68px;
    font-size: 14px;
  }
`;

export const InformationVoucher = styled.p`
  margin-bottom: 0;
  font-size: 11px;
  color: #f78e1e !important;
`;

export const InformationProduct = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  margin-top: 12px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .group-information {
    margin-left: 16px;

    .ant-select .ant-select-selector {
      z-index: 2;
      padding: 0 10px 0 0px !important;
    }

    .title-information {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: #353839;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: #353839;
    }
  }
`;
export const ProductNameType = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .remove-display-calendar {
    display: flex;
    flex-direction: row;
    > span {
      > div {
        width: 130px;
      }
      > span {
      display: none;
    }
    }
  }

  .name-product {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 16px;

    > span {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #353839;
    }

    .code-product {
      font-weight: 400;
      padding-top: 6px;
      font-size: 11px;
      line-height: 9px;
      color: #8c8c8c;
      display: flex;
      flex-direction: row;

      .number-product {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    .name-product {
      padding: 12px 0;
    }
  }
`;

export const ValueProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }

  .value-per-unity {
    margin-top: 4px;
  }

  span {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #8c8c8c;
  }

  span.value {
    font-weight: 700;
    font-size: 21px;
    line-height: 21px;
  }

  span.currency,
  span.value {
    color: #f78e1e;
  }
`;

export const GroupTypeSaleProduct = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  .button-type-sale {
    display: flex;
    width: 33%;
    flex-direction: column;
    height: 60px;
    padding: 0 16px;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      color: #353839;
    }

    span.value > span {
      font-size: 15px;
      line-height: 21px;
      color: #f78e1e;
    }

    &.focus {
      background-color: #f78e1e;
      border-color: #f78e1e;

      span > span,
      span {
        color: #fff;
      }
    }
  }

  .no-border-left {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .no-border-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
`;

export const MinQuantityProduct = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #353839;
  margin-right: 64px;

  > span {
    color: #8c8c8c;
  }
`;

export const AvailableQuantity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: #353839;

  .calc-available {
    color: #8c8c8c;
  }

  @media (max-width: 768px) {
    .value-available,
    .calc-available {
      margin: 0 16px;
    }
  }
`;

export const WarningLabel = styled.p`
  padding: 0 8px;
`;
