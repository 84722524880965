import { memo } from 'react';
import { Tooltip } from 'antd';
import { Container } from './styles';

interface Props {
  directedMessage: string;
}

function InfoDirected({ directedMessage }: Props) {
  return (
    <Tooltip title={directedMessage}>
      <Container>{directedMessage}</Container>
    </Tooltip>
  );
}

export default memo(InfoDirected);
