import { HomeOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0px 8px;
  }
`;

export const BreadcrumbIcon = styled(HomeOutlined)`
  width: 16px;
  height: 16px;
  color: #fce6e6;
`;

export const BreadcrumbText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #fce6e6;
`;
