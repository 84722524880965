import Monetary from 'components/UI/Data/Monetary';
import { useAuth } from 'hooks/useAuth';
import ProducerProduct from 'model/ProducerProduct';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

type ProducerProductCardProps = {
  producerProduct: ProducerProduct;
  cardClassName?: string;
};

export default function ProducerProductCard(props: ProducerProductCardProps) {
  const { producerProduct, cardClassName } = props;
  const { name, imageUrl, price, producerName } = producerProduct;
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  return (
    <S.ProducerProductCard className={`${cardClassName} card-home`}>
      <S.ProductImage src={imageUrl} alt={name} />
      <S.ProductCardFooter>
        <S.ProductProducer>
          <S.Product>{name}</S.Product>
          <S.ProducedBy>
            <S.By>Produtor:</S.By>
            <S.Producer>{producerName}</S.Producer>
          </S.ProducedBy>
        </S.ProductProducer>
        {isAuthenticated && (
          <S.ProductPrice>
            <S.CurrencySymbol>{t('common.monetary.symbol')}</S.CurrencySymbol>
            <S.MonetaryValue>
              <Monetary value={price} />
            </S.MonetaryValue>
          </S.ProductPrice>
        )}
      </S.ProductCardFooter>
    </S.ProducerProductCard>
  );
}
