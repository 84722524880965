import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  row-gap: 0px;
  flex-flow: row wrap;
`;

export const Col = styled.div`
  position: relative;
  min-height: 1px;
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
`;

export const RowTitle = styled(Row)`
  margin-bottom: 12px;
`;

export const ColTitle = styled(Col)`
  color: #f78e1e;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
`;
