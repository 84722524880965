import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;

  h2 {
    margin-bottom: 0;
  }
  
  .icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 8px;

    button{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;