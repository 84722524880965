import styled, { createGlobalStyle } from 'styled-components';
import { darken, lighten } from 'polished';
import { Table } from 'antd';

import 'antd/dist/antd.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    &:focus{
      outline: 0;
    }
  }

  --antd-wave-shadow-color: red;

  html, body, #root {
    height: 100%;
    --antd-wave-shadow-color: #FAAD14 !important;
  }

  body{
    background: #fff;
    color: #263238;
    -webkit-font-smoothing: antialiased !important;
  }

  .remove-button-close {
    .ant-modal-close {
      display: none !important;
    }
  }

  div.hover-card-product.card-home {
    width: unset !important;
    padding: unset !important;
  }

  body, input, button, label {
    font: 13px 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  .ant-menu-light .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .ant-menu.ant-menu-sub.ant-menu-vertical {
    background-color: #fff !important;
  }

  .ant-menu-item:active, .ant-menu-submenu-title:active, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.active-filter {
    color: #FFAC47 !important;
    background-color: #fff !important;
  }
  .ant-menu-item:active, .ant-menu-submenu-title:active, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:not(.active-filter) {
    color: rgba(0,0,0,0.85) !important;
    background-color: #fff !important;
  }

  .ant-pagination-item:hover, .ant-pagination-item-link:hover {

    border-color: #FAAD14 !important;
    a, span {
      color: #FAAD14 !important;
    }
  }

  .anticon.anticon-double-right.ant-pagination-item-link-icon {
    color: #FAAD14 !important;
  }

  ::selection {
    color: #fff;
    background: #F78E1E;
  }

  input[type="text"], input[type="date"], input[type="email"], input[type="password"], select, textarea {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 12px;
    position: static;
    width: 100%;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;
    flex: 1;
    margin: 0;
  }

  label {
    font-style: normal;
    font-weight: normal;
    color: #353839;
    margin-bottom: 3px;
  }

  .background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
  }

  .p-top-8 {
    padding-top: 8px;
  }

  .p-top-16 {
    padding-top: 16px;
  }

  .p-sides-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-table-wrapper {
    background: #fff !important;
  }
  .ant-tabs-tab{
    margin-left: 15px !important;
    padding: 12px;
    padding-bottom: 8px;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0 11px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }
   .ant-tabs-tab-active{
    border-bottom: 2px solid #F78E1E !important;
  }
  .ant-table-content {
    overflow: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: #F5F5F5;
    }

    &::-webkit-scrollbar-track {
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
    }


  }
  .mt-10{
    margin-top: 10px;
  }

  .ant-table .ant-table-container::before, .ant-table .ant-table-container::after {
    display: none;
  }

  .ant-table-tbody > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
    content: '';
  }

  .ant-pagination.mini .ant-pagination-item {
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
  }

  .ant-pagination-item-active, .ant-pagination-item-active a {
    border-color: #F78E1E !important;
  }

  .ant-pagination-item-active a {
    color: #F78E1E !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px !important;
    padding: 0 11px !important;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 22px !important;
    line-height: 32px;
  }

  td.ant-picker-cell:not(.ant-picker-cell-disabled) {
    >div {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .ant-pagination.mini .ant-pagination-options {
    margin-left: 16px;
  }

  .ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    min-width: 32px;
    height: 32px;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s;
  }

  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background-color: #fff;
    border-color: #d9d9d9;
  }

  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
     line-height: 32px;
  }

  .ant-table-container {
    border: 1px solid #D9D9D9;
    border-radius: 2px;
  }

  .ant-table {
    table {
      border: none;
      margin-bottom: 1px;
    }

    th, td {
      white-space: nowrap;
      text-align: left !important;
      color: #353839 !important;
      font: 12px 'Roboto', sans-serif !important;

      span {
        text-align: left !important;
      }

      &::before {
        height: 100% !important;
        background-color: #D9D9D9;
      }
    }

    th  {
      background: #F5F5F5;
      font-weight: bold !important;
    }

    td {
      background: #fff;
    }
  }

  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-size: 14px;
  }
  
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #BFBFBF;
    font-size: 12px;
  }
  
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #f78e1e !important;
  }

  .ant-steps-item-icon > .ant-steps-icon {
    font-size: 14px;
  }

  .ant-steps-navigation .ant-steps-item::before, .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #f78e1e !important;
  }
  
  .ant-steps-item-finish .ant-steps-item-icon, .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    border-color: #f78e1e !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #F78E1E;
  }

  .ant-input:hover, .ant-input:focus, .ant-input:active, .ant-picker:hover, .ant-picker-focused, .ant-picker-input > input:hover {
    border-color: #F78E1E;
    border-right-width: 1px !important;
  }

  .ant-picker-today-btn {
    color: #F78E1E;
    :hover {
      color: #F78E1E;
    }
  }
  
  .ant-picker-header-view button:hover {
    color: #F78E1E;
  }

  .ant-btn:not(.not-active):active {
    background-color: #fff;
    border-color: #D9D9D9;
    color: #262626;
  }

  .ant-btn:hover, .ant-btn:focus {
    background-color: ${darken(0.05, '#fff')};
    border-color: #D9D9D9;
    color: ${lighten(0.2, '#262626')};
  }

  .ant-btn-primary {
    background-color: #F78E1E;
    color: #fff;
  }

  .ant-btn-primary, .ant-btn-primary:active, .ant-btn-primary:hover, .ant-btn-primary:focus,.ant-checkbox-checked .ant-checkbox-inner {
    border-color: #F78E1E;
    background-color: ${darken(0.1, '#F78E1E')};
    color: #fff;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked::after {
    border-color: #F78E1E;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #F78E1E;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector, .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    border-color: #F78E1E;
    border-right-width: 1px !important;
  }

  .ant-table-customized {
    background: #D9D9D9;
    margin: 0;
    padding: 0;

    table {
      border: none;
      margin-bottom: 1px;
    }

    th, td {
      text-align: left;
      padding: 13px 12px !important;
      color: #353839 !important;
      font: 13px 'Roboto', sans-serif !important;
    }

    th {
      background: #F5F5F5;
      font-weight: bold !important;
    }

    td {
      background: #fff;
    }
  }

  .ant-input-number, .ant-picker {
    width: 100%;
  }

  .plus-button {
    color: #F78E1E;
    border-color: #F78E1E;
    border: 1px solid;
    padding: 9px;
  }

  .ant-drawer-content-wrapper {
    width: 250px !important;
  }

  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;

    button, div {
      color: #fff;
    }

    div {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .collapse-drawer-filter {
      .ant-collapse-content.ant-collapse-content-active {
        padding: 0 10px 0 0 !important;
    }
  }

  .collapse-drawer, .collapse-drawer-filter {
    margin: 20px 0;

    *::-webkit-scrollbar-track {
        background-color: #F5F5F5;
        border-radius: 4px;
      }

      *::-webkit-scrollbar
      {
        border-radius: 4px;
        width: 6px;
        background-color: #F5F5F5;
      }

      *::-webkit-scrollbar-thumb
      {
        border-radius: 4px;
        background-color: #BFBFBF;
      }

    .ant-collapse-content-box, .group-options-filters {
      display: flex;
      flex-direction: column;
      padding: 0 !important;
      margin-top: 16px;
      max-height: 290px;
      overflow: auto;

      label {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .group-options-filters {
      margin-top: 4px;
    }

    .ant-collapse-header {
      padding: 0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center !important;
      flex-direction: row-reverse;
      width: 100%;

      color: #353839;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;

      svg {
        color: #353839 !important;
      }
    }
  }

  .ant-drawer-body::-webkit-scrollbar {
    display: none;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .p-8{
    padding: 8px;
  }
  .b-0{
    border: 0;
  }
  .bb-0{
    border-bottom: 0;
  }
  .bb-1{
    border-bottom: 1px solid #d9d9d9;
  }
  .d-none{
    display: none;
  }
  
  .modalCard .ant-modal-content {
    border-radius: 10px;
  }

  .modalCard .ant-modal-title{
    text-align: center;
  }
  .rank-number{
    color: #262626;

  }
  .h-100{
    height: 100%;
  }
  .hideHeader .ant-modal-header{
    display:none
  }
  .hideHeader .ant-modal-close{
    display: none
  }
  .hide-button-border button {
    border: none;

  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: #F78E1E !important;
  }

  .ant-tabs-ink-bar, .ant-picker-week-panel-row-selected:hover td, .ant-picker-week-panel-row-selected td, .ant-picker-range .ant-picker-active-bar, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-spin-dot-item {
    background: #F78E1E;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${lighten(0.38, '#F78E1E')};
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: ${lighten(0.34, '#F78E1E')};
  }

  .ant-collapse-ghost > .ant-collapse-item {
    margin-bottom: -15px;

    &:last-child {
      margin-bottom: inherit;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    margin-bottom: -15px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    padding-top: 6px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    color: #BFBFBF;
  }

  .end > .ant-dropdown-trigger{
    display: flex;
    justify-content: flex-end;
  }

  .ant-pro-table-column-setting-overlay {
    .ant-tree-checkbox .ant-tree-checkbox-inner, .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #F78E1E !important;
      border-color: #F78E1E !important;

      .ant-modal-title{
        font-weight: bold;
      }
    }
  }

  .ant-pro-table-list-toolbar-setting-items {
    .ant-space-item {
      display: none !important;

      &:last-child {
        display: inherit !important;
      }
    }
  }

  .ant-menu-submenu-popup > .ant-menu, .ant-menu-sub.ant-menu-inline {
    margin-left: -5px;
  }

  .ant-menu-vertical .ant-menu-item:hover .ant-menu-title-content, .ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: ${lighten(0.1, '#F78E1E')} !important;
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-submenu:hover > .ant-menu-submenu-title {
    color: ${lighten(0.1, '#F78E1E')} !important;
  }

  .ant-menu-item {
    &::after {
      border-right: 0 solid #F78E1E !important;
    }
  }

  .ant-menu-item-group {
    width: calc(100% + 1px);
  }

  .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }

  .ant-menu-item:active, .ant-menu-submenu-title:active, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.active-filter {
    color: '#F78E1E' !important;
  }
  .ant-menu-item:active, .ant-menu-submenu-title:active, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:not(.active-filter) {
    color: rgba(0,0,0,0.85) !important;
  }

  
  .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active {
    color: ${lighten(0.1, '#F78E1E')} !important;
    
    &.grid-action-menu {
      color: ${lighten(0.1, '#F78E1E')} !important;
    }
  }
  

  
  .ant-btn-primary {
    background-color: #F78E1E;
    border-color: #F78E1E;
  }

  .center-th .center{
    text-align: center !important;
  }
  .bbPQFD .ant-table-thead tr th span {
    font-size: 11px !important;
    font-weight: normal;
    display: block;
  }

  .ant-table  .center-th{
    text-align: center !important;
  }

  .width0 {
    width: 0px !important;
  }

  .width65 {
    width: 65px !important;
  }

  .reality-span{
    font-size: 11px !important;
    font-weight: normal !important;
    display: block;
    text-align: center !important;
  }
  .ant-table th span{
    text-align: center !important;

  }
 .ant-table-thead tr th .reality-span {
    font-size: 11px !important;
  }
  .ant-tabs-tab {
    padding: 6px 12px 6px !important;
    font-weight: bold;
  }
  .ant-tooltip-disabled-compatible-wrapper{
    width: 100%;

  }
  .ant-picker-week-panel-row  .ant-picker-cell-week{
    color: rgb(247, 142, 30) ;
    font-weight: bold ;
  }
  .ant-picker-week-panel-row  .ant-picker-cell-week  .ant-picker-week-panel-row-selected {
    color: #fff !important;
  }
  .ant-picker-week-panel-row-selected .ant-picker-cell {
    color: #fff !important;
  }
  .break-word {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    > span {
      margin-left: 3px;
    }
  }
  .ant-image-img{
    object-fit: cover;
  }

`;
export const SpanCenter = styled.span`
  font-size: 11px !important;
`;

export const TableCustom = styled(Table)`
  .ant-table-cell {
    font-weight: bold;
  }
`;

export const TableFather = styled(Table)`
  .ant-table-thead tr th {
    background-color: #f5f5f5;
    color: #353839;
    font-weight: bold;
  }

  .ant-table-thead tr th span {
    font-size: 14px !important;
  }
`;
export const TableChild = styled(Table)`
  margin-top: 20px;
  .ant-table-thead tr th {
    background-color: #fff9eb;
    color: #353839;
    font-weight: bold;
    text-align: center !important;
    border-top: 1px solid #d9d9d9 !important;
  }
  .ant-table-tbody tr td {
    border-top: 1px solid #d9d9d9 !important;
  }
  .ant-table {
    margin-left: 88px !important;
  }
  .disabled_row .ant-table-cell {
    background-color: #f5f5f5;
    font-weight: bold !important;
  }
  .disabled_row .last_year {
    color: #fa541c !important;
    font-weight: bold !important;
  }
  .disabled_row .ant-table-cell .ant-table-row-expand-icon-cell {
    display: none !important;
  }
`;
