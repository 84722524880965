import { QualityColorEnum } from 'enums/qualityColor.enum';
import { DeliveryPatterns } from './DeliveryPatterns';
import DirectedOfferCustomer from './DirectedOfferCustomer';
import Packing from './Packing';
import ProductOffer from './ProductOffer';
import UnavailableDate from './UnavailableDate';

export default class ProductOfferWithBox {
  public readonly hasBox: boolean;

  public offers: ProductOffer[];


  constructor(
    hasBox: boolean,
    offers: ProductOffer[]

  ) {
    this.hasBox  = hasBox;
    this.offers = offers

  }
}
