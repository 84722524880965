import styled from 'styled-components';


export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block !important;
      height: 150px;
    }
  }
`;

export const Divider = styled.div`
  background: #d9d9d9;
  height: 1px;
`;
