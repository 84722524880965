import React from 'react';

import { ModalProps, Row, Col, Button } from 'antd';

import { Modal } from './styles';

export type GenericModalProps = ModalProps & {
  children?: React.ReactNode;
};

type FooterProps = {
  okText?: string;
  cancelText?: string;
  onCancel: () => void;
  onOk: () => void;
  loading?: boolean;
};

export const Footer = ({
  okText,
  cancelText,
  onCancel,
  onOk
}: FooterProps): JSX.Element => {
  return (
    <Row
      gutter={16}
      style={{
        padding: 8
      }}
    >
      <Col xs={12}>
        <Button onClick={onCancel} block>
          {cancelText || 'Cancelar'}
        </Button>
      </Col>
      <Col xs={12}>
        <Button onClick={onOk} block type="primary">
          {okText || 'Aplicar'}
        </Button>
      </Col>
    </Row>
  );
};

export const GenericModal = ({
  children,
  ...restModalProps
}: GenericModalProps) => {
  return <Modal {...restModalProps}>{children}</Modal>;
};

export default GenericModal;
