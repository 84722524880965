import {
  AnyAction,
  isAsyncThunkAction,
  isFulfilled,
  isPending,
  isRejected,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';

import moment from 'moment';
import { getAuthorizationToken, getExpireIn, parseJwt } from 'services/config';
import { loaderActions, sessionActions } from 'store/state/slices';

const IGNORED_ENDPOINTS = [
  'getOfferFilters',
  'getFilteredOffers',
  'getBalance',
  'getDirectedOffersCount',
  'getMyOffersCount',
  'getSummaryCart',
  'getUserWithCustomers',
  'getProductsBasedOnLastVisit',
  'getProductsMostSearched',
];

const requests: string[] = [];
export const loaderMiddleware: Middleware =
  () => (next) => (action: AnyAction) => {
    if (action.type === 'ecommerceApi/executeQuery/pending') {
      const accessToken = getAuthorizationToken();
      const expiresIn = getExpireIn();
      if (accessToken) {
        console.log('teste:: entrou no if action type', expiresIn);
        const { exp }: any = parseJwt(accessToken);
        const expireDate = moment(exp * 1000);

        const now = moment().format('DD/MM/YYYY HH:mm:ss');

        const timeLeftInMs = moment(expireDate, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(now, 'DD/MM/YYYY HH:mm:ss'),
        );
        const timeLeftInMin = Math.floor(timeLeftInMs / 60000);

        if (timeLeftInMin <= 3) {
          next(sessionActions.setRefreshToken(true));
        }
      }
    }
    if (isAsyncThunkAction(action)) {
      if (action.meta.arg) {
        const args = action.meta.arg as any;
        if (IGNORED_ENDPOINTS.includes(args.endpointName)) {
          return next(action);
        }
      }
      if (isPending(action)) {
        requests.push(action.meta.requestId);
        next(loaderActions.show());

        const accessToken = getAuthorizationToken();
        const expiresIn = getExpireIn();
        if (accessToken) {
          const { exp }: any = parseJwt(accessToken);
          const expireDate = moment(exp * 1000);

          const now = moment().format('DD/MM/YYYY HH:mm:ss');

          const timeLeftInMs = moment(expireDate, 'DD/MM/YYYY HH:mm:ss').diff(
            moment(now, 'DD/MM/YYYY HH:mm:ss'),
          );
          const timeLeftInMin = Math.floor(timeLeftInMs / 60000);

          if (timeLeftInMin <= 3) {
            next(sessionActions.setRefreshToken(true));
          }
        }
      } else if (
        isFulfilled(action) ||
        isRejected(action) ||
        isRejectedWithValue(action)
      ) {
        const index = requests.findIndex((id) => id === action.meta.requestId);
        if (index >= 0) {
          requests.splice(index, 1);
        }
      }

      if (!requests.length) {
        next(loaderActions.hide());
      }
    }

    return next(action);
  };
