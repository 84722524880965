import Product from 'model/Product';
import React from 'react';
import * as S from './styles';

type ProductCardProps = {
  product: Product;
  cardClassName?: string;
};
export default function ProductCardMostBuy(props: ProductCardProps) {
  const { product, cardClassName } = props;
  const { productDescription, imageUrl, categoryDescription } = product;
  return (
    <S.ProductCard className={cardClassName}>
      <S.ProductImage src={imageUrl} alt={productDescription} />
      <S.CategoryDescription>{categoryDescription}</S.CategoryDescription>
      <S.Product>{productDescription}</S.Product>
    </S.ProductCard>
  );
}
