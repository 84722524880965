import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ShippingFeeProps = {
  value: number | string;
  symbol?: boolean;
};

export default function ShippingFee(props: ShippingFeeProps) {
  const { value, symbol = false } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'common.monetary' });
  const convertValueStr = useCallback(
    (value: number | string) => value.toString().replace('.', ','),
    [],
  );

  const valueWithSymbol = useCallback((value) => `R$${value}`, []);

  const renderValue = useMemo(() => {
    if (value === 0.0 || Number.isNaN(value)) {
      return '-';
    }
    const valueNumber = typeof value === 'string' ? parseFloat(value) : value;

    const valueStr = convertValueStr(valueNumber.toFixed(6));
    return valueWithSymbol(valueStr);
  }, [value, convertValueStr, valueWithSymbol]);

  return <span>{renderValue}</span>;
}
