import { FastBuyContainer } from 'components/BusinessLogic/Product/ProductActions/styles';
import { FastCartIcon } from '../Icons';
import * as S from './styles';

type BuyNowButtonProps = {
  onClickFunction: () => void;
};
export default function BuyNowButton(props: BuyNowButtonProps) {
  const { onClickFunction } = props;
  return (
    <FastBuyContainer
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'end',
        paddingLeft: '0',
        paddingRight: '1px',
      }}
    >
      <S.ButtonNetwork
        style={{
          lineHeight: '16px',
          fontSize: '13px',
          maxWidth: '69px',
          minWidth: '65px',
          minHeight: '60px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        type="default"
        icon={<FastCartIcon />}
        onClick={() => onClickFunction()}
      >
        <span>Comprar</span>
        <span>agora</span>
      </S.ButtonNetwork>
    </FastBuyContainer>
  );
}
