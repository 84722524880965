import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BalanceState = {
  balance: number;
};

export const initialState: BalanceState = {
  balance: 0,
};

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    setBalance(state, action: PayloadAction<number>) {
      state.balance = action.payload;
    },
  },
  extraReducers: {},
});

export const { actions, reducer } = balanceSlice;
