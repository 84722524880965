import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';
import ProductOffer from 'model/ProductOffer';
import { OfferService } from 'services/offerService';
import { alertActions } from 'store/state/slices';
import * as buyIntentionServices from 'services/buyIntentionService';
import * as BuyIntentionModels from 'model/BuyIntention';
import commomImage from 'assets/default/image.png';
import { generateErrorMessage } from 'utils/error';

export const fetchProductsList = createAsyncThunk(
  `buyIntention/fetchProductsList`,
  async (params: any) => {
    try {
      const data = await buyIntentionServices.getProducts(params);
      return data;
    } catch (err: any) {
      return false;
    }
  },
);

export const fetchProducers = createAsyncThunk(
  `buyIntention/fetchProducers`,
  async () => {
    try {
      const data = await buyIntentionServices.getProducers();
      return data.data;
    } catch (err: any) {
      return false;
    }
  },
);

export const fetchPacking = createAsyncThunk(
  `buyIntention/fetchPacking`,
  async (params: BuyIntentionModels.FetchPackings, { rejectWithValue, dispatch }) => {
    try {
      const response = await buyIntentionServices.getPackings(params);
      if(!response.data?.length){
        throw new Error("Não há embalagens cadastradas para este produto.");
      }
      return response.data;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar embalagens.',
      );
      dispatch(
        alertActions.alertRequest({
          message: errorMessage,
          type: 'error',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return rejectWithValue(err);
    }
  },
);

export const postBuyIntention = createAsyncThunk(
  `buyIntention/postBuyIntention`,
  async (params: any, { rejectWithValue, dispatch }) => {
    try {
      await buyIntentionServices.postBuyIntention(params);
      dispatch(
        alertActions.alertRequest({
          message: 'Intenção de Compra registrada com sucesso!',
          type: 'success',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return true;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao responder pedido.',
      );
      dispatch(
        alertActions.alertRequest({
          message: errorMessage,
          type: 'error',
          showIcon: true,
          timeout: 3000,
        }),
      );
      return rejectWithValue(err);
    }
  },
);

export const fetchOffersByProducers = createAsyncThunk(
  `buyIntention/fetchOffersByProducers`,
  async (params: {
    producerId: number;
    keyword: string | undefined;
    accountCode: string | undefined;
  }) => {
    try {
      const response = await buyIntentionServices.getOffersByProducers({
        siteId: params.producerId,
        accountCode: params.accountCode,
      });
      const quality = await buyIntentionServices.getQuality();
      const newdata: any[] =
        response.data.results.map((product: BuyIntentionModels.BuyIntentionData) => ({
          ...product,
          id: (Math.random() + 1).toString(36).substring(7),
          qualitySelect: '',
          qualityList: quality.data,
          packingSelected: undefined,
          productPreference: undefined,
          quantityPacking: '',
          quantityUnit: '',
          unitPrice: 0,
          unitPriceNumber: 0,
          totalPrice: 0,
          embPrice: 0,
          observationText: '',
          bought: false,
          intention: false,
        })) || [];
      if (params.keyword) {
        const newDataFilterProduct = newdata.filter(
          (product: any) =>
            product.productName.indexOf(params.keyword?.toLocaleUpperCase()) >
              -1 || product.productCode.indexOf(params.keyword) > -1,
        );
        return newDataFilterProduct;
      }

      return newdata;
    } catch (err: any) {
      return false;
    }
  },
);

export const fetchOffersByProducts = createAsyncThunk(
  `buyIntention/fetchOffersByProducts`,
  async (params: BuyIntentionModels.SearchProducts) => {
    try {
      const data = await buyIntentionServices.getOffersByProducts(params);
      const quality = await buyIntentionServices.getQuality();
      const newdata: any[] =
        data.data.map((product: BuyIntentionModels.BuyIntentionData) => ({
          ...product,
          id: (Math.random() + 1).toString(36).substring(7),
          qualitySelect: '',
          qualityList: quality.data,
          packingSelected: undefined,
          productPreference: undefined,
          quantityPacking: '',
          quantityUnit: '',
          unitPrice: 0,
          unitPriceNumber: 0,
          totalPrice: 0,
          embPrice: 0,
          observationText: '',
          bought: false,
          intention: false,
        })) || [];
      return newdata;
    } catch (err: any) {
      return false;
    }
  },
);

export const fetchOffersList = createAsyncThunk(
  `buyIntention/fetchOffersList`,
  async (params: any) => {
    try {
      const data = await buyIntentionServices.requestOffer(params);
      const newdata: ProductOffer[] =
        data.data.offers.map((product: any) => ({
          ...product,
          imagesFormatted:
            product.images?.length > 0
              ? product.images
              : [product.defaultImage || commomImage],
          id: (Math.random() + 1).toString(36).substring(7),
          infoVoucher: OfferService.generateVoucherDescription(
            product.reajustmentAmount,
            product.minimumUnitQuantityVoucher,
            product.voucherQuantityType,
          ),
          endDateFormatted: moment(product.endDate).format('DD/MM'),
          siteDeliveryPatterns: product.siteDeliveryPatterns.sort(
            (a: any, b: any) => a.orderEndDayNumber - b.orderEndDayNumber,
          ),
        })) || [];
      return newdata;
    } catch (err: any) {
      return false;
    }
  },
);
