import Product from 'model/Product';
import React from 'react';
import * as S from './styles';

type ProductCardProps = {
  product: Product;
  cardClassName?: string;
};
export default function ProductCard(props: ProductCardProps) {
  const { product, cardClassName } = props;
  const { name, imageUrl } = product;
  return (
    <S.ProductCard className={cardClassName}>
      <S.ProductImage src={imageUrl} alt={name} />
      <S.Product>{name}</S.Product>
    </S.ProductCard>
  );
}
