import { useState } from 'react';
import { Menu } from 'antd';
import { useMyOrderIntentionsContext } from 'contexts/MyOrderIntentionsContext';
import * as MyOrderIntentionEditCalls from 'store/state/myOrderIntentionEdit/apiCalls';
import { actions as MyOrderIntentionEditActions } from 'store/state/myOrderIntentionEdit/myOrderIntentionEditSlice';
import { useDispatch, useSelector } from 'react-redux';
import { OrderIntentionOriginEnum } from 'enums/orderIntentionOrigin.enum';
import { OrderIntentionStatusEnum } from 'enums/orderIntentionStatus.enum';
import { sessionSelectors } from 'store/state/selectors';
import { OrderIntention } from 'model/OrderIntention';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface Props {
  record: OrderIntention;
}
export default function TableRowDropdown({ record }: Props) {
  const dispatch = useDispatch();
  const {
    setShowDetailsModal,
    setSelectedOrderIntentions,
    setSelectedOrderIntentionsKeys,
  } = useMyOrderIntentionsContext();
  const { t } = useTranslation();
  const { customers } = useSelector(sessionSelectors.getRoot);
  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );
  const [customerSelected, setCustomerSelected] = useState(
    customers.find((customer) => customer.id === selectedCustomerId),
  );

  return (
    <Menu>
      {record.origin === OrderIntentionOriginEnum.DIRECT_SALE &&
      record.status === OrderIntentionStatusEnum.PENDENTE ? (
        <Menu.Item
          onClick={() => {
            dispatch(MyOrderIntentionEditActions.setShowModalAprove(true));
            dispatch(MyOrderIntentionEditActions.setIdsBulkAprove([record.id]));
          }}
        >
          Aprovar
        </Menu.Item>
      ) : null}
      {record.origin === OrderIntentionOriginEnum.DIRECT_SALE &&
      record.status === OrderIntentionStatusEnum.PENDENTE ? (
        <Menu.Item
          onClick={() => {
            if (!record.justification) {
              dispatch(
                MyOrderIntentionEditActions.setShowModalNoJustification(true),
              );
            } else {
              dispatch(
                MyOrderIntentionEditActions.setJustificationText(
                  record.justification ?? '',
                ),
              );
              dispatch(MyOrderIntentionEditActions.setShowModalReprove(true));
              dispatch(
                MyOrderIntentionEditActions.setIdsBulkReprove([record.id]),
              );
            }
          }}
        >
          Recusar
        </Menu.Item>
      ) : null}
      {record.origin === OrderIntentionOriginEnum.ORDER_INTENTION &&
      record.status === OrderIntentionStatusEnum.EXPIRADO ? (
        <Menu.Item
          onClick={() => {
            if (
              moment(record.deliveryDate).isBefore(moment().add(-1, 'day')) ||
              moment(record.deliveryDate).format('DD') ===
                moment().add(-1, 'day').format('DD')
            ) {
              dispatch(
                MyOrderIntentionEditActions.setShowModalNoRepublish(true),
              );
            } else {
              dispatch(MyOrderIntentionEditActions.setShowModalRepublish(true));
              dispatch(MyOrderIntentionEditActions.setIdRepublish([record.id]));
            }
          }}
        >
          Republicar
        </Menu.Item>
      ) : null}
      {record.origin === OrderIntentionOriginEnum.ORDER_INTENTION &&
      (record.status === OrderIntentionStatusEnum.EXPIRADO ||
        record.status === OrderIntentionStatusEnum.RECUSADO) ? (
        <Menu.Item
          onClick={() => {
            dispatch(
              MyOrderIntentionEditCalls.fetchOffersByProducts({
                order: record,
                accountCode: customerSelected?.accountCode,
              }),
            );
            dispatch(
              MyOrderIntentionEditActions.setFormInfo({
                customerId: record.customerId,
                customerCode: record.customerCode,
                customerName: record.customerName,
                commercialDate: record.commercialDate,
                deliveryDate: record.deliveryDate,
                id: record.id,
              }),
            );
          }}
        >
          Editar
        </Menu.Item>
      ) : null}
      {record.origin === OrderIntentionOriginEnum.ORDER_INTENTION &&
      record.status === OrderIntentionStatusEnum.CANCELADO && !record.orderNumber ? (
        <Menu.Item
          onClick={() => {
            dispatch(
              MyOrderIntentionEditCalls.fetchOffersByProducts({
                order: record,
                accountCode: customerSelected?.accountCode,
              }),
            );
            dispatch(
              MyOrderIntentionEditActions.setFormInfo({
                customerId: record.customerId,
                customerCode: record.customerCode,
                customerName: record.customerName,
                commercialDate: record.commercialDate,
                deliveryDate: record.deliveryDate,
                id: record.id,
              }),
            );
          }}
        >
          Editar
        </Menu.Item>
      ) : null}
      {record.origin === OrderIntentionOriginEnum.ORDER_INTENTION &&
      record.status === OrderIntentionStatusEnum.AGUARDANDO_PRODUTOR ? (
        <Menu.Item
          onClick={() => {
            dispatch(MyOrderIntentionEditActions.setShowModalCancel(true));
            dispatch(MyOrderIntentionEditActions.setIdCancel([record.id]));
          }}
        >
          Cancelar IC
        </Menu.Item>
      ) : null}
      <Menu.Item
        onClick={() => {
          setShowDetailsModal(true);
          setSelectedOrderIntentions([record]);
          // setSelectedOrderIntentionsKeys([record.id]);
        }}
      >
        {t('myOrderIntentions.table.rowDropdown.seeDetails')}
      </Menu.Item>
    </Menu>
  );
}
