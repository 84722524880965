import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const ModalShoppingByQrCodeComponent = styled(Modal)``;

export const ModalShoppingByQrCodeComponentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitlePrincipal = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #f78e1e;
  margin-bottom: 0 !important;
`;

export const Information = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #353839;
`;

export const ButtonFinish = styled(Button)`
  margin-top: 8px;
  width: 100%;
`;