import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { ErrorState } from './errorSlice';

export const getRoot = (state: { error: ErrorState }) => state.error as ErrorState;

export const getLoading = (state: RootState) => state.loader.loading;

export const getError = createSelector(
  [getRoot],
  (state) => state.error,
);

