import { memo, useCallback, useMemo, useState } from 'react';

import { Button, Popover, Switch } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import { Container, PopoverContainer, PopoverItem, Label, Title } from './styles';

interface Props {
    modePagination: boolean;
    changeSwitch(modePagination: boolean): void;
}

function Config({ modePagination, changeSwitch }: Props) {
    const [showPopover, setShowPopover] = useState(false);

    const handleClickSwitchConfig = useCallback((modePagination: boolean) => {
        changeSwitch(modePagination);
        setShowPopover(false);
    }, [changeSwitch]);

    const handleClickChange = useCallback((visible: boolean) => {
        setShowPopover(visible);
    }, []);

    const popoverContent = useMemo(() => (
        <PopoverContainer>
            <Title>Configurações da listagem de produtos</Title>
            <PopoverItem>
                <Label>Lista com paginação</Label>
                <Switch
                    size="small"
                    id="switch-pagination"
                    checked={modePagination}
                    onChange={() => handleClickSwitchConfig(true)}
                />
            </PopoverItem>
            <PopoverItem>
                <Label>Lista infinita</Label>
                <Switch
                    size="small"
                    id="switch-infinite"
                    checked={!modePagination}
                    onChange={() => handleClickSwitchConfig(false)}
                />
            </PopoverItem>
        </PopoverContainer>
    ), [modePagination, handleClickSwitchConfig])

    return (
        <Container>
            <Popover
                placement='right'
                content={popoverContent}
                trigger="click"
                visible={showPopover}
                onVisibleChange={handleClickChange}
            >
                <Button shape='circle' size='large' icon={<SettingOutlined />} />
            </Popover>
        </Container>
    )
}

export default memo(Config);
