import { OfferType } from 'model/types';

type OfferTypeTextProps = {
  offerType: OfferType;
};

export const offerTypeText: Record<OfferType, string> = {
  onSite: 'estoque no produtor (ENP)',
  received: 'recepcionado (LKP)',
  inTransit: 'no sítio (LKP)',
  directed: 'oferta direcionada',
};

export default function OfferTypeText({ offerType }: OfferTypeTextProps) {
  return <span>{offerTypeText[offerType]}</span>;
}
