export enum PackingType {
  Packing = 'Packing',
  Layer = 'Layer',
  Trolley = 'Trolley',
}

export enum PackingTypeMap {
  Packing = 'Embalagem',
  Layer = 'Camada',
  Trolley = ' Carrinho',
}
