export const numberUtils = {
  onlyNumber(evt: any) {
    const numberPattern = /\d+/g;
    const keysAccepteds = [
      'Delete',
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ];

    if (!numberPattern.test(evt.key) && !keysAccepteds.includes(evt.key)) {
      return evt.preventDefault();
    }
    return true;
  },
};

export function formatDecimal(value: number, locale = 'pt-BR') {
  return value.toLocaleString(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
}

export function stringToFloat(value: string) {
  return parseFloat(value.replace('.', '').replace(',', '.'));
};
