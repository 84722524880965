import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { ecommerceApi } from 'services/ecommerceApi';
import { identityApi } from 'services/identityApi';
import { errorMiddleware } from './middlewares/errorMiddleware';
import { loaderMiddleware } from './middlewares/loaderMiddleware';
import {
  loaderReducer,
  errorReducer,
  sessionReducer,
  cartReducer,
  alertReducer,
  offerFiltersReducer,
  balanceReducer,
  buyIntentionReducer,
  myOrderIntentionEditReducer,
  productsReducer
} from './state/slices';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [ecommerceApi.reducerPath]: ecommerceApi.reducer,
    [identityApi.reducerPath]: identityApi.reducer,
    session: sessionReducer,
    cart: cartReducer,
    alert: alertReducer,
    loader: loaderReducer,
    error: errorReducer,
    offerFilters: offerFiltersReducer,
    balance: balanceReducer,
    buyIntention: buyIntentionReducer,
    myOrderIntentionEdit: myOrderIntentionEditReducer,
    products: productsReducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
      {
        serializableCheck: false
      }
    )
      .concat(ecommerceApi.middleware)
      .concat(identityApi.middleware)
      .concat(loaderMiddleware)
      .concat(errorMiddleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
