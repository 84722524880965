import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

function FastCart() {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      data-icon="shopping-cart"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M6.9657 8.3818H2.01853C1.43651 8.3818 1 7.94528 1 7.36326C1 6.78124 1.43651 6.34473 2.01853 6.34473H6.9657V8.3818Z" />
      <path d="M7.98328 11.5839H3.03611C2.45409 11.5839 2.01758 11.1474 2.01758 10.5654C2.01758 9.98339 2.45409 9.54688 3.03611 9.54688H7.98328V11.5839Z" />
      <path d="M8.42081 14.9296H5.0742C4.49218 14.9296 3.91016 14.3476 3.91016 13.7656C3.91016 13.1836 4.34667 12.7471 4.92869 12.7471H8.27531V14.9296H8.42081Z" />
      <path d="M21.9527 16.0954H8.42078L9.14831 14.6404H20.4977C20.9342 14.6404 21.2252 14.3494 21.2252 14.0584L22.8258 5.32808C22.8258 5.03707 22.8258 4.89156 22.6803 4.74606C22.5348 4.45505 22.3893 4.45505 22.3893 4.45505C22.2438 4.45505 22.2438 4.30954 22.0983 4.30954H7.69326L7.54775 3.72752C7.40225 3.29101 7.11124 3 6.67472 3H3.1826C3.0371 3 2.74609 3.1455 2.60058 3.29101C2.45508 3.43651 2.45508 3.58202 2.45508 3.87303C2.45508 4.01853 2.45508 4.30954 2.60058 4.45505C2.74609 4.60055 2.89159 4.74606 3.1826 4.74606H6.0927L6.67472 7.36515L7.98427 13.7674L6.23821 16.3865C6.0927 16.532 6.0927 16.6775 6.0927 16.823C6.0927 16.9685 6.0927 17.114 6.23821 17.2595C6.23821 17.5505 6.52922 17.696 6.82023 17.696H8.27528C7.98427 18.1325 7.83876 18.569 7.83876 19.1511C7.83876 20.4606 8.8573 21.4791 10.1668 21.4791C11.4764 21.4791 12.4949 20.4606 12.4949 19.1511C12.4949 18.7145 12.3494 18.1325 12.0584 17.696H15.696C15.405 18.1325 15.2595 18.569 15.2595 19.1511C15.2595 20.4606 16.2781 21.4791 17.5876 21.4791C18.8971 21.4791 19.9157 20.4606 19.9157 19.1511C19.9157 18.7145 19.7702 18.1325 19.4792 17.696H22.0983C22.5348 17.696 22.9713 17.405 22.9713 16.823C22.9713 16.6775 22.8258 16.3865 22.6803 16.241C22.3893 16.0954 22.2438 16.0954 21.9527 16.0954ZM7.98427 5.9101H21.0797L19.7702 13.0398H9.43932L7.98427 5.9101ZM10.1668 19.7331C9.73033 19.7331 9.43932 19.4421 9.43932 19.0055C9.43932 18.569 9.73033 18.278 10.1668 18.278C10.6034 18.278 10.8944 18.569 10.8944 19.0055C10.8944 19.1511 10.7489 19.4421 10.7489 19.4421C10.4579 19.7331 10.3123 19.7331 10.1668 19.7331ZM17.5876 19.7331C17.1511 19.7331 16.8601 19.4421 16.8601 19.0055C16.8601 18.569 17.1511 18.278 17.5876 18.278C18.0241 18.278 18.3151 18.569 18.3151 19.0055C18.3151 19.1511 18.1696 19.4421 18.1696 19.4421C17.8786 19.7331 17.7331 19.7331 17.5876 19.7331Z" />
      <path d="M16.7118 8.53024H15.2568L16.5663 6.92968V6.78418H13.9472L12.2012 9.69428V9.83978H13.3652L12.9287 12.0224C12.9287 12.0224 12.9287 12.1679 13.0742 12.0224L16.7118 8.53024Z" />
    </svg>
  );
}

export function FastCartIcon(
  props: Pick<IconComponentProps, 'component'> &
    React.RefAttributes<HTMLSpanElement>,
) {
  return <Icon component={FastCart} {...props} />;
}
