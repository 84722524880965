import { useEffect } from 'react';
import { JwtToken } from 'model/types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getProducerUrl,
  parseJwt,
  setAuthorizationToken,
  setExpireIn,
  setRefreshToken,
  setScope,
  setTokenType,
  verifyIsProducer,
} from 'services/config';
import { useGetLinkSiteMutation, useGetUserWithCustomersQuery } from 'services/ecommerceApi';
import { sessionActions } from 'store/state/slices';
import { sessionSelectors } from 'store/state/selectors';
import { UserTypeEnum } from 'enums/userType';

type AuthParam =
  | 'accessToken'
  | 'refreshToken'
  | 'tokenType'
  | 'expiresIn'
  | 'scope';

type AuthParamsMap = {
  [key in AuthParam]: string;
};

export default function Landing() {
  const rolesAllowed = [
    UserTypeEnum.CUSTOMER,
    UserTypeEnum.CUSTOMER_EMPLOYEE,
    UserTypeEnum.PRODUCER,
    UserTypeEnum.PRODUCER_EMPLOYEE,
    UserTypeEnum.VEILING_EMPLOYEE
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const [getLinkSite] = useGetLinkSiteMutation();
  const params: AuthParamsMap = Object.fromEntries(
    urlSearchParams,
  ) as AuthParamsMap;

  const tokenObject: JwtToken = parseJwt(params.accessToken);

  const verifyRoleIsValid = (roles: number | number[]): boolean => {
    if (Array.isArray(roles)) {
      const rolesAllowedFiltered = roles.filter(role => rolesAllowed.includes(+role));
      return rolesAllowedFiltered && rolesAllowedFiltered.length > 0;
    }

    return rolesAllowed.includes(+roles);
  };

  useEffect(() => {
    if (
      params.accessToken &&
      verifyRoleIsValid(tokenObject.veiling_claim_type_role)
    ) {
      setAuthorizationToken(params.accessToken);
      setRefreshToken(params.refreshToken);
      setScope(tokenObject.scope.reverse().toString());
      setExpireIn(String(3600));
      setTokenType(params.tokenType);
    } else {
      dispatch(sessionActions.setFailedLogin(true));
      navigate(`/veiling/`);
    }
  }, []);

  const {
    data: user,
    isSuccess,
    isError,
    isLoading,
  } = useGetUserWithCustomersQuery(undefined, {
    skip: !isAuthenticated || !params?.accessToken,
  });

  const getSiteLinks = async () => {
    console.log('Entrou no getsiteLink na tela de landing');
    const resultProducerUrls: any = await getLinkSite();
    if (resultProducerUrls?.data) {
      dispatch(sessionActions.setProducerInformations({ isProducerAuthenticated: true, urls: resultProducerUrls?.data }));
      if(resultProducerUrls.data.length > 1) {
        navigate(`/veiling/`);
      } else {
        navigate(`/${resultProducerUrls?.data[0]?.url}`);
      }
    }
    else {
      dispatch(sessionActions.setProducerInformations({ isProducerAuthenticated: true, urls: [] }));
      navigate(`/veiling/`);
    };
  };

  useEffect(() => {
    if (
      params.accessToken &&
      verifyRoleIsValid(tokenObject.veiling_claim_type_role)
    ) {
      dispatch(sessionActions.loginSuccess());
    }
  }, []);

  useEffect(() => {
    let paramSession = localStorage.getItem('producerUrl');
    if (!paramSession) {
      paramSession = "producerUndefined";
    }

    console.log("landing", paramSession);
    if (isSuccess && user) {
      dispatch(sessionActions.update({ user }));
      if (verifyIsProducer(tokenObject)) {
        getSiteLinks();
      } else if (paramSession) {
        console.log('Fez o redirect para o param session');
        navigate(`/${paramSession}/`);
      } else {
        console.log('Fez o redirect para o veiling');
        navigate(`/veiling/`);
      }
    } else if (isError && !isSuccess && !isLoading) {
      console.log('Deu erro');
      if (paramSession) {
        navigate(`/${paramSession}/`);
      } else {
        navigate(`/veiling/`);
      }
    }
  }, [isSuccess, user, isLoading]);

  return null;
}
