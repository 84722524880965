import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from 'model/User';

export type SessionState = {
  authenticated: boolean;
  name: string;
  userId: number;
  email: string;
  roleId: number;
  isProducer: boolean;
  producerUrls: ProducerUrls[];
  selectedCustomerId: number;
  selectedCustomerIdFilter: number;
  deliveryDate: string;
  billingDate: string;
  customers: Customer[];
  showBalance: boolean;
  refreshToken: boolean;
  failedLogin: boolean;
  producerUrl: string;
  sitesIds: number[];
  customer: Customer | undefined;
  userAction: boolean;
  timeLeft: number;
};

export type Customer = {
  id: number;
  name: string;
  balance: number;
  accountCode: string;
  hasXmlOrder: boolean;
  branch: string;
};

export type Dates = {
  deliveryDate: string;
  billingDate: string;
};

export type ProducerUrls = {
  isActive: boolean;
  producerIds: number[];
  url: string;
};

export type ProducerPage = {
  producerUrl: string;
  sitesIds: number[];
};

export type ProducerInformations = {
  isProducerAuthenticated: boolean;
  urls: ProducerUrls[];
};

type UserPayload = {
  user: User;
};

export const initialState: SessionState = {
  authenticated: false,
  producerUrl: '',
  sitesIds: [],
  name: '',
  userId: Number.NaN,
  email: '',
  roleId: Number.NaN,
  refreshToken: false,
  isProducer: false,
  producerUrls: [],
  selectedCustomerId: Number.NaN,
  selectedCustomerIdFilter: 0,
  deliveryDate: '',
  billingDate: '',
  customers: [],
  showBalance: false,
  failedLogin: false,
  customer: undefined,
  userAction: false,
  timeLeft: 60
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loginSuccess(state) {
      state.authenticated = true;
    },
    loginError(state) {
      state.authenticated = false;
    },
    setRefreshToken(state, action: PayloadAction<boolean>) {
      state.refreshToken = action.payload;
    },
    setProducerPage(state, action: PayloadAction<ProducerPage>) {
      state.producerUrl = action.payload.producerUrl;
      state.sitesIds = action.payload.sitesIds;
    },
    update(state, action: PayloadAction<UserPayload>) {
      const { user: apiUsers } = action.payload;
      const {
        name: userName,
        userId,
        email,
        roleId,
        customers: apiCustomers,
      } = apiUsers;
      const customers = apiCustomers.map(({ id, name, balance, accountCode, hasXmlOrder, branch }) => ({
        id,
        name,
        balance,
        accountCode,
        hasXmlOrder,
        branch
      }));
      Object.assign(state, {
        name: userName,
        userId,
        email,
        roleId,
        customers,
      
      });
      if (customers.length > 0) {
        state.selectedCustomerId = customers[0].id;
      }
    },
    setSelectedCustomer(state, action: PayloadAction<number>) {
      state.selectedCustomerId = action.payload;
    },
    setSelectedCustomerFilter(state, action: PayloadAction<number>) {
      state.selectedCustomerIdFilter = action.payload;
    },
    setProducerInformations(state, action: PayloadAction<ProducerInformations>) {
      state.isProducer = action.payload.isProducerAuthenticated;
      state.producerUrls = action.payload.urls;
    },
    setFailedLogin(state, action: PayloadAction<boolean>) {
      state.failedLogin = action.payload;
    },
    setBillingDate(state, action: PayloadAction<string>) {
      state.billingDate = action.payload;
    },
    setDeliveryDate(state, action: PayloadAction<string>) {
      state.deliveryDate = action.payload;
    },
    setDates(state, action: PayloadAction<Dates>) {
      state.deliveryDate = action.payload.deliveryDate;
      state.billingDate = action.payload.billingDate;
    },
    logout(state) {
      state.authenticated = false;
      state.isProducer = false;
    },
    setCustomer: (
      state,
      action: PayloadAction<Customer | undefined>,
    ) => {
      state.customer = action.payload;
    },
    setUserAction(state, action: PayloadAction<boolean>) {
      state.userAction = action.payload;
    },
    setTimeLeft(state, action: PayloadAction<number>) {
      state.timeLeft = action.payload;
    }
  },
  extraReducers: {},
});

export const { actions, reducer } = sessionSlice;
