import { Order } from 'model/Order';
import moment from 'moment';

export const dateUtils = {
  prepareDate(date: string | Date) {
    return typeof date === 'string' ? new Date(date) : date;
  },

  createDateWithTimeZone(date = '') {
    if (!date) return date;

    if (date.includes('Z')) {
      let time = new Date().getTimezoneOffset() / 60;

      time = time > 0 ? time - 3 : time;

      return new Date(
        date.replace(
          'Z',
          `${time < 0 ? '+' : '-'}${
            Math.abs(time).toString().length > 1
              ? Math.abs(time)
              : `0${Math.abs(time)}`
          }:00`,
        ),
      ).toISOString();
    }
    return date;
  },

  isExpired(date: string | Date) {
    const now = new Date();
    const dateFormatted = this.prepareDate(date);
    if (!dateFormatted) {
      return false;
    }

    let time = new Date().getTimezoneOffset() / 60;

    if (time < 0) {
      time = (Math.abs(time) * 2 + 3) * -1;
    } else if (time > 0) {
      time = Math.abs(time) * 2 - 3;
    }

    if (time > 0) {
      return (
        dateFormatted?.getTime() -
          new Date(
            this.AddTimeZoneInExpirationDate(now.toString()),
          ).getTime() <=
        0
      );
    }

    return (
      dateFormatted?.getTime() -
        new Date(this.createDateWithTimeZone(now.toString())).getTime() <=
      0
    );
  },

  AddTimeZoneInExpirationDate(date = '') {
    if (!date) return date;

    date = new Date(date).toUTCString();

    if (!date.includes('Z')) {
      date = `${date}Z`;
    }
    if (date.includes('Z')) {
      let time = Math.round(new Date().getTimezoneOffset() / 60);

      if (time < 0) {
        time = (Math.abs(time) * 2 + 3) * -1;
      } else if (time > 0) {
        time = Math.abs(time) * 2 - 3;
      }

      if (time !== 0) {
        const dateTeste = new Date(
          date.replace(
            'Z',
            `${time > 0 ? '+' : '-'}${
              Math.abs(time).toString().length > 1
                ? Math.abs(time)
                : `0${Math.abs(time)}`
            }:00`,
          ),
        ).toISOString();
        return dateTeste;
      }
      return date;
    }
    return date;
  },
  removeTimeZoneInExpirationDate(date = '') {
    if (!date) return date;
    if (!date.includes('Z')) {
      date = `${date}Z`;
    }
    if (date.includes('Z')) {
      let time = new Date().getTimezoneOffset() / 60;
      time = Math.round(time);

      return new Date(
        date.replace(
          'Z',
          `${time > 0 ? '+' : '-'}${
            Math.abs(time).toString().length > 1
              ? Math.abs(time)
              : `0${Math.abs(time)}`
          }:00`,
        ),
      ).toISOString();
    }
    return date;
  },
  formatDate(dateValue: string, format: string) {
    return moment(dateValue).format(format);
  },
  getDateStringNumber(dateString: string) {
    return moment(dateString).unix();
  },
  orderDate(actualDate: string, nextDate: string) {
    return moment(actualDate).unix() - moment(nextDate).unix();
  },
  getDateIfLkp(order: Order) {
    if (order.isLKPSale) {
      return moment().format('YYYY-MM-DD');
    }
    return order.deliveryDate;
  },
};
