import { Modal, Spin } from 'antd';
import styled from 'styled-components';

export const ModalCreateComposition = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
    .ant-modal-close {
        display: none;
    }
    .ant-modal-content {
        border-radius: 8px;
    }
`;

export const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
`;

export const ModalHeaderCreateComposition = styled.div`
    height: 100%;   
    display: flex;
    flex-direction: column;   
    padding: 20px;

    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #f78e1e;

    span {
        color: #fff;
        font-size: 22px;
        line-height: 20px;
        font-weight: 500;
    }
    p { 
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        
        padding: 0;
        margin-bottom: 0;
        margin-top: 12px;
    }
`;

export const ModalBodyCreateComposition = styled.div`
    .cards {
        max-height: 550px;
        width: 100%;
        overflow: auto;
    }
`;

export const ModalFooterCreateComposition = styled.div`
    width: 100%;
    padding:  8px 40px;
`;

export const ModalFooterInformations = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
export const ModalFooterInformationText = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:space-between;
    margin-bottom: 8px; 
    > p {
        text-align: start;
        margin: 0;
        margin-right: 8px;
        padding-bottom: 16px;
        margin-bottom: -3px;
        color: #8c8c8c;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    } 
    > span {
        text-align: start;
        color: #353839;
        padding-bottom: 16px;      
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;

    }
`;
export const ModalFooterActions = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
`;
