import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

interface CountdownTimerProps {
  expirationTime: Moment;
  onExpire: () => void;
  expiringSecondsAlert?: number;
  onExpiring?: () => void;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
  expirationTime,
  onExpire,
  expiringSecondsAlert = 1800,
  onExpiring,
}) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(0);

  useEffect(() => {
    const now = moment();
    const secondsDifference = expirationTime.diff(now, 'seconds');
    if (secondsDifference <= 0) {
      onExpire();
      return () => {};
    }
    if (secondsDifference > 0) {
      setTimeRemaining(secondsDifference);
      const timer = setInterval(() => {
        if (timeRemaining > 0) {
          setTimeRemaining(timeRemaining - 1);
          return;
        }
        onExpire();
      }, 1000);
      if (
        expiringSecondsAlert &&
        onExpiring &&
        secondsDifference <= expiringSecondsAlert
      ) {
        onExpiring();
      }
      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [expirationTime, timeRemaining]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  return (
    <p style={{ paddingTop: '10px' }}>
      {minutes < 10 ? '0' : ''}
      {minutes}:{seconds < 10 ? '0' : ''}
      {seconds}
    </p>
  );
};
