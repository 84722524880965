import React from 'react';
import { Col, Row, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import ProducerProduct from 'model/ProducerProduct';
import ProducerProductCard from 'components/BusinessLogic/Product/ProducerProductCard';

import { useGetProductsBasedOnLastVisitQuery } from 'services/ecommerceApi';

import { sessionSelectors } from 'store/state/selectors';
import { useSelector } from 'react-redux';
import * as S from './styles';

export default function LastVisit() {
  const { t } = useTranslation();
  const siteId = useSelector(sessionSelectors.getSitesIds);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const { data: productsBasedOnLastVisit } =
    useGetProductsBasedOnLastVisitQuery(siteId, {skip: !isAuthenticated});

  return (
    <S.Root>
      <Row>
        <Col xs={24}>
          <S.Header>
            <S.SectionTitle>
              {isAuthenticated
                ? t('home.basedOnLastVisit')
                : 'Baseado nos mais visitados'}
            </S.SectionTitle>
          </S.Header>
        </Col>
        <Col xs={24}>
          <S.ProductCards>
            <Row>
              {productsBasedOnLastVisit &&
              productsBasedOnLastVisit.length > 0 ? (
                productsBasedOnLastVisit.map(
                  (producerProduct: ProducerProduct) => (
                    <Col
                      key={`col${producerProduct.id}`}
                      xs={24}
                      md={12}
                      lg={8}
                      xl={6}
                    >
                      <ProducerProductCard
                        key={`LastVisit-${producerProduct.id}`}
                        producerProduct={producerProduct}
                        cardClassName="hover-card-product"
                      />
                    </Col>
                  ),
                )
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Row>
          </S.ProductCards>
        </Col>
      </Row>
    </S.Root>
  );
}
