import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SummaryCart } from 'model/types';
import { dateUtils } from 'utils/date';

export type CartState = {
  lkpOfferId?: number;
  volOfferId?: number;
  customerId: number;
  volTotal: number;
  lkpTotal: number;
  totalItems: number;
  user: string;
  deliveryDate: string;
  billingDate: string;
  volExpiration: string;
  lkpExpiration: string;
  totalLkpItems: number;
  totalVolItems: number;
  lKpCreationDate: string;
  volCreationDate: string;
  refetchRequestCartItems: boolean;
};

export const initialState: CartState = {
  lkpOfferId: 0,
  volOfferId: 0,
  customerId: Number.NaN,
  volTotal: 0,
  lkpTotal: 0,
  totalItems: 0,
  user: '',
  deliveryDate: '',
  billingDate: '',
  volExpiration: '',
  lkpExpiration: '',
  totalLkpItems: 0,
  totalVolItems: 0,
  lKpCreationDate: '',
  volCreationDate: '',
  refetchRequestCartItems: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setRefetchRequestCartItems(state, action: PayloadAction<boolean>) {
      state.refetchRequestCartItems = action.payload;
    },
    setLkpOfferId(state, action: PayloadAction<number>) {
      state.lkpOfferId = action.payload;
    },
    setVolOfferId(state, action: PayloadAction<number>) {
      state.volOfferId = action.payload;
    },
    setCustomerId(state, action: PayloadAction<number>) {
      state.customerId = action.payload;
    },   
    setVolTotal(state, action: PayloadAction<number>) {
      state.volTotal = action.payload;
    },
    setLkpTotal(state, action: PayloadAction<number>) {
      state.lkpTotal = action.payload;
    },
    
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload;
    },
    setUser(state, action: PayloadAction<string>) {
      state.user = action.payload;
    },
    setDeliveryDate(state, action: PayloadAction<string>) {
      state.deliveryDate = action.payload;
    },
    setBillingDate(state, action: PayloadAction<string>) {
      state.billingDate = action.payload;
    },
    setVolExpiration(state, action: PayloadAction<string>) {
      state.volExpiration = action.payload;
    },
    setLkpExpiration(state, action: PayloadAction<string>) {
      state.lkpExpiration = action.payload;
    },
    setTotalLkpItems(state, action: PayloadAction<number>) {
      state.totalLkpItems = action.payload;
    },
    setTotalVolItems(state, action: PayloadAction<number>) {
      state.totalVolItems = action.payload;
    },
    setLKpCreationDate(state, action: PayloadAction<string>) {
      state.lKpCreationDate = action.payload;
    },
    setVolCreationDate(state, action: PayloadAction<string>) {
      state.volCreationDate = action.payload;
    },
    setSummaryCart(state, action: PayloadAction<SummaryCart>) {
      state.lkpOfferId = action.payload.lkpOfferId;
      state.volOfferId = action.payload.volOfferId;
      state.customerId = action.payload.customerId;
      state.volTotal = action.payload.volTotal;
      state.lkpTotal = action.payload.lkpTotal;
      state.totalItems = action.payload.totalItems;
      state.user = action.payload.user;
      state.deliveryDate = dateUtils.createDateWithTimeZone(action.payload.deliveryDate);
      state.billingDate = dateUtils.createDateWithTimeZone(action.payload.billingDate);
      state.volExpiration = action.payload.volExpiration ? dateUtils.removeTimeZoneInExpirationDate(new Date(action.payload.volExpiration).toUTCString()).replace('Z', '') : '';
      state.lkpExpiration = action.payload.lkpExpiration ? dateUtils.removeTimeZoneInExpirationDate(new Date(action.payload.lkpExpiration).toUTCString()).replace('Z', '') : '';
      state.totalLkpItems = action.payload.totalLkpItems;
      state.totalVolItems = action.payload.totalVolItems;
      state.lKpCreationDate = action.payload.lKpCreationDate;
      state.volCreationDate = action.payload.volCreationDate;
    },
    clearSummaryCart(state) {
      state.lkpOfferId = initialState.lkpOfferId;
      state.volOfferId = initialState.volOfferId;
      state.customerId = initialState.customerId;
      state.volTotal = initialState.volTotal;
      state.lkpTotal = initialState.lkpTotal;
      state.totalItems = initialState.totalItems;
      state.user = initialState.user;
      state.deliveryDate = initialState.deliveryDate;
      state.billingDate = initialState.billingDate;
      state.volExpiration = initialState.volExpiration;
      state.lkpExpiration = initialState.lkpExpiration;
      state.totalLkpItems = initialState.totalLkpItems;
      state.totalVolItems = initialState.totalVolItems;
      state.lKpCreationDate = initialState.lKpCreationDate;
      state.volCreationDate = initialState.volCreationDate;
    },
  },
  extraReducers: {},
});

export const { actions, reducer } = cartSlice;
