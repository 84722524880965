import styled, { css } from 'styled-components';

interface InformationContainer {
  inline?: number;
}

interface ContainerComplementary {
  show: number;
}

interface ContainerFixed {
  isShowingComplement: number;
}

interface ImageContainer {
  isShowingComplement: number;
}

interface InformationIconContainerProps {
  isLkpOffer?: number;
}

export const Container = styled.div`
  margin-bottom: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  * {
    line-height: 1;
  }
`;

export const ContainerFixed = styled.div.attrs({
  role: 'button',
})<ContainerFixed>`
  position: relative;
  display: flex;

  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${(props) =>
    !props.isShowingComplement &&
    css`
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    `}
`;

export const ContainerComplementary = styled.div<ContainerComplementary>`
  display: ${(props) => (props.show ? 'block' : 'none')};

  background-color: #fff;
  border-top: 1px solid #d9d9d9;

  padding: 8px;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ImageContainer = styled.div<ImageContainer>`
  background-color: #f78e1e;
  width: 24px;
  padding: 5px;
  border-top-left-radius: 8px;

  ${(props) =>
    !props.isShowingComplement &&
    css`
      border-bottom-left-radius: 8px;
    `}
`;

export const ButtonImage = styled.button.attrs({ type: 'button' })`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  svg {
    color: #fff;
  }
`;

export const ProductInfoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  position: relative;
  padding: 6px 10px;
`;

export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InfoRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const PrimaryInfo = styled.div`
  display: flex;
`;

export const InformationIconContainer = styled.div<InformationIconContainerProps>`
  margin-right: ${(props) => (props.isLkpOffer ? 6 : 12)}px;

  svg {
    color: #f78e1e;
    font-size: 14px;
  }

  p {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: bold;
    color: rgba(0, 91, 167, 1);
    margin-left: -6.5px;
    line-height: 12px;
  }
`;

export const InformationContainer = styled.div``;

export const ProductName = styled.p`
  font-size: 12px;
  font-weight: bold;

  margin-bottom: 4px;
`;

export const BarCode = styled.span`
  font-size: 8px;
  color: #8c8c8c;
  > span + span {
    margin-left: 8px;
  }
`;

export const SecondaryInfo = styled.div``;

export const Information = styled.div<InformationContainer>`
  display: flex;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
`;

export const InformationLabel = styled.p`
  font-size: 8px;
  color: #353839;
  margin-bottom: 2px;
`;

export const InformationValue = styled.span`
  font-weight: bold;
  color: #353839;
`;

export const ProducerLabel = styled.p`
  font-size: 10px;
  color: #353839;
`;

export const ProducerValue = styled.div`
  font-size: 10px;
  margin-left: 4px;
  color: #f78e1e;
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  .badge-component {
    width: auto;
    padding: 20px;
    height: 14px;
    > span {
      white-space: nowrap;
      max-width: 102px;
      font-size: 8px;
    }
  }
`;

export const UnitValueContainer = styled.div`
  .value-product {
    max-width: 100%;
    > h1 {
      padding-top: 2px !important;
    }
    > div {
      justify-content: center;
    }
  }
`;

export const HideContainer = styled.div`
  .image-gallery {
    display: none !important;
  }
`;

export const InfoComplementary = styled.div``;

export const Actions = styled.div`
  margin: -8px;
  border-top-width: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .product-card-actions {
    .button-select {
      height: 30px;
      > span {
        &:first-child {
          font-size: 8px;
        }
        &:last-child {
          font-size: 12px;
        }
      }
    }
    .ant-form-item {
      margin-bottom: 0;
      &-control-input {
        min-height: 0;
      }
    }

    .ant-form-item-with-help .ant-form-item-explain {
      min-height: 0;
      > div[role='alert'] {
        margin: 4px 0;
      }
    }

    .row-info {
      margin-bottom: 0;
    }

    .order-actions {
      .ant-input-group-wrapper {
      }
      button {
        height: 24px;
        width: 24px;
      }
      input[type='text'],
      input[type='tel'] {
        height: 24px;
        width: 100px !important;
      }
    }

    .group-type-sale-product {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const InfoValueContainerGroup = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  + div {
    margin-top: 8px;
  }
`;

export const InfoValueContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const InfoValue = styled.div`
  color: #353839;
`;

export const Label = styled.p`
  font-size: 8px;
  margin-bottom: 2px;
  color: inherit;
  line-height: 8px;
  margin-block: 0;
`;

export const Value = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: inherit;
  line-height: 12px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 !important;
    height: 16px !important;
  }
  .ant-form-item-control-input {
    min-height: 1px;
  }
  .select-packing {
    font-size: 12px;
    .ant-select-selection-item {
      height: 16px;
      line-height: 16px !important;
    }

    .ant-select-selection-search-input {
      height: auto !important;
    }
  }
`;

export const ComponentInfoNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #353839;
  color: #fff;
  border-radius: 16px;
  font-size: 8px;

  padding: 2px 10px;
`;

export const ComponentInfoVoucher = styled.span`
  padding: 2px;
  left: 0;
  text-align: center;
  background-color: #f78e1e;
  color: #fff;
  border-radius: 4px;
  word-break: break;

  span.valeu {
    font-weight: bold;
    font-size: inherit;
  }

  display: flex;
  gap: 4px;
  font-size: 8px;
`;

export const ContainerFloat = styled.div`
  position: absolute;
  bottom: -2px;
  right: 2px;

  display: flex;
  gap: 2px;
`;

export const InformationVoucher = styled.p`
  margin-bottom: 0;
  font-size: 11px;
  color: #f78e1e !important;
  padding: 0px 0px 16px 0px;
`;
