import { memo, useMemo } from 'react';

import { DeliveryPatterns } from 'model/DeliveryPatterns';

import { RowTitle, ColTitle, Row, Col } from './styles';

interface Props {
  data: DeliveryPatterns[];
}

function DeliveryScheduleTable({ data }: Props) {
  const deliveryPatternsFormatted = useMemo(
    () =>
      data.map((deliveryPattern) => ({
        ...deliveryPattern,
        orderEndDayName: deliveryPattern.orderEndDayName.substring(0, 3),
        orderEndTime: deliveryPattern.orderEndTime.substring(0, 5),
        deliveryDayName: deliveryPattern.deliveryDayName.substring(0, 3),
        deliveryTime: deliveryPattern.deliveryTime.substring(0, 5),
      })),
    [data],
  );

  return (
    <>
      <RowTitle>
        <ColTitle className="col-left">Pedidos feitos até:</ColTitle>
        <ColTitle className="col-right">Previsão de entrega na CVH:</ColTitle>
      </RowTitle>
      {deliveryPatternsFormatted.map((deliveryPattern, index) => (
        <Row key={`${deliveryPattern.deliveryDayName}-${2 * index}`}>
          <Col className="col-left">
            {deliveryPattern.orderEndDayName}: {deliveryPattern.orderEndTime}
          </Col>
          <Col className="col-right">
            {deliveryPattern.deliveryDayName}: {deliveryPattern.deliveryTime}
          </Col>
        </Row>
      ))}
    </>
  );
}

export default memo(DeliveryScheduleTable);
