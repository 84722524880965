import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import * as BuyIntentionModels from 'model/BuyIntention';
import moment, { Moment } from 'moment';
import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as BuyIntentionCalls from 'store/state/buyIntention/apiCalls';
import * as BuyIntentionSelectors from 'store/state/buyIntention/buyIntenteionSelectors';
import {
  alertActions,
  buyIntentionActions,
  sessionActions,
} from 'store/state/slices';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as S from './style';

const { Option } = Select;
const UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST';

function prepareDate(date: Moment) {
  return date.set('hour', 0).format('YYYY-MM-DD');
}

export default function FormBuyIntention() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customer = useSelector(BuyIntentionSelectors.getCustomer);
  const currentStep = useSelector(BuyIntentionSelectors.getCurrentStep);
  const producers = useSelector(BuyIntentionSelectors.getProducers);

  const [formBuyIntention] = Form.useForm();
  const [deliveryDate, setDeliveryDate] = useState<string | undefined>(
    undefined,
  );
  const [comercialDate, setComercialDate] = useState<string | undefined>(
    undefined,
  );
  const [product, setProduct] = useState<string | undefined>(undefined);
  const [producerId, setProducerId] = useState<number | undefined>(undefined);

  const onChangeDeliveryDate: DatePickerProps['onChange'] = (dateString) => {
    const date = moment(dateString).format('YYYY-MM-DD');
    const dateFinish = moment(dateString).format('YYYY-MM-DDTHH:mm:ss.SSSS');
    setComercialDate(undefined);
    formBuyIntention.setFieldsValue({ comercialDate: null });
    if (dateString !== null) {
      setDeliveryDate(date);
      dispatch(buyIntentionActions.setDeliveryDateFinish(dateFinish));
    } else {
      setDeliveryDate(undefined);
      dispatch(buyIntentionActions.setDeliveryDateFinish(''));
      dispatch(buyIntentionActions.setComercialDateFinish(''));
    }
  };

  const onChangeComercialDate: DatePickerProps['onChange'] = (dateString) => {
    const date = moment(dateString).format('YYYY-MM-DD');
    const dateFinish = moment(dateString).format('YYYY-MM-DDTHH:mm:ss.SSSS');
    if (dateString !== null) {
      setComercialDate(date);
      dispatch(buyIntentionActions.setComercialDateFinish(dateFinish));
    } else {
      setComercialDate(undefined);
      dispatch(buyIntentionActions.setComercialDateFinish(''));
    }
  };

  const onSubmit = useCallback(
    (formValues: BuyIntentionModels.FormFilters) => {
      const delivery = formBuyIntention.getFieldValue('deliveryDate');
      const comercial = formBuyIntention.getFieldValue('comercialDate');
      // if (!customer?.accountCode) {
      //   dispatch(errorActions.throw(UNAUTHORIZED_REQUEST));
      //   return;
      // }
      dispatch(
        sessionActions.setDates({
          billingDate: comercial ? prepareDate(comercial) : '',
          deliveryDate: delivery ? prepareDate(delivery) : '',
        }),
      );
      dispatch(
        buyIntentionActions.setDatesForOffers({
          comercial: comercial ? prepareDate(comercial) : '',
          delivery: delivery ? prepareDate(delivery) : '',
        }),
      );
      if (formValues.producer) {
        dispatch(
          BuyIntentionCalls.fetchOffersByProducers({
            producerId: formValues?.producer,
            keyword: formValues.product,
            accountCode: customer?.accountCode,
          }),
        );
        dispatch(buyIntentionActions.setIsLoading(true));
        dispatch(buyIntentionActions.setSClearTotalPriceOffers());
      } else if (formValues.product) {
        dispatch(
          BuyIntentionCalls.fetchOffersByProducts({
            keyword: formValues?.product,
            accountCode: customer?.accountCode,
          }),
        );
        dispatch(buyIntentionActions.setIsLoading(true));
        dispatch(buyIntentionActions.setSClearTotalPriceOffers());
      } else {
        dispatch(
          alertActions.alertRequest({
            message: 'Preencha o campo Produto ou Produtor!',
            type: 'warning',
            showIcon: true,
            timeout: 3000,
          }),
        );
      }
    },
    [formBuyIntention, dispatch],
  );

  const disableDatesDelivery = (value: Moment) => {
    const today = moment().startOf('day');
    const day = value.date();
    const month = value.month();
    return (
      value.isBefore(today) ||
      (month === 11 && day === 25) ||
      (month === 0 && day === 1)
    );
  };

  const disableDatesComercial = (value: Moment) => {
    const today = moment().startOf('day');
    const day = value.date();
    const month = value.month();
    if (deliveryDate !== undefined) {
      let lastDay: any;
      const initalDay = moment(deliveryDate).startOf('day');
      if (moment(deliveryDate).format('dddd') === 'sábado') {
        lastDay = moment(deliveryDate).add(3, 'days').startOf('day');
      } else {
        lastDay = moment(deliveryDate).add(2, 'days').startOf('day');
      }

      return (
        value.isAfter(lastDay) ||
        value.isBefore(initalDay) ||
        (month === 11 && day === 25) ||
        (month === 0 && day === 1)
      );
    }
    return value.isBefore(today);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      formBuyIntention.submit();
    }
  };

  const clearForm = () => {
    setComercialDate(undefined);
    setDeliveryDate(undefined);
    setProducerId(undefined);
    setProduct(undefined);
    formBuyIntention.resetFields();
  };

  function getPopupContainer(triggerNode: any) {
    return triggerNode.parentElement;
  }

  return (
    <S.Step form={formBuyIntention} key="1">
      <Form layout="horizontal" form={formBuyIntention} onFinish={onSubmit}>
        <Row gutter={8}>
          <S.ColStyled xs={24} md={12} lg={12}>
            <Form.Item label={t('buyIntention.form.customer')} name="customer">
              <Select
                disabled
                placeholder={
                  customer
                    ? `${customer?.accountCode} - ${customer?.name}`
                    : 'Indefinido'
                }
              />
            </Form.Item>
          </S.ColStyled>

          <S.ColStyled xs={24} md={6} lg={6}>
            <Form.Item
              key="form-item-delivery-date"
              label={t('buyIntention.form.deliveryDate')}
              name="deliveryDate"
              rules={[
                {
                  required: true,
                  message: 'Selecione a data.',
                },
              ]}
            >
              <DatePicker
                getPopupContainer={(v) => getPopupContainer(v)}
                allowClear
                disabledDate={disableDatesDelivery}
                format="DD/MM/YYYY"
                placeholder="Selecione"
                onChange={onChangeDeliveryDate}
              />
            </Form.Item>
          </S.ColStyled>

          <S.ColStyled xs={24} md={6} lg={6}>
            <Form.Item
              key="form-item-comercial-date"
              label={t('buyIntention.form.comercialDate')}
              name="comercialDate"
              rules={[
                {
                  required: true,
                  message: 'Selecione a data.',
                },
              ]}
            >
              <DatePicker
                getPopupContainer={(v) => getPopupContainer(v)}
                allowClear
                disabledDate={disableDatesComercial}
                disabled={!deliveryDate}
                format="DD/MM/YYYY"
                placeholder="Selecione"
                onChange={onChangeComercialDate}
              />
            </Form.Item>
          </S.ColStyled>
        </Row>

        <S.ContentTitleProducers stepTwo={currentStep === 1}>
          <S.Line lineSize={3} />
          <S.TitleProducers>
            {t('buyIntention.form.productsLine')}
          </S.TitleProducers>
          <S.Line lineSize={100} />
        </S.ContentTitleProducers>

        {currentStep === 0 && (
          <S.RowStyled justify="space-between">
            <S.ColStyled
              isMobile={isMobile}
              xs={24}
              md={8}
              lg={8}
              style={{ zIndex: 10 }}
            >
              <Form.Item label={t('buyIntention.form.product')} name="product">
                <Input
                  placeholder="Pesquise aqui"
                  value={product}
                  style={{
                    width: isMobile ? '100%' : 300,
                  }}
                  onChange={(v) => setProduct(v.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </Form.Item>
            </S.ColStyled>

            <S.ContentAside isMobile={isMobile}>
              <S.ColStyled xs={24} md={8} lg={8}>
                <Form.Item
                  label={t('buyIntention.form.producer')}
                  name="producer"
                >
                  <Select
                    getPopupContainer={(v) => getPopupContainer(v)}
                    style={{ width: isMobile ? '100%' : 330 }}
                    onChange={setProducerId}
                    value={producerId}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption
                    placeholder="Selecione"
                    onKeyDown={handleKeyDown}
                    dropdownStyle={{ minWidth: isMobile ? '100%' : '490px' }}
                  >
                    {Array.isArray(producers) &&
                      producers.map((value: BuyIntentionModels.Producers) => (
                        <Option key={value.id} value={value.id}>
                          {value.account}-{value.corporateName}(
                          {value.tradingName})
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </S.ColStyled>

              {!isMobile && (
                <>
                  <S.ContentButton>
                    <Button onClick={clearForm}>
                      <ClearOutlined />
                    </Button>
                  </S.ContentButton>

                  <S.ContentButton>
                    <Button
                      type="primary"
                      onClick={() => formBuyIntention.submit()}
                    >
                      <SearchOutlined />
                    </Button>
                  </S.ContentButton>
                </>
              )}
            </S.ContentAside>

            {isMobile && (
              <S.ContentMobileButton>
                <S.ContentButton isMobile={isMobile}>
                  <Button onClick={clearForm}>
                    <ClearOutlined />
                  </Button>
                </S.ContentButton>

                <S.ContentButton isMobile={isMobile}>
                  <Button
                    type="primary"
                    onClick={() => formBuyIntention.submit()}
                  >
                    <SearchOutlined />
                  </Button>
                </S.ContentButton>
              </S.ContentMobileButton>
            )}
          </S.RowStyled>
        )}
      </Form>
    </S.Step>
  );
}
