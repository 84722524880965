import { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { Col, Form } from 'antd';
import { PackingTypeSale, PackingTypeSaleMap } from 'enums/packingTypeSale';
import Packing from 'model/Packing';
import ProductOffer from 'model/ProductOffer';
import { useDispatch, useSelector } from 'react-redux';
import { actions as buyIntentionActions } from 'store/state/buyIntention/buyIntentionSlice';
import * as BuyIntensionSelectors from 'store/state/buyIntention/buyIntenteionSelectors';
import { sessionSelectors } from 'store/state/selectors';
import { getShowPinnedFilter } from 'store/state/offerFilters/offerFiltersSelectors';
import { KeyPress } from 'utils/keypress';
import { calculateMinBySaleType } from 'utils/util';
import { validatorUtils } from 'utils/validator';
import { ButtonPacking } from 'components/BusinessLogic/Product/ButtonPacking';
import ProductShippingFee from 'components/UI/ProductShippingFee';
import { Product } from 'model';
import {
  ProductCardActions,
  PriceOptions,
  GroupTypeSaleProduct,
  AmountInfo,
  MinimumPurchase,
  MinimumPurchaseLabel,
  MinimunAmount,
  AvailableInfo,
  AvailableAmount,
  AvailableInfoLabel,
  AvailablePer,
  AvailablePackage,
  OrderActions,
  CardBottomContainer,
  PackagesDiv,
  PackagesInput,
  FormItem,
  ButtonType,
  ThemeProps,
  MainContent,
  ModeType,
  FormType,
  Content,
  Available,
} from './styles';


interface Props {
  packingSelected: Packing;
  product: ProductOffer;
  isProducerAuthenticated: boolean;
  onClickBuy: (
    quantityPacking: number,
    selectedTypeSale: PackingTypeSale,
  ) => void;
  onClickFastBuy: (
    quantityPacking: number,
    selectedTypeSale: PackingTypeSale,
  ) => void;
  actionsButtonType: ButtonType;
  formType?: FormType;
  mode?: ModeType;
  theme?: ThemeProps;
  selectedTypeSale: PackingTypeSale;
  onChangePackingTypeSale: (packingTypeSale: PackingTypeSale) => void;
}

function ProductActions({
  product,
  packingSelected,
  isProducerAuthenticated,
  actionsButtonType,
  mode = 'column',
  theme = 'default',
  formType = 'medium',
  onClickBuy,
  onClickFastBuy,
  selectedTypeSale,
  onChangePackingTypeSale,
}: Props) {
  const dispatch = useDispatch();

  const showPinnedFilter = useSelector(getShowPinnedFilter);
  const submitOffers = useSelector(BuyIntensionSelectors.getSubmitOffers);
  const idsSelected = useSelector(BuyIntensionSelectors.getIdsProductSelected);
  const idsAccepted = useSelector(BuyIntensionSelectors.getIdsProductAccepted);
  const { customers } = useSelector(sessionSelectors.getRoot);
  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );

  const validationComposition =
    product.isCompositionEnabled && product.productCategoryId === 1;

  const validationQuantity = validationComposition
    ? calculateMinBySaleType(
        selectedTypeSale,
        packingSelected,
        product.minimunQuantityCutProductComposition,
        validationComposition,
      )
    : packingSelected?.minimumQuantity;

  const formValidation = {
    quantityPacking: [
      {
        required: true,
        message: 'A quantidade é obrigatória.',
      },
      {
        validator: (_: any, value: string) =>
          !value || Number(value) <= getMaxAvailable.max
            ? Promise.resolve()
            : Promise.reject(
                new Error(
                  `A quantidade máxima é ${getMaxAvailable.max} ${textInputTypeSale}`,
                ),
              ),
      },
      {
        validator: (_: any, value: string) =>
          !value ||
          (validationComposition
            ? Number(value)
            : Number(value) * getMaxAvailable.min) >= validationQuantity
            ? Promise.resolve()
            : Promise.reject(new Error(getErrorMinMessage())),
      },
      {
        validator: (_: any, value: number) =>
          validatorUtils.validMultiple(
            value,
            packingSelected?.multiple ?? 0,
            selectedTypeSale,
            getMaxAvailableLabel,
            getMultipleCalc(),
            textInputTypeSale,
          ),
      },
    ],
  };

  const [form] = Form.useForm();

  const [onlyBuy, setOnlyBuy] = useState(false);
  const [quantityPacking, setQuantityPacking] = useState(0);
  const [selectedCustomer, _setSelectedCustomer] = useState(
    customers?.find((customer) => customer.id === selectedCustomerId),
  );

  const shippingFee = useMemo(
    () =>
      product?.shippingFeeFilials?.find(
        (shippingFee) =>
          shippingFee.organizationName === selectedCustomer?.branch,
      )?.productShippingValue,
    [product.shippingFeeFilials, selectedCustomer?.branch],
  );

  const textInputTypeSale = useMemo(
    () => `${PackingTypeSaleMap[selectedTypeSale]}(s)`,
    [selectedTypeSale],
  );

  const getMaxAvailableLabel = useMemo(() => {
    const prop = `maxAvailable${selectedTypeSale}sLabel` as keyof Packing;
    return packingSelected[prop] as string;
  }, [packingSelected, selectedTypeSale]);

  const getMaxAvailable = useMemo(() => {
    if (selectedTypeSale === PackingTypeSale.Packing) {
      return {
        label: packingSelected?.maxAvailablePackingsLabel || '',
        max: packingSelected?.maxAvailablePackingsQuantity || 0,
        min: packingSelected?.unitsPerPackage,
      };
    }
    if (selectedTypeSale === PackingTypeSale.Layer) {
      return {
        label: packingSelected?.maxAvailableLayersLabel || '',
        max: packingSelected?.maxAvailableLayersQuantity || 0,
        min:
          packingSelected?.packagingByLayer * packingSelected?.unitsPerPackage,
      };
    }
    return {
      label: packingSelected?.maxAvailableTrolleysLabel || '',
      max: packingSelected?.maxAvailableTrolleysQuantity || 0,
      min:
        packingSelected?.packagingByLayer *
        packingSelected?.layersByTrolley *
        packingSelected?.unitsPerPackage,
    };
  }, [packingSelected, selectedTypeSale]);

  const amountInfoFC = useMemo(
    () => (
      <AmountInfo className="row-info" mode={mode}>
        <div className="container">
          <Col>
            <MinimumPurchase>
              <MinimumPurchaseLabel>Compra mínima:</MinimumPurchaseLabel>
              <MinimunAmount>
                {calculateMinBySaleType(
                  selectedTypeSale,
                  packingSelected,
                  product.minimunQuantityCutProductComposition,
                  product.isCompositionEnabled &&
                    product.productCategoryId === 1,
                )}{' '}
                {textInputTypeSale}
              </MinimunAmount>
            </MinimumPurchase>
          </Col>
          <Col>
            <Available>
              <AvailableInfo>
                <AvailableAmount>{getMaxAvailable.max}</AvailableAmount>
                <AvailableInfoLabel>disponíveis</AvailableInfoLabel>
              </AvailableInfo>
              <AvailablePer>
                <AvailablePackage>{getMaxAvailable.label}</AvailablePackage>
              </AvailablePer>
            </Available>
          </Col>
        </div>
      </AmountInfo>
    ),
    [
      getMaxAvailable.label,
      getMaxAvailable.max,
      mode,
      packingSelected,
      selectedTypeSale,
      textInputTypeSale,
      product,
    ],
  );

  const getMin = (
    selectedTypeSale: PackingTypeSale,
    packingSelected: Packing,
    calcField: 'multiple' | 'minimumQuantity',
    prefrerQuantity?: number,
    preferQauntityActive?: boolean,
  ) => {
    const minimum =
      preferQauntityActive && prefrerQuantity
        ? prefrerQuantity * +packingSelected?.maxAvailablePackingsLabel
        : packingSelected[calcField];
    if (selectedTypeSale === PackingTypeSale.Packing) {
      const calc = minimum / +packingSelected?.maxAvailablePackingsLabel;
      return calc < 1 ? 1 : calc;
    }
    const values = (
      selectedTypeSale === PackingTypeSale.Layer
        ? packingSelected.maxAvailableLayersLabel
        : packingSelected.maxAvailableTrolleysLabel
    )
      .split('x')
      .map((x) => +x)
      .reduce((a, b) => +a * +b);
    const calc = minimum / values;
    return calc < 1 ? 1 : Math.ceil(calc);
  };

  const handleTypeSale = (packingType: PackingTypeSale) => {
    onChangePackingTypeSale(packingType);
  };

  const getErrorMinMessage = useCallback(
    () =>
      `Quantidade mínima: ${getMin(
        selectedTypeSale,
        packingSelected,
        'minimumQuantity',
        product.minimunQuantityCutProductComposition,
        validationComposition,
      )} ${textInputTypeSale}`,
    [
      selectedTypeSale,
      packingSelected,
      textInputTypeSale,
      product.minimunQuantityCutProductComposition,
      validationComposition,
    ],
  );

  const getMultipleCalc = useCallback(
    () => getMin(selectedTypeSale, packingSelected, 'multiple'),
    [selectedTypeSale, packingSelected],
  );

  const fastBuyProducts = () => {
    console.log('deu certo');
    if (idsAccepted.indexOf(product.id) !== -1 && onlyBuy) {
      onClickFastBuy(quantityPacking, selectedTypeSale);
      setOnlyBuy(false);
      dispatch(buyIntentionActions.setShowModalOffers(false));
      dispatch(buyIntentionActions.setIsLoading(true));
    }
  };

  const onSubmit = useCallback(
    (data: { quantityPacking: string }) => {
      console.log(`onSubmit:: `, data);
      dispatch(buyIntentionActions.setIdsProductsAccepted(product.id));
    },
    [dispatch, product.id],
  );

  const handleClickFastBuy = useCallback(() => {
    form.submit();
  }, [form]);

  const calculateSubTotal = (value: number) => {
    if (selectedTypeSale === 'Packing') {
      const subPrice =
        value *
        packingSelected.unitsPerPackage *
        (packingSelected.additionalCost
          ? product.packagingPrice + product.packingCost + (shippingFee ?? 0)
          : product.packagingPrice + (shippingFee ?? 0));

      dispatch(
        buyIntentionActions.setSubTotalList({
          id: product.id,
          value: subPrice,
        }),
      );
    }
    if (selectedTypeSale === 'Layer') {
      const subPrice =
        value *
        packingSelected.packagingByLayer *
        packingSelected.unitsPerPackage *
        (packingSelected.additionalCost
          ? product.layerPrice + product.packingCost + (shippingFee ?? 0)
          : product.layerPrice + (shippingFee ?? 0));

      dispatch(
        buyIntentionActions.setSubTotalList({
          id: product.id,
          value: subPrice,
        }),
      );
    }
    if (selectedTypeSale === 'Trolley') {
      const subPrice =
        value *
        packingSelected.layersByTrolley *
        packingSelected.packagingByLayer *
        packingSelected.unitsPerPackage *
        (packingSelected.additionalCost
          ? product.trolleyPrice + product.packingCost + (shippingFee ?? 0)
          : product.trolleyPrice + (shippingFee ?? 0));

      dispatch(
        buyIntentionActions.setSubTotalList({
          id: product.id,
          value: subPrice,
        }),
      );
    }
  };

  useEffect(() => {
    calculateSubTotal(quantityPacking);
  }, [selectedTypeSale, packingSelected]);

  useEffect(() => {
    if (submitOffers) {
      if (idsSelected.indexOf(product.id) !== -1) {
        setOnlyBuy(true);
        form.submit();
      }
      dispatch(buyIntentionActions.setSubmitOffers(false));
    }
  }, [submitOffers]);

  useEffect(() => {
    if (idsSelected.length === idsAccepted.length && idsAccepted.length !== 0) {
      fastBuyProducts();
    }
    dispatch(buyIntentionActions.setSubmitOffers(false));
  }, [idsAccepted]);

  return (
    <ProductCardActions
      themeType={theme}
      pinned={showPinnedFilter}
      typeOffer={product.offerType}
    >
      {mode === 'row' && amountInfoFC}
      <Content direction={mode}>
        <MainContent>
          <PriceOptions>
            <GroupTypeSaleProduct type={actionsButtonType}>
              <ButtonPacking
                handleTypeSale={handleTypeSale}
                selectedTypeSale={selectedTypeSale}
                packingSelected={packingSelected}
                price={product.packagingPrice}
                packingCost={product.packingCost}
                packingType={PackingTypeSale.Packing}
                quantityPacking={quantityPacking}
                specialPrice={product.specialPrice}
                minimumQuantity={product.minimumQuantity}
              />
              <ButtonPacking
                handleTypeSale={handleTypeSale}
                selectedTypeSale={selectedTypeSale}
                packingSelected={packingSelected}
                price={product.layerPrice}
                packingCost={product.packingCost}
                packingType={PackingTypeSale.Layer}
                quantityPacking={quantityPacking}
                specialPrice={product.specialPrice}
                minimumQuantity={product.minimumQuantity}
              />
              <ButtonPacking
                handleTypeSale={handleTypeSale}
                selectedTypeSale={selectedTypeSale}
                packingSelected={packingSelected}
                price={product.trolleyPrice}
                packingCost={product.packingCost}
                packingType={PackingTypeSale.Trolley}
                quantityPacking={quantityPacking}
                specialPrice={product.specialPrice}
                minimumQuantity={product.minimumQuantity}
              />
            </GroupTypeSaleProduct>

            {product.shippingFeeFilials.length > 0 && (
              <ProductShippingFee product={product} />
            )}

            {mode === 'column' && amountInfoFC}
          </PriceOptions>
          <OrderActions>
            <Form form={form} onFinish={onSubmit}>
              <CardBottomContainer>
                <PackagesDiv size={formType}>
                  <FormItem
                    name="quantityPacking"
                    rules={
                      quantityPacking > 0
                        ? formValidation.quantityPacking
                        : undefined
                    }
                  >
                    <PackagesInput
                      id="quantityPacking"
                      onChange={({ target }) => {
                        const value = Number(target.value);
                        if (value >= 0) {
                          setQuantityPacking(value);
                          form.setFieldsValue({ quantityPacking: value });
                          calculateSubTotal(value);
                        }
                        if (value > 0) {
                          dispatch(
                            buyIntentionActions.setIdsProductsSelected(
                              product.id,
                            ),
                          );
                        } else {
                          dispatch(buyIntentionActions.setDelIds(product.id));
                        }
                      }}
                      autoComplete="off"
                      onKeyPress={KeyPress.onlyNumber}
                      addonAfter={textInputTypeSale}
                      type="tel"
                    />
                  </FormItem>
                </PackagesDiv>
              </CardBottomContainer>
            </Form>
          </OrderActions>
        </MainContent>
      </Content>
    </ProductCardActions>
  );
}

export default memo(ProductActions);
