import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import { OfferTypeEnum } from 'enums/offerType';
import { Order } from 'model/Order';
import { useForm } from 'antd/lib/form/Form';
import { OrdersFiltersParam } from 'model/types';
import moment from 'moment';
import { DateTypeFilterEnum } from 'enums/dateTypeFilter';
import { SaleTypeFilterEnum } from 'enums/saleTypeFilter';
import { fetchMyOrders } from 'services/ordersService';
import { AxiosResponse } from 'axios';
import Response from 'model/Response';
import { Loader, Overlay } from 'components/Wired/DefaultLoader/styles';
import FormFiltersFields from 'model/FormFiltersFields';
import { myOrdersContext } from 'contexts/MyOrdersContext';
import MyOrdersTable from '../MyOrdersTable';
import { PaginationContainer } from '../styles';
import MyOrdersFilter from '../MyOrdersFilter';

interface Props {
  customerId: number;
}

const PAGE_SIZE = 10;

export default function Tab({ customerId }: Props) {
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [dataTable, setDataTable] = useState<Order[] | undefined>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [form] = useForm();
  const { loading, setLoading } = useContext(myOrdersContext);
  const getSaleTypeParameters = useCallback(
    (saleType: string, filters: OrdersFiltersParam) => {
      if (saleType === SaleTypeFilterEnum.DIRECTED) {
        filters['Data.IsDirected'] = true;
      }
      if (saleType === SaleTypeFilterEnum.VOL) {
        filters['Data.IsVol'] = true;
      }
      if (saleType === SaleTypeFilterEnum.LKP) {
        filters['Data.IsTransit'] = true;
        filters['Data.IsReceived'] = true;
      }
      if (saleType === SaleTypeFilterEnum.ALL) {
        filters['Data.IsDirected'] = true;
        filters['Data.IsVol'] = true;
        filters['Data.IsTransit'] = true;
        filters['Data.IsReceived'] = true;
      }
    },
    [],
  );
  const handleFilters = useCallback(
    (formFiltersFields: FormFiltersFields) => {
      const filters: OrdersFiltersParam = {
        'Data.Status': formFiltersFields['Data.Status'],
        'Data.SiteTradingName': formFiltersFields['Data.SiteTradingName'],
        'Data.ProductDescription': formFiltersFields['Data.ProductDescription'],
        'Data.OrderNumber': formFiltersFields['Data.OrderNumber'],
        'Data.UserName': formFiltersFields['Data.UserName'],
      };
      getSaleTypeParameters(formFiltersFields['Data.SaleTypes'], filters);
      if (formFiltersFields['Data.TypeDate'] === DateTypeFilterEnum.BUY_DATE) {
        filters['Data.SaleDate'] =
          formFiltersFields['Data.Date'].format('YYYY-MM-DD');
      }
      if (
        formFiltersFields['Data.TypeDate'] === DateTypeFilterEnum.DELIVERY_DATE
      ) {
        filters['Data.DeliveryDate'] =
          formFiltersFields['Data.Date'].format('YYYY-MM-DD');
      }
      if (
        formFiltersFields['Data.TypeDate'] === DateTypeFilterEnum.BILLING_DATE
      ) {
        filters['Data.BillingDate'] =
          formFiltersFields['Data.Date'].format('YYYY-MM-DD');
      }
      return filters;
    },
    [getSaleTypeParameters],
  );

  const fetchOrders = useCallback(
    (formFilters: OrdersFiltersParam, pageNumber: number) => {
      setLoading(true);
      fetchMyOrders({
        'Data.CustomerId': customerId,
        pageNumber,
        pageSize: PAGE_SIZE,
        ...formFilters,
      })
        .then((response: AxiosResponse<Response<Order[]>>) => {
          setLoading(false);
          const { results, totalCount } = response.data;
          setDataTable((oldValue) => {
            if (typeof oldValue !== 'undefined') {
              return [...oldValue, ...results];
            }
            return [...results];
          });
          setTotalResults(totalCount);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [customerId, setLoading],
  );

  const submit = useCallback(
    (pageNumberParam?: number) => {
      const formFiltersFields: FormFiltersFields = form.getFieldsValue();
      const formFilters = handleFilters(formFiltersFields);
      fetchOrders(formFilters, pageNumberParam ?? 1);
    },
    [form, handleFilters, fetchOrders],
  );

  const onFormSubmit = useCallback(() => {
    setDataTable([]);
    setTotalResults(0);
    submit();
  }, [submit]);

  useEffect(() => {
    form.setFieldsValue({
      'Data.Date': moment(),
      'Data.TypeDate': DateTypeFilterEnum.BUY_DATE,
      'Data.SaleTypes': 'ALL',
      'Data.Status': '',
    });
  }, [form]);

  const isLastPage = useCallback(
    () => typeof dataTable !== 'undefined' && dataTable.length >= totalResults,
    [dataTable, totalResults],
  );

  const onLoadMoreClick = useCallback(() => {
    setPageNumber((oldValue) => oldValue + 1);
    submit(pageNumber + 1);
  }, [submit, pageNumber]);

  useEffect(() => {
    if (isLastPage()) {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
  }, [dataTable, isLastPage]);

  useEffect(() => {
    setDataTable([]);
  }, [customerId]);

  return (
    <>
      <MyOrdersFilter form={form} onSubmit={onFormSubmit} />
      <MyOrdersTable
        form={form}
        offerType={OfferTypeEnum.VOL}
        data={dataTable}
      />
      {showPagination && (
        <PaginationContainer>
          <Button
            onClick={() => onLoadMoreClick()}
            type="primary"
            icon={<DownCircleOutlined />}
          >
            Carregar mais itens
          </Button>
        </PaginationContainer>
      )}
      {loading && (
        <Overlay>
          <Loader spinning={loading} size="large" />
        </Overlay>
      )}
    </>
  );
}
