import styled from 'styled-components';

import { Col, Row } from 'antd';

export const ProductCardListComponent = styled(Row)`
  margin: 0px 0px 4px 0;
  padding: 2px 6px 2px 6px;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ProductCardComponentDetails = styled(Col)`

  .icon-information {
    color: #f78e1e;
  }
  padding: 0 8px;

  .row-details {
    height: min-content;
  }

  .value-product {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .col-details-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 60px;

    p {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #353839;

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
      }

      .producer-name {
        color: #f78e1e;
      }

      &.break-word {
        margin-bottom: 0 !important;
      }
    }
  }

  @media (max-width: 992px) {
    margin-bottom: 60px;
  }
`;

export const ProductCardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 150px;
`;

export const ProductNameType = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 5px;

  > div {
    flex: 1;
  }

  .name-product-div {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .date-lkp-offer {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: bold;
    color: rgba(0, 91, 167, 1);
    margin-left: -6.5px;
    line-height: 12px;
  }

  .name-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 16px;

    > span {
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #353839;
    }

    .code-product {
      font-weight: 400;
      margin-left: 8px;
      font-size: 10px;
      line-height: 8px;
      color: #8c8c8c;
      display: flex;
      flex-direction: row;

      .number-product {
        margin-right: 12px;
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    .name-product {
      padding: 12px 0;
    }
  }
`;

export const InformationProduct = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-top: 2px;
  margin-bottom: 10px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .group-information {
    margin-left: 10px;

    .ant-select .ant-select-selector {
      z-index: 2;
      padding: 0 10px 0 0px !important;
    }

    .title-information {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      color: #353839;
    }
    span {
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
    }
  }
`;

export const Actions = styled.div``;

export const InfoNewContainer = styled.div`
  position: absolute;
  top: -11px;
  right: -8px;
`;

export const InfoDirectedTagContainer = styled.div`
  position: absolute;
  top: -2px;
  left: -12px !important;
`;

export const InfoVoucherTagContainer = styled.div`
  position: absolute;
  top: -2px;
  left: -12px !important;
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

export const BadgeContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 16px;

  > div:first-child {
    max-width: 110px;
  }

  > div:last-child {
    width: 117px;
  }
`;

export const ProductName = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: rgb(53, 56, 57);
  white-space: normal;
  padding-top: 5px;
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProductNameNormal = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: rgb(53, 56, 57);
  white-space: normal;
  padding-top: 5px;
`;
