import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as S from './styles';

type ModalConfirmationProps = {
  modalIsVisible: boolean;
  setModalIsVisible: (value: boolean) => void;
};

export default function ModalInformationExpiredItems({
  modalIsVisible,
  setModalIsVisible,
}: ModalConfirmationProps) {
  return (
    <S.ModalConfirmation
      closable={false}
      centered
      visible={modalIsVisible}
      width="400px"
      footer={
        <S.ButtonsActions>
          <S.ButtonOk onClick={() => setModalIsVisible(false)}>Ok</S.ButtonOk>
        </S.ButtonsActions>
      }
    >
      <S.ModalConfirmationHeader>
        <ExclamationCircleOutlined />
        <span className="title">
          Não foi possível finalizar a compra
        </span>
      </S.ModalConfirmationHeader>
      <S.ModalConfirmationBody>
        <p>
          Você possui um ou mais itens no carrinho que estão expirados, para finalizar a compra é necessário removê-los.
        </p>
      </S.ModalConfirmationBody>
    </S.ModalConfirmation>
  );
}
