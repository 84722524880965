import { Fragment } from 'react';
import * as S from './styles';

interface Breadcrumb {
  description: string;
}

type BreadcrumbProps = {
  levels: Breadcrumb[];
};

export default function Breadcrumb({ levels }: BreadcrumbProps) {
  return (
    <S.Breadcrumb>
      <S.BreadcrumbIcon />
      {levels.map(({ description }) => (
        <Fragment key={description}>
          <S.BreadcrumbText>/</S.BreadcrumbText>
          <S.BreadcrumbText>{description}</S.BreadcrumbText>
        </Fragment>
      ))}
    </S.Breadcrumb>
  );
}
