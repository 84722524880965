import { getLoading } from 'store/state/loader/loaderSelectors';
import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './styles';

export default function DefaultLoader() {
  const isLoading = useSelector(getLoading);

  return isLoading ? (
    <S.Overlay>
      <S.Loader spinning={isLoading} size="large" />
    </S.Overlay>
  ) : null;
}
