import { createSelector } from '@reduxjs/toolkit';
import { OfferFiltersState } from './offerFiltersSlice';

export const getOfferFilters = (state: { offerFilters: OfferFiltersState }) =>
  state.offerFilters as OfferFiltersState;

export const getClearProductList = createSelector(
  [getOfferFilters],
  (state) => state.clearProductList
);

export const getFilterByHeader = createSelector(
	[getOfferFilters],
	(state) => state.filterByHeader
);

export const getClearFilter = createSelector(
	[getOfferFilters],
	(state) => state.clearFilter
);

export const getShowPinnedFilter = createSelector(
	[getOfferFilters],
	(state) => state.showPinnedFilter
);

export const getTypeMenu = createSelector(
	[getOfferFilters],
	(state) => state.typeMenu
);

export const getCustomer = createSelector(
	[getOfferFilters],
	(state) => state.customer
);