import styled from 'styled-components';

import { Col, Space, Row, Image } from 'antd';

export const ProductCardCart = styled(Row).attrs({
  align: 'middle',
})`
  width: 100%;
  padding: 24px;
  border-radius: 4px;

  &:not(.expired) {
    background-color: #fff;
  }
  &.expired {
    background-color: transparent ;
  }

  &.borde-box-shadow:not(.expired) {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 5%), 0px 0px 32px rgb(0 0 0 / 8%), 0px 0px 3px 0px rgb(0 0 0 / 12%);
  }
  
  :hover {
    &.borde-box-shadow:not(.expired) {
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    }
  }
`;

export const ProductPhoto = styled(Image)``;

export const Frame812 = styled(Col).attrs({ md: 24, xs: 24 })`
  margin: 0;
`;

export const Frame812Spacing = styled(Space).attrs({
  direction: 'vertical',
  size: 10.0,
})`
  width: 100%;
`;

export const ProductCard = styled(Row).attrs({
  gutter: [24.0, 0],
  align: 'middle',
})`
  max-width: 100%;
`;


export const ProductInfo = styled(Row).attrs({
  gutter: [24.0, 0],
  align: 'middle',
  // justify: 'space-between',
})``;

export const PackingPrice = styled(Col).attrs({ xs: 12, sm: 12, md: 12, lg: 24, xl: 12 })`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.left {
    justify-content: flex-start;
  }
  padding: 0 !important;
  margin: 0 !important;

  p {
    margin-bottom: 0 !important;
  }
`;

export const PackingPriceLabel = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    font-size: 11px !important;
  }
`;

export const PackingPriceInfoLabel = styled.span`
  font-family: Roboto;
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  &:not(.expired) {  
    color: #f88f1f;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: center;
  margin-left: 8px;
`;

export const PackingPriceInfoValue = styled.span`
  font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  &:not(.expired) {  
    color: #f88f1f;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: center;
  @media (max-width: 500px) {
    font-size: 14px !important;
  }
`;

export const ProductPhotoCol = styled(Col).attrs({ xs: 24, sm: 24, md: 2, lg: 2, xl: 2 })`
    display: flex;
    justify-content: center;

    img {
      max-height: 150px !important;
    }

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
`;


export const ProductCardCol = styled(Col)``;

export const InfoLeft = styled(Col).attrs({ xs: 24, md: 24, lg: 8, xl: 8 })``;
export const InfoCenter = styled(Col).attrs({ xs: 24, md: 24, lg: 9, xl: 9 })`
  padding: 0 !important;

  @media(max-width: 992px) {
    margin: 16px 0; 
  }
`;
export const Frame811 = styled(Col).attrs({ xs: 24, md: 24, lg: 7, xl: 7 })`
  padding: 0 !important;
`;



export const InfoLeftSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 8.0,
})`
  width: 100%;
`;
export const ProductCardHeader = styled(Row).attrs({
  gutter: [12.0, 0],
  align: 'middle',
  // justify: 'space-between',
})``;

export const MainInfo = styled(Col).attrs({ md: 24, xs: 24 })``;

export const MainInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 4.0,
})`
  width: 100%;
`;
export const ProductName = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin: 0;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF !important;
  }
  text-align: start;
  
  @media(max-width: 500px) {
    font-size: 14px !important;
  }
`;

export const ProductCodes = styled.div`
  display: flex;
  align-items: center;
`

export const ProductCode = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  &:not(.expired) {  
    color: #8d8d8d;
  }
  &.expired {  
    color: #BFBFBF;
  }
`;

export const BarCode = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-left: 16px;
  &:not(.expired) {  
    color: #8d8d8d;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: center;
`;

export const ProductOfferType = styled(Row)``;

export const ProductOfferTypeValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  text-align: center;
`;

export const ProductDetail = styled(Row).attrs({
  gutter: [20, 0],
  align: 'top'
})`
  > div {
    margin: 0 !important;
    margin-right: 16px !important;
  }
  @media (max-width: 900px) {
    margin-right: 32px !important;
  }
`;

export const ProductQuality = styled(Row).attrs({
  gutter: [8.0, 0],
  justify: 'space-between',
  align: 'middle',
})`
  flex-wrap: nowrap;
  align-items: center;

  h1 { 
    margin-bottom: 0px !important;
  }
`;

export const QualityIcon = styled(Row)`
  border-radius: 4px;

  &:not(.expired) > span > svg {
    font-size: 16px;
    color: #f88f1f;
  }
  &.expired > span > svg {
    font-size: 16px;
    color: #BFBFBF;
  }
`;

export const QualityInfo = styled(Col).attrs({ md: 18, xs: 24 })``;

export const QualityInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const QualityInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;
`;

export const QualityInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const ProductColor = styled(Row).attrs({
  gutter: [8.0, 0],
  justify: 'space-between',
  align: 'middle',
})`
  flex-wrap: nowrap;
  align-items: center;
  h1 { 
    margin-bottom: 0px !important;
  }
`;

export const ColorIcon = styled(Row)`
  border-radius: 4px;
  &:not(.expired) > span > svg {
      font-size: 16px;
      color: #f88f1f;
    }
    &.expired > span > svg {
      font-size: 16px;
      color: #BFBFBF;
    }
`;

export const ColorInfo = styled(Col).attrs({ md: 16, xs: 24 })``;

export const ColorInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const ColorInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;
`;

export const ColorInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const ProductPackage = styled(Row).attrs({
  gutter: [8.0, 0],
  justify: 'space-between',
  align: 'middle',
})`
  flex-wrap: nowrap;
  align-items: center;
  h1 { 
    margin-bottom: 0px !important;
  }
`;

export const PackageIcon = styled(Col).attrs({ md: 4 })`
  border-radius: 4px;
  &:not(.expired) > span > svg {
      font-size: 16px;
      color: #f88f1f;
    }
    &.expired > span > svg {
      font-size: 16px;
      color: #BFBFBF;
    }
`;

export const PackageInfo = styled(Col).attrs({ md: 20, xs: 24 })``;

export const PackageInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const PackageInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;
`;

export const PackageInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const ProductSize = styled(Row).attrs({
  gutter: [8.0, 0],
  align: 'middle',
})`
  flex-wrap: nowrap;
  align-items: center;
  h1 { 
    margin-bottom: 0px !important;
  }
`;

export const SizeIcon = styled(Row)`
  border-radius: 4px;
  &:not(.expired) > span > svg {
      font-size: 16px;
      color: #f88f1f;
    }
    &.expired > span > svg {
      font-size: 16px;
      color: #BFBFBF;
    }
`;

export const SizeInfo = styled(Col).attrs({ md: 16, xs: 24 })``;

export const SizeInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 2.0,
})`
  width: 100%;
`;
export const SizeInfoLabel = styled.h1`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;
`;

export const SizeInfoValue = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const InfoCenterSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 8.0,
})`
  width: 100%;
  padding: 0 !important;
`;
export const ProducedBy = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 !important;
  margin: 0 !important;

  p {
    margin-bottom: 0 !important;
  }
`;

export const ProducedByLabel = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: center;
`;

export const GroupLineItemCart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const ButtonText = styled.span`
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f88f1f;
  text-align: left;

  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const ProductNote = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 !important;
  margin: 0 !important;

  p {
    margin-bottom: 0 !important;
  }
`;

export const ProductDeliveryLabel = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;
`;

export const ProductDeliveryValue = styled.span`
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const ProductDelivery = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 !important;
  margin: 0 !important;

  p {
    margin-bottom: 0 !important;
  }
`;

export const Frame811Spacing = styled(Space).attrs({
  direction: 'vertical',
  size: 10.0,
})`
  width: 100%;
`;
export const InfoRight = styled(Row).attrs({
  gutter: [8.0, 0],
  justify: 'space-between',
})``;

export const QuantityInfo = styled(Col).attrs({ md: 11, xs: 24 })``;

export const QuantityInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 8.0,
})`
  width: 100%;
`;

export const UnitInfo = styled(Col).attrs({ xs: 8, sm: 8, md: 8, lg: 24, xl: 8 })`
  p {
    margin-bottom: 0 !important;
  }
`;

export const UnitInfoValue = styled.p`
  font-family: Roboto;
  font-weight: bold;
  font-size: 13px;
  line-height: 22px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const UnitInfoLabel = styled.span`
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;
  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const TotalInfo = styled(Col).attrs({ xs: 12, sm: 12, md: 12, lg: 24, xl: 12 })`
  p {
    margin-bottom: 0 !important;
  }

  @media (max-width: 1200px) {
    justify-content: flex-end;
  }
`;

export const TotalInfoLabel = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;
  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const TotalInfoValue = styled.span`
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;
  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const TotalInfoUnit = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #36393a;
  text-align: left;
  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const PriceInfo = styled(Col).attrs({ md: 24, xs: 24 })`
  .ant-row {
    margin-bottom: 16px;
  }
`;

export const PriceInfoSpacing = styled(Space).attrs({
  direction: 'vertical',
  size: 8.0,
})`
  width: 100%;
`;

export const UnitPrice = styled(Col).attrs({ xs: 16, sm: 16, md: 16, lg: 24, xl: 16})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 !important;
  margin: 0 !important;

  p {
    margin-bottom: 0 !important;
  }
`;

export const UnitPriceLabel = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  white-space: nowrap;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  display: flex;
  align-items: center;
  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const UnitPriceInfoLabel = styled.span`
  font-family: Roboto;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  white-space: nowrap;
  &:not(.expired) {  
    color: #f88f1f;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: center;
  margin-left: 8px;
`;

export const UnitPriceInfoValue = styled.span`
  font-family: Roboto;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  &:not(.expired) {  
    color: #f88f1f;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: center;
  @media(max-width: 500px) {
    font-size: 14px !important;
  }
`;

export const TotalPrice = styled(Col).attrs({ xs: 12, sm: 12, md: 12, lg: 24, xl: 12 })`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 !important;
  margin: 0 !important;

  p {
    margin-bottom: 0 !important;
  }
`;

export const TotalPriceLabel = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  &:not(.expired) {  
    color: #36393a;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: left;

  display: flex;
  align-items: center;
  @media(max-width: 500px) {
    font-size: 12px !important;
  }
`;

export const TotalPriceInfoLabel = styled.span`
  font-family: Roboto;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  &:not(.expired) {  
    color: #f88f1f;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: center;
  margin-left: 8px;
`;

export const DeleteButtonComponent = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  
  @media (max-width: 576px) {
    margin-top: 0;
    margin-left: 32px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const GroupRemoveAndExpireTime = styled(Col).attrs({ xs: 24, sm: 2, md: 2, lg: 2, xl: 2 })`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-right: 16px;
  
  @media (max-width: 576px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 0;
  }
`;


export const ExpireTimeComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LabelExpireTime = styled.span`
    &:not(.expired) {  
    color: #f78e1e;
  }
  &.expired {  
    color: #BFBFBF;
  }
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
`;

export const TimerExpireTime = styled.span`
    &:not(.expired) {  
    color: #f78e1e;
  }
  &.expired {  
    color: #BFBFBF;
  }
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
`;

export const TotalPriceInfoValue = styled.span`
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  &:not(.expired) {  
    color: #f88f1f;
  }
  &.expired {  
    color: #BFBFBF;
  }
  text-align: center;

  @media(max-width: 500px) {
    font-size: 14px !important;
  }
`;
