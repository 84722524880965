import { Collapse } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;

  .ant-divider {
    margin: 12px 0 !important;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Body = styled.div`
  padding: 8px 0 16px 0;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    width: 100%;
  }
`;

export const CollapseComponent = styled(Collapse)`
  width: 100%;
  margin-top: 16px;

  div.ant-collapse-header {
    padding: 12px 16px 24px 16px !important;
  }

  div.ant-collapse-content-box {
    padding: 0 !important;
  }
`;