import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import * as S from './styles';

type ModalConfirmationProps = {
  modalIsVisible?: boolean;
  widthModal?: number;
  actionButtonCancel?: () => void;
  actionButtonOk?: () => void;
  textTitle?: string;
  textDescription?: string;
  textButtonOk?: string;
  textButtonCancel?: string;
};

export default function ModalConfirmation({
  modalIsVisible,
  widthModal,
  textTitle,
  textDescription,
  textButtonOk,
  textButtonCancel,
  actionButtonOk,
  actionButtonCancel,
}: ModalConfirmationProps) {
  return (
    <S.ModalConfirmation
      closable={false}
      visible={modalIsVisible}
      width={widthModal ?? '350px'}
      footer={
        <S.ButtonsActions>
          {actionButtonCancel && (
            <S.ButtonCancel onClick={actionButtonCancel}>
              {textButtonCancel ?? 'Cancelar'}
            </S.ButtonCancel>
          )}
          <S.ButtonOk onClick={actionButtonOk}>
            {textButtonOk ?? 'Continuar'}
          </S.ButtonOk>
        </S.ButtonsActions>
      }
    >
      <S.ModalConfirmationHeader>
        <ExclamationCircleOutlined />
        <span className="title">{textTitle}</span>
      </S.ModalConfirmationHeader>
      <S.ModalConfirmationBody>
        <p>{textDescription}</p>
      </S.ModalConfirmationBody>
    </S.ModalConfirmation>
  );
}
