import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HubConnectionBuilder } from '@microsoft/signalr';

import { cartSelectors, sessionSelectors } from 'store/state/selectors';
import { getAuthorizationToken } from 'services/config';
import { showAlertError, showAlertInfo, showAlertSuccess, showAlertWarning } from 'services/alertService';
import { useGetSummaryCartQuery } from 'services/ecommerceApi';
import { cartActions, sessionActions } from 'store/state/slices';
import { dateUtils } from 'utils/date';

export default function Signalr() {
  const dispatch = useDispatch();
  const [connection, setConnection] = useState<any>(null);
  const [retryConnect, setRetryConnect] = useState<boolean | null>(null);
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const selectedCustomerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const totalItems = useSelector(cartSelectors.getTotalItems);
  const totalVol = useSelector(cartSelectors.getVolTotal);


  const { data: summaryCart, isError: errorRequestSummary, refetch: refetchSummaryCart } = useGetSummaryCartQuery(
    {
      customerId: selectedCustomerId,
    },
    { skip: !isAuthenticated || !selectedCustomerId },
  );

  useEffect(() => {
    if (summaryCart && summaryCart.totalItems > 0 && !errorRequestSummary) {
      dispatch(cartActions.setSummaryCart(summaryCart));
    }
    if (summaryCart && totalVol > 0) {
      dispatch(
        sessionActions.setBillingDate(
          dateUtils.createDateWithTimeZone(summaryCart.billingDate),
        ),
      );
      dispatch(
        sessionActions.setDeliveryDate(
          dateUtils.createDateWithTimeZone(summaryCart.deliveryDate),
        ),
      );
    }
    if (!summaryCart) {
      cleanSummaryCart();
    }
  }, [dispatch, summaryCart, totalItems, totalVol, errorRequestSummary]);

  useEffect(() => {
    if (errorRequestSummary) {
      cleanSummaryCart();
    }
  }, [dispatch, errorRequestSummary]);

  const cleanSummaryCart = () => {
    dispatch(
      cartActions.setSummaryCart({
        lkpOfferId: 0,
        volOfferId: 0,
        customerId: Number.NaN,
        volTotal: 0,
        lkpTotal: 0,
        totalItems: 0,
        user: '',
        deliveryDate: '',
        billingDate: '',
        volExpiration: '',
        lkpExpiration: '',
        totalLkpItems: 0,
        totalVolItems: 0,
        lKpCreationDate: '',
        volCreationDate: ''
      }),
    );
  };

  useEffect(() => {
    if (isAuthenticated && selectedCustomerId && (retryConnect === null || retryConnect === true)) {
      const token = getAuthorizationToken();
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_ECOMMERCE_URL}hubs/user-notifications` ?? '', { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }
  }, [isAuthenticated, selectedCustomerId, retryConnect]);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then((result: any) => {
          console.log('Connected!');
          connection.on('receive-message', (message: any) => {
            console.log('Message', message);
            console.log('selectedCustomerId', selectedCustomerId);
            if (message.customerId === selectedCustomerId) {
              switch (message.type) {
                case 'E': {
                  showAlertError(dispatch, `${message.message}`, true, message.timeout);
                  dispatch(cartActions.setRefetchRequestCartItems(true));
                  refetchSummaryCart();
                  break;
                }
                case 'I':
                  showAlertInfo(dispatch, `${message.message}`, true, message.timeout);
                  break;
                case 'W':
                  showAlertWarning(dispatch, `${message.message}`, true, message.timeout);
                  break;
                case 'S': {
                  showAlertSuccess(dispatch, `${message.message}`, true, message.timeout);
                  dispatch(cartActions.setRefetchRequestCartItems(true));
                  refetchSummaryCart();
                  break;
                }
                default:
                  showAlertInfo(dispatch, `${message.message}`, true, message.timeout);
                  break;
              }
            }
          });
        })
        .catch((e: any) => {
          setRetryConnect(true);
        });
    }
  }, [connection, selectedCustomerId]);

  return (<>
  </>);
}