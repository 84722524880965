import { Form } from 'antd';
import styled, { css } from 'styled-components';

interface InformationContainerProps {
  inline?: number;
}

interface ContainerComplementaryProps {
  show: number;
}

interface ContainerFixedProps {
  isShowingComplement: number;
}

interface ImageContainerProps {
  isShowingComplement: number;
}

interface InformationIconContainerProps {
  isLkpOffer?: number;
}

interface ColContainerProps {
  alignItems?: string;
}

export const Container = styled.div`
  margin-bottom: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  width: 100%;
`;

export const ContainerFixed = styled.div.attrs({
  role: 'button',
})<ContainerFixedProps>`
  position: relative;
  display: flex;

  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${(props) =>
    !props.isShowingComplement &&
    css`
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    `}
`;

export const ContainerComplementary = styled.div<ContainerComplementaryProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};

  background-color: #fff;
  border-top: 1px solid #d9d9d9;

  padding: 8px;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  background-color: #f78e1e;
  width: 24px;
  padding: 5px;
  border-top-left-radius: 8px;

  ${(props) =>
    !props.isShowingComplement &&
    css`
      border-bottom-left-radius: 8px;
    `}
`;

export const ButtonImage = styled.button.attrs({ type: 'button' })`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  svg {
    color: #fff;
  }
`;

export const ProductInfoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  cursor: pointer;

  position: relative;
  padding: 6px 10px;
`;

export const ColContainer = styled.div<ColContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
`;

export const InfoRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const PrimaryInfo = styled.div`
  display: flex;
`;

export const InformationIconContainer = styled.div<InformationIconContainerProps>`
  margin-right: ${(props) => (props.isLkpOffer ? 6 : 12)}px;

  svg {
    color: #f78e1e;
    font-size: 14px;
  }

  p {
    margin-bottom: 0;
    font-size: 10px;
    font-weight: bold;
    color: rgba(0, 91, 167, 1);
    margin-left: -6.5px;
    line-height: 12px;
  }
`;

export const InformationContainer = styled.div``;

export const SecondaryInfo = styled.div``;

export const Information = styled.div<InformationContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
`;

export const InformationLabel = styled.p`
  font-size: 8px;
  color: #353839;
  margin-bottom: 2px;
`;

export const InformationValue = styled.span`
  display: flex;

  font-weight: bold;
  color: #353839;
`;

export const ProducerLabel = styled.p`
  font-size: 10px;
  color: #353839;
`;

export const ProducerValue = styled.div`
  font-size: 10px;
  margin-left: 4px;
  color: #f78e1e;
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const UnitValueContainer = styled.div``;

export const HideContainer = styled.div``;

export const InfoComplementary = styled.div``;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -8px -8px;
  border-top-width: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const InfoValueContainerGroup = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  + div {
    margin-top: 8px;
  }
`;

export const InfoValueContainer = styled.div`
  display: flex;
`;

export const InfoValue = styled.div`
  color: #353839;
`;

export const Label = styled.p`
  font-size: 8px;
  margin-bottom: 2px;
  color: inherit;
  line-height: 8px;
  margin-block: 0;
`;

export const Value = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: inherit;
  line-height: 12px;
`;

export const ContainerFloat = styled.div`
  position: absolute;
  bottom: -2px;
  right: 2px;

  display: flex;
  gap: 2px;
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 4px;
`;
