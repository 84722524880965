import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as BuyIntentionModels from 'model/BuyIntention';
import { Key } from 'react';
import { OrderIntention } from 'model/OrderIntention';
import * as BuyIntentionCalls from './apiCalls';


export type myOrderIntentionEditState = {
  showIntentionModal: boolean;
  pagination: BuyIntentionModels.DefaultPagination;
  currentStep: number;
  reloadPage: boolean;
  customer: BuyIntentionModels.Customer | undefined;
  productList: any;
  producerList: any;
  buyIntentionEdit: any | undefined;
  overviewEdit: any | undefined;
  offersList: any | undefined;
  subTotalPriceList: BuyIntentionModels.SubTotalPrice[];
  submitOffers: boolean;
  idsProductsSelected: string[];
  idsProductsAccepted: string[];
  deliveryDateForOffers: string | undefined;
  comercialDateForOffers: string | undefined;
  deliveryDateForFinish: string | undefined;
  comercialDateForFinish: string | undefined;
  idSelectedNow: string | undefined;
  isLoading: boolean;
  showModalOffers: boolean;
  idProductAdded: string | undefined;
  totalPriceOffers: BuyIntentionModels.SubTotalPrice[];
  totalPriceOffersOverview: BuyIntentionModels.SubTotalPrice[];
  producers: BuyIntentionModels.Producers[] | undefined;
  reloadOffers: boolean;
  formInfo: BuyIntentionModels.FormInfo | undefined;
  refreshForm: boolean;
  clearCheckBox: boolean;
  showmodalCacel: boolean;
  idCancel: number[] | Key[];
  showmodalRepublish: boolean;
  idRepublish: number[] | Key[];
  showmodalAprove: boolean;
  idsBulkAprove: number[] | Key[];
  showmodalReprove: boolean;
  idsBulkReprove: number[] | Key[];
  idsJustification: number[] | Key[];
  itemsJustification: OrderIntention[];
  showmodalNoRepublish: boolean;
  showmodalNoJustification: boolean;
  justificationText: string;
  filterExport: any;
};

export const initialState: myOrderIntentionEditState = {
  showIntentionModal: false,
  pagination: { PageSize: 100 } as BuyIntentionModels.DefaultPagination,
  currentStep: 1,
  reloadPage: false,
  customer: undefined,
  productList: undefined,
  producerList: undefined,
  buyIntentionEdit: [],
  overviewEdit: [],
  offersList: [],
  subTotalPriceList: [],
  submitOffers: false,
  idsProductsSelected: [],
  idsProductsAccepted: [],
  deliveryDateForOffers: undefined,
  comercialDateForOffers: undefined,
  deliveryDateForFinish: undefined,
  comercialDateForFinish: undefined,
  idSelectedNow: undefined,
  isLoading: false,
  showModalOffers: false,
  idProductAdded: undefined,
  totalPriceOffers: [],
  totalPriceOffersOverview: [],
  producers: undefined,
  reloadOffers: false,
  formInfo: undefined,
  refreshForm: false,
  clearCheckBox: false,
  showmodalCacel: false,
  idCancel: [],
  showmodalRepublish: false,
  idRepublish: [],
  showmodalAprove: false,
  idsBulkAprove: [],
  showmodalReprove: false,
  idsBulkReprove: [],
  idsJustification: [],
  itemsJustification: [],
  showmodalNoRepublish: false,
  showmodalNoJustification: false,
  justificationText: '',
  filterExport: undefined
};

const buyIntentionSlice = createSlice({
  name: 'myOrderIntentionEdit',
  initialState,
  reducers: {
    setIdsJustification(state, action: PayloadAction<number[] | Key[]>) {
      state.idsJustification = action.payload;
    },
    setItemsJustification(state, action: PayloadAction<OrderIntention[]>) {
      state.itemsJustification = action.payload;
    },
    setShowIntentionModal(state, action: PayloadAction<boolean>) {
      state.showIntentionModal = action.payload;
    },
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setTotalPriceOverview(
      state,
      action: PayloadAction<BuyIntentionModels.SubTotalPrice>,
    ) {
      const { payload } = action;
      const subTotalFind = state.totalPriceOffersOverview?.filter(
        (product) => product.id === payload.id,
      ).length;
      if (subTotalFind > 0) {
        const updatedSubTotalList: any | undefined =
          state.totalPriceOffersOverview?.map((product: any) => {
            if (product.id === payload.id) {
              return payload;
            }
            return product;
          });
        state.totalPriceOffersOverview = updatedSubTotalList;
      } else {
        const addSubTotalList = [...state.totalPriceOffersOverview];
        addSubTotalList.push(payload);
        state.totalPriceOffersOverview = addSubTotalList;
      }
    },
    setFilterExport: (state, action: PayloadAction<any>) => {
      state.filterExport = action.payload;
    },
    setTotalPriceOffers(
      state,
      action: PayloadAction<BuyIntentionModels.SubTotalPrice>,
    ) {
      const { payload } = action;
      const subTotalFind = state.totalPriceOffers?.filter(
        (product) => product.id === payload.id,
      ).length;
      if (subTotalFind > 0) {
        const updatedSubTotalList: any | undefined =
          state.totalPriceOffers?.map((product: any) => {
            if (product.id === payload.id) {
              return payload;
            }
            return product;
          });
        state.totalPriceOffers = updatedSubTotalList;
      } else {
        const addSubTotalList = [...state.totalPriceOffers];
        addSubTotalList.push(payload);
        state.totalPriceOffers = addSubTotalList;
      }
    },
    setDeleteTotalPriceOffers(
      state,
      action: PayloadAction<BuyIntentionModels.SubTotalPrice>,
    ) {
      const { payload } = action;
      const subTotalFind = state.totalPriceOffers?.filter(
        (product) => product.id !== payload.id,
      );
      state.totalPriceOffers = subTotalFind;
    },
    setSClearTotalPriceOffers: (state) => {
      state.totalPriceOffers = [];
    },
    setReloadPage: (state, action: PayloadAction<boolean>) => {
      state.reloadPage = action.payload;
    },
    setCustomer: (
      state,
      action: PayloadAction<BuyIntentionModels.Customer | undefined>,
    ) => {
      state.customer = action.payload;
    },
    setSubmitOffers: (state, action: PayloadAction<boolean>) => {
      state.submitOffers = action.payload;
    },
    setIdsProductsSelected: (state, action: PayloadAction<string>) => {
      const newIds = [...state.idsProductsSelected];
      if (newIds.indexOf(action.payload) === -1) {
        newIds.push(action.payload);
      }
      state.idsProductsSelected = newIds;
    },
    setIdsProductsAccepted: (state, action: PayloadAction<string>) => {
      const newIdsAccepted = [...state.idsProductsAccepted];
      if (newIdsAccepted.indexOf(action.payload) === -1) {
        newIdsAccepted.push(action.payload);
      }
      state.idsProductsAccepted = newIdsAccepted;
    },
    setDelIds: (state, action: PayloadAction<string>) => {
      const newIds = state.idsProductsSelected.filter(
        (product) => product !== action.payload,
      );
      const newIdsAccepted = state.idsProductsAccepted.filter(
        (product) => product !== action.payload,
      );
      state.idsProductsSelected = newIds;
      state.idsProductsAccepted = newIdsAccepted;
    },
    setClearIdsProduct: (state) => {
      state.idsProductsSelected = [];
      state.idsProductsAccepted = [];
      state.offersList = [];
    },
    setPropertyValue: (
      state,
      action: PayloadAction<BuyIntentionModels.SetPropertyAction>,
    ) => {
      const { payload } = action;
      const updatedTableData: any | undefined = state.buyIntentionEdit?.map(
        (order: any) => {
          if (order.id === payload.id) {
            const updatedData = order;
            updatedData[payload.property] = payload.value;
            return updatedData;
          }
          return order;
        },
      );
      state.buyIntentionEdit = updatedTableData;
    },
    setPagination: (
      state,
      action: PayloadAction<BuyIntentionModels.DefaultPagination>,
    ) => {
      state.pagination = action.payload;
    },
    setOverview: (
      state,
      action: PayloadAction<BuyIntentionModels.SetPropertyAction>,
    ) => {
      const { payload } = action;
      const updatedTableData: any | undefined = state.overviewEdit?.map(
        (order: any) => {
          if (order.id === payload.id) {
            const updatedData = order;
            updatedData[payload.property] = payload.value;
            return updatedData;
          }
          return order;
        },
      );
      state.overviewEdit = updatedTableData;
    },
    setDeleteOffer: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action;
      const newTableData: any[] | undefined = state.overviewEdit?.filter(
        (offer: any) => offer.id !== payload,
      );
      const newPrices = state.totalPriceOffersOverview?.filter(
        (price: any) => price.id !== payload,
      );

      const updatedTableData: any | undefined = state.buyIntentionEdit?.map(
        (order: any) => {
          if (order.id === payload) {
            const updatedData = order;
            updatedData.intention = false;
            return updatedData;
          }
          return order;
        },
      );
      state.totalPriceOffersOverview = newPrices;
      state.buyIntentionEdit = updatedTableData;
      state.overviewEdit = newTableData;
    },
    setOverviewEdit: (state) => {
      const addedProducts = state.buyIntentionEdit?.filter(
        (product: any) => product.intention === true,
      );

      const addedIds: any = [];
      addedProducts.forEach((elemetn: any) => {
        addedIds.push(elemetn.id);
      });

      const addedPrices = state.totalPriceOffers?.filter(
        (product: BuyIntentionModels.SubTotalPrice) =>
          addedIds.includes(product.id),
      );

      state.totalPriceOffersOverview = addedPrices;
      state.overviewEdit = addedProducts;
    },
    setSubTotalList: (
      state,
      action: PayloadAction<BuyIntentionModels.SubTotalPrice>,
    ) => {
      const { payload } = action;
      const subTotalFind = state.subTotalPriceList?.filter(
        (product) => product.id === payload.id,
      ).length;
      if (subTotalFind > 0) {
        const updatedSubTotalList: any | undefined =
          state.subTotalPriceList?.map((product: any) => {
            if (product.id === payload.id) {
              return payload;
            }
            return product;
          });
        state.subTotalPriceList = updatedSubTotalList;
      } else {
        const addSubTotalList = [...state.subTotalPriceList];
        addSubTotalList.push(payload);
        state.subTotalPriceList = addSubTotalList;
      }
    },
    setSClearSubTotal: (state) => {
      state.subTotalPriceList = [];
    },
    setDatesForOffers: (
      state,
      action: PayloadAction<BuyIntentionModels.DatesForOffers>,
    ) => {
      state.deliveryDateForOffers = action.payload.delivery;
      state.comercialDateForOffers = action.payload.comercial;
    },
    setIdSelectedNow: (state, action: PayloadAction<string>) => {
      state.idSelectedNow = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowModalOffers: (state, action: PayloadAction<boolean>) => {
      state.showModalOffers = action.payload;
    },
    setResetValues: (state) => {
      state.buyIntentionEdit = [];
      state.overviewEdit = [];
      state.comercialDateForFinish = undefined;
      state.deliveryDateForFinish = undefined;
      state.idSelectedNow = undefined;
      state.totalPriceOffers = [];
    },
    setIdProductAdded: (state, action: PayloadAction<string | undefined>) => {
      state.idProductAdded = action.payload;
    },
    setReloadOffers: (state, action: PayloadAction<boolean>) => {
      state.reloadOffers = action.payload;
    },
    setDeliveryDateFinish: (state, action: PayloadAction<string>) => {
      state.deliveryDateForFinish = action.payload;
    },
    setComercialDateFinish: (state, action: PayloadAction<string>) => {
      state.comercialDateForFinish = action.payload;
    },
    setFormInfo: (
      state,
      action: PayloadAction<BuyIntentionModels.FormInfo | undefined>,
    ) => {
      state.formInfo = action.payload;
    },
    setRefreshForm: (state, action: PayloadAction<boolean>) => {
      state.refreshForm = action.payload;
    },
    setClearCheckbox: (state, action: PayloadAction<boolean>) => {
      state.clearCheckBox = action.payload;
    },
    setShowModalCancel: (state, action: PayloadAction<boolean>) => {
      state.showmodalCacel = action.payload;
    },
    setIdCancel: (state, action: PayloadAction<number[] | Key[]>) => {
      state.idCancel = action.payload;
    },
    setShowModalAprove: (state, action: PayloadAction<boolean>) => {
      state.showmodalAprove = action.payload;
    },
    setIdsBulkAprove: (state, action: PayloadAction<number[] | Key[]>) => {
      state.idsBulkAprove = action.payload;
    },
    setShowModalReprove: (state, action: PayloadAction<boolean>) => {
      state.showmodalReprove = action.payload;
    },
    setIdsBulkReprove: (state, action: PayloadAction<number[] | Key[]>) => {
      state.idsBulkReprove = action.payload;
    },
    setShowModalRepublish: (state, action: PayloadAction<boolean>) => {
      state.showmodalRepublish = action.payload;
    },
    setShowModalNoRepublish: (state, action: PayloadAction<boolean>) => {
      state.showmodalNoRepublish = action.payload;
    },
    setShowModalNoJustification: (state, action: PayloadAction<boolean>) => {
      state.showmodalNoJustification = action.payload;
    },
    setJustificationText: (state, action: PayloadAction<string>) => {
      state.justificationText = action.payload;
    },
    setIdRepublish: (state, action: PayloadAction<number[] | Key[]>) => {
      state.idRepublish = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      BuyIntentionCalls.fetchProductsList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.productList = action.payload;
      },
    );
    builder.addCase(
      BuyIntentionCalls.fetchOffersByProducts.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.overviewEdit = action.payload;
        state.showIntentionModal = true;
      },
    );
    builder.addCase(BuyIntentionCalls.putBuyIntention.fulfilled, (state) => {
      state.showIntentionModal = false;
      state.refreshForm = true;
      state.clearCheckBox = true;
    });
    builder.addCase(BuyIntentionCalls.cancelBuyIntention.fulfilled, (state) => {
      state.refreshForm = true;
      state.clearCheckBox = true;
    });
    builder.addCase(BuyIntentionCalls.republishBuyIntention.fulfilled, (state) => {
      state.refreshForm = true;
      state.clearCheckBox = true;
    });
    builder.addCase(BuyIntentionCalls.aproveOrReproveDirectSale.fulfilled, (state) => {
      state.refreshForm = true;
      state.clearCheckBox = true;
    });
    builder.addCase(
      BuyIntentionCalls.fetchPacking.fulfilled,
      (state, action: PayloadAction<any>) => {
        const updatedTableData: any | undefined = state.buyIntentionEdit?.map(
          (order: any) => {
            if (order.id === state.idSelectedNow) {
              const updatedData = order;
              updatedData.productPreference = action.payload;
              const packings = action.payload.filter(
                (packing: BuyIntentionModels.PackingFull) =>
                  packing.type === 'RETORNAVEL',
              );
              updatedData.packingSelected =
                packings.length > 0 ? packings[0] : action.payload[0];
              return updatedData;
            }
            return order;
          },
        );
        state.buyIntentionEdit = updatedTableData;
        state.isLoading = false;
      },
    );
  },
});

export const { actions, reducer } = buyIntentionSlice;
