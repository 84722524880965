import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetSitesSelectQuery } from 'services/ecommerceApi';

interface Props extends SelectProps<number | ''> {
  allowEmpty?: boolean;
}
export default function SitesSelect({ allowEmpty = false, ...props }: Props) {
  const { t } = useTranslation();

  const { data: sites } = useGetSitesSelectQuery();

  return (
    <Select
      showSearch
      allowClear
      placeholder={t('generals.selectPlaceholder')}
      optionFilterProp="children"
      filterOption
      {...props}
    >
      {allowEmpty ? (
        <Select.Option key="all" value="">
          Todos
        </Select.Option>
      ) : null}
      {sites?.map((site) => (
        <Select.Option
          key={site.id}
          title={`${site.account} - ${site.corporateName}(${site.tradingName})`}
          value={site.id}
        >
          {`${site.account} - ${site.corporateName}(${site.tradingName})`}
        </Select.Option>
      ))}
    </Select>
  );
}
