import React from 'react';
import Screen from 'components/UI/Screen';
import Header from 'components/UI/Screen/Header';
import CategoriesFilter from 'components/UI/Screen/CategoriesFilter';
import Footer from 'components/UI/Screen/Footer';

type LoggedScreenProps = {
  content: React.ReactNode;
};

export function LoggedScreen({ content }: LoggedScreenProps) {
  return (
    <Screen
      header={<Header />}
      subHeader={<CategoriesFilter />}
      footer={<Footer />}
    >
      {content}
    </Screen>
  );
}

export default LoggedScreen;
