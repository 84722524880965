import { CheckCircleOutlined } from '@ant-design/icons';
import React from 'react';
import * as S from './styles';

type ModalConfirmationProps = {
  modalIsVisible: boolean;
  actionButtonCancel: () => void;
  actionButtonOk: () => void;
};

export default function ModalSuccessfulPurchase({
  modalIsVisible,
  actionButtonOk,
  actionButtonCancel,
}: ModalConfirmationProps) {
  return (
    <S.ModalConfirmation
      closable={false}
      visible={modalIsVisible}
      width="350px"
      footer={
        <S.ButtonsActions>
          <S.ButtonCancel onClick={actionButtonCancel}>Fechar</S.ButtonCancel>
          <S.ButtonOk onClick={actionButtonOk}>Meus pedidos</S.ButtonOk>
        </S.ButtonsActions>
      }
    >
      <S.ModalConfirmationHeader>
        <CheckCircleOutlined />
        <span className="title">Compra finalizada</span>
      </S.ModalConfirmationHeader>
      <S.ModalConfirmationBody>
        <p>
          Seu pedido está sendo processado, você pode acompanhar os status em{' '}
          &quot;<span style={{ fontWeight: 'bold' }}> Meus Pedidos </span>&quot;
        </p>
      </S.ModalConfirmationBody>
    </S.ModalConfirmation>
  );
}
