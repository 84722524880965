import { memo, RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Empty, Pagination, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { offerTypeMap } from 'enums/offerTypeMap';
import { ModeType } from 'screens/DirectedOffers/utils/enums/ModeType.enum';
import {
  PAGE_SIZE_OPTIONS,
  TOTAL_PAGE,
} from 'screens/DirectedOffers/utils/consts/Pagination.const';
import {
  getModePagination,
  setModePagination as setModePaginationSession,
} from 'services/config';
import ImageGallery from 'components/UI/ImageGallery';
import ProductOffer from 'model/ProductOffer';
import { getShowPinnedFilter } from 'store/state/offerFilters/offerFiltersSelectors';
import FilterPinned from 'components/UI/FilterPinned';
import ProductItem from '../ProductItem';
import Config from '../Config';
import {
  Content,
  TextLoadMore,
  LastRef,
  HideContainer,
  PaginationContainer,
  ContentRow,
} from './styles';

interface Props {
  products: ProductOffer[];
  mode: ModeType;
  productsRendered: boolean;
  showMoreItens: boolean;
  lastRef: RefObject<HTMLDivElement>;
  offerInserted(offerId: number, offerType: offerTypeMap): void;
  runInMobile: boolean;
  page: number;
  pageSize: number;
  pagesCount: number;
  onChangePagination(page: number, pageSize: number): void;
}

function ProductList({
  products,
  mode,
  lastRef,
  productsRendered,
  showMoreItens,
  offerInserted,
  runInMobile,
  page,
  pageSize,
  pagesCount,
  onChangePagination,
}: Props) {
  const openImage = useRef(() => null);
  const contentRef = useRef<HTMLDivElement>(null);

  const showPinnedFilter = useSelector(getShowPinnedFilter);

  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [modePagination, setModePagination] = useState(() => {
    if (isMobile) {
      return false;
    }
    const modePaginationForDesktop = getModePagination();
    if (modePaginationForDesktop) {
      return modePaginationForDesktop === 'true';
    }
    return true;
  });

  const loadingFC = useMemo(
    () => (
      <TextLoadMore>
        <Spin spinning size="small" />
        <p>Carregando itens...</p>
      </TextLoadMore>
    ),
    [],
  );

  const paginationTotal = useMemo(
    () => pagesCount * pageSize,
    [pagesCount, pageSize],
  );

  const handleOpenImage = useCallback((product: ProductOffer) => {
    setGalleryImages(product.imagesFormatted);
    setTimeout(() => {
      openImage.current();
    });
  }, []);

  const handleChangePagination = useCallback(
    (pageChanged: number, pageSizeChanged: number) => {
      onChangePagination(pageChanged, pageSizeChanged);
      contentRef.current?.scrollIntoView();
    },
    [onChangePagination],
  );

  const handleChangeSwitch = useCallback(
    (modePagination: boolean) => {
      if (page !== 1 || pageSize !== TOTAL_PAGE) {
        handleChangePagination(1, TOTAL_PAGE);
      }
      setModePagination(modePagination);
      setModePaginationSession(modePagination);
    },
    [page, pageSize, handleChangePagination],
  );

  return (
    <>
      <HideContainer>
        {galleryImages.length > 0 && (
          <ImageGallery
            images={galleryImages}
            currentImage={galleryImages[0]}
            alt="imagem do produto"
            maxHeight="150px"
            open={openImage}
          />
        )}
      </HideContainer>
      <ContentRow>
        {showPinnedFilter && <FilterPinned />}
        <Content
          ref={contentRef}
          className={products?.length ? '' : 'empty-data'}
          listMode={mode}
        >
          {products.map((product) => (
            <ProductItem
              key={product.id}
              product={product}
              mode={mode}
              openImage={handleOpenImage}
              offerInserted={offerInserted}
              runInMobile={runInMobile}
            />
          ))}

          {!productsRendered && loadingFC}
          {productsRendered && !products?.length && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {modePagination && (
            <PaginationContainer>
              <Pagination
                current={page}
                pageSize={pageSize}
                total={paginationTotal}
                onChange={handleChangePagination}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
              />
            </PaginationContainer>
          )}

          <LastRef
            ref={lastRef}
            hidden={!showMoreItens || !productsRendered || modePagination}
          >
            {loadingFC}
          </LastRef>
        </Content>
      </ContentRow>

      {!isMobile && (
        <Config
          modePagination={modePagination}
          changeSwitch={handleChangeSwitch}
        />
      )}
    </>
  );
}

export default memo(ProductList);
