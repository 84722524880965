import { Button } from 'antd';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;

  .ant-divider {
    margin: 12px 0 !important;
  }
`;

export const Body = styled.div`
  padding: 8px 0 16px 0;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    width: 100%;
  }
`;

export const PrimaryInformations = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const TitleInformation = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  margin-bottom: 8px;
  text-align: center;
`;

export const SecondaryInformation = styled.span`
  font-size: 12px;               
  font-weight: 400;
  color: #595959;
  margin-bottom: 8px;
`;

export const Observation = styled.span`
  font-size: 10px;
  font-weight: 400;
  color: #8c8c8c;
  margin-bottom: 8px;

  span {
    margin-right: 4px;
  }
`;

export const InformationGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonProduct = styled(Button)`
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  height: 100%;
`;

export const ProductKey = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #595959;
  width: 20%;
`;

export const InformationsProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 8px;
`;

export const LineInformation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 12px;
`;

export const InfoProduct = styled.span`
  font-size: 12px;               
  font-weight: 400;
  color: #353839;
`;

export const TitleProduct = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #353839;
  margin-bottom: 8px;
  margin-bottom: 8px;
  white-space: break-spaces;
`;