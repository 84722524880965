import { createSlice } from '@reduxjs/toolkit';

export type LoaderState = {
  loading: boolean;
};

export const initialState = { loading: false } as LoaderState;

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    show(state) {
      state.loading = true;
    },
    hide(state) {
      state.loading = false;
    },
  },
  extraReducers: {},
});

export const { actions, reducer } = loaderSlice;
