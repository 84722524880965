import { useCallback, useEffect } from 'react';

import { Header } from 'antd/lib/layout/layout';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Badge } from 'antd';
import { setOfferTypeProductList, setPageProductList } from 'services/config';
import {
  useGetDirectedOffersCountQuery,
  useGetMyOffersCountQuery,
  useGetProductCategoriesQuery,
} from 'services/ecommerceApi';
import { offerFiltersSelectors, sessionSelectors } from 'store/state/selectors';
import { offerFiltersActions } from 'store/state/slices';
import { RootState } from 'store/store';
import { redirectMyOffers } from 'utils/util';
import * as S from './styles';

export default function CategoriesFilter() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector(sessionSelectors.getAuthenticated);
  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );
  const { deliveryDate, producerUrl } = useSelector(sessionSelectors.getRoot);
  const { categories: [category] = [], offerTypes: offerType = [] } =
    useSelector((state: RootState) => state.offerFilters);
  const offersFilters = useSelector(offerFiltersSelectors.getOfferFilters);
  const isProducerAuthenticated = useSelector(
    sessionSelectors.isProducerAuthenticated,
  );
  const lengthItems = offerType?.length ?? 0;

  const { data: countDirectedOffers } = useGetDirectedOffersCountQuery(
    { deliveryDate, customerId: selectedCustomerId },
    { skip: !selectedCustomerId },
  );

  const { data: countMyOffers } = useGetMyOffersCountQuery(
    { deliveryDate, customerId: selectedCustomerId },
    { skip: !selectedCustomerId },
  );

  const filterCategory = useCallback(
    (category: string | number) => {
      setPageProductList(1);
      setOfferTypeProductList([]);
      dispatch(
        offerFiltersActions.filter({
          ...offersFilters,
          categories: [+category],
        }),
      );
      dispatch(
        offerFiltersActions.setFilterByHeader({ categories: [+category] }),
      );
      navigate(`/${producerUrl}/products`);
    },
    [dispatch, navigate, offersFilters, producerUrl],
  );

  const filterProducts = useCallback(
    (key: string | string[]) => {
      setPageProductList(1);
      const keyValue = Array.isArray(key) ? key : [key];
      dispatch(
        offerFiltersActions.filter({
          ...offersFilters,
          offerTypes: keyValue,
        }),
      );
      setOfferTypeProductList(keyValue);
      navigate(`/${producerUrl}/products`);
    },
    [dispatch, navigate, offersFilters, producerUrl],
  );

  const { data: productCategories } = useGetProductCategoriesQuery({});

  const isActive = useCallback(
    (condition: boolean) => location.pathname.includes('products') && condition,
    [],
  );

  useEffect(() => {
    if ((slug || slug === '') && +slug !== category) {
      filterCategory(slug);
    }
  }, []);

  return (
    <S.Root>
      <Header>
        <S.Container
          mode="horizontal"
          triggerSubMenuAction={isMobile ? 'click' : 'hover'}
        >
          {isAuthenticated && (
            <>
              <S.Category
                onClick={() => filterProducts([])}
                key="Todos"
                active={
                  isActive(
                    !offerType || !offerType.length || offerType.length >= 3,
                  )
                    ? 1
                    : 0
                }
                className={
                  isActive(
                    !offerType || !offerType.length || offerType.length >= 3,
                  )
                    ? 'active-filter'
                    : ''
                }
              >
                Todos
              </S.Category>

              <S.Category
                onClick={() => filterProducts(['inTransit', 'received'])}
                key="lkp"
                active={
                  isActive(
                    offerType.includes('inTransit') &&
                      offerType.includes('received') &&
                      lengthItems === 2,
                  )
                    ? 1
                    : 0
                }
                className={
                  isActive(
                    offerType.includes('inTransit') &&
                      offerType.includes('received') &&
                      lengthItems === 2,
                  )
                    ? 'active-filter'
                    : ''
                }
              >
                LKP
              </S.Category>

              <S.OfferTypeMenu
                onClick={() => filterProducts('onSite')}
                key="onSite"
                active={
                  isActive(offerType.includes('onSite') && lengthItems === 1)
                    ? 1
                    : 0
                }
                className={
                  offerType.includes('onSite') && lengthItems === 1
                    ? 'active-filter'
                    : ''
                }
              >
                Estoque no Produtor
              </S.OfferTypeMenu>
            </>
          )}

          {productCategories?.length &&
            productCategories.map((productCategory) => (
              <S.Category
                onClick={() => filterCategory(productCategory.id)}
                key={productCategory.description + productCategory.code}
                active={isActive(productCategory.id === category) ? 1 : 0}
                className={
                  isActive(productCategory.id === category)
                    ? 'active-filter'
                    : ''
                }
              >
                {productCategory.description}
              </S.Category>
            ))}

          {!isProducerAuthenticated && isAuthenticated && (
            <>
              <S.Category
                onClick={() => redirectMyOffers(producerUrl)}
                key="Ofertas exclusivas"
                active={
                  isActive(
                    !offerType || !offerType.length || offerType.length >= 3,
                  )
                    ? 1
                    : 0
                }
                className={
                  isActive(
                    !offerType || !offerType.length || offerType.length >= 3,
                  )
                    ? 'active-filter'
                    : ''
                }
              >
                <Badge size="small" count={countMyOffers} color="#FFC000">
                  <strong>Ofertas exclusivas</strong>
                </Badge>
              </S.Category>
              <S.Category
                onClick={() => filterProducts(['directed'])}
                key="Ofertas direcionadas"
                active={
                  isActive(
                    !offerType || !offerType.length || offerType.length >= 3,
                  )
                    ? 1
                    : 0
                }
                className={
                  isActive(
                    !offerType || !offerType.length || offerType.length >= 3,
                  )
                    ? 'active-filter'
                    : ''
                }
              >
                <Badge size="small" count={countDirectedOffers} color="#FFC000">
                  <strong>Ofertas direcionadas</strong>
                </Badge>
              </S.Category>
            </>
          )}
        </S.Container>
      </Header>
    </S.Root>
  );
}
