import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useGetProductRecipientsQuery } from 'services/ecommerceApi';
import { ProductRecipient, ProductSelectOption } from 'model/types';

interface Props extends SelectProps<number | ''> {
  allowEmpty?: boolean;
  filteredProducts?: ProductSelectOption[];
  siteId: number;
  productId: number;
}
export default function ProductRecipientsSelect({
  allowEmpty = false,
  filteredProducts,
  siteId,
  productId,
  ...props
}: Props) {
  const { t } = useTranslation();
  const [productRecipients, setProductRecipients] = useState<
    ProductRecipient[]
  >([]);

  const { data, error, isLoading } = useGetProductRecipientsQuery({
    siteId,
  });

  useEffect(() => {
    if (data?.length) {
      let filteredRecipients: ProductRecipient[] = [...data];
      if (filteredProducts?.length) {
        filteredRecipients = filteredRecipients.filter((recipient) =>
          recipient.productIds?.some((id) =>
            filteredProducts.some((product) => product.id === id),
          ),
        );
      }
      if (productId && !Number.isNaN(productId)) {
        filteredRecipients = filteredRecipients.filter((recipient) =>
          recipient.productIds?.some((id) => id === productId),
        );
      }
      setProductRecipients(filteredRecipients);
    }
  }, [data, filteredProducts, productId]);

  return (
    <Select
      showSearch
      allowClear
      loading={isLoading}
      placeholder={t('generals.selectPlaceholder')}
      optionFilterProp="children"
      filterOption
      {...props}
    >
      {allowEmpty ? (
        <Select.Option key="all" value="">
          Todos
        </Select.Option>
      ) : null}

      {!error
        ? productRecipients?.map((productRecipient) => (
            <Select.Option
              key={productRecipient.description}
              value={productRecipient.description}
            >
              {productRecipient.description}
            </Select.Option>
          ))
        : null}
    </Select>
  );
}
