import { DeliveryPatterns } from 'model/DeliveryPatterns';
import moment from 'moment';

export function deliveryDatePattern(siteDeliveryPatterns: DeliveryPatterns[]) {
  function nextDate(dayDate: number) {
    const orderEndDayNumbers = siteDeliveryPatterns.map(
      (deliveryPattern) => deliveryPattern.orderEndDayNumber,
    );
    if (!orderEndDayNumbers.includes(dayDate)) {
      orderEndDayNumbers.push(dayDate);
    }
    orderEndDayNumbers.sort((a, b) => a - b);
    const index = orderEndDayNumbers.findIndex((order) => order === dayDate);
    if (index === orderEndDayNumbers.length - 1) {
      const orderEndDayNumber = orderEndDayNumbers[0];
      return [orderEndDayNumber, orderEndDayNumber + 7 - dayDate];
    }
    const { orderEndDayNumber } = siteDeliveryPatterns.find(
      (deliveryPattern) =>
        deliveryPattern.orderEndDayNumber === orderEndDayNumbers[index + 1],
    )!;
    return [orderEndDayNumber, orderEndDayNumber - dayDate];
  }

  function getDeliveryDatesByDayDate(dayDate: number) {
    return siteDeliveryPatterns.filter(
      (deliveryPattern) => deliveryPattern.orderEndDayNumber === dayDate,
    );
  }

  function getHour(dayDate: number, date: Date = new Date()) {
    const [hour, minute, second] = [
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ];
    return moment.min().set({ day: dayDate, hour, minute, second });
  }

  function splitHour(hour: string) {
    return hour.split(':').map((value) => Number(value));
  }

  function getDifferenceBetweenOrderAndDelivery(
    deliveryDayNumber: number,
    orderEndDayNumber: number,
  ) {
    if (deliveryDayNumber >= orderEndDayNumber) {
      return deliveryDayNumber - orderEndDayNumber;
    }
    return deliveryDayNumber + 7 - orderEndDayNumber;
  }

  function getDeliveryDateByDatePatterns(dayDate: number) {
    const hourOfNow = getHour(dayDate);
    const deliverysPatternFoundedAux = getDeliveryDatesByDayDate(dayDate);

    if (deliverysPatternFoundedAux.length) {
      for (
        let index = 0;
        index < deliverysPatternFoundedAux.length;
        index += 1
      ) {
        const deliveryPattern = deliverysPatternFoundedAux[index];
        const [hour, minute, second] = splitHour(deliveryPattern.orderEndTime);
        const dateOrderEnd = moment
          .min()
          .set({ day: dayDate, hour, minute, second });
        if (dateOrderEnd.isAfter(hourOfNow)) {
          const [hourDeliveryTime, minuteDeliveryTime, secondDeliveryTime] =
            splitHour(deliveryPattern.deliveryTime);
          return moment()
            .add(
              getDifferenceBetweenOrderAndDelivery(
                deliveryPattern.deliveryDayNumber,
                deliveryPattern.orderEndDayNumber,
              ),
              'days',
            )
            .set({
              hour: hourDeliveryTime,
              minute: minuteDeliveryTime,
              second: secondDeliveryTime,
            });
        }
      }
    }

    const [dayNumber, differenceDaysToSum] = nextDate(dayDate);
    const nextDeliveryDate = getDeliveryDatesByDayDate(dayNumber)[0];
    const [hour, minute, second] = splitHour(nextDeliveryDate.deliveryTime);
    return moment()
      .add(
        differenceDaysToSum +
          getDifferenceBetweenOrderAndDelivery(
            nextDeliveryDate.deliveryDayNumber,
            nextDeliveryDate.orderEndDayNumber,
          ),
        'days',
      )
      .set({ hour, minute, second });
  }

  return {
    getDeliveryDateByDatePatterns,
  };
}
